import { apiVersions } from "../constants/apiVersions";
import { ITenantService } from "../interfaces/services/TenantService";
import { Tenant } from "../interfaces/locations/Tenant";
import { doRicsApiXhr } from "./XhrService";

const getCurrentTenant = (): Promise<Tenant> => {
  return new Promise((resolve, reject) => {
    const endpoint = `v${apiVersions.Default}/Tenants/Current`;

    const onSuccess = (response: string) => {
      const tenant: Tenant = JSON.parse(response);
      resolve(tenant);
    };

    const onFailure = () => {
      reject();
    };

    doRicsApiXhr(endpoint, null, onSuccess, onFailure);
  });
};

export const TenantService: ITenantService = {
  getCurrentTenant: getCurrentTenant,
};
