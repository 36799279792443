import { apiVersions } from "../constants/apiVersions";
import { IAuthService } from "../interfaces/services/AuthService";
import { Identity } from "../interfaces/users/User";
import { doRicsApiXhr } from "./XhrService";

const getIdentityByVerificationToken = (
  verificationToken: string,
  version: string
): Promise<Identity> => {
  return new Promise((resolve, reject) => {
    if (version == apiVersions.TwoDotOne) {
      getIdentityEntityByVerificationToken(
        verificationToken,
        version,
        resolve,
        reject
      );
      return;
    }

    getIdentityDocumentByVerificationToken(
      verificationToken,
      version,
      resolve,
      reject
    );
  });
};

const getIdentityDocumentByVerificationToken = (
  verificationToken: string,
  version: string,
  resolve: (identity: Identity) => void,
  reject: (reason?: string) => void
) => {
  const endpoint = `v${version}/Auth/GetIdentityByVerificationToken`;
  const payload = {
    VerificationToken: verificationToken,
  };

  const onSuccess = (response: string) => {
    const identityDocument = JSON.parse(response);
    const identity = {
      identityId: identityDocument.IdentityId,
      firstName: identityDocument.FirstName,
      lastName: identityDocument.LastName,
      emailAddress: identityDocument.EmailAddress,
      phoneNumber: identityDocument.PhoneNumber,
    } as Identity;
    resolve(identity);
  };

  const onFailure = () => {
    const reason = `Failed to get identity with verification token ${verificationToken}`;
    console.error(reason);
    reject(reason);
  };

  doRicsApiXhr(endpoint, payload, onSuccess, onFailure, "POST");
};

const getIdentityEntityByVerificationToken = (
  verificationToken: string,
  version: string,
  resolve: (identity: Identity) => void,
  reject: (reason?: string) => void
) => {
  const endpoint = `v${version}/Auth/GetIdentityByVerificationToken?verificationToken=${verificationToken}`;

  const onSuccess = (response: string) => {
    const identity: Identity = JSON.parse(response);
    resolve(identity);
  };

  const onFailure = () => {
    const reason = `Failed to get identity with verification token ${verificationToken}`;
    console.error(reason);
    reject(reason);
  };

  doRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
};

export const AuthService: IAuthService = {
  getIdentityByVerificationToken: getIdentityByVerificationToken,
};
