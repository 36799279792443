import React from "react";

import { Box, SxProps, Theme, Typography } from "@mui/material";
import { Location } from "../../interfaces/locations/Location";
import LocationInviteButtonComponent from "./LocationInviteButton";
import LocationProgressBarComponent from "./LocationProgressBar";
import { useTheme } from "@mui/material/styles";

interface LocationStatusProps {
  location: Location;
  clickToBegin: boolean;
  setShowModal: (showModal: boolean, location?: Location) => void;
}

const LocationStatusComponent: React.FC<LocationStatusProps> = ({
  location,
  setShowModal,
}) => {
  const theme = useTheme();
  const readyToInvite = () => {
    return (
      !location.setupWizard.isFinishAndInviteUsersCompleted &&
      location.setupWizard.arePaymentsSetup &&
      location.setupWizard.isBrandingSetup &&
      location.setupWizard.areUsersSetup &&
      location.setupWizard.areSettingsSetup &&
      location.setupWizard.areTaxesSetup
    );
  };

  const locationStatusStyles: Record<string, SxProps<Theme>> = {
    finishAndInviteButton: {
      backgroundColor: theme.palette.common.ricsPurple,
      p: "7 0 7",
      color: "white",
    },
    finishAndInviteIcon: {
      ml: 1,
    },
    progressBarHolder: {
      height: "61px",
      marginTop: "20px",
      paddingTop: "15px",
      borderTop: "1px solid #efefef",
    },
    progressBarTopText: {
      color: "#8c8e8f",
      opacity: 1,
      fontSize: "12px",
    },
    progressBar: {
      mt: "10px",
      display: "inline-block",
    },
  };

  return (
    <>
      {!location.isActive && (
        <Box sx={locationStatusStyles.progressBarHolder}>
          {readyToInvite() ?
            <LocationInviteButtonComponent
              location={location}
              setShowModal={setShowModal}
            />
          : <>
              <Box>
                <Typography sx={locationStatusStyles.progressBarTopText}>
                  Click to begin setup...
                </Typography>
              </Box>
              <Box sx={locationStatusStyles.progressBar}>
                <LocationProgressBarComponent
                  arePaymentsSetup={location.setupWizard.arePaymentsSetup}
                  areSettingsSetup={location.setupWizard.areSettingsSetup}
                  areTaxesSetup={location.setupWizard.areTaxesSetup}
                  areUsersSetup={location.setupWizard.areUsersSetup}
                  isBrandingSetup={location.setupWizard.isBrandingSetup}
                />
              </Box>
            </>
          }
        </Box>
      )}
    </>
  );
};

export default LocationStatusComponent;
