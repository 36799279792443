import moment from "moment";
import { eCommerceConstants } from "../constants/eCommerceConstants";
import {
  getRandomNumber,
  numberToStringWithPad,
} from "../helpers/numberHelpers";
import { EmailVerification } from "../interfaces/integrations/ecommerce/EmailVerification";
import { doRicsApiXhr } from "./XhrService";

export const sendVerificationEmail = async (
  email: string
): Promise<boolean> => {
  return new Promise((resolve) => {
    const onSuccess = () => resolve(true);

    const onFailure = () => resolve(false);

    const endpoint = "v1/ecommerce/email/verify";

    const code = getRandomNumber(0, 999999);
    const emailCode: EmailVerification = {
      code: numberToStringWithPad(code, 6),
      expiration: moment().add(10, "m").toDate(),
    };

    window.sessionStorage.setItem(
      eCommerceConstants.emailCodeKey,
      btoa(JSON.stringify(emailCode))
    );

    const payload = {
      Email: email,
      Code: code,
    };

    doRicsApiXhr(endpoint, payload, onSuccess, onFailure, "POST");
  });
};
