export const featureFlags = {
  Clutch: "clutch",
  IntegrationsPage: "integrations-page",
  AmazonIntegrationsPage: "amazon-integration-enablement",
  AdditionalReceiptInformation: "additional-receipt-information",
  UnlinkedRefunds: "next-gen-unlinked-refunds",
  ReceiptOptions: "receipt-options",
  CashCount: "cash-count",
  DeleteUser: "delete-user",
  LabelsPage: "portal-labels-page",
  StandAloneReturns: "stand-alone-returns",
  GlobalProductCatalogPage: "portal-global-product-catalog-page",
} as const;
