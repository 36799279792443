import React from "react";
import { useUsersContext } from "../../../providers/UsersProvider";
import { User } from "../../../interfaces/users/User";
import { Box, Checkbox, Grid, SxProps, Theme, Typography } from "@mui/material";
import AddUserRowComponent from "./AddUserRow";
export interface AddUserGridProps {
  unassignedUsers: User[];
  locationId?: string;
}

export const AddUserGridComponent: React.FC<AddUserGridProps> = ({
  unassignedUsers,
  locationId,
}) => {
  const { updateUser } = useUsersContext();

  const isAllUsersChecked = unassignedUsers.every(
    (u) => u.selectedToImport == true
  );

  const handleSelectAllCheckbox = () => {
    const isAnyUserSelectedToImport = unassignedUsers.some(
      (user) => user.selectedToImport
    );
    unassignedUsers.forEach((user) => {
      const updatedUser = {
        ...user,
        selectedToImport: !isAnyUserSelectedToImport,
      };
      updateUser(user.userId, updatedUser);
    });
  };

  const addUserGridStyles: Record<string, SxProps<Theme>> = {
    headerGrid: {
      lineHeight: "16px",
      alignItems: "center",
      textAlign: "center",
      background: "#ffffff",
      borderBottom: "2px solid #F4F4F6",
      width: "100%",
    },
    headerGridText: {
      fontSize: "14px",
      letterSpacing: "-0.5px",
      fontWeight: "bold",
    },
    addUserGridContainer: {
      overflowY: "auto",
      flex: "1 1 auto",
      letterSpacing: "-0.5px",
      alignItems: "center",
      maxWidth: "100%",
      width: "100%",
      textAlign: "center",
      scrollbarGutter: "stable",
    },
    addUserRowGrid: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      background: "#ffffff",
      color: "#7F7F7F",
      fontSize: "14px",
      letterSpacing: "-0.5px",
    },
  };

  return (
    <Box sx={addUserGridStyles.addUserGridContainer}>
      <Grid container sx={addUserGridStyles.headerGrid}>
        <Grid item xs={1}>
          <Checkbox
            checked={isAllUsersChecked}
            onChange={handleSelectAllCheckbox}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography noWrap sx={addUserGridStyles.headerGridText}>
            User ID
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={addUserGridStyles.headerGridText}>
            First Name
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={addUserGridStyles.headerGridText}>
            Last Name
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={addUserGridStyles.headerGridText}>Email</Typography>
        </Grid>
      </Grid>

      <Grid
        container
        data-testid="addUserRowGrid"
        sx={addUserGridStyles.addUserRowGrid}
      >
        {unassignedUsers.map((user, index) => {
          if (!user.isHidden) {
            return (
              <AddUserRowComponent
                key={index}
                locationId={locationId}
                user={user}
              />
            );
          }
        })}
      </Grid>
    </Box>
  );
};
