import { Box, Checkbox, Grid, SxProps, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Device } from "../../interfaces/devices/Device";
import { SelectableObject } from "../../interfaces/common/SelectableObject";
import { useDataService } from "../../providers/DataServiceProvider";
import { usePortal } from "../../providers/PortalProvider";
import DeviceActionButtonComponent from "./DeviceActionButton";
import DevicesGridRowComponent from "./DevicesGridRow";
import { DeviceLocation } from "../../interfaces/devices/DeviceLocation";

export interface DevicesGridProps {
  locationId?: string;
  setDevicesCount?: (count: number) => void;
}

const DevicesGridComponent: React.FC<DevicesGridProps> = ({
  locationId,
  setDevicesCount,
}) => {
  const portal = usePortal();
  const dataService = useDataService();
  const [selectableDevices, setSelectableDevices] = useState<
    Array<SelectableObject<Device>>
  >([]);
  const [isScrollbarVisible, setIsScrollbarVisible] = useState<boolean>(false);
  const devicesGridContainerRef = useRef<HTMLDivElement>(null);

  const checkScrollbarVisibility = () => {
    const element = devicesGridContainerRef.current;
    const hasScrollbar =
      element ? element.scrollHeight > element.clientHeight : false;
    setIsScrollbarVisible(hasScrollbar);
  };

  const sortDevicesByLastActive = (
    devices: Device[],
    locationId?: string
  ): Device[] => {
    return devices.sort((a, b) => {
      const getLastActiveTimestamp = (device: Device): number => {
        if (!device.deviceLocations || device.deviceLocations.length === 0) {
          return 0;
        }

        let latestTimestamp: number;

        if (locationId) {
          const specificLocationTimestamp = device.deviceLocations
            .filter((dl) => dl.locationId === locationId)
            .map((dl) => Date.parse(dl.lastActive || ""))[0];

          latestTimestamp = specificLocationTimestamp || 0;
        } else {
          latestTimestamp = Math.max(
            ...device.deviceLocations.map((dl) =>
              Date.parse(dl.lastActive || "")
            )
          );
        }

        return latestTimestamp || 0;
      };

      const aLastActive = getLastActiveTimestamp(a);
      const bLastActive = getLastActiveTimestamp(b);

      return bLastActive - aLastActive;
    });
  };

  const getTenantDevices = async () => {
    portal!.navigation.isLoading(true);
    try {
      const devicesResponse = await dataService.devices.getDevices();
      const filteredDevices =
        locationId ?
          devicesResponse.filter((device) =>
            device.deviceLocations.some(
              (deviceLocation: DeviceLocation) =>
                deviceLocation.locationId === locationId
            )
          )
        : devicesResponse;

      const sortedDevices = sortDevicesByLastActive(filteredDevices);

      setSelectableDevices(
        sortedDevices.map((d) => {
          const sd: SelectableObject<Device> = {
            selected: false,
            value: d,
          };
          return sd;
        })
      );

      if (setDevicesCount) {
        setDevicesCount(sortedDevices.length);
      }
    } catch (err) {
      alert("Unable to get devices.");
      console.error(err);
    } finally {
      portal!.navigation.isLoading(false);
    }
  };

  const onCheckAllChanged = (checked: boolean) => {
    setSelectableDevices((prev) =>
      prev.map((d) => {
        d.selected = checked;
        return d;
      })
    );
  };

  const onSelect = (deviceId: string, checked: boolean) => {
    setSelectableDevices((prev) =>
      prev.map((d) => {
        if (d.value.id === deviceId) {
          d.selected = checked;
        }

        return d;
      })
    );
  };

  const onActivate = () => {
    dataService.devices
      .activateDevices(
        selectableDevices.filter((d) => d.selected).map((d) => d.value.id),
        locationId
      )
      .then(getTenantDevices);
  };

  const onDelete = () => {
    dataService.devices
      .deleteDevices(
        selectableDevices.filter((d) => d.selected).map((d) => d.value.id),
        locationId
      )
      .then(getTenantDevices);
  };

  const onRevoke = () => {
    dataService.devices
      .declineDeviceActivations(
        selectableDevices.filter((d) => d.selected).map((d) => d.value.id),
        locationId
      )
      .then(getTenantDevices);
  };

  useEffect(() => {
    getTenantDevices();
  }, []);

  useEffect(() => {
    checkScrollbarVisibility();
    window.addEventListener("resize", checkScrollbarVisibility);
    return () => {
      window.removeEventListener("resize", checkScrollbarVisibility);
    };
  }, [selectableDevices]);

  const devicesGridStyles: Record<string, SxProps | undefined> = {
    headerGrid: {
      lineHeight: "16px",
      alignItems: "center",
      textAlign: "center",
      background: "#ffffff",
      borderBottom: "2px solid #F4F4F6",
      width: "100%",
      pr: isScrollbarVisible ? "17px" : "initial",
    },
    headerText: {
      fontSize: "14px",
      letterSpacing: "-0.5px",
      fontWeight: "bold",
    },
    devicesGridContainer: {
      overflowY: "auto",
      flex: "1 1 auto",
      letterSpacing: "-0.5px",
      alignItems: "center",
      width: "100%",
      textAlign: "center",
    },
    devicesRowGrid: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      background: "#ffffff",
      color: "#7F7F7F",
      fontSize: "14px",
      letterSpacing: "-0.5px",
    },
    deviceActionsButtonGridItem: {
      display: "flex",
      justifyContent: "center",
    },
  };

  return (
    <>
      <Grid container columns={24} sx={devicesGridStyles.headerGrid}>
        <Grid item xs={1}>
          <Checkbox
            checked={selectableDevices.every((s) => s.selected)}
            data-testid="checkAllCheckbox"
            onChange={(e, v) => onCheckAllChanged(v)}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography sx={devicesGridStyles.headerText}>Device Name</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={devicesGridStyles.headerText}>Device Type</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={devicesGridStyles.headerText}>Device ID</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={devicesGridStyles.headerText}>
            Requester Name
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={devicesGridStyles.headerText}>Last Active</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography sx={devicesGridStyles.headerText}>Status</Typography>
        </Grid>
        <Grid item sx={devicesGridStyles.deviceActionsButtonGridItem} xs={1}>
          <DeviceActionButtonComponent
            anyChecked={selectableDevices.filter((s) => s.selected).length > 0}
            rowButton={false}
            onActivate={onActivate}
            onDelete={onDelete}
            onRevoke={onRevoke}
          />
        </Grid>
      </Grid>
      <Box
        ref={devicesGridContainerRef}
        sx={devicesGridStyles.devicesGridContainer}
      >
        <Grid
          container
          columns={24}
          data-testid="devicesGridViewContainer"
          sx={devicesGridStyles.devicesRowGrid}
        >
          {selectableDevices.length > 0 &&
            selectableDevices.map((d) => {
              return (
                <DevicesGridRowComponent
                  key={d.value.id}
                  selectableDevice={d}
                  onSelect={onSelect}
                  onUpdate={getTenantDevices}
                />
              );
            })}
        </Grid>
      </Box>
    </>
  );
};

export default DevicesGridComponent;
