import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Box, SxProps, Theme, useTheme } from "@mui/material";

import React from "react";

import TenantLevelPageHeader from "../components/common/TenantLevelPageHeader";
import SettingsContentComponent from "../components/settings/SettingsContent";

interface SettingsPageProps {
  locationId?: string;
}

const SettingsPage: React.FC<SettingsPageProps> = ({ locationId }) => {
  const globalTheme = useTheme();

  const settingsStyles: Record<string, SxProps<Theme> | undefined> = {
    settingsBackgroundBox: {
      backgroundColor: globalTheme.palette.common.contentPageBackground,
      display: "flex",
      justifyContent: "center",
      width: "100%",
      flexGrow: 1,
    },
  };

  return (
    <>
      <TenantLevelPageHeader
        headerIcon={<SettingsOutlinedIcon />}
        headerText="Settings"
      />
      <Box sx={settingsStyles.settingsBackgroundBox}>
        <SettingsContentComponent locationId={locationId} />
      </Box>
    </>
  );
};
export default SettingsPage;
