import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";
import React from "react";

interface TextButtonProps {
  textColor?: string;
  hoverColor?: string;
  focusColor?: string;
}

const TextButtonComponent = styled(
  ({ ...props }: TextButtonProps & ButtonProps) => <Button {...props} />,
  {
    shouldForwardProp: (prop) =>
      !["textColor", "hoverColor", "focusColor"].includes(prop),
  }
)(({ textColor, focusColor, hoverColor }) => ({
  backgroundColor: "transparent",
  color: textColor,
  padding: 0,
  justifyContent: "start",
  "&:hover": {
    backgroundColor: "transparent",
    textDecoration: "underline",
    color: hoverColor,
  },
  "&:focus": {
    backgroundColor: "transparent",
    color: focusColor,
  },
}));

export default TextButtonComponent;
