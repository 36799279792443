import { UserPermissions } from "../interfaces/providers/ApplicationPortal";

export const claims = {
  Portal: "portal",
  Labels: "labels",
  Owner: "owner",
};

export const hasPermissionTo = (
  claim: string,
  permissions: Array<UserPermissions> | undefined
) => {
  if (!permissions) {
    return false;
  }

  const allClaims = permissions.flatMap((u) => u.claims);
  if (allClaims.some((c) => c == claim)) {
    return true;
  }

  return false;
};
