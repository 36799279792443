import styled from "@emotion/styled";
import { Switch, SwitchProps } from "@mui/material";
import React from "react";
import globalTheme from "../../theme/globalTheme";

const StyledSwitchComponent = styled(({ className, ...props }: SwitchProps) => (
  <>
    <Switch disableRipple {...props} classes={{ root: className }} />
  </>
))({
  height: "40px",
  width: "60px",
  "&.MuiSwitch-root": {
    margin: "-12px",
  },
  "&.MuiSwitch-root .MuiSwitch-thumb": {
    height: "12px",
    width: "12px",
  },
  "& .MuiSwitch-switchBase": {
    padding: "14px",
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-checked": {
      color: globalTheme.palette.common.white,
      "& + .MuiSwitch-track": {
        opacity: 1,
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
});

export default StyledSwitchComponent;
