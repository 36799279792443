import { AuthResponse } from "../interfaces/responses/AuthResponse";
import { paths } from "../constants/paths";
import { isEmpty } from "../helpers/stringHelpers";
import { ApplicationPortal } from "../interfaces/providers/ApplicationPortal";
import { getToken, logout } from "./DataService";
import { useNavigate } from "react-router-dom";
import { MessageNumberPayload } from "../interfaces/payloads/MessageNumberPayload";

const portal: ApplicationPortal = window.portal || {};
portal.common = portal.common || {};
portal.common.baseUrl = portal.common.baseUrl || "";

export const doRicsXhr = <T>(
  endpoint: string,
  payload: T | null,
  callback: ((response: string) => void) | null,
  failCallback: ((error: string) => void) | null
) => {
  const navigate = useNavigate();
  var xhr = new XMLHttpRequest();
  const xhrCallback = function (this: XMLHttpRequest) {
    if (this.readyState !== 4) {
      return;
    }

    if (this.status === 200) {
      var parsedResponse = JSON.parse(this.responseText);
      if (
        parsedResponse._header &&
        !parsedResponse._header.Success &&
        failCallback
      ) {
        failCallback(this.responseText);
      } else {
        if (callback) {
          callback(this.responseText);
        }
      }
    } else if (this.status === 202) {
      var requestNumber = (this.responseText && JSON.parse(this.responseText))
        ._header.MessageNumber;
      if (requestNumber) {
        setTimeout(() => {
          var asyncXhr = new XMLHttpRequest();
          xhr = asyncXhr;
          const payload = { MessageNumber: requestNumber };

          sendXhr("GetResult", payload);
        }, 5000);
        return;
      }
    } else if (this.status === 401) {
      logout();
      navigate(paths.Login);
    } else if (failCallback) {
      failCallback(this.responseText);
    }
  };

  const sendXhr = <U>(_endpoint: string, _payload: U | null) => {
    xhr.onreadystatechange = xhrCallback;
    xhr.timeout = 60 * 5 * 1000;

    xhr.open("POST", portal.common.baseUrl + _endpoint, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Token", getToken()!);

    xhr.addEventListener(
      "cancelXhr",
      () => {
        xhr.abort();
      },
      false
    );

    xhr.send((_payload && JSON.stringify(_payload)) || "");
  };

  sendXhr(endpoint, payload);
};

export const doRicsApiXhr = <T>(
  endpoint: string,
  payload: T,
  callback: (response: string) => void,
  failCallback: (response?: string) => void,
  verb = "GET"
) => {
  var xhr = new XMLHttpRequest();

  const sendXhr = <U>(
    _endpoint: string,
    _payload: U,
    token: string = getToken()!
  ) => {
    xhr.onreadystatechange = xhrCallback;
    xhr.timeout = 60 * 5 * 1000;

    var baseUrl = process.env.REACT_APP_API_URL;

    xhr.open(verb, baseUrl + _endpoint, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Token", token);

    xhr.addEventListener(
      "cancelXhr",
      () => {
        xhr.abort();
      },
      false
    );

    xhr.send((_payload && JSON.stringify(_payload)) || "");
  };

  var xhrCallback = function (this: XMLHttpRequest) {
    if (this.readyState !== 4) {
      return;
    }

    if (this.status === 200) {
      try {
        var parsedResponse = JSON.parse(this.responseText);
        if (
          parsedResponse._header &&
          !parsedResponse._header.Success &&
          failCallback
        ) {
          failCallback(this.responseText);
        } else {
          if (callback) {
            callback(this.responseText);
          }
        }
      } catch (e) {
        if (callback) {
          callback(this.responseText);
        }
      }
    } else if (this.status === 204) {
      if (callback) {
        callback(this.responseText);
      }
    } else if (this.status === 202) {
      var requestNumber = (this.responseText && JSON.parse(this.responseText))
        ._header.MessageNumber;
      if (requestNumber) {
        setTimeout(() => {
          var asyncXhr = new XMLHttpRequest();
          xhr = asyncXhr;

          sendXhr<MessageNumberPayload>("GetResult", {
            messageNumber: requestNumber,
          });
        }, 5000);
        return;
      }
    } else if (this.status === 401) {
      if (
        !portal.features.refreshTokenEnabled ||
        portal.state.xhrRetryAttempted
      ) {
        portal.state.xhrRetryAttempted = false;
        failCallback();
      } else {
        const onRefreshRetryCall = (token: string) =>
          sendXhr(endpoint, payload, token);

        portal.state.xhrRetryAttempted = true;
        refreshToken(onRefreshRetryCall, logout);
      }
    } else if (failCallback) {
      failCallback(this.responseText);
    }
  };

  sendXhr(endpoint, payload);
};

export const refreshToken = (
  successCallback: (token: string) => void,
  failCallback: () => void
) => {
  const refreshToken = window.localStorage.getItem("RefreshToken");
  let loginEmailAddress = window.localStorage.getItem("LoginEmailAddress");
  let currentUserEmailAddress = portal.state.currentUser?.emailAddress;

  const cancelRefresh = () => {
    failCallback();
    return;
  };

  if (isEmpty({ str: refreshToken })) {
    cancelRefresh();
  }

  if (isEmpty({ str: loginEmailAddress })) {
    if (!isEmpty({ str: currentUserEmailAddress })) {
      loginEmailAddress = currentUserEmailAddress;
    } else {
      cancelRefresh();
    }
  }

  const endpoint = "v1/Auth/RefreshToken";

  const payload = {
    EmailAddress: loginEmailAddress,
    RefreshToken: refreshToken,
  };

  const onSuccess = (response: string) => {
    const authResponse: AuthResponse = JSON.parse(response);
    window.localStorage.setItem("Token", authResponse.token);
    successCallback(authResponse.token);
  };

  doRicsApiXhr(endpoint, payload, onSuccess, failCallback, "PUT");
};

export const doRics9Xhr = <T>(
  token: string,
  endpoint: string,
  payload: T,
  callback: (response: string) => void,
  failCallback: (error?: string) => void
) => {
  const xhr = new XMLHttpRequest();
  const xhrCallback = function (this: XMLHttpRequest) {
    if (this.readyState !== 4) {
      return;
    }

    if (this.status === 200) {
      const parsedResponse = JSON.parse(this.responseText);
      if (parsedResponse._header && !parsedResponse._header.Success) {
        failCallback(this.responseText);
      } else {
        callback(this.responseText);
      }
    } else {
      failCallback(this.responseText);
    }
  };

  const sendXhr = <U>(_endpoint: string, _payload: U) => {
    xhr.onreadystatechange = xhrCallback;
    xhr.timeout = 60 * 5 * 1000;

    const baseUrl = process.env.REACT_APP_RICS9_URL;

    xhr.open("POST", baseUrl + _endpoint, true);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Token", token);

    xhr.addEventListener(
      "cancelXhr",
      () => {
        xhr.abort();
      },
      false
    );

    xhr.send(JSON.stringify(_payload));
  };

  sendXhr(endpoint, payload);
};

export const fileUploadXhr = (
  endpoint: string,
  payload: FormData,
  method: "POST",
  callback: (response: string) => void,
  failCallback: () => void
) => {
  const baseUrl = process.env.REACT_APP_API_URL;

  const xhr = new XMLHttpRequest();

  xhr.addEventListener("readystatechange", () => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200 || xhr.status === 204) {
        callback(xhr.responseText);
      } else {
        if (failCallback) {
          failCallback();
        }
      }
    }
  });

  xhr.open(method, baseUrl + endpoint);
  xhr.setRequestHeader("Token", getToken()!);
  xhr.send(payload);
};
