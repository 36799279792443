import React from "react";
import {
  Box,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@mui/material/";
import { useTheme } from "@mui/material/styles";
import { AdvancedTaxConfigurationTableRowComponent } from "./AdvancedTaxConfigurationTableRow";
import { InfoTooltipComponent } from "../../../common/InfoTooltip";
import { ProductTaxCondition } from "../../../../interfaces/settings/Taxes";

export interface AdvancedTaxConfigurationTableProps {
  productTaxConditions: ProductTaxCondition[];
  setProductTaxConditions: React.Dispatch<
    React.SetStateAction<ProductTaxCondition[]>
  >;
  selectedTaxId: string;
}

export const AdvancedTaxConfigurationTableComponent: React.FC<
  AdvancedTaxConfigurationTableProps
> = ({ productTaxConditions, setProductTaxConditions, selectedTaxId }) => {
  const globalTheme = useTheme();

  const updateProductTaxCondition = (
    id: string,
    updatedCondition: ProductTaxCondition
  ) => {
    setProductTaxConditions((prevConditions: ProductTaxCondition[]) =>
      prevConditions.map((condition) =>
        condition.id === id ? { ...condition, ...updatedCondition } : condition
      )
    );
  };

  const clearProductTaxCondition = (id: string) => {
    setProductTaxConditions((prevConditions) =>
      prevConditions.map((condition) =>
        condition.id === id ?
          {
            ...condition,
            productType: "",
            taxRate: undefined,
            threshold: undefined,
            onlyTaxAboveThreshold: undefined,
          }
        : condition
      )
    );
  };

  const advancedTaxConfigurationTableStyles: Record<string, SxProps<Theme>> = {
    productTypeCell: {
      width: "30%",
      fontSize: 12,
      fontWeight: "bold",
      borderRight: 1,
      borderColor: globalTheme.palette.common.inactive,
    },
    taxRateCell: {
      width: "15%",
      fontSize: 12,
      fontWeight: "bold",
      borderRight: 1,
      borderColor: globalTheme.palette.common.inactive,
    },
    thresholdCell: {
      width: "10%",
      fontSize: 12,
      fontWeight: "bold",
      borderRight: 1,
      borderColor: globalTheme.palette.common.inactive,
    },
    aboveThresholdCell: {
      width: "20%",
      fontSize: 12,
      fontWeight: "bold",
      borderRight: 1,
      borderColor: globalTheme.palette.common.inactive,
    },
    deleteCell: {
      width: "5%",
      fontSize: 12,
      fontWeight: "bold",
    },
    thresholdCellBox: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
    },
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={advancedTaxConfigurationTableStyles.productTypeCell}>
              Product Type
            </TableCell>
            <TableCell sx={advancedTaxConfigurationTableStyles.taxRateCell}>
              Tax Rate
            </TableCell>
            <TableCell sx={advancedTaxConfigurationTableStyles.thresholdCell}>
              <Box sx={advancedTaxConfigurationTableStyles.thresholdCellBox}>
                Threshold
                <InfoTooltipComponent
                  hoverText="The dollar amount over which you begin collecting sales tax."
                  placement="top"
                />
              </Box>
            </TableCell>
            <TableCell
              sx={advancedTaxConfigurationTableStyles.aboveThresholdCell}
            >
              <Box sx={advancedTaxConfigurationTableStyles.thresholdCellBox}>
                Only Tax Above Threshold
                <InfoTooltipComponent
                  hoverText="The tax will only be applied to the amount over the tax threshold."
                  placement="top"
                />
              </Box>
            </TableCell>

            <TableCell
              sx={advancedTaxConfigurationTableStyles.deleteCell}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productTaxConditions
            .filter((p) => p.taxId === selectedTaxId)
            .map((p) => (
              <AdvancedTaxConfigurationTableRowComponent
                clearProductTaxCondition={() => clearProductTaxCondition(p.id)}
                key={p.id}
                productTaxCondition={p}
                updateProductTaxCondition={(
                  updatedCondition: ProductTaxCondition
                ) => updateProductTaxCondition(p.id, updatedCondition)}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
