import React, { useEffect } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Button, List, Popover, SxProps, Theme } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { apiVersions } from "../../constants/apiVersions";
import { roleNames } from "../../constants/roles";
import { getVersion } from "../../helpers/apiVersionsHelpers";
import {
  getRoleDisplayName,
  getSelectedRoles,
} from "../../helpers/rolesHelpers";
import { Role } from "../../interfaces/users/Role";
import { User } from "../../interfaces/users/User";
import UserRoleSelectionItemComponent from "./UserRoleSelectionItem";

interface UserRolesPopoverProps {
  user: User;
  id: string;
  onUserChanged: (updatedUser: User) => void;
  errorOutline?: boolean;
}

const UserRolesPopoverComponent: React.FC<UserRolesPopoverProps> = ({
  user,
  onUserChanged,
  errorOutline,
}) => {
  const flags = useFlags();
  const [selectedRoles, setSelectedRoles] = React.useState<Array<Role>>(
    user.roles
  );
  const [userPopoverAnchor, setUserPopoverAnchor] =
    React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserPopoverAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setUserPopoverAnchor(null);
  };
  const isPopoverOpen = Boolean(userPopoverAnchor);
  useEffect(() => {
    setSelectedRoles(user.roles);
  }, [user.roles]);

  const onSelectRoles = (roles: Array<Role>) => {
    setSelectedRoles(roles);
    const updatedUser: User = { ...user };
    updatedUser.roles = roles;
    onUserChanged(updatedUser);
  };

  const onSelectedRoleChanged = (role: Role) => {
    const roles: Array<Role> =
      role.isSelected ?
        [...selectedRoles, role]
      : selectedRoles.filter((r) => r.id !== role.id);
    onSelectRoles(roles);
  };

  const hideRole = (role: Role) =>
    role.isHidden ||
    (role.name === roleNames.Inventory &&
      getVersion(flags) !== apiVersions.TwoDotOne &&
      getVersion(flags) !== apiVersions.TwoDotTwo) ||
    (role.name === roleNames.Buyer &&
      getVersion(flags) !== apiVersions.TwoDotTwo);

  const userRolesPopoverStyles: Record<string, SxProps> = {
    button: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      color: "primary.main",
      background: "hsla(236, 75%, 65%, 0.07)",
      borderRadius: "4px",
      padding: "5px",
      width: "134px",
      whiteSpace: "nowrap",
      margin: "auto",
      "&:hover": {
        backgroundColor: "hsla(236, 75%, 65%, 0.07)",
      },
      "&:focus": {
        backgroundColor: "hsla(236, 75%, 65%, 0.07)",
        color: "primary.main",
      },
    },
    buttonError: {
      border: "0.1px solid red",
    },
    userRolesPopoverPaper: {
      bgcolor: "white",
      width: "350px",
    },
  };

  return (
    <>
      <Button
        data-testid="userRolesPopoverButton"
        sx={
          {
            ...userRolesPopoverStyles.button,
            ...(errorOutline ? userRolesPopoverStyles.buttonError : {}),
          } as SxProps<Theme>
        }
        onClick={handleClick}
      >
        {getRoleDisplayName(selectedRoles)}
        <ExpandMoreIcon />
      </Button>
      <Popover
        PaperProps={{ sx: userRolesPopoverStyles.userRolesPopoverPaper }}
        anchorEl={userPopoverAnchor}
        data-testid="userRolesPopover"
        open={isPopoverOpen}
        onClose={handleClose}
      >
        <List disablePadding>
          {getSelectedRoles(selectedRoles).map((role) => {
            if (hideRole(role)) {
              return;
            }

            return (
              <UserRoleSelectionItemComponent
                key={role.id}
                role={role}
                onSelectedRoleChanged={(r) => onSelectedRoleChanged(r)}
              />
            );
          })}
        </List>
      </Popover>
    </>
  );
};

export default UserRolesPopoverComponent;
