import React from "react";
import {
  Card,
  CardContent,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactNode } from "react";

interface TaxOptionsCardProps {
  dataTestId: string;
  header: string;
  subheader: string;
  children?: ReactNode;
}

const LocationDetailsTaxesCardComponent: React.FC<TaxOptionsCardProps> = ({
  dataTestId,
  header,
  subheader,
  children,
}) => {
  const globalTheme = useTheme();

  const taxesCardComponentStyles: Record<string, SxProps<Theme>> = {
    card: {
      borderRadius: "16px",
    },
    headerText: {
      fontSize: "20px",
      color: globalTheme.palette.common.black,
    },
    subHeaderText: {
      fontSize: "14px",
      color: globalTheme.palette.common.gray,
    },
  };
  return (
    <Card data-testid={dataTestId} sx={taxesCardComponentStyles.card}>
      <CardContent>
        <Typography sx={taxesCardComponentStyles.headerText}>
          {header}
        </Typography>
        <Typography gutterBottom sx={taxesCardComponentStyles.subHeaderText}>
          {subheader}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

export default LocationDetailsTaxesCardComponent;
