import {
  Backdrop,
  Box,
  Button,
  Grid,
  Popover,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import React from "react";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { UserImportModalComponent } from "./UserImportModal";
import DownloadIcon from "@mui/icons-material/Download";
import { AddUserToLocationModalComponent } from "../locationDetails/users/AddUserToLocationModal";
export interface AddUserButtonProps {
  onImportSuccess: () => void;
  locationId?: string;
}

const AddUserButtonComponent: React.FC<AddUserButtonProps> = ({
  onImportSuccess,
  locationId,
}) => {
  const [addUserPopoverAnchor, setAddUserPopoverAnchor] =
    React.useState<HTMLButtonElement | null>(null);
  const [isUserImportOpen, setIsUserImportOpen] = React.useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = React.useState(false);

  const isPopoverOpen = Boolean(addUserPopoverAnchor);
  const handleImportUserClick = () => {
    setIsUserImportOpen(true);
    handleAddUserPopoverClose();
  };
  const handleAddExistingUserClick = () => {
    setIsAddUserModalOpen(true);
    handleAddUserPopoverClose();
  };
  const handleAddUserPopoverClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAddUserPopoverAnchor(event.currentTarget);
  };

  const handleAddUserPopoverClose = () => {
    setAddUserPopoverAnchor(null);
  };

  const addUserButtonStyles: Record<string, SxProps<Theme> | undefined> = {
    addUserButton: {
      bgcolor: "white",
      pr: 1,
    },
    buttonBox: {
      display: "flex",
      flexDirection: "column",
      gap: 1,
      p: 1,
    },
    buttonGrid: {
      alignItems: "center",
      spacing: 1,
    },
    buttonGridIconItem: {
      pr: 1,
      position: "relative",
      top: "4px",
    },
    headerButton: {
      ml: 1,
      mr: 1,
    },
  };

  return (
    <>
      <Button
        data-testid="addUserButton"
        sx={addUserButtonStyles.headerButton}
        variant="contained"
        onClick={handleAddUserPopoverClick}
      >
        Add User
      </Button>
      <Backdrop
        open={isPopoverOpen}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        onClick={handleAddUserPopoverClose}
      />
      <Popover
        PaperProps={{ sx: addUserButtonStyles.addUserButton }}
        anchorEl={addUserPopoverAnchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        data-testid="addUserPopover"
        open={isPopoverOpen}
        onClose={handleAddUserPopoverClose}
      >
        <Box sx={addUserButtonStyles.buttonBox}>
          {locationId && (
            <Button onClick={handleAddExistingUserClick}>
              <Grid container sx={addUserButtonStyles.buttonGrid}>
                <Grid item sx={addUserButtonStyles.buttonGridIconItem}>
                  <AccountCircleOutlinedIcon />
                </Grid>
                <Grid item>
                  <Typography>Add An Existing User</Typography>
                </Grid>
              </Grid>
            </Button>
          )}
          <Button onClick={handleImportUserClick}>
            <Grid container sx={addUserButtonStyles.buttonGrid}>
              <Grid item sx={addUserButtonStyles.buttonGridIconItem}>
                <DownloadIcon />
              </Grid>
              <Grid item>
                <Typography>Import User from RICS 9</Typography>
              </Grid>
            </Grid>
          </Button>
        </Box>
      </Popover>
      <UserImportModalComponent
        locationId={locationId}
        setUserImportOpen={setIsUserImportOpen}
        userImportOpen={isUserImportOpen}
        onImportSuccess={onImportSuccess}
      />
      {locationId && (
        <AddUserToLocationModalComponent
          isAddUserModalOpen={isAddUserModalOpen}
          locationId={locationId}
          setIsAddUserModalOpen={setIsAddUserModalOpen}
        />
      )}
    </>
  );
};

export default AddUserButtonComponent;
