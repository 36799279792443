import React, { useEffect } from "react";
import { Role } from "../../interfaces/users/Role";
import { Checkbox, Grid, ListItem, ListItemText, SxProps } from "@mui/material";

interface UserRoleSelectionItemProps {
  role: Role;
  onSelectedRoleChanged: (role: Role) => void;
}

const UserRoleSelectionItemComponent: React.FC<UserRoleSelectionItemProps> = ({
  role,
  onSelectedRoleChanged,
}) => {
  const [isSelected, setIsSelected] = React.useState(role.isSelected ?? false);

  useEffect(() => {
    setIsSelected(role.isSelected ?? false);
  }, [role.isSelected]);

  const onCheckboxChange = (checked: boolean) => {
    setIsSelected(checked);
    role.isSelected = checked;
    onSelectedRoleChanged(role);
  };

  const userRoleSelectionItemStyles: Record<string, SxProps> = {
    checkboxGrid: {
      mr: 2,
    },
    roleTitle: {
      fontSize: 14,
      fontWeight: "bold",
      letterSpacing: "-0.5px",
      lineHeight: "20px",
      width: "100%",
    },
    roleDescription: {
      fontSize: 14,
      letterSpacing: "-0.5px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.5)",
    },
    titleListItem: {
      pb: 0,
    },
    descriptionListItem: {
      pb: 0,
      pt: 0,
    },
  };

  return (
    <Grid container>
      <Grid container item alignItems="center" xs={12}>
        <Grid item xs={1} />
        <Grid item xs>
          <ListItem sx={userRoleSelectionItemStyles.titleListItem}>
            <ListItemText
              primaryTypographyProps={{
                sx: userRoleSelectionItemStyles.roleTitle,
              }}
            >
              {role.displayName}
            </ListItemText>
          </ListItem>
        </Grid>
      </Grid>
      <Grid container item alignItems="center" xs={12}>
        <Grid item xs={1}>
          <Checkbox
            checked={isSelected}
            key={role.name}
            size="small"
            onChange={(e) => onCheckboxChange(e.target.checked)}
          />
        </Grid>
        <Grid item xs>
          <ListItem sx={userRoleSelectionItemStyles.descriptionListItem}>
            <ListItemText
              primaryTypographyProps={{
                sx: userRoleSelectionItemStyles.roleDescription,
              }}
            >
              {role.description}
            </ListItemText>
          </ListItem>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserRoleSelectionItemComponent;
