import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { roundingRuleIds } from "../../../constants/saleSettingsConstants";
import { SaleSettings } from "../../../interfaces/salesettings/SaleSettings";
import { usePortal } from "../../../providers/PortalProvider";
import LeftRightContentComponent from "../../common/LeftRightContent";
import StyledSwitchComponent from "../../styled/StyledSwitch";
import { handleLocationsOverrideChange } from "../LocationsCanOverrideToggle";
import SettingsCardComponent from "./SettingsCard";

export interface PaymentDetailsProps {
  id: string;
  locationSettings: SaleSettings;
  onSaveSettingsCard: (
    settingsToSave: SaleSettings,
    modifySettings?: (settings: SaleSettings, locationId?: string) => void
  ) => Promise<void>;
  locationId?: string;
}

const PaymentDetailsComponent: React.FC<PaymentDetailsProps> = ({
  id,
  locationSettings,
  onSaveSettingsCard,
  locationId,
}) => {
  const portal = usePortal();
  const globalTheme = useTheme();

  const [canSave, setCanSave] = useState(false);

  const [canLocationsOverride, setCanLocationsOverride] = useState(
    portal!.state.saleSettings["Tenant"].locationsCanOverridePaymentDetails ??
      true
  );

  const [tenderTypeOptions, setTenderTypeOptions] = React.useState(
    locationSettings.tenderTypeOptions
  );
  const [returnMethods, setReturnMethods] = React.useState(
    locationSettings.returnMethods
  );

  const [roundingRuleId, setRoundingRuleId] = React.useState(
    locationSettings.roundingRule
  );
  const [isNoTendersErrorVisible, setShowIsNoTendersErrorVisible] = useState(
    locationSettings.tenderTypeOptions?.every((option) => !option.enabled)
  );

  const initialRoundingRuleId = locationSettings.roundingRule;
  const canLocationsOverrideInitial =
    portal!.state.saleSettings["Tenant"].locationsCanOverridePaymentDetails ??
    true;
  const [isTenderTypesUpdated, setIsTenderTypesUpdated] = useState(false);
  const [isRoundingRuleUpdated, setIsRoundingRuleUpdated] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const canSaveCheck = () => {
    const isAnyOptionEnabled = tenderTypeOptions!.some(
      (option) => option.enabled
    );

    const hasCanLocationsOverrideChanged =
      canLocationsOverride !== canLocationsOverrideInitial;
    setShowIsNoTendersErrorVisible(!isAnyOptionEnabled);
    return (
      isAnyOptionEnabled &&
      (isTenderTypesUpdated ||
        isRoundingRuleUpdated ||
        hasCanLocationsOverrideChanged)
    );
  };

  useEffect(() => {
    setCanSave(canSaveCheck());
  }, [tenderTypeOptions, roundingRuleId, canLocationsOverride]);

  useEffect(() => {}, [tenderTypeOptions]);

  useEffect(() => {
    const clutchTenderOption = tenderTypeOptions!.find(
      (option) =>
        option.tenderTypeOptionId === "GiftCard" &&
        option.tenderTypeOptionSubTypeId === "Clutch"
    );

    if (clutchTenderOption) {
      const updatedReturnMethods = returnMethods!.map((method) => {
        if (
          method.returnMethodId === "GiftCard" &&
          method.returnMethodSubTypeId === "Clutch"
        ) {
          return { ...method, enabled: clutchTenderOption.enabled };
        }
        return method;
      });

      if (
        JSON.stringify(returnMethods) !== JSON.stringify(updatedReturnMethods)
      ) {
        setReturnMethods(updatedReturnMethods);
      }
    }
  }, [tenderTypeOptions, returnMethods]);

  useEffect(() => {
    setIsDisabled(!!locationId && !canLocationsOverride);
  }, [canLocationsOverride, locationId]);

  const handleSaveSettings = () => {
    const settingsToSave: SaleSettings = {
      tenderTypeOptions: tenderTypeOptions,
      returnMethods: returnMethods,
      roundingRule: roundingRuleId,
      locationId: locationId,
    };

    const modifySettings = (settings: SaleSettings) => {
      if (!locationId) {
        settings.locationsCanOverridePaymentDetails = canLocationsOverride;
      } else {
        if (settings.deviceSettings) {
          settings.deviceSettings.locationsCanOverride = undefined;
        }
        settings.locationsCanOverridePaymentDetails = undefined;
        settings.locationsCanOverrideRefunds = undefined;
      }
    };

    onSaveSettingsCard(settingsToSave, modifySettings);
    setCanSave(false);
  };

  const onUpdateItems = (index: number) => {
    setTenderTypeOptions((tenderTypeOptions) => {
      const updatedOptions = [...tenderTypeOptions!];
      updatedOptions[index] = {
        ...updatedOptions[index],
        enabled: !updatedOptions[index].enabled,
      };
      return updatedOptions;
    });
    setIsTenderTypesUpdated(true);
  };

  const updateSelectRounding = (rule: string) => {
    if (rule !== initialRoundingRuleId) {
      setIsRoundingRuleUpdated(true);
    }
    setRoundingRuleId(rule);
  };

  const paymentDetailsStyles: Record<string, SxProps<Theme>> = {
    clutchText: {
      fontSize: "12px",
      textAlign: "center",
      color: "red",
    },
    errorTypography: {
      color: "red",
      textAlign: "center",
    },
    roundingSecondaryText: {
      color: globalTheme.palette.common.gray,
    },
    tenderTypeToggleBox: {
      padding: "8px",
      display: "flex",
      alignItems: "center",
    },
    tenderTypeOptionText: {
      fontSize: "14px",
      ml: 1,
    },
  };

  return (
    <SettingsCardComponent
      canSave={canSave}
      disabled={isDisabled}
      headerText="Payment Details"
      id={id}
      locationsCanOverrideToggleProps={{
        locationsCanOverride: canLocationsOverride,
        onChange: handleLocationsOverrideChange(setCanLocationsOverride),
        disabled: !!locationId,
      }}
      subHeaderText="Specify supported tender, currency type, and rounding rules."
      onSave={handleSaveSettings}
    >
      <Box>
        <LeftRightContentComponent
          leftHeading="Tender Types"
          rightHeading="How can customers pay at the register?"
        >
          <Box>
            <Grid container spacing={1}>
              {tenderTypeOptions!.map((tenderTypeOption, index) => (
                <Grid
                  item
                  key={
                    tenderTypeOption.tenderTypeOptionId +
                    tenderTypeOption.tenderTypeOptionSubTypeId
                  }
                  xs={6}
                >
                  <Box sx={paymentDetailsStyles.tenderTypeToggleBox}>
                    <StyledSwitchComponent
                      checked={tenderTypeOption.enabled}
                      data-testid={`tenderTypeToggle-${tenderTypeOption.tenderTypeOptionId}-${tenderTypeOption.tenderTypeOptionSubTypeId}`}
                      disabled={isDisabled}
                      onChange={() => onUpdateItems(index)}
                    />
                    <Typography sx={paymentDetailsStyles.tenderTypeOptionText}>
                      {tenderTypeOption.tenderTypeOptionSubTypeLabel ??
                        tenderTypeOption.tenderTypeOptionLabel}
                    </Typography>
                  </Box>
                  {tenderTypeOption.tenderTypeOptionId === "GiftCard" &&
                    tenderTypeOption.tenderTypeOptionSubTypeId === "Clutch" &&
                    tenderTypeOption.enabled &&
                    !locationId && (
                      <Grid item xs={6}>
                        <Typography sx={paymentDetailsStyles.clutchText}>
                          Configure at Location(s)
                        </Typography>
                      </Grid>
                    )}
                </Grid>
              ))}
            </Grid>
          </Box>
        </LeftRightContentComponent>
        {isNoTendersErrorVisible && (
          <Typography sx={paymentDetailsStyles.errorTypography}>
            Please select at least one tender type option.
          </Typography>
        )}
      </Box>

      <LeftRightContentComponent
        leftHeading="Rounding Rules"
        rightHeading="Sometimes discounts cause transaction subtotals to fall on fractions of a cent. In these cases, the system will round the subtotal according to the rule you specify below."
      >
        <FormControl disabled={isDisabled}>
          <RadioGroup
            value={roundingRuleId}
            onChange={(e) => updateSelectRounding(e.target.value)}
          >
            <FormControlLabel
              control={<Radio data-testid="roundNearestRadioButton" />}
              label={
                <>
                  <Typography component="span">
                    Round Nearest (Most Common){" "}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    component="span"
                    variant="body2"
                  >
                    (e.g. $50.096 → $50.10 or $50.093 → $50.09)
                  </Typography>
                </>
              }
              value={roundingRuleIds.nearest}
            />
            <FormControlLabel
              control={<Radio />}
              label={
                <>
                  <Typography component="span">Round Up </Typography>
                  <Typography
                    color="textSecondary"
                    component="span"
                    variant="body2"
                  >
                    (e.g. $50.096 → $50.10 or $50.093 → $50.10)
                  </Typography>
                </>
              }
              value={roundingRuleIds.up}
            />
            <FormControlLabel
              control={<Radio />}
              label={
                <>
                  <Typography component="span">Round Down </Typography>
                  <Typography
                    color="textSecondary"
                    component="span"
                    variant="body2"
                  >
                    (e.g. $50.096 → $50.09)
                  </Typography>
                </>
              }
              value={roundingRuleIds.down}
            />
            <FormControlLabel
              control={<Radio />}
              label={
                <>
                  <Typography component="span">Round Nearest 5¢ </Typography>
                  <Typography
                    color="textSecondary"
                    component="span"
                    variant="body2"
                  >
                    (e.g. $50.03 → $50.05)
                  </Typography>
                </>
              }
              value={roundingRuleIds.nearestFiveCents}
            />
          </RadioGroup>
        </FormControl>
      </LeftRightContentComponent>
    </SettingsCardComponent>
  );
};

export default PaymentDetailsComponent;
