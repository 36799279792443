import React, { useEffect, useState } from "react";
import { Grid, SxProps, Theme } from "@mui/material";
import ImageSearchRollerIcon from "@mui/icons-material/ImagesearchRollerOutlined";
import { usePortal } from "../../../providers/PortalProvider";
import LocationDetailsBrandingCardComponent from "./LocationDetailsBrandingCard";
import { BrandingSettings } from "../../../interfaces/settings/BrandingSettings";
import LocationDetailsPageComponent from "../LocationDetailsPage";

export interface LocationDetailsBrandingProps {
  locationId: string;
}

const LocationDetailsBrandingComponent: React.FC<
  LocationDetailsBrandingProps
> = ({ locationId }) => {
  const portal = usePortal();

  const [brandingSettings, setBrandingSettings] = useState<BrandingSettings>({
    additionalReceiptInformation: false,
    id: "",
    locationId: locationId,
    logos: { smallLogoUrl: "", largeLogoUrl: "" },
    receiptFooterText: "",
    receiptIntroText: "",
  });
  const [isBrandingLoaded, setIsBrandingLoaded] = useState(false);

  useEffect(() => {
    const loadBrandingSettings = () => {
      let settings: BrandingSettings = portal?.state.brandingSettings[
        locationId
      ] ||
        portal?.state.brandingSettings["Tenant"] || {
          additionalReceiptInformation: false,
          id: "",
          locationId: locationId,
          logos: {
            smallLogoUrl: portal?.state.currentLocation.imageUrl,
            largeLogoUrl: "",
          },
          receiptFooterText: getConfigurationFooterText(),
          receiptIntroText: getConfigurationIntroText(),
          additionalReceiptText: "",
          locationsCanOverride: true,
        };
      setBrandingSettings(settings);
    };

    loadBrandingSettings();
    setIsBrandingLoaded(true);
  }, [locationId]);

  const getConfigurationIntroText = () => {
    if (portal?.configurations.receiptIntroText) {
      return getConfigurationText(portal.configurations.receiptIntroText);
    }
    return "";
  };

  const getConfigurationFooterText = () => {
    if (portal?.configurations.receiptFooterText) {
      return getConfigurationText(portal.configurations.receiptFooterText);
    }
    return "";
  };

  const getConfigurationText = (configurationName: string) => {
    const configurations =
      portal?.configurations.configurations[configurationName];
    if (configurations) {
      for (const config of configurations) {
        if (
          config.locationId &&
          config.configurationValue &&
          config.locationId.includes(portal.state.currentLocation.locationId)
        ) {
          return config.configurationValue;
        }
      }
    }
    return "";
  };

  const locationDetailsBrandingStyles: Record<string, SxProps<Theme>> = {
    cardItem: {
      width: "50%",
      paddingTop: 4,
    },
  };

  return (
    <LocationDetailsPageComponent
      dataTestId="locationDetailsBranding"
      headerIcon={<ImageSearchRollerIcon />}
      headerText="Branding Manager"
    >
      <Grid container alignItems="center" direction="column">
        <Grid item sx={locationDetailsBrandingStyles.cardItem} xs={12}>
          {isBrandingLoaded && (
            <LocationDetailsBrandingCardComponent
              brandingSettings={brandingSettings}
              locationId={locationId}
            />
          )}
        </Grid>
      </Grid>
    </LocationDetailsPageComponent>
  );
};
export default LocationDetailsBrandingComponent;
