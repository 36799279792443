import { DraggableProvided, DraggableStateSnapshot } from "@hello-pangea/dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import {
  Grid,
  IconButton,
  ListItem,
  MenuItem,
  Select,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { inventoryBehaviors } from "../../../../constants/settings/refunds/inventoryBehaviors";
import { ReturnReasons } from "../../../../interfaces/salesettings/ReturnReasons";
import StyledSwitchComponent from "../../../styled/StyledSwitch";

interface ReturnReasonListItemProps {
  returnReason: ReturnReasons;
  index: number;
  isOptionDisabled: boolean;
  updateReturnReasons: (index: number, changes: Partial<ReturnReasons>) => void;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
}

export const ReturnReasonItemComponent: React.FC<ReturnReasonListItemProps> = ({
  returnReason,
  index,
  isOptionDisabled,
  updateReturnReasons,
  provided,
  snapshot,
}) => {
  const globalTheme = useTheme();

  const returnReasonItemStyles: Record<string, SxProps<Theme>> = {
    behaviorSelectItem: {
      fontSize: "12px",
      height: "40px",
      width: "175px",
      ".MuiSelect-select": {
        paddingTop: "5px",
        paddingBottom: "5px",
      },
    },
    dragIconButton: {
      "&:hover": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
      "&:active": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
    },
    returnReasonsOptionText: {
      ml: 1,
      fontSize: "14px",
    },
  };

  const getBehaviorListItemStyle = (isDragging: boolean): SxProps<Theme> => ({
    borderRadius: "8px",
    boxShadow: isDragging ? "0px 0px 10px rgba(0, 0, 0, 0.2)" : "none",
    backgroundColor: isDragging ? globalTheme.palette.primary.main : "inherit",
    "&:hover": {
      backgroundColor: isOptionDisabled ? "inherit" : "#eaeaea",
      boxShadow: isOptionDisabled ? "none" : "0px 4px 8px rgba(0, 0, 0, 0.1)",
    },
  });
  return (
    <ListItem
      ref={provided.innerRef}
      {...provided.draggableProps}
      sx={{
        ...provided.draggableProps.style,
        ...getBehaviorListItemStyle(snapshot.isDragging),
      }}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={1}>
          <IconButton
            sx={returnReasonItemStyles.dragIconButton}
            {...provided.dragHandleProps}
            disableRipple
            disabled={isOptionDisabled}
          >
            <DragIndicatorIcon />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <StyledSwitchComponent
            checked={returnReason.enabled}
            disabled={isOptionDisabled}
            onChange={() =>
              updateReturnReasons(index, {
                enabled: !returnReason.enabled,
              })
            }
          />
        </Grid>
        <Grid item xs={5}>
          <Typography sx={returnReasonItemStyles.returnReasonsOptionText}>
            {returnReason.returnReasonLabel}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Select
            fullWidth
            disabled={isOptionDisabled}
            sx={returnReasonItemStyles.behaviorSelectItem}
            value={returnReason.inventoryBehavior}
            onChange={(e) => {
              const selectedId = e.target
                .value as keyof typeof inventoryBehaviors;
              const selectedBehavior = inventoryBehaviors[selectedId];
              updateReturnReasons(index, {
                inventoryBehavior: selectedId,
                inventoryBehaviorLabel: selectedBehavior.label,
              });
            }}
          >
            {Object.entries(inventoryBehaviors).map(([id, { label }]) => (
              <MenuItem key={id} value={id}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </ListItem>
  );
};
