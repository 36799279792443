import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
  Box,
  Divider,
  List,
  ListItemButton,
  Popover,
  SxProps,
  Typography,
} from "@mui/material";
import React from "react";
import { useTheme } from "@mui/material/styles";

export interface DeviceActionButtonProps {
  anyChecked?: boolean;
  rowButton: boolean;
  deviceIsActive?: boolean;
  onDelete?: () => void;
  onRevoke?: () => void;
  onActivate?: () => void;
}

const DeviceActionButtonComponent: React.FC<DeviceActionButtonProps> = ({
  anyChecked,
  rowButton,
  deviceIsActive,
  onDelete,
  onRevoke,
  onActivate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const theme = useTheme();

  const isOpen = Boolean(anchorEl);
  const id = isOpen ? "deviceActionsButton" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deviceActionButtonStyles: Record<string, SxProps | undefined> = {
    deviceActionsButton: {
      backgroundColor: "#EDEDED",
      borderRadius: "100%",
      width: "24px",
      height: "24px",
      ":hover": {
        cursor: "pointer",
      },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    deviceActionsIcon: {
      fontSize: "large",
    },
    actionButtonsPopoverContent: {
      backgroundColor: "#ededed",
      p: 0,
    },
    actionButton: {
      color: "black",
      p: 2,
    },
    deleteButton: {
      color: theme.palette.common.delete,
      p: 2,
    },
    actionButtonIcon: {
      fontSize: "medium",
    },
    actionButtonText: {
      fontSize: "12px",
      fontWeight: 600,
      marginLeft: "8px",
    },
  };

  return (
    <>
      <Box
        aria-describedby={id}
        data-testid="devicesPopoverButton"
        sx={deviceActionButtonStyles.deviceActionsButton}
        onClick={handleClick}
      >
        <MoreVertOutlinedIcon sx={deviceActionButtonStyles.deviceActionsIcon} />
      </Box>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        data-testid="devicesPopover"
        id={id}
        open={isOpen}
        onClose={handleClose}
      >
        <List sx={deviceActionButtonStyles.actionButtonsPopoverContent}>
          <ListItemButton
            data-testid="devicesRevokeButton"
            disabled={
              (!anyChecked && !rowButton) || (rowButton && !deviceIsActive)
            }
            sx={deviceActionButtonStyles.actionButton}
            onClick={() => {
              onRevoke && onRevoke();
              handleClose();
            }}
          >
            <CloseOutlinedIcon sx={deviceActionButtonStyles.actionButtonIcon} />
            <Typography sx={deviceActionButtonStyles.actionButtonText}>
              Revoke Access{rowButton ? "" : " for selected"}
            </Typography>
          </ListItemButton>
          <Divider />
          <ListItemButton
            data-testid="devicesActivateButton"
            disabled={
              (!anyChecked && !rowButton) || (rowButton && deviceIsActive)
            }
            sx={deviceActionButtonStyles.actionButton}
            onClick={() => {
              onActivate && onActivate();
              handleClose();
            }}
          >
            <CheckOutlinedIcon sx={deviceActionButtonStyles.actionButtonIcon} />
            <Typography sx={deviceActionButtonStyles.actionButtonText}>
              Activate{rowButton ? "" : " selected"}
            </Typography>
          </ListItemButton>
          <Divider />
          <ListItemButton
            data-testid="devicesDeleteButton"
            disabled={!anyChecked && !rowButton}
            sx={deviceActionButtonStyles.deleteButton}
            onClick={() => {
              onDelete && onDelete();
              handleClose();
            }}
          >
            <DeleteForeverOutlinedIcon
              sx={deviceActionButtonStyles.actionButtonIcon}
            />
            <Typography sx={deviceActionButtonStyles.actionButtonText}>
              Delete{rowButton ? "" : " selected"}
            </Typography>
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
};

export default DeviceActionButtonComponent;
