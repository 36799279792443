import { MerchantType } from "../enums/MerchantType";

export const merchantTypesLabels = new Map<string, string>([
  [MerchantType.Manual, "Non-Integrated"],
  [MerchantType.Payload, "Payload"], //Can be removed once Payload is removed from RicsApi
  [MerchantType.RicsPay, "RICS>Pay"],
]);

export const roundingRuleIds = {
  nearest: "Nearest",
  up: "Up",
  down: "Down",
  nearestFiveCents: "NearestFiveCents",
};
