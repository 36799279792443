import React from "react";
import StyledTooltipComponent from "../styled/StyledTooltip";
import InfoIcon from "@mui/icons-material/Info";
import { SxProps, Theme } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { TooltipProps } from "@mui/material/Tooltip";

interface InfoTooltipProps {
  hoverText: string;
  placement: TooltipProps["placement"];
}

export const InfoTooltipComponent: React.FC<InfoTooltipProps> = ({
  hoverText,
  placement,
}) => {
  const globalTheme = useTheme();

  const infoTooltipStyles: Record<string, SxProps<Theme>> = {
    tooltipIcon: {
      fontSize: "20px",
      fill: globalTheme.palette.common.inactive,
      ":hover": {
        cursor: "pointer",
        fill: globalTheme.palette.common.black,
      },
    },
  };

  return (
    <StyledTooltipComponent arrow placement={placement} title={hoverText}>
      <InfoIcon sx={infoTooltipStyles.tooltipIcon} />
    </StyledTooltipComponent>
  );
};
