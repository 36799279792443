import { doRicsApiXhr } from "./XhrService";

const verifyMerchantId = (
  merchantId: string,
  returnUrl: string,
  locationId?: string
): Promise<void> => {
  return new Promise((resolve, reject) => {
    let endpoint = "v1/RicsPay/VerifyMerchantId";
    let url = encodeURI(returnUrl);
    url = url.replace(/#/g, "%23");

    if (!!locationId) {
      endpoint = `${endpoint}?locationId=${locationId}&merchantId=${merchantId}&returnUrl=${url}`;
    } else {
      endpoint = `${endpoint}?merchantId=${merchantId}&returnUrl=${url}`;
    }

    const onSuccess = () => {
      resolve();
    };

    const onFailure = () => {
      const reason =
        "You have entered an invalid Merchant ID. Please verify your Merchant ID and try again.";
      console.error(reason);
      reject(reason);
    };

    doRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
  });
};

const getSsoToken = (
  returnUrl: string,
  locationId?: string
): Promise<string> => {
  return new Promise((resolve, reject) => {
    let endpoint = "v1/RicsPay/ssotoken";
    let url = encodeURI(returnUrl);
    url = url.replace(/#/g, "%23");

    if (!!locationId) {
      endpoint = `${endpoint}?locationId=${locationId}&returnUrl=${url}`;
    } else {
      endpoint = `${endpoint}?returnUrl=${url}`;
    }

    doRicsApiXhr(endpoint, null, resolve, reject, "GET");
  });
};

export const RicsPayService = {
  verifyMerchantId: verifyMerchantId,
  getSsoToken: getSsoToken,
};
