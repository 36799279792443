import React, { useEffect, useState } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import LocationsGridComponent from "../components/locations/LocationsGrid";
import TenantLevelPageHeaderComponent from "../components/common/TenantLevelPageHeader";
import ListAndCardsToggleComponent from "../components/users/ListAndCardsToggle";
import { Location } from "../interfaces/locations/Location";
import { usePortal } from "../providers/PortalProvider";
import { useDataService } from "../providers/DataServiceProvider";
import { useTheme } from "@mui/material/styles";

const AllLocationsPage: React.FC = () => {
  const dataService = useDataService();
  const portal = usePortal();
  const theme = useTheme();
  const [locationsCount, setLocationsCount] = useState(
    portal!.state.locations?.length
  );
  const [locations, setLocations] = useState<Array<Location>>(
    portal!.state.locations
  );

  portal!.features = portal!.features || {};
  const [isCardMode, setIsCardMode] = useState(true);
  const [isSearchInputVisible, setSearchInputVisible] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    let numLocationsToUpdate = portal!.state.locationIdsToUpdate?.length;

    const onLocationRefresh = () => {
      numLocationsToUpdate--;
      if (numLocationsToUpdate == 0) {
        loadLocationsStateFromPortalState();
      } else {
        updateNextLocation();
      }
    };

    const onFailure = () => {
      alert(
        "Could not retrieve latest location information. Location information may be out of date."
      );
      portal!.navigation.isLoading(false);
    };

    const updateNextLocation = () => {
      dataService!.getLocation(
        portal!.state.locationIdsToUpdate[0],
        onLocationRefresh,
        onFailure
      );
    };

    if (portal!.state.shouldUpdateAllLocations) {
      portal!.navigation.isLoading(true);
      dataService!.refreshLocations(
        0,
        loadLocationsStateFromPortalState,
        onFailure
      );
    } else if (numLocationsToUpdate > 0) {
      portal!.navigation.isLoading(true);
      updateNextLocation();
    }
  });

  const loadLocationsStateFromPortalState = () => {
    setLocations([]);
    setLocations(portal!.state.locations);
    setLocationsCount(portal!.state.locations?.length);
    portal!.state.shouldUpdateAllLocations = false;
    portal!.state.locationIdsToUpdate = [];
    portal!.navigation.isLoading(false);
  };

  const filterListbySearchTerm = (searchTerm: string) => {
    searchTerm = searchTerm.toUpperCase();

    const filteredLocations = locations.map((l: Location) => {
      if (
        l.name?.toUpperCase().includes(searchTerm) ||
        l.contact?.address?.city?.toUpperCase().includes(searchTerm) ||
        l.contact?.address?.line1?.toUpperCase().includes(searchTerm) ||
        l.contact?.address?.line2?.toUpperCase().includes(searchTerm) ||
        l.contact?.address?.state?.toUpperCase().includes(searchTerm) ||
        l.contact?.address?.zipCode?.toUpperCase().includes(searchTerm)
      ) {
        l.hidden = false;
      } else {
        l.hidden = true;
      }

      return l;
    });

    setLocations(filteredLocations);
  };
  const allLocationsStyles: Record<string, SxProps<Theme> | undefined> = {
    headerIcon: {
      color: "white",
    },
    allLocationsContentBox: {
      position: "relative",
      backgroundColor: theme.palette.common.contentPageBackground,
      pt: 2,
      flexGrow: 1,
      overflow: "auto",
    },
  };

  return (
    <>
      <TenantLevelPageHeaderComponent
        headerIcon={<StoreIcon sx={allLocationsStyles.headerIcon} />}
        headerText="All Locations"
        itemsCount={locationsCount}
      >
        <ListAndCardsToggleComponent
          searchPlaceholder="Search for locations"
          searchText={searchText}
          setIsCardMode={setIsCardMode}
          setSearchText={setSearchText}
          setShowSearchInput={setSearchInputVisible}
          showSearchInput={isSearchInputVisible}
          onChangeSearchText={filterListbySearchTerm}
        />
      </TenantLevelPageHeaderComponent>
      <Box sx={allLocationsStyles.allLocationsContentBox}>
        {locationsCount > 0 && (
          <LocationsGridComponent
            isCardMode={isCardMode}
            locations={locations}
          />
        )}
        {locationsCount === 0 && (
          <Box className="noState">
            <Box className="content">
              <Box
                className="iconHolder"
                component="img"
                src="assets/img/locations.svg"
              ></Box>
              <Box className="heading">No Locations exist</Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default AllLocationsPage;
