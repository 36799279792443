import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface CommonColors {
    linkColor: string;
    ricsPurple: string;
    contentPageBackground: string;
    delete: string;
    active: string;
    inactive: string;
    gray: string;
    disabled: string;
  }
}

const globalTheme = createTheme({
  palette: {
    common: {
      linkColor: "#0000EE",
      ricsPurple: "#592A60",
      contentPageBackground: "#F4F4F6",
      delete: "#FA5A5A",
      active: "#00C19C",
      inactive: "#C8C8C8",
      gray: "gray",
      disabled: "#EDEDED",
    },
    primary: {
      main: "#616ae8",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderRadius: "15px",
          backgroundColor: "white",
          '& input[type="text"]::placeholder': {
            color: "grey",
          },
          '& input[type="text"]:not(:placeholder-shown)': {
            color: "black",
          },
        },
      },
    },
  },
});

export default globalTheme;
