import React, { createContext, useContext } from "react";
import ECommerceService from "../interfaces/integrations/ecommerce/ECommerceService";
import { sendVerificationEmail } from "../services/ECommerceService";

const ECommerceServiceContext = createContext<ECommerceService | undefined>(
  undefined
);

interface ECommerceServiceProviderProps {
  children?: React.ReactNode;
}

export const ECommerceServiceProvider: React.FC<
  ECommerceServiceProviderProps
> = ({ children }) => {
  const ecommerceService: ECommerceService = {
    sendVerificationEmail: sendVerificationEmail,
  };

  return (
    <ECommerceServiceContext.Provider value={ecommerceService}>
      {children}
    </ECommerceServiceContext.Provider>
  );
};

export const useECommerceService = () => {
  return useContext(ECommerceServiceContext);
};
