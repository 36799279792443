import { Box, Button, Modal, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { paths } from "../../constants/paths";
import { Location } from "../../interfaces/locations/Location";
import { Identity } from "../../interfaces/users/User";
import { useDataService } from "../../providers/DataServiceProvider";
import { usePortal } from "../../providers/PortalProvider";
import { useXhrService } from "../../providers/XhrServiceProvider";

interface FinishAndInviteUsersModalProps {
  location?: Location;
  showModal: boolean;
  setShowModal: (showModal: boolean, location?: Location) => void;
}

const FinishAndInviteUsersModalComponent: React.FC<
  FinishAndInviteUsersModalProps
> = ({ location, showModal, setShowModal }) => {
  const xhrService = useXhrService();
  const dataService = useDataService();
  const portal = usePortal();
  const navigate = useNavigate();

  const finishAndInvite = () => {
    setShowModal(false);
    portal!.navigation.isLoading(true);
    const finalSuccessCallback = () => {
      portal!.state.setWorkflowButton();
      navigate(paths.Locations);
      portal!.navigation.isLoading(false);
    };

    const onSuccess = () => {
      setupCompleteConfigurations(finalSuccessCallback, onFailure);
    };

    const onFailure = () => {
      portal!.navigation.isLoading(false);
      alert("Something unexpected went wrong");
    };

    sendInvitationEmails(onSuccess, onFailure);
    createPosTerminal();
  };

  const sendInvitationEmails = (
    finalSuccess: () => void,
    onFailure: () => void
  ) => {
    let userCount = -1;
    const nextSuccess = () => {
      userCount++;
      if (
        !portal!.state.currentLocation.usersAtLocation ||
        userCount >= portal!.state.currentLocation.usersAtLocation.length
      ) {
        finalSuccess();
        return;
      }

      var user = portal!.state.currentLocation.usersAtLocation[userCount];

      sendVerificationEmailFromApi(
        user,
        portal!.state.currentLocation,
        nextSuccess,
        onFailure
      );
    };

    nextSuccess();
  };

  const sendVerificationEmailFromApi = (
    identity: Identity,
    location: Location,
    resolve: () => void,
    reject: () => void
  ) => {
    const endpoint = "v1/Auth/SendEmailVerification";

    let baseUrl = process.env.REACT_APP_PORTAL_BASE_URL;
    baseUrl = baseUrl?.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl;
    const redirectUri = `${baseUrl}/password/`;

    const payload = {
      identityId: identity.identityId,
      tenantId: portal!.state.tenantInfo.tenantId,
      locationId: location.locationId,
      redirectUri: redirectUri,
    };

    const onSuccess = () => {
      resolve();
    };

    const onFailure = () => {
      reject();
    };

    xhrService!.doRicsApiXhr(endpoint, payload, onSuccess, onFailure, "PUT");
  };

  const setupCompleteConfigurations = (
    successCallback: () => void,
    failureCallback: () => void
  ) => {
    const setupCompleteConfiguration = {
      ConfigurationName: portal!.configurations.setupWizardComplete,
      ConfigurationValue: true,
      LocationId: portal!.state.currentLocation.locationId,
    };

    const endpoint = "v1/Configurations";

    const onSuccess = () => {
      dataService!.loadConfigurations(successCallback, failureCallback);
    };

    xhrService!.doRicsApiXhr(
      endpoint,
      setupCompleteConfiguration,
      onSuccess,
      failureCallback,
      "POST"
    );
  };

  const createPosTerminal = () => {
    const endpoint = "v1/Rics9/CreatePosTerminal";
    const payload = {
      LocationId: portal!.state.currentLocation.locationId,
      Description: "RICS Checkout",
    };

    xhrService!.doRicsApiXhr(endpoint, payload, undefined, undefined, "POST");
  };

  const inviteUsersModalStyles: Record<string, SxProps<Theme>> = {
    modalBody: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      width: { xs: "90%", sm: "75%", md: "50%", lg: "35%", xl: "25%" },
      maxHeight: "80%",
      borderRadius: "10px",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
    topRow: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      mt: "40px",
    },
    bottomRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      paddingTop: "40px",
    },
    heading: {
      fontSize: "24px",
      fontWeight: "bold",
    },
    subHeading: {
      fontSize: "14px",
      marginTop: "20px",
    },
    inviteUsersIcon: {
      paddingLeft: "5px",
    },
  };

  return (
    <Modal id="modal" open={showModal} onClose={() => setShowModal(false)}>
      <Box sx={inviteUsersModalStyles.modalBody}>
        <Box>
          <Box sx={inviteUsersModalStyles.topRow}>
            <Box component="img" src="img/finish-and-invite.svg"></Box>
            <Box sx={inviteUsersModalStyles.topRow}>
              <Typography sx={inviteUsersModalStyles.heading}>
                Are you ready to send invitations?
              </Typography>
            </Box>
            <Box>
              <Typography sx={inviteUsersModalStyles.subHeading}>
                You are about to invite{" "}
                <b>{location?.usersAtLocation.length} users</b> to{" "}
                {location?.name}. Invited users will receive an email with login
                details for accessing the point of sale system.
                <br />
                <br />
                Are you ready to send invitations?
              </Typography>
            </Box>
          </Box>
          <Box sx={inviteUsersModalStyles.bottomRow}>
            <Button sx={{ mr: 1 }} onClick={() => setShowModal(false)}>
              No, not yet.
            </Button>
            <Button
              sx={{ ml: 1 }}
              variant="contained"
              onClick={finishAndInvite}
            >
              Yes, invite all users!
              <Box
                component="img"
                src="img/send.svg"
                sx={inviteUsersModalStyles.inviteUsersIcon}
              />
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default FinishAndInviteUsersModalComponent;
