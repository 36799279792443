import styled from "@emotion/styled";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import React from "react";
import globalTheme from "../../theme/globalTheme";

const StyledTooltipComponent = styled(
  ({ className, ...props }: TooltipProps) => (
    <>
      <Tooltip {...props} arrow classes={{ popper: className }} />
    </>
  )
)(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: globalTheme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: globalTheme.palette.common.black,
  },
}));

export default StyledTooltipComponent;
