import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
  SxProps,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import React, { createRef, useEffect, useState } from "react";
import { merchantTypesLabels } from "../../../constants/saleSettingsConstants";
import { MerchantType } from "../../../enums/MerchantType";
import { enumFromValue } from "../../../helpers/enumHelper";
import { MerchantSettings } from "../../../interfaces/salesettings/MerchantSettings";
import { SaleSettings } from "../../../interfaces/salesettings/SaleSettings";
import { useAlert } from "../../../providers/AlertProvider";
import { useDataService } from "../../../providers/DataServiceProvider";
import { usePortal } from "../../../providers/PortalProvider";
import { RicsPayService } from "../../../services/RicsPayService";
import { SaleSettingsService } from "../../../services/SaleSettingsService";
import LeftRightContentComponent from "../../common/LeftRightContent";
import PrimaryButtonComponent from "../../styled/PrimaryButton";
import StyledSwitchComponent from "../../styled/StyledSwitch";
import TextButtonComponent from "../../styled/TextButton";
import { handleLocationsOverrideChange } from "../LocationsCanOverrideToggle";
import SettingsCardComponent from "./SettingsCard";

export interface PaymentProcessingProps {
  id: string;
  onSaveSettingsCard: (
    settingsToSave: SaleSettings | MerchantSettings,
    modifySettings?: (
      settings: SaleSettings | MerchantSettings,
      locationId?: string
    ) => void
  ) => Promise<void>;
  merchantSettings?: MerchantSettings;
  locationId?: string;
}

const PaymentProcessingComponent: React.FC<PaymentProcessingProps> = ({
  id,
  onSaveSettingsCard,
  merchantSettings,
  locationId,
}) => {
  const globalTheme = useTheme();
  const portal = usePortal();
  const dataService = useDataService();
  const { showAlert } = useAlert();

  const nonProdMerchantTrackUrl =
    "https://merchanttrack-ext.fullsteampay.net/Account/SingleSignOn";
  const prodMerchantTrackUrl =
    "https://merchanttrack.fullsteampay.net/Account/SingleSignOn";
  const merchantTrackUrl =
    portal!.common.environment === "prod" ?
      prodMerchantTrackUrl
    : nonProdMerchantTrackUrl;

  const [canLocationsOverride, setCanLocationsOverride] = useState(
    portal?.state.saleSettings["Tenant"]?.merchantSettings
      ?.locationsCanOverride ?? true
  );
  const [paymentProcessor, setPaymentProcessor] = useState(
    merchantSettings?.merchantType ?? MerchantType.Manual
  );
  const [merchantId, setMerchantId] = useState(
    merchantSettings?.merchantId ?? ""
  );
  const [isRicsPaySetup, setIsRicsPaySetup] = useState(
    !!merchantSettings?.merchantId
  );
  const [isStoreAndForwardEnabled, setIsStoreAndForwardEnabled] = useState(
    merchantSettings?.storeAndForwardEnabled
  );
  const [
    storeAndForwardTransactionThreshold,
    setStoreAndForwardTransactionThreshold,
  ] = useState(merchantSettings?.storeAndForwardTransactionThreshold);
  const [
    isStoreAndForwardTransactionThresholdValid,
    setIsStoreAndForwardTransactionThresholdValid,
  ] = useState(false);
  const [storeAndForwardOutageThreshold, setStoreAndForwardOutageThreshold] =
    useState(merchantSettings?.storeAndForwardOutageThreshold);
  const [
    isStoreAndForwardOutageThresholdValid,
    setIsStoreAndForwardOutageThresholdValid,
  ] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const hasPhoneNumber = !!portal!.state.currentUser.phoneNumber;
  const locationName =
    portal?.state.locations.find((l) => l.locationId == locationId)?.name ??
    portal?.state.tenantInfo.businessName;

  const ssoSubmitForm = createRef<HTMLFormElement>();
  const ssoHiddenField = createRef<HTMLInputElement>();

  const updatePaymentProcessor = (value: string) => {
    let processor = enumFromValue(value, MerchantType);
    if (!processor) {
      throw Error();
    }

    if (processor !== MerchantType.RicsPay) {
      setMerchantId("");
      setIsRicsPaySetup(false);
    } else {
      setMerchantId(merchantSettings?.merchantId ?? "");
    }

    setPaymentProcessor(processor);
  };

  const validateMerchantId = async () => {
    if (!merchantId) {
      return;
    }
    portal!.navigation.isLoading(true);

    await RicsPayService.verifyMerchantId(
      merchantId,
      window.location.href,
      locationId
    )
      .then(() => {
        setIsRicsPaySetup(true);
        portal!.navigation.isLoading(false);
      })
      .catch(() => {
        setIsRicsPaySetup(false);
        showAlert(
          "You have entered an invalid Merchant ID. Please verify your Merchant ID and try again.",
          "error",
          <ErrorIcon />
        );
        portal!.navigation.isLoading(false);
      });
  };

  const disconnectRicsPay = async () => {
    portal!.navigation.isLoading(true);
    setMerchantId("");
    setIsRicsPaySetup(false);
    setPaymentProcessor(MerchantType.Manual);

    let salesSettings = {} as SaleSettings;
    if (locationId) {
      salesSettings =
        portal!.state.saleSettings[locationId] ?
          portal!.state.saleSettings[locationId]
        : portal!.state.saleSettings["Tenant"];
    } else {
      salesSettings = portal!.state.saleSettings["Tenant"];
    }

    let settingsToSave: MerchantSettings = {
      merchantType: MerchantType.Manual,
      merchantId: undefined,
    };

    Object.assign(salesSettings.merchantSettings as object, settingsToSave);

    if (!locationId) {
      salesSettings.merchantSettings!.locationsCanOverride =
        canLocationsOverride;
    } else {
      salesSettings.locationId = locationId;
      salesSettings.merchantSettings!.locationsCanOverride = undefined;
    }

    await SaleSettingsService.save(salesSettings)
      .then((saleSettings) => {
        updateCachedSaleSettings(saleSettings);
        portal!.navigation.isLoading(false);
      })
      .catch(() => {
        portal!.navigation.isLoading(false);
      });
  };

  const logIntoMerchantTrack = async () => {
    portal!.navigation.isLoading(true);
    await RicsPayService.getSsoToken(window.location.href, locationId)
      .then((ssoToken) => {
        ssoHiddenField!.current!.value = ssoToken;
        ssoSubmitForm!.current!.submit();
        portal!.navigation.isLoading(false);
      })
      .catch(() => {
        showAlert(
          "Something went wrong while trying to log into MerchantTrack. Please verify your Merchant ID and try again.",
          "error",
          <ErrorIcon />
        );
        portal!.navigation.isLoading(false);
      });
  };

  const updateStoreAndForwardEnabled = (checked: boolean) => {
    if (!checked) {
      setStoreAndForwardOutageThreshold(undefined);
      setStoreAndForwardTransactionThreshold(undefined);
    }

    setIsStoreAndForwardEnabled(checked);
  };

  const parseThresholdNumber = (value: string) => {
    let numberValue =
      value === "" || value.includes("e") ? undefined : Number(value);
    numberValue = numberValue && numberValue < 0 ? undefined : numberValue;
    return numberValue;
  };

  const updateStoreAndForwardTransactionThreshold = (value: string) => {
    let numberValue = parseThresholdNumber(value);
    setIsStoreAndForwardTransactionThresholdValid(!!numberValue);
    setStoreAndForwardTransactionThreshold(numberValue);
  };

  const updateStoreAndForwardOutageThreshold = (value: string) => {
    let numberValue = parseThresholdNumber(value);
    setIsStoreAndForwardOutageThresholdValid(!!numberValue);
    setStoreAndForwardOutageThreshold(numberValue);
  };

  const updateCanSave = () => {
    const isRicsPayValid =
      paymentProcessor === MerchantType.RicsPay &&
      !!merchantId &&
      isRicsPaySetup;
    const isPaymentProcessorValid =
      (paymentProcessor && paymentProcessor !== MerchantType.RicsPay) ||
      isRicsPayValid;
    const isStoreAndForwardValid =
      !isStoreAndForwardEnabled ||
      (isStoreAndForwardEnabled &&
        !!storeAndForwardOutageThreshold &&
        !!storeAndForwardTransactionThreshold);

    const isValid = isPaymentProcessorValid && isStoreAndForwardValid;

    const hasLocationsCanOverrideChanged =
      canLocationsOverride !==
      portal?.state.saleSettings["Tenant"]?.merchantSettings
        ?.locationsCanOverride;
    const hasPaymentProcessorChanged =
      paymentProcessor !== merchantSettings?.merchantType;
    const hasMerchantIdChanged = merchantId !== merchantSettings?.merchantId;
    const hasStoreAndForwardEnabledChanged =
      isStoreAndForwardEnabled !== merchantSettings?.storeAndForwardEnabled;
    const hasStoreAndForwardOutageThresholdChanged =
      storeAndForwardOutageThreshold !==
      merchantSettings?.storeAndForwardOutageThreshold;
    const hasStoreAndForwardTransactionThresholdChanged =
      storeAndForwardTransactionThreshold !==
      merchantSettings?.storeAndForwardTransactionThreshold;

    const hasChange =
      hasLocationsCanOverrideChanged ||
      hasPaymentProcessorChanged ||
      hasMerchantIdChanged ||
      hasStoreAndForwardEnabledChanged ||
      hasStoreAndForwardOutageThresholdChanged ||
      hasStoreAndForwardTransactionThresholdChanged;

    setCanSave(hasChange && isValid);
  };

  const handleSave = () => {
    const settingsToSave: MerchantSettings = {
      merchantType: paymentProcessor,
      merchantId: merchantId,
      storeAndForwardEnabled: isStoreAndForwardEnabled,
      storeAndForwardTransactionThreshold: storeAndForwardTransactionThreshold,
      storeAndForwardOutageThreshold: storeAndForwardOutageThreshold,
    };

    const modifySettings = (settings: SaleSettings | MerchantSettings) => {
      if (locationId) {
        dataService.enqueueLocationForUpdate(locationId);
        (settings as SaleSettings).locationId = locationId;
        (settings as SaleSettings).merchantSettings!.locationsCanOverride =
          undefined;
      } else {
        portal!.state.shouldUpdateAllLocations = true;
        (settings as SaleSettings).merchantSettings!.locationsCanOverride =
          canLocationsOverride;
      }
    };

    onSaveSettingsCard(settingsToSave, modifySettings);
  };

  const updateCachedSaleSettings = (saleSettings: SaleSettings) => {
    let key = !!locationId ? locationId : "Tenant";
    portal!.state.saleSettings[key] = saleSettings;
  };

  useEffect(() => {
    updateCanSave();
  }, [
    canLocationsOverride,
    paymentProcessor,
    merchantId,
    isRicsPaySetup,
    isStoreAndForwardEnabled,
    storeAndForwardOutageThreshold,
    storeAndForwardTransactionThreshold,
  ]);

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
    } else {
      setCanSave(true);
    }
  }, [canLocationsOverride]);

  useEffect(() => {
    setIsDisabled(!!locationId && !canLocationsOverride);
  }, [canLocationsOverride, locationId]);

  const paymentProcessingStyles: Record<string, SxProps<Theme> | undefined> = {
    paymentProcessorRadio: {
      "& .MuiRadio-root": {
        paddingY: "0",
        paddingRight: "4px",
      },
    },
    paymentProcessorDescriptionText: {
      fontSize: "14px",
    },
    merchantIdInput: {
      borderRadius: "8px",
      maxWidth: "12rem",
      "& .MuiOutlinedInput-root": {
        fontSize: "16px",
        "& fieldset": {
          borderColor:
            !!merchantId && isRicsPaySetup ?
              globalTheme.palette.common.inactive
            : "red",
        },
        "&:hover fieldset": {
          borderColor:
            !!merchantId && isRicsPaySetup ?
              globalTheme.palette.common.inactive
            : "red",
        },
        "&.Mui-focused fieldset": {
          borderColor: globalTheme.palette.primary.main,
        },
        "&.Mui-disabled > fieldset": {
          borderColor: globalTheme.palette.common.disabled,
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: "8px",
      },
    },
    merchantIdVerifyBtn: {
      maxWidth: "5rem",
      mt: 1,
    },
    manageAccountContent: {
      display: "flex",
      flexDirection: "column",
      rowGap: "16px",
    },
    accountBanner: {
      display: "flex",
      backgroundColor: globalTheme.palette.primary.main,
      alignItems: "center",
      padding: "8px",
      gap: "8px",
      color: globalTheme.palette.common.white,
      borderRadius: "8px",
      maxWidth: "17rem",
    },
    accountBannerIconHolder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "20px",
      height: "20px",
      borderRadius: "50%",
      backgroundColor: globalTheme.palette.common.active,
    },
    accountBannerIcon: {
      height: "26px",
      width: "26px",
    },
    disconnectBtn: {
      maxWidth: "15rem",
    },
    merchantTrackBtn: {
      maxWidth: "15rem",
    },
    missingPhoneNumberText: {
      color: "red",
      display: hasPhoneNumber ? "none" : "block",
      fontSize: "14px",
    },
    storeAndForwardContent: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    storeAndForwardSwitchText: {
      fontSize: "14px",
      color: globalTheme.palette.common.black,
    },
    storeAndForwardThresholdsContent: {
      display: isStoreAndForwardEnabled ? "flex" : "none",
      flexDirection: "column",
      rowGap: "16px",
      marginTop: "16px",
    },
    storeAndForwardThresholdsContentText: {
      fontSize: "14px",
      color: globalTheme.palette.common.gray,
    },
    storeAndForwardThresholds: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    storeAndForwardThreshold: {
      borderRadius: "4px",
      maxWidth: "8rem",
      "&.MuiOutlinedInput-root": {
        fontSize: "16px",
        paddingLeft: "8px",
        "& fieldset": {
          borderColor: globalTheme.palette.common.inactive,
        },
        "&:hover fieldset": {
          borderColor: globalTheme.palette.common.inactive,
        },
        "&.Mui-focused fieldset": {
          borderColor: globalTheme.palette.primary.main,
        },
        "&.Mui-disabled fieldset": {
          borderColor: globalTheme.palette.common.inactive,
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: "8px 8px 8px 0",
      },
    },
    storeAndForwardThresholdInvalid: {
      borderRadius: "4px",
      maxWidth: "8rem",
      "&.MuiOutlinedInput-root": {
        fontSize: "16px",
        paddingLeft: "8px",
        "& fieldset": {
          borderColor: "red",
        },
        "&:hover fieldset": {
          borderColor: "red",
        },
        "&.Mui-focused fieldset": {
          borderColor: globalTheme.palette.primary.main,
        },
        "&.Mui-disabled fieldset": {
          borderColor: globalTheme.palette.common.inactive,
        },
      },
      "& .MuiOutlinedInput-input": {
        padding: "8px 8px 8px 0",
      },
    },
    storeAndForwardThresholdText: {
      fontSize: "14px",
      color: globalTheme.palette.common.black,
    },
  };

  return (
    <SettingsCardComponent
      canSave={canSave}
      disabled={isDisabled}
      headerText="Payment Processing"
      id={id}
      locationsCanOverrideToggleProps={{
        locationsCanOverride: canLocationsOverride,
        onChange: handleLocationsOverrideChange(setCanLocationsOverride),
        disabled: !!locationId,
      }}
      subHeaderText="Specify how you would like to process credit card payments."
      onSave={handleSave}
    >
      <LeftRightContentComponent
        leftHeading="Credit Card Processing"
        rightHeading={
          <>
            We recommend using RICS&gt;Pay™ for fully-integrated support, but
            you can manually collect
            <br /> payments via any non-integrated credit card processing
            device.
          </>
        }
      >
        <FormControl disabled={isDisabled}>
          <RadioGroup
            sx={{
              rowGap: "8px",
            }}
            value={paymentProcessor}
            onChange={(e) => updatePaymentProcessor(e.target.value)}
          >
            <FormControlLabel
              control={<Radio data-testid="nonIntegratedPaymentRadioButton" />}
              label={
                <Typography
                  sx={paymentProcessingStyles.paymentProcessorDescriptionText}
                >
                  Non-Integrated Payment Processor (credit card machine)
                </Typography>
              }
              sx={paymentProcessingStyles.paymentProcessorRadio}
              value={MerchantType.Manual}
            />
            <FormControlLabel
              control={<Radio data-testid="integratedPaymentRadioButton" />}
              label={
                <Typography
                  sx={paymentProcessingStyles.paymentProcessorDescriptionText}
                >
                  Integrated Payment Processor (RICS&gt;Pay™)
                </Typography>
              }
              sx={paymentProcessingStyles.paymentProcessorRadio}
              value={MerchantType.RicsPay}
            />
          </RadioGroup>
        </FormControl>
      </LeftRightContentComponent>
      <LeftRightContentComponent
        hidden={paymentProcessor !== MerchantType.RicsPay}
        rightHeading="Enter your RICS>Pay™ Merchant ID to finish setting up your account"
        testId="merchantIdEntryComponent"
      >
        <TextField
          required
          data-testid="merchantIdTextfield"
          disabled={isRicsPaySetup || isDisabled}
          sx={paymentProcessingStyles.merchantIdInput}
          value={merchantId}
          variant="outlined"
          onChange={(e) => setMerchantId(e.target.value)}
        />
        <PrimaryButtonComponent
          data-testid="validateMerchantIdButton"
          disabled={!merchantId || isRicsPaySetup || isDisabled}
          sx={paymentProcessingStyles.merchantIdVerifyBtn}
          onClick={validateMerchantId}
        >
          Verify
        </PrimaryButtonComponent>
      </LeftRightContentComponent>
      <LeftRightContentComponent
        hidden={!isRicsPaySetup}
        rightHeading="Manage your RICS>Pay™ Account"
      >
        <Box sx={paymentProcessingStyles.manageAccountContent}>
          <Box sx={paymentProcessingStyles.accountBanner}>
            <Box sx={paymentProcessingStyles.accountBannerIconHolder}>
              <CheckCircleIcon sx={paymentProcessingStyles.accountBannerIcon} />
            </Box>
            <Typography>
              {locationName} {merchantTypesLabels.get(MerchantType.RicsPay)}™
              Account
            </Typography>
          </Box>
          <TextButtonComponent
            disableRipple
            disabled={isDisabled}
            focusColor="red"
            hoverColor="red"
            sx={paymentProcessingStyles.disconnectBtn}
            textColor="red"
            onClick={disconnectRicsPay}
          >
            Disconnect {merchantTypesLabels.get(MerchantType.RicsPay)}™ Account
          </TextButtonComponent>
          <PrimaryButtonComponent
            disabled={!hasPhoneNumber || isDisabled}
            sx={paymentProcessingStyles.merchantTrackBtn}
            onClick={logIntoMerchantTrack}
          >
            Log into MerchantTrack
          </PrimaryButtonComponent>
          <Typography sx={paymentProcessingStyles.missingPhoneNumberText}>
            Phone number is required to log into MerchantTrack. Please navigate
            to the All Users page and update your account with a valid phone
            number.
          </Typography>
          <form
            action={merchantTrackUrl}
            hidden={true}
            method="post"
            ref={ssoSubmitForm}
            target="_blank"
          >
            <input
              id="AuthenticationKey"
              name="AuthenticationKey"
              ref={ssoHiddenField}
              type="hidden"
            />
          </form>
        </Box>
      </LeftRightContentComponent>
      <LeftRightContentComponent
        leftHeading="Store & Forward"
        rightHeading="Capture credit transactions during a payment outage."
      >
        <Box sx={paymentProcessingStyles.storeAndForwardContent}>
          <StyledSwitchComponent
            checked={isStoreAndForwardEnabled}
            data-testid="storeAndForwardToggle"
            disabled={isDisabled}
            onChange={(e) => updateStoreAndForwardEnabled(e.target.checked)}
          />
          <Typography sx={paymentProcessingStyles.storeAndForwardSwitchText}>
            Enable Store & Forward
          </Typography>
        </Box>
        <Box
          data-testid="storeAndForwardThresholdsContent"
          sx={paymentProcessingStyles.storeAndForwardThresholdsContent}
        >
          <Typography
            sx={paymentProcessingStyles.storeAndForwardThresholdsContentText}
          >
            During a payment outage, these thresholds are the maximum dollar
            amount that can be processed per terminal.
          </Typography>
          <Box sx={paymentProcessingStyles.storeAndForwardThresholds}>
            <Typography
              sx={paymentProcessingStyles.storeAndForwardThresholdText}
            >
              Transaction Threshold Dollar Amount:
            </Typography>
            <OutlinedInput
              required
              disabled={isDisabled}
              placeholder="e.g. 100"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              sx={
                isStoreAndForwardTransactionThresholdValid ?
                  paymentProcessingStyles.storeAndForwardThreshold
                : paymentProcessingStyles.storeAndForwardThresholdInvalid
              }
              type="number"
              value={storeAndForwardTransactionThreshold}
              onChange={(e) =>
                updateStoreAndForwardTransactionThreshold(e.target.value)
              }
            />
          </Box>
          <Box sx={paymentProcessingStyles.storeAndForwardThresholds}>
            <Typography
              sx={paymentProcessingStyles.storeAndForwardThresholdText}
            >
              Outage Threshold Dollar Amount:
            </Typography>
            <OutlinedInput
              required
              disabled={isDisabled}
              placeholder="e.g. 1000"
              startAdornment={
                <InputAdornment position="start">$</InputAdornment>
              }
              sx={
                isStoreAndForwardOutageThresholdValid ?
                  paymentProcessingStyles.storeAndForwardThreshold
                : paymentProcessingStyles.storeAndForwardThresholdInvalid
              }
              type="number"
              value={storeAndForwardOutageThreshold}
              onChange={(e) =>
                updateStoreAndForwardOutageThreshold(e.target.value)
              }
            />
          </Box>
        </Box>
      </LeftRightContentComponent>
    </SettingsCardComponent>
  );
};

export default PaymentProcessingComponent;
