import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";

import { usePortal } from "../../providers/PortalProvider";
import { useDataService } from "../../providers/DataServiceProvider";
import { showWelcomeModal } from "../../interfaces/settings/Configuration";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useAlert } from "../../providers/AlertProvider";
import { LDFlagSet } from "launchdarkly-js-client-sdk";

import { paths, getDefaultPath } from "../../constants/paths";
import { apiVersions } from "../../constants/apiVersions";
import { claims, hasPermissionTo } from "../../constants/claims";
import { featureFlags } from "../../constants/featureFlags";

import { UsersProvider } from "../../providers/UsersProvider";
import { TaxesProvider } from "../../providers/TaxesProvider";

import RicsToolbarComponent from "../common/RicsToolbar";
import PasswordPage from "../../pages/PasswordPage";
import AccountSetupCompletePage from "../../pages/AccountSetupCompletePage";
import PrivacyPolicyPage from "../../pages/PrivacyPolicyPage";
import DeviceActivationPage from "../../pages/DeviceActivationPage";
import AboutPage from "../../pages/AboutPage";
import Welcome from "../../pages/WelcomePage";
import AllLocationsPage from "../../pages/AllLocationsPage";
import LocationDetailsPage from "../../pages/LocationDetailsPage";
import { AllUsersPage } from "../../pages/AllUsersPage";
import DevicesPage from "../../pages/DevicesPage";
import SettingsPage from "../../pages/SettingsPage";
import HomePage from "../../pages/HomePage";
import IntegrationsPage from "../../pages/IntegrationsPage";
import AmazonPage from "../../pages/AmazonPage";
import AmazonAccountsPage from "../../pages/AmazonAccountsPage";
import ManageLabelsPage from "../../pages/ManageLabelsPage";
import LoginPage from "../../pages/LoginPage";
import { GenericAlertComponent } from "../styled/GenericAlert";

const RouterComponent: React.FC = () => {
  const ldClient = useLDClient();
  const flags = useFlags();
  const portal = usePortal();
  const dataService = useDataService();
  const windowPortal = window.portal;

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    initialize();
  }, []);

  const loadData = (
    ldFlags: LDFlagSet | null,
    onSuccess: () => void,
    onFailure: () => void
  ) => {
    const version =
      ldFlags && ldFlags[featureFlags.LabelsPage] ?
        apiVersions.TwoDotOne
      : apiVersions.Default;
    dataService.getIdentity(version, onSuccess, onFailure);
  };

  const initialize = (onSuccess?: () => void, onFailure?: () => void) => {
    portal?.navigation.isLoading(true);
    setIsLoading(true);

    const onSuccessCallback = () => {
      portal?.navigation.isLoading(false);
      setIsLoading(false);
      if (onSuccess) {
        onSuccess();
      }
    };

    const onFailureCallback = () => {
      window.localStorage.clear();
      window.sessionStorage.clear();
      setIsLoading(false);
      if (onFailure) {
        onFailure();
      }
      portal?.navigation.isLoading(false);
    };

    if (window.localStorage.getItem("Token")) {
      dataService.tenants
        .getCurrentTenant()
        .then((tenant) => {
          initializeLD(tenant.tenantId, onSuccessCallback, onFailureCallback);
        })
        .catch(() => {
          onFailureCallback();
        });
      return;
    }

    onFailureCallback();
  };

  const initializeLD = (
    tenantId: string,
    onSuccess: () => void,
    onFailure: () => void
  ) => {
    portal?.navigation.isLoading(true);

    const user =
      windowPortal.state.currentUser ?
        {
          key: windowPortal.state.currentUser.emailAddress,
          name: windowPortal.state.currentUser.emailAddress,
          email: windowPortal.state.currentUser.emailAddress,
          custom: {
            TenantId: tenantId,
          },
        }
      : {
          anonymous: true,
          custom: {
            TenantId: tenantId,
          },
        };

    ldClient?.identify(user, undefined, (err, ldFlags) => {
      if (err) {
        console.log(err.message);
        if (onFailure) {
          onFailure();
        }
        return;
      }

      loadData(ldFlags, onSuccess, onFailure);
    });
  };

  const PrivateRoutes = () => {
    return isAuthenticated() ? <Outlet /> : <Navigate to={paths.Login} />;
  };

  const OwnerRoutes = () => {
    return isAuthenticated() && isAuthorized(claims.Owner) ?
        <Outlet />
      : <Navigate to={getDefaultRedirectPath()} />;
  };

  const LabelRoutes = () => {
    return isAuthenticated() && isAuthorized(claims.Labels || claims.Owner) ?
        <Outlet />
      : <Navigate to={getDefaultRedirectPath()} />;
  };

  const isAuthenticated = () => {
    const token = window.localStorage.getItem("Token");
    return !!token;
  };

  const isAuthorized = (claim?: string) => {
    const userPermissions = portal!.state.userPermissions;
    const hasPortalAccessPermission = hasPermissionTo(
      flags[featureFlags.LabelsPage] ? claims.Portal : claims.Owner,
      userPermissions
    );
    const hasPageAccess =
      flags[featureFlags.LabelsPage] && claim ?
        hasPermissionTo(claim, userPermissions)
      : true;
    if (hasPortalAccessPermission && hasPageAccess) {
      return true;
    }

    return false;
  };

  const getDefaultRedirectPath = () => {
    return getDefaultPath(
      flags[featureFlags.LabelsPage],
      showWelcomeModal(portal!.configurations),
      portal!.state.userPermissions
    );
  };

  const { alert, closeAlert } = useAlert();

  return isLoading ?
      <></>
    : <>
        {alert.open && (
          <GenericAlertComponent
            icon={alert.icon}
            message={alert.message}
            open={alert.open}
            severity={alert.severity}
            onClose={closeAlert}
          />
        )}
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route
              element={<Navigate to={getDefaultRedirectPath()} />}
              path={paths.Default}
            />
            <Route element={<OwnerRoutes />}>
              <Route element={<RicsToolbarComponent />}>
                <Route element={<DevicesPage />} path={paths.Devices} />
                <Route element={<HomePage />} path={paths.Home} />
                <Route
                  element={
                    <TaxesProvider>
                      <UsersProvider>
                        <LocationDetailsPage />
                      </UsersProvider>
                    </TaxesProvider>
                  }
                  path={`${paths.LocationDetails}/:locationId`}
                />
                <Route element={<AllLocationsPage />} path={paths.Locations} />
                <Route element={<SettingsPage />} path={paths.Settings} />

                <Route
                  element={
                    <UsersProvider>
                      <AllUsersPage />
                    </UsersProvider>
                  }
                  path={paths.Users}
                />
                <Route element={<Welcome />} path={paths.Welcome} />

                <Route path={paths.Integrations}>
                  <Route index element={<IntegrationsPage />} />
                  <Route path={paths.Amazon}>
                    <Route index element={<AmazonPage />} />
                    <Route
                      element={<AmazonAccountsPage />}
                      path={paths.AmazonAccounts}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route element={<RicsToolbarComponent />}>
              <Route element={<LabelRoutes />}>
                <Route
                  element={<ManageLabelsPage />}
                  path={paths.ManageLabels}
                />
              </Route>
            </Route>
          </Route>

          <Route element={<AboutPage />} path={paths.About} />
          <Route
            element={<AccountSetupCompletePage />}
            path={paths.AccountSetupComplete}
          />
          <Route
            element={<DeviceActivationPage />}
            path={paths.DeviceActivationApproved}
          />
          <Route
            element={<DeviceActivationPage />}
            path={paths.DeviceActivationDeclined}
          />
          <Route
            element={
              isAuthenticated() ?
                <Navigate to={getDefaultRedirectPath()} />
              : <LoginPage initialize={initialize} />
            }
            path={paths.Login}
          />
          <Route element={<PasswordPage />} path={paths.Password} />
          <Route element={<PrivacyPolicyPage />} path={paths.PrivacyPolicy} />
        </Routes>
        ;
      </>;
};

export default RouterComponent;
