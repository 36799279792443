import { UserPermissions } from "../interfaces/providers/ApplicationPortal";
import { claims, hasPermissionTo } from "./claims";

export const paths = {
  Default: "/",
  Login: "/login",
  Password: "/password",
  AccountSetupComplete: "/accountsetupcomplete",
  PrivacyPolicy: "/privacypolicy",
  DeviceActivationApproved: "/deviceActivationApproved",
  DeviceActivationDeclined: "/deviceActivationDeclined",
  About: "/about",
  Welcome: "/welcome",
  Locations: "/locations",
  LocationDetails: "/locationdetails",
  Users: "/users",
  Devices: "/devices",
  Settings: "/settings",
  Home: "/home",
  Integrations: "/integrations",
  Amazon: "/integrations/amazon",
  AmazonAccounts: "/integrations/amazon/accounts",
  ManageLabels: "/managelabels",
} as const;

export const getDefaultPath = (
  labelsPageEnabled: boolean,
  showWelcomeModal: boolean,
  permissions: Array<UserPermissions> | undefined
) => {
  if (showWelcomeModal) {
    return paths.Welcome;
  }

  if (
    labelsPageEnabled &&
    !hasPermissionTo(claims.Owner, permissions) &&
    hasPermissionTo(claims.Labels, permissions)
  ) {
    return paths.ManageLabels;
  }

  return paths.Locations;
};
