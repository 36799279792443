import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PercentIcon from "@mui/icons-material/Percent";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { useFlags } from "launchdarkly-react-client-sdk";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { featureFlags } from "../../../constants/featureFlags";
import { deviceTimeout } from "../../../constants/settings/posDeviceSettings/deviceTimeouts";
import { isValidNonNegativeDecimal } from "../../../helpers/numberHelpers";
import { DeviceSettings } from "../../../interfaces/salesettings/DeviceSettings";
import { ManagerActions } from "../../../interfaces/salesettings/ManagerActions";
import { SaleSettings } from "../../../interfaces/salesettings/SaleSettings";
import { useDataService } from "../../../providers/DataServiceProvider";
import { usePortal } from "../../../providers/PortalProvider";
import LeftRightContentComponent from "../../common/LeftRightContent";
import StyledSwitchComponent from "../../styled/StyledSwitch";
import { handleLocationsOverrideChange } from "../LocationsCanOverrideToggle";
import SettingsCardComponent from "./SettingsCard";

export interface PosDeviceSettingsProps {
  id: string;
  locationSettings: SaleSettings;
  onSaveSettingsCard: (
    settingsToSave: SaleSettings,
    modifySettings?: (settings: SaleSettings, locationId?: string) => void
  ) => Promise<void>;
  locationId?: string;
}

export const PosDeviceSettingsComponent: React.FC<PosDeviceSettingsProps> = ({
  id,
  locationSettings,
  onSaveSettingsCard,
  locationId,
}) => {
  const portal = usePortal();
  const globalTheme = useTheme();
  const dataService = useDataService();
  const flags = useFlags();

  const defaultManagerActions: ManagerActions = {
    applyDiscounts: {
      enabled: false,
      dollarAmount: 0,
      percent: 0,
    },
    modifyPrice: false,
    returnThreshold: {
      enabled: false,
      dollarAmount: 0,
    },
    manageDrawer: false,
    allowStandAloneReturns: false,
  };

  const [canSave, setCanSave] = useState(false);
  const [deviceTimeoutId, setDeviceTimeoutId] = useState(
    locationSettings.deviceSettings?.deviceTimeoutId
  );
  const [managerActions, setManagerActions] = React.useState<ManagerActions>(
    locationSettings.deviceSettings?.managerActions ?? defaultManagerActions
  );
  const [canLocationsOverride, setCanLocationsOverride] = useState(
    portal?.state.saleSettings["Tenant"]?.deviceSettings
      ?.locationsCanOverride ?? true
  );
  const [isDiscountPercentageValid, setIsDiscountPercentageValid] =
    useState(true);
  const [isDiscountDollarValid, setIsDiscountDollarValid] = useState(true);
  const [isRefundDollarValid, setIsRefundDollarValid] = useState(true);

  const [isCashCountEnabled, setIsCashCountEnabled] = useState(
    locationSettings.deviceSettings?.cashCountEnabled ?? false
  );

  const [isDisabled, setIsDisabled] = React.useState(false);

  const isStandAloneReturnsEnabled =
    portal?.state.saleSettings[locationId ?? "Tenant"].enableStandAloneReturns;

  useEffect(() => {
    if (!isStandAloneReturnsEnabled && managerActions.allowStandAloneReturns) {
      setManagerActions((prevManagerActions) => ({
        ...prevManagerActions,
        allowStandAloneReturns: false,
      }));
    }
  }),
    [isStandAloneReturnsEnabled];
  useEffect(() => {
    setCanSave(canSaveCheck());
  }, [
    deviceTimeoutId,
    isCashCountEnabled,
    managerActions,
    canLocationsOverride,
    isDiscountDollarValid,
    isRefundDollarValid,
    isDiscountPercentageValid,
  ]);

  useEffect(() => {
    setIsDisabled(!!locationId && !canLocationsOverride);
  }, [locationId, canLocationsOverride]);

  const deviceTimeoutIdInitial =
    locationSettings.deviceSettings?.deviceTimeoutId;
  const managerActionsInitial = locationSettings.deviceSettings?.managerActions;
  const isCashCountEnabledInitial =
    locationSettings.deviceSettings?.cashCountEnabled ?? false;
  const canLocationsOverrideInitial =
    portal?.state.saleSettings["Tenant"]?.deviceSettings
      ?.locationsCanOverride ?? true;
  const isStandAloneFeatureFlagEnabled = flags[featureFlags.StandAloneReturns];
  const canSaveCheck = () => {
    const isAnyDeviceTimeoutSelected: boolean = deviceTimeoutId !== "";

    const hasDeviceTimeoutIdChanged: boolean =
      deviceTimeoutId !== deviceTimeoutIdInitial;

    const hasManagerActionsChanged: boolean = !isEqual(
      managerActions,
      managerActionsInitial
    );
    const hasCashCountChanged: boolean =
      isCashCountEnabled !== isCashCountEnabledInitial;

    const hasLocationsCanOverrideChanged: boolean =
      canLocationsOverride !== canLocationsOverrideInitial;

    const isNumericInputsValid: boolean =
      isDiscountDollarValid && isDiscountPercentageValid && isRefundDollarValid;

    return (
      isAnyDeviceTimeoutSelected &&
      isNumericInputsValid &&
      (hasDeviceTimeoutIdChanged ||
        hasManagerActionsChanged ||
        hasCashCountChanged ||
        hasLocationsCanOverrideChanged)
    );
  };

  const handleSaveSettings = () => {
    const settingsToSave: DeviceSettings = {
      deviceTimeoutId: deviceTimeoutId,
      cashCountEnabled: isCashCountEnabled,
      managerActions: {
        applyDiscounts: {
          enabled: managerActions?.applyDiscounts?.enabled ?? false,
          dollarAmount: managerActions?.applyDiscounts?.dollarAmount,
          percent: managerActions?.applyDiscounts?.percent,
        },
        modifyPrice: managerActions?.modifyPrice,
        manageDrawer: managerActions?.manageDrawer,
        returnThreshold: {
          enabled: managerActions?.returnThreshold?.enabled ?? false,
          dollarAmount: managerActions?.returnThreshold?.dollarAmount,
        },
        allowStandAloneReturns: managerActions?.allowStandAloneReturns,
      },
    };

    const modifySettings = (settings: SaleSettings) => {
      if (locationId) {
        dataService.enqueueLocationForUpdate(locationId);
        (settings as SaleSettings).locationId = locationId;
        (settings as SaleSettings).deviceSettings!.locationsCanOverride =
          undefined;
      } else {
        portal!.state.shouldUpdateAllLocations = true;
        (settings as SaleSettings).deviceSettings!.locationsCanOverride =
          canLocationsOverride;
      }
    };

    const updatedSaleSettings: SaleSettings = {
      ...locationSettings,
      deviceSettings: settingsToSave,
    };

    onSaveSettingsCard(updatedSaleSettings, modifySettings);
    setCanSave(false);
  };

  const handleDiscountValueChange = (
    property: "percent" | "dollarAmount",
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const isValidNumber = isValidNonNegativeDecimal(event.target.value);

    if (property === "percent") {
      setIsDiscountPercentageValid(isValidNumber);
    } else if (property === "dollarAmount") {
      setIsDiscountDollarValid(isValidNumber);
    }

    if (isValidNumber) {
      setManagerActions((prevManagerActions) => ({
        ...prevManagerActions,
        applyDiscounts: {
          ...prevManagerActions.applyDiscounts,
          enabled: prevManagerActions.applyDiscounts?.enabled ?? true,
          [property]:
            event.target.value === "" ? undefined : Number(event.target.value),
        },
      }));
    }
  };

  const handleReturnValueChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const isValidNumber = isValidNonNegativeDecimal(event.target.value);
    setIsRefundDollarValid(isValidNumber);
    if (isValidNumber) {
      setManagerActions((prevManagerActions) => ({
        ...prevManagerActions,
        returnThreshold: {
          ...prevManagerActions.applyDiscounts,
          enabled: prevManagerActions.applyDiscounts?.enabled ?? true,
          dollarAmount:
            event.target.value === "" ? undefined : Number(event.target.value),
        },
      }));
    }
  };

  const deviceSettingsStyles: Record<string, SxProps<Theme>> = {
    multiLineSettingsItem: {
      display: "flex",
      alignItems: "center",
      gap: 2,
    },
    numericInputField: {
      width: "15%",
      height: "30px",
      "& .MuiInputBase-root": {
        height: "30px",
      },
      "& .MuiInputBase-input::placeholder": {
        fontSize: "12px",
      },
      "& .MuiOutlinedInput-root": {
        paddingLeft: 0.5,
        paddingRight: 0.5,
      },
    },
    toggleSwitchBox: {
      display: "flex",
      alignItems: "center",
    },
    toggleSwitchText: {
      fontSize: "14px",
    },
    toggleSubText: {
      fontSize: "12px",
      color: globalTheme.palette.common.gray,
    },
  };

  return (
    <SettingsCardComponent
      canSave={canSave}
      disabled={isDisabled}
      headerText="Point of Sale Device Settings"
      id={id}
      locationsCanOverrideToggleProps={{
        locationsCanOverride: canLocationsOverride,
        onChange: handleLocationsOverrideChange(setCanLocationsOverride),
        disabled: !!locationId,
      }}
      subHeaderText="Configure preferences and settings for the POS Devices"
      onSave={handleSaveSettings}
    >
      <LeftRightContentComponent
        leftHeading="Device Timeout"
        rightHeading="When would you like the POS to automatically lock and return to the PIN screen?"
      >
        <FormControl disabled={isDisabled}>
          <RadioGroup
            value={deviceTimeoutId}
            onChange={(e) => setDeviceTimeoutId(e.target.value)}
          >
            <FormControlLabel
              control={<Radio />}
              label={deviceTimeout.Never.displayText}
              value={deviceTimeout.Never.id}
            />
            <FormControlLabel
              control={<Radio />}
              label={deviceTimeout.OneMinute.displayText}
              value={deviceTimeout.OneMinute.id}
            />
            <FormControlLabel
              control={<Radio />}
              label={deviceTimeout.FiveMinutes.displayText}
              value={deviceTimeout.FiveMinutes.id}
            />
            <FormControlLabel
              control={<Radio />}
              label={deviceTimeout.TenMinutes.displayText}
              value={deviceTimeout.TenMinutes.id}
            />
            <FormControlLabel
              control={<Radio />}
              label={deviceTimeout.ThirtyMinutes.displayText}
              value={deviceTimeout.ThirtyMinutes.id}
            />
            <FormControlLabel
              control={<Radio />}
              label={deviceTimeout.EverySale.displayText}
              value={deviceTimeout.EverySale.id}
            />
          </RadioGroup>
        </FormControl>
      </LeftRightContentComponent>

      <LeftRightContentComponent
        leftHeading="Cash Count"
        rightHeading="Will require all cash drawers to be counted at the start and end of each day/before any sale can be made. If enabled with the manager action 'Manage the POS Drawer', a manager will need to enter their pin in order to access the cash count feature and open the POS for the day."
      >
        <Grid container direction="column">
          <Grid container item>
            <Grid item xs={1}>
              <StyledSwitchComponent
                checked={isCashCountEnabled}
                disabled={isDisabled}
                inputProps={{ "aria-label": "Enable cash count" }}
                onChange={(e) => setIsCashCountEnabled(e.target.checked)}
              />
            </Grid>
            <Grid item xs={11}>
              <Typography sx={deviceSettingsStyles.toggleSwitchText}>
                Enable the cash count feature on the POS
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </LeftRightContentComponent>

      <LeftRightContentComponent
        leftHeading="Manager Actions"
        rightHeading="For security purposes, you can require a manager PIN for certain actions. All actions left un-toggled will not require a manager PIN."
      >
        <Grid container direction="column" spacing={2}>
          <Grid container item>
            <Grid item xs={1}>
              <StyledSwitchComponent
                checked={managerActions.applyDiscounts?.enabled}
                disabled={isDisabled}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  setManagerActions((prevManagerActions) => ({
                    ...prevManagerActions,
                    applyDiscounts: {
                      ...prevManagerActions.applyDiscounts,
                      enabled: isChecked,
                      dollarAmount:
                        isChecked ?
                          prevManagerActions.applyDiscounts?.dollarAmount
                        : undefined,
                      percent:
                        isChecked ?
                          prevManagerActions.applyDiscounts?.percent
                        : undefined,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={11}>
              <Typography sx={deviceSettingsStyles.toggleSwitchText}>
                Apply manual discounts (scanned coupons do not require a PIN)
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <Typography sx={deviceSettingsStyles.toggleSubText}>
                Optionally, you can specify thresholds above which a PIN will be
                required:
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <Box sx={deviceSettingsStyles.multiLineSettingsItem}>
                <Typography sx={deviceSettingsStyles.toggleSwitchText}>
                  Require a PIN above:
                </Typography>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                  }}
                  data-testid="discountDollarField"
                  disabled={
                    !managerActions.applyDiscounts?.enabled || isDisabled
                  }
                  error={!isDiscountDollarValid}
                  id="discountDollarField"
                  placeholder="e.g. 50"
                  size="small"
                  sx={deviceSettingsStyles.numericInputField}
                  value={
                    managerActions.applyDiscounts?.enabled ?
                      managerActions.applyDiscounts?.dollarAmount
                    : ""
                  }
                  variant="outlined"
                  onChange={(event) =>
                    handleDiscountValueChange("dollarAmount", event)
                  }
                />
                <Typography sx={deviceSettingsStyles.toggleSwitchText}>
                  and/or:
                </Typography>
                <TextField
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <PercentIcon />
                      </InputAdornment>
                    ),
                  }}
                  data-testid="discountPercentField"
                  disabled={
                    !managerActions.applyDiscounts?.enabled || isDisabled
                  }
                  error={!isDiscountPercentageValid}
                  id="discountPercentField"
                  inputMode="numeric"
                  placeholder="e.g. 30"
                  size="small"
                  sx={deviceSettingsStyles.numericInputField}
                  type="text"
                  value={
                    managerActions.applyDiscounts?.enabled ?
                      managerActions.applyDiscounts?.percent
                    : ""
                  }
                  variant="outlined"
                  onChange={(event) =>
                    handleDiscountValueChange("percent", event)
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={1}>
              <StyledSwitchComponent
                checked={managerActions.modifyPrice}
                disabled={isDisabled}
                onChange={(e) =>
                  setManagerActions((prevManagerActions) => ({
                    ...prevManagerActions,
                    modifyPrice: e.target.checked,
                  }))
                }
              />
            </Grid>
            <Grid item xs={11}>
              <Typography sx={deviceSettingsStyles.toggleSwitchText}>
                Modify Price
              </Typography>
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item xs={1}>
              <StyledSwitchComponent
                checked={managerActions.returnThreshold?.enabled}
                disabled={isDisabled}
                onChange={(e) =>
                  setManagerActions((prevManagerActions) => ({
                    ...prevManagerActions,
                    returnThreshold: {
                      ...prevManagerActions.returnThreshold,
                      enabled: e.target.checked,
                      dollarAmount: undefined,
                    },
                  }))
                }
              />
            </Grid>
            <Grid item xs={11}>
              <Typography sx={deviceSettingsStyles.toggleSwitchText}>
                Return a product
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <Typography sx={deviceSettingsStyles.toggleSubText}>
                Optionally, you can specify a threshold above which a PIN will
                be required:
              </Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11}>
              <Box sx={deviceSettingsStyles.multiLineSettingsItem}>
                <Typography sx={deviceSettingsStyles.toggleSwitchText}>
                  Require a PIN above:
                </Typography>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon />
                      </InputAdornment>
                    ),
                  }}
                  data-testid="returnDollarField"
                  disabled={
                    !managerActions.returnThreshold?.enabled || isDisabled
                  }
                  error={!isRefundDollarValid}
                  id="returnDollarField"
                  placeholder="e.g. 50"
                  size="small"
                  sx={deviceSettingsStyles.numericInputField}
                  value={managerActions.returnThreshold?.dollarAmount}
                  variant="outlined"
                  onChange={(event) => handleReturnValueChange(event)}
                />
              </Box>
            </Grid>
          </Grid>
          {isCashCountEnabled && (
            <Grid container item data-testid="manage-drawer-container">
              <Grid item xs={1}>
                <StyledSwitchComponent
                  checked={managerActions.manageDrawer}
                  disabled={isDisabled}
                  onChange={(e) =>
                    setManagerActions((prevManagerActions) => ({
                      ...prevManagerActions,
                      manageDrawer: e.target.checked,
                    }))
                  }
                />
              </Grid>

              <Grid item xs={11}>
                <Typography sx={deviceSettingsStyles.toggleSwitchText}>
                  Manage the POS Drawer
                </Typography>
              </Grid>
            </Grid>
          )}
          {isStandAloneFeatureFlagEnabled && (
            <Grid container item data-testid="standAloneReturnContainer">
              <Grid item xs={1}>
                <StyledSwitchComponent
                  checked={managerActions.allowStandAloneReturns}
                  disabled={!isStandAloneReturnsEnabled || isDisabled}
                  inputProps={{ "aria-label": "Enable Stand Alone Return" }}
                  onChange={(e) =>
                    setManagerActions((prevManagerActions) => ({
                      ...prevManagerActions,
                      allowStandAloneReturns: e.target.checked,
                    }))
                  }
                />
              </Grid>
              <Grid item xs={11}>
                <Typography sx={deviceSettingsStyles.toggleSwitchText}>
                  Enable Stand Alone Return
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </LeftRightContentComponent>
    </SettingsCardComponent>
  );
};
