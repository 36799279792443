import React, { useEffect, useState } from "react";
import { Box, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import RicsSideMenuComponent from "../components/locationDetails/RicsSideMenu";
import LocationDetailsInfoComponent from "../components/locationDetails/LocationDetailsInfo";
import LocationDetailsBrandingComponent from "../components/locationDetails/branding/LocationDetailsBranding";
import LocationDetailsDevicesComponent from "../components/locationDetails/devices/LocationDetailsDevices";
import LocationDetailsUsersComponent from "../components/locationDetails/users/LocationDetailsUsers";
import LocationDetailsDrawerHistoryComponent from "../components/locationDetails/drawerHistory/LocationDetailsDrawerHistory";
import LocationDetailsTaxesComponent from "../components/locationDetails/taxes/LocationDetailsTaxes";
import SettingsPage from "./SettingsPage";
import { useFlags } from "launchdarkly-react-client-sdk";
import { featureFlags } from "../constants/featureFlags";
import { locationDetailsTabs } from "../constants/locationDetailsTabs";
import { usePortal } from "../providers/PortalProvider";

const LocationDetailsPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const portal = usePortal();
  const flags = useFlags();

  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get("tab");

  const locationGroupMatches = window.location.href.match(
    /locationdetails\/([^?]*)/
  );
  let locationId = "";

  if (locationGroupMatches) {
    locationId = locationGroupMatches[1];
  }

  locationId = decodeURIComponent(locationId);
  let isLocationMatchFound = false;

  for (let i = 0; i < portal!.state.locations.length; i++) {
    const tempLocation = portal!.state.locations[i];
    if (tempLocation.locationId === locationId) {
      portal!.state.currentLocation = tempLocation;
      isLocationMatchFound = true;
      break;
    }
  }

  const selectedLocation = {
    name: portal!.state.currentLocation?.name,
    locationId: portal!.state.currentLocation?.locationId,
  };

  const [isCashCountFeatureEnabled, setIsCashCountFeatureEnabled] =
    useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState(() => {
    return (
      locationDetailsTabs.find((t) => t.tabKey === initialTab) ||
      locationDetailsTabs[0]
    );
  });

  useEffect(() => {
    const cashCountFeatureFlag = flags[featureFlags.CashCount];
    setIsCashCountFeatureEnabled(cashCountFeatureFlag);
  }, [flags[featureFlags.CashCount]]);

  useEffect(() => {
    const tabKey = queryParams.get("tab");
    if (tabKey && tabKey !== currentTab.tabKey) {
      setCurrentTab(
        locationDetailsTabs.find((t) => t.tabKey === tabKey) ||
          locationDetailsTabs[0]
      );
    }
  }, [location.search]);

  const handleTabChange = (tabKey: string) => {
    setCurrentTab(
      locationDetailsTabs.find((t) => t.tabKey === tabKey) ||
        locationDetailsTabs[0]
    );
    navigate(`?tab=${tabKey}`);
  };

  const globalTheme = useTheme();
  const locationDetailsStyles: Record<string, SxProps<Theme>> = {
    locationDetailsContainer: {
      backgroundColor: globalTheme.palette.common.contentPageBackground,
      display: "flex",
      justifyContent: "center",
      width: "100%",
      flexGrow: 1,
    },
  };

  return (
    <Box sx={locationDetailsStyles.locationDetailsContainer}>
      <RicsSideMenuComponent
        contentId="locationsPageContent"
        location={selectedLocation}
        navigationEnabled={true}
        selectedTab={currentTab}
        setSelectedTab={handleTabChange}
        tabs={locationDetailsTabs}
      />
      <Box sx={{ flexGrow: 1 }}>
        {isLocationMatchFound && (
          <>
            {currentTab.tabKey === "Details" && (
              <LocationDetailsInfoComponent
                currentLocation={portal!.state.currentLocation}
                selectedTab={currentTab}
              />
            )}
            {currentTab.tabKey === "Branding" && (
              <LocationDetailsBrandingComponent locationId={locationId} />
            )}
            {currentTab.tabKey === "Devices" && (
              <LocationDetailsDevicesComponent locationId={locationId} />
            )}
            {currentTab.tabKey === "Settings" && (
              <SettingsPage locationId={selectedLocation.locationId} />
            )}
            {currentTab.tabKey === "Users" && (
              <LocationDetailsUsersComponent
                locationId={selectedLocation.locationId}
              />
            )}
            {currentTab.tabKey === "Taxes" && (
              <LocationDetailsTaxesComponent
                locationId={selectedLocation.locationId}
              />
            )}
            {currentTab.tabKey === "DrawerHistory" &&
              isCashCountFeatureEnabled && (
                <LocationDetailsDrawerHistoryComponent />
              )}
            {!isLocationMatchFound && locationGroupMatches && (
              <Typography>
                No location was found that matches the ID in the URL
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default LocationDetailsPage;
