import React from "react";
import { Avatar, Box, Card, SxProps, Theme, Typography } from "@mui/material";

import { getRoleDisplayName } from "../../helpers/rolesHelpers";
import { User } from "../../interfaces/users/User";

interface UserCardProps {
  user: User;
}

const UserCardComponent: React.FC<UserCardProps> = ({ user }) => {
  const userCardStyles: Record<string, SxProps<Theme> | undefined> = {
    cardStyle: {
      backgroundColor: "white",
      width: "264px",
      maxWidth: "264px",
      m: "10px",
    },
    cardActionArea: {
      "&:hover": {
        backgroundColor: "white",
      },
      "&:focus": {
        backgroundColor: "white",
      },
    },
    cardContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
    },
    logoBox: {
      height: "72px",
      width: "72px",
      marginBottom: "25px",
    },
    logoImage: {
      height: "100%",
      width: "100%",
      margin: "auto",
      backgroundColor: "black",
    },
    heading: {
      fontSize: "16px",
      textTransform: "capitalize",
      marginBottom: "15px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      textAlign: "center",
    },
    subHeading: {
      fontSize: "14px",
      textTransform: "lowercase",
      color: "#8c8e8f",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
      textAlign: "center",
    },
    roleHolder: {
      position: "relative",
      display: "inline-block",
      background: "#edfafd",
      color: "#616AE8",
      height: "24px",
      fontSize: "12px",
      marginTop: "30px",
      borderRadius: "4px",
      padding: "4px 9px 0px 5px",
    },
    headingBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "90%",
      maxWidth: "90%",
      overflow: "hidden",
    },
  };

  return (
    <Card
      data-testid={`user-card-${user.userId}`}
      sx={userCardStyles.cardStyle}
    >
      <Box sx={userCardStyles.cardContent}>
        <Box sx={userCardStyles.logoBox}>
          <Avatar sx={userCardStyles.logoImage}>
            {(user.firstName ?
              user.firstName[0]
            : user.email[0].toUpperCase()) +
              (user.lastName ? user.lastName[0] : user.email[0].toUpperCase())}
          </Avatar>
        </Box>
        <Box sx={userCardStyles.headingBox}>
          <Typography noWrap sx={userCardStyles.heading}>
            {user.firstName || user.lastName ?
              user.firstName + " " + user.lastName
            : user.email}
          </Typography>
          <Typography sx={userCardStyles.subHeading}>{user.email}</Typography>
        </Box>
        <Box sx={userCardStyles.roleHolder}>
          {getRoleDisplayName(user.roles)}
        </Box>
      </Box>
    </Card>
  );
};

export default UserCardComponent;
