import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { Box, SxProps, Theme, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";

interface GlobalSettingsBannerProps {
  locationId?: string;
}

const GlobalSettingsBannerComponent: React.FC<GlobalSettingsBannerProps> = ({
  locationId,
}) => {
  const globalTheme = useTheme();
  const [isHidden, setIsHidden] = useState(false);

  const globalText =
    "These are global settings that will apply to all child locations. Please note that these settings can be overridden at the location level.";
  const locationText =
    "These are location level settings. Once a setting is updated here, it will no longer inherit the settings from the global settings page.";

  const globalSettingsBannerStyles: Record<string, SxProps<Theme> | undefined> =
    {
      banner: {
        display: isHidden ? "none" : "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: globalTheme.palette.primary.main,
        borderRadius: "4px",
        p: "5px",
      },
      icon: {
        color: globalTheme.palette.common.white,
      },
      text: {
        color: globalTheme.palette.common.white,
        fontSize: "14px",
        fontWeight: "bold",
        flexGrow: 1,
        ml: "10px",
      },
      closeIcon: {
        color: globalTheme.palette.common.white,
        "&:hover": {
          cursor: "pointer",
        },
      },
    };

  return (
    <Box sx={globalSettingsBannerStyles.banner}>
      <InfoIcon sx={globalSettingsBannerStyles.icon} />
      <Typography sx={globalSettingsBannerStyles.text}>
        {locationId ? locationText : globalText}
      </Typography>
      <CloseIcon
        sx={globalSettingsBannerStyles.closeIcon}
        onClick={() => setIsHidden(true)}
      />
    </Box>
  );
};

export default GlobalSettingsBannerComponent;
