import {
  Box,
  SxProps,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { isEmpty } from "../../../helpers/stringHelpers";
import { ClutchSettings } from "../../../interfaces/salesettings/ClutchSettings";
import { CustomerSettings } from "../../../interfaces/salesettings/CustomerSettings";
import { SaleSettings } from "../../../interfaces/salesettings/SaleSettings";
import { useDataService } from "../../../providers/DataServiceProvider";
import LeftRightContentComponent from "../../common/LeftRightContent";
import StyledSwitchComponent from "../../styled/StyledSwitch";
import SettingsCardComponent from "./SettingsCard";

export interface ClutchSettingsProps {
  id: string;
  locationSettings?: SaleSettings;
  onSaveSettingsCard: (
    settingsToSave: SaleSettings,
    modifySettings?: (settings: SaleSettings, locationId?: string) => void
  ) => Promise<void>;
  locationId?: string;
  hidden?: boolean;
}

const ClutchSettingsComponent: React.FC<ClutchSettingsProps> = ({
  id,
  locationId,
  locationSettings,
  onSaveSettingsCard,
  hidden,
}) => {
  const globalTheme = useTheme();
  const dataService = useDataService();

  const [canSave, setCanSave] = useState(false);
  const [apiKey, setApiKey] = useState(
    locationSettings?.clutchSettings?.apiKey
  );
  const [apiSecret, setApiSecret] = useState(
    locationSettings?.clutchSettings?.apiSecret
  );
  const [brandId, setBrandId] = useState(
    locationSettings?.clutchSettings?.brandId
  );
  const [clutchLocationId, setClutchLocationId] = useState(
    locationSettings?.clutchSettings?.locationId
  );
  const [terminalId, setTerminalId] = useState(
    locationSettings?.clutchSettings?.terminalId
  );
  const [isPinRequired, setIsPinRequired] = useState(
    locationSettings?.clutchSettings?.giftCardRequirePIN
  );

  const handleSave = () => {
    const settingsToSave: ClutchSettings = {
      apiKey: apiKey,
      apiSecret: apiSecret,
      brandId: brandId,
      locationId: clutchLocationId,
      terminalId: terminalId,
      giftCardRequirePIN: isPinRequired,
    };

    const modifySettings = (settings: SaleSettings | CustomerSettings) => {
      if (locationId) {
        dataService.enqueueLocationForUpdate(locationId);
        (settings as SaleSettings).locationId = locationId;
        (settings as SaleSettings).customerSettings!.locationsCanOverride =
          undefined;
      }
    };

    const updatedSaleSettings: SaleSettings = {
      ...locationSettings,
      clutchSettings: settingsToSave,
    };

    onSaveSettingsCard(updatedSaleSettings, modifySettings);
    setCanSave(false);
  };

  const updateCanSave = () => {
    const isApiKeySet = !isEmpty({ str: apiKey });
    const isApiSecretSet = !isEmpty({ str: apiSecret });
    const isBrandIdSet = !isEmpty({ str: brandId });
    const isLocationIdSet = !isEmpty({ str: clutchLocationId });
    const isTerminalIdSet = !isEmpty({ str: terminalId });

    const hasApiKeyChanged =
      isApiKeySet && apiKey !== locationSettings?.clutchSettings?.apiKey;

    const hasApiSecretChanged =
      isApiSecretSet &&
      apiSecret !== locationSettings?.clutchSettings?.apiSecret;

    const hasBrandIdChanged =
      isBrandIdSet && brandId !== locationSettings?.clutchSettings?.brandId;

    const hasLocationIdChanged =
      isLocationIdSet &&
      clutchLocationId !== locationSettings?.clutchSettings?.locationId;

    const hasTerminalIdChanged =
      isTerminalIdSet &&
      terminalId !== locationSettings?.clutchSettings?.terminalId;

    const hasIsPinRequiredChanged =
      isApiKeySet &&
      isApiSecretSet &&
      isBrandIdSet &&
      isLocationIdSet &&
      isTerminalIdSet &&
      isPinRequired !==
        (locationSettings?.clutchSettings?.giftCardRequirePIN ?? false);

    setCanSave(
      (hasApiKeyChanged &&
        hasApiSecretChanged &&
        hasBrandIdChanged &&
        hasLocationIdChanged &&
        hasTerminalIdChanged) ||
        hasIsPinRequiredChanged
    );
  };

  useEffect(() => {
    updateCanSave();
  }, [apiKey, apiSecret, brandId, clutchLocationId, terminalId, isPinRequired]);

  const clutchSettingsStyles: Record<string, SxProps<Theme> | undefined> = {
    credentialInputHolder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      maxWidth: "20rem",
      marginBottom: "12px",
    },
    credentialInputLabel: {
      fontSize: "16px",
      color: globalTheme.palette.common.black,
    },
    credentialInput: {
      borderRadius: "4px",
    },
    requirePINContent: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    requirePINSwitchText: {
      fontSize: "14px",
      color: globalTheme.palette.common.black,
    },
  };

  return (
    <SettingsCardComponent
      canSave={canSave}
      headerText="Clutch Integration Settings"
      hidden={hidden}
      id={id}
      locationsCanOverrideToggleProps={{
        hidden: true,
      }}
      subHeaderText="Configure preferences and settings for Clutch."
      onSave={handleSave}
    >
      <LeftRightContentComponent
        leftHeading="Authentication"
        rightHeading="Authentication credentials provided by Clutch"
      >
        <Box sx={clutchSettingsStyles.credentialInputHolder}>
          <Typography sx={clutchSettingsStyles.credentialInputLabel}>
            API Key
          </Typography>
          <TextField
            required
            placeholder="API Key"
            size="small"
            sx={clutchSettingsStyles.credentialInput}
            value={apiKey}
            onChange={(e) => setApiKey(e.currentTarget.value)}
          />
        </Box>
        <Box sx={clutchSettingsStyles.credentialInputHolder}>
          <Typography sx={clutchSettingsStyles.credentialInputLabel}>
            API Secret
          </Typography>
          <TextField
            required
            placeholder="API Secret"
            size="small"
            sx={clutchSettingsStyles.credentialInput}
            type="password"
            value={apiSecret}
            onChange={(e) => setApiSecret(e.currentTarget.value)}
          />
        </Box>
      </LeftRightContentComponent>
      <LeftRightContentComponent
        leftHeading="Location"
        rightHeading="Location credentials provided by Clutch"
      >
        <Box sx={clutchSettingsStyles.credentialInputHolder}>
          <Typography sx={clutchSettingsStyles.credentialInputLabel}>
            Brand ID
          </Typography>
          <TextField
            required
            placeholder="Brand ID"
            size="small"
            sx={clutchSettingsStyles.credentialInput}
            value={brandId}
            onChange={(e) => setBrandId(e.currentTarget.value)}
          />
        </Box>
        <Box sx={clutchSettingsStyles.credentialInputHolder}>
          <Typography sx={clutchSettingsStyles.credentialInputLabel}>
            Location ID
          </Typography>
          <TextField
            required
            placeholder="Location ID"
            size="small"
            sx={clutchSettingsStyles.credentialInput}
            value={clutchLocationId}
            onChange={(e) => setClutchLocationId(e.currentTarget.value)}
          />
        </Box>
        <Box sx={clutchSettingsStyles.credentialInputHolder}>
          <Typography sx={clutchSettingsStyles.credentialInputLabel}>
            Terminal ID
          </Typography>
          <TextField
            required
            placeholder="Terminal ID"
            size="small"
            sx={clutchSettingsStyles.credentialInput}
            value={terminalId}
            onChange={(e) => setTerminalId(e.currentTarget.value)}
          />
        </Box>
      </LeftRightContentComponent>
      <LeftRightContentComponent
        leftHeading="Gift Cards"
        rightHeading="Require a PIN when processing a Gift Card"
      >
        <Box sx={clutchSettingsStyles.requirePINContent}>
          <StyledSwitchComponent
            checked={isPinRequired}
            onChange={(e) => setIsPinRequired(e.currentTarget.checked)}
          />
          <Typography sx={clutchSettingsStyles.requirePINSwitchText}>
            Require PIN
          </Typography>
        </Box>
      </LeftRightContentComponent>
    </SettingsCardComponent>
  );
};

export default ClutchSettingsComponent;
