export const toLocalIsoString = (dt: Date) => {
  const timezoneOffsetMin: number = new Date().getTimezoneOffset();
  let offsetHrs: string = Math.abs(timezoneOffsetMin / 60).toString();
  let offsetMin: string = Math.abs(timezoneOffsetMin % 60).toString();
  let timezoneStandard: string = "Z";

  if (parseInt(offsetHrs) < 10) offsetHrs = "0" + offsetHrs;

  if (parseInt(offsetMin) < 10) offsetMin = "0" + offsetMin;

  if (timezoneOffsetMin < 0)
    timezoneStandard = "+" + offsetHrs + ":" + offsetMin;
  else if (timezoneOffsetMin > 0)
    timezoneStandard = "-" + offsetHrs + ":" + offsetMin;

  const currentDate: string = dt.getDate().toString().padStart(2, "0");
  const currentMonth: string = (dt.getMonth() + 1).toString().padStart(2, "0");
  const currentHrs: string = dt.getHours().toString().padStart(2, "0");
  const currentMins: string = dt.getMinutes().toString().padStart(2, "0");
  const currentSecs: string = dt.getSeconds().toString().padStart(2, "0");
  const currentYear: number = dt.getFullYear();

  return (
    currentYear +
    "-" +
    currentMonth +
    "-" +
    currentDate +
    "T" +
    currentHrs +
    ":" +
    currentMins +
    ":" +
    currentSecs +
    timezoneStandard
  );
};

export const toLocalString = (dt: Date) => {
  let timezoneOffsetMin: number = new Date().getTimezoneOffset();
  let offsetHrs: string = Math.abs(timezoneOffsetMin / 60).toString();
  let offsetMin: string = Math.abs(timezoneOffsetMin % 60).toString();

  if (parseInt(offsetHrs) < 10) offsetHrs = "0" + offsetHrs;

  if (parseInt(offsetMin) < 10) offsetMin = "0" + offsetMin;

  const currentDate: number = dt.getDate();
  const currentMonth: number = dt.getMonth() + 1;
  const currentHrs: number = dt.getHours();
  const currentMins: string = dt.getMinutes().toString().padStart(2, "0");
  const currentYear: number = dt.getFullYear();
  const period: string = currentHrs > 12 ? "PM" : "AM";

  return `${currentMonth}/${currentDate}/${currentYear} ${
    currentHrs > 12 ? currentHrs - 12 : currentHrs
  }:${currentMins} ${period}`;
};

export const toShortString = (dt: Date) => {
  const currentDate = `${dt.getDate()}`;
  const currentMonth = `${dt.getMonth() + 1}`;
  const currentYear = dt.getFullYear();
  return `${currentMonth.padStart(2, "0")}/${currentDate.padStart(
    2,
    "0"
  )}/${currentYear}`;
};
