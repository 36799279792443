import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  ReactElement,
} from "react";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { AlertColor, AlertProps } from "@mui/material/Alert";

interface AlertState {
  open: boolean;
  message: string;
  severity: AlertColor;
  icon?: ReactElement<SvgIconProps>;
  variant?: AlertProps["variant"];
}

interface AlertContextType {
  alert: AlertState;
  showAlert: (
    message: string,
    severity: AlertState["severity"],
    icon?: ReactElement<SvgIconProps>,
    variant?: AlertProps["variant"]
  ) => void;
  closeAlert: () => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const AlertProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [alert, setAlert] = useState<AlertState>({
    open: false,
    message: "",
    severity: "info",
    variant: "filled",
  });

  const showAlert = (
    message: string,
    severity: AlertState["severity"] = "info",
    icon?: ReactElement<SvgIconProps>,
    variant: AlertProps["variant"] = "filled"
  ) => {
    setAlert({ open: true, message, severity, icon, variant });
  };

  const closeAlert = () => {
    setAlert({
      open: false,
      message: "",
      severity: "info",
      variant: "filled",
      icon: undefined,
    });
  };

  return (
    <AlertContext.Provider value={{ alert, showAlert, closeAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};
