import React, { ReactNode } from "react";
import { IDataService } from "../interfaces/services/DataService";
import { DataService } from "../services/DataService";

const { createContext, useContext } = React;
interface DataServiceProviderProps {
  children: ReactNode;
  dataService?: IDataService;
}
const DataServiceContext = createContext<IDataService>(DataService);

export const DataServiceProvider = ({
  children,
  dataService,
}: DataServiceProviderProps) => {
  return (
    <DataServiceContext.Provider value={dataService || DataService}>
      {children}
    </DataServiceContext.Provider>
  );
};

export const useDataService = () => {
  return useContext(DataServiceContext);
};
