import { Box, SxProps, Theme } from "@mui/material";
import React from "react";
import GlobalSettingsBannerComponent from "./GlobalSettingsBanner";
import SettingsGridComponent from "./SettingsGrid";

export interface SettingsContentProps {
  locationId?: string;
}

const SettingsContentComponent: React.FC<SettingsContentProps> = ({
  locationId,
}) => {
  const settingsContentStyles: Record<string, SxProps<Theme> | undefined> = {
    settingsContentBox: {
      width: "1100px",
      pt: "20px",
      display: "flex",
      flexDirection: "column",
      rowGap: "24px",
      height: "100%",
    },
  };

  return (
    <Box
      data-testid="settingsContent"
      sx={settingsContentStyles.settingsContentBox}
    >
      <GlobalSettingsBannerComponent locationId={locationId} />
      <SettingsGridComponent locationId={locationId} />
    </Box>
  );
};
export default SettingsContentComponent;
