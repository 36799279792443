import {
  Box,
  Checkbox,
  Grid,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";

import { Role } from "../../interfaces/users/Role";
import { User } from "../../interfaces/users/User";

import UserLocationsPopoverComponent from "./UserLocationsPopover";
import UserRolesPopoverComponent from "./UserRolesPopover";

export interface UserImportRowProps {
  user: User;
  id: string;
  onUpdateUser: (id: string, updatedUser: User) => void;
  locationId?: string;
}

export const UserImportRowComponent: React.FC<UserImportRowProps> = ({
  user,
  id,
  onUpdateUser,
  locationId,
}) => {
  const emailIsValid = (email: string) => {
    return /^[a-zA-Z0-9.+-_]*@\w+([\.-]?\w+)*(\.\w{2,})+$/im.test(email);
  };

  const [userEmail, setUserEmail] = React.useState(user.email);
  const [debouncedEmail, setDebouncedEmail] = React.useState(user.email);

  const [isLocationsValid, setIsLocationsValid] = React.useState(
    user.locations.length > 0
  );
  const [isRolesValid, setIsRolesValid] = React.useState(user.roles.length > 0);

  const isUserValid = (
    email: string,
    locations: Array<string | undefined>,
    roles: Array<Role | undefined>
  ) => {
    return emailIsValid(email) && locations.length > 0 && roles.length > 0;
  };

  useEffect(() => {
    const isValid = isUserValid(userEmail, user.locations, user.roles);

    if (isValid) {
      user.isValid = true;
    } else {
      user.isValid = false;
      user.selectedToImport = false;
    }
  }, [userEmail, user.locations, user.roles]);

  useEffect(() => {
    setUserEmail(userEmail);
    setIsLocationsValid(user.locations.length > 0);
    setIsRolesValid(user.roles.length > 0);
  }, [user]);

  useEffect(() => {
    const timerId = setTimeout(() => setDebouncedEmail(userEmail), 500);

    return () => clearTimeout(timerId);
  }, [userEmail]);

  useEffect(() => {
    const isValid = isUserValid(debouncedEmail, user.locations, user.roles);
    const updatedUser = {
      ...user,
      email: debouncedEmail,
      valid: isValid,
    };

    if (debouncedEmail !== user.email) {
      onUpdateUser(user.ricsUserId, updatedUser);
    }
  }, [debouncedEmail, user, onUpdateUser]);

  const onUserChanged = (updatedUser: User) => {
    onUpdateUser(user.ricsUserId, updatedUser);
  };

  const onCheckboxClick = (checkedValue: boolean) => {
    if (user.isValid || !checkedValue) {
      const updatedUser = { ...user, selectedToImport: checkedValue };
      onUserChanged(updatedUser);
    }
  };
  const userImportRowStyles: Record<string, SxProps> = {
    userImportRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      pb: 1,
      pt: 1,
      userSelect: "text",
      width: "100%",
      borderBottom: "1px solid #F4F4F6",
    },
    button: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      color: "primary.main",
      background: "hsla(236, 75%, 65%, 0.07)",
      borderRadius: "4px",
      padding: "5px",
      width: "65%",
      margin: "auto",
    },
  };

  return (
    <Box
      data-testid={`userImportRow-${id}`}
      sx={userImportRowStyles.userImportRow}
    >
      <Grid item xs={1}>
        <Box>
          <Checkbox
            checked={user.selectedToImport}
            data-testid={`userImportRowCheckbox-${id}`}
            onChange={(e) => onCheckboxClick(e.target.checked)}
          />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Typography>{user.firstName}</Typography>
      </Grid>
      <Grid item xs={5}>
        <Typography>{user.lastName}</Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          data-testid="userImportEmailTextField"
          error={!emailIsValid(userEmail)}
          placeholder="Email..."
          size="small"
          value={userEmail}
          variant="outlined"
          onChange={(event) => setUserEmail(event.target.value)}
        />
      </Grid>
      <Grid item xs={4}>
        <Box>
          <UserRolesPopoverComponent
            errorOutline={!isRolesValid}
            id={id}
            user={user}
            onUserChanged={onUserChanged}
          />
        </Box>
      </Grid>
      <Grid item xs={4}>
        {!locationId && (
          <Box>
            <UserLocationsPopoverComponent
              errorOutline={!isLocationsValid}
              id={id}
              user={user}
              onUserChanged={onUserChanged}
            />
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default UserImportRowComponent;
