import React from "react";
import { SxProps, Theme } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

interface LocationProgressBarProps {
  arePaymentsSetup: boolean;
  isBrandingSetup: boolean;
  areUsersSetup: boolean;
  areSettingsSetup: boolean;
  areTaxesSetup: boolean;
}

const locationProgressBarStyles: Record<string, SxProps<Theme>> = {
  progressIconHolderDone: {
    color: "#30B6FF",
  },
  progressIconHolderNotDone: {
    color: "black",
    opacity: "0.1",
  },
};

const LocationProgressBarComponent: React.FC<LocationProgressBarProps> = ({
  arePaymentsSetup,
  isBrandingSetup,
  areUsersSetup,
  areSettingsSetup,
  areTaxesSetup,
}) => {
  const renderHolder = (done: boolean) => {
    const holder =
      done ?
        <CheckCircleRoundedIcon
          sx={locationProgressBarStyles.progressIconHolderDone}
        />
      : <CheckCircleOutlinedIcon
          sx={locationProgressBarStyles.progressIconHolderNotDone}
        />;
    return holder;
  };

  return (
    <>
      {renderHolder(arePaymentsSetup)}
      {renderHolder(isBrandingSetup)}
      {renderHolder(areUsersSetup)}
      {renderHolder(areSettingsSetup)}
      {renderHolder(areTaxesSetup)}
    </>
  );
};

export default LocationProgressBarComponent;
