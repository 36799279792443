import React, { ReactNode } from "react";
import { ApplicationPortal } from "../interfaces/providers/ApplicationPortal";

const { createContext, useContext } = React;

const PortalContext = createContext<ApplicationPortal | null>(null);

type PortalProviderProps = {
  children: ReactNode;
  initialState?: ApplicationPortal;
};

export const PortalProvider = ({
  children,
  initialState,
}: PortalProviderProps) => {
  const value: ApplicationPortal = initialState ?? window.portal;

  return (
    <PortalContext.Provider value={value}>{children}</PortalContext.Provider>
  );
};

export const usePortal = () => {
  return useContext(PortalContext);
};
