import {
  Box,
  Checkbox,
  Grid,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useUsersContext } from "../../../providers/UsersProvider";
import { User } from "../../../interfaces/users/User";

export interface AddUserRowProps {
  user: User;
  locationId?: string;
}

export const AddUserRowComponent: React.FC<AddUserRowProps> = ({ user }) => {
  const { updateUser } = useUsersContext();
  const onCheckboxClick = (checkedValue: boolean) => {
    const updatedUser = { ...user, selectedToImport: checkedValue };
    updateUser(user.userId, updatedUser);
  };
  const addUserRowStyles: Record<string, SxProps> = {
    addUserRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      pb: 1,
      pt: 1,
      userSelect: "text",
      width: "100%",
      borderBottom: "1px solid #F4F4F6",
    },
    button: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      color: "primary.main",
      background: "hsla(236, 75%, 65%, 0.07)",
      borderRadius: "4px",
      padding: "5px",
      width: "65%",
      margin: "auto",
    },
  };

  return (
    <Box
      data-testid={`addUserRow-${user.userId}`}
      sx={addUserRowStyles.addUserRow}
    >
      <Grid item xs={1}>
        <Box>
          <Checkbox
            checked={user.selectedToImport}
            data-testid={`addUserRowCheckbox-${user.userId}`}
            onChange={(e) => onCheckboxClick(e.target.checked)}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Typography>{user.userId}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography>{user.firstName}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography>{user.lastName}</Typography>
      </Grid>
      <Grid item xs={3}>
        <TextField
          disabled
          data-testid="addUserEmailTextField"
          placeholder="Email..."
          size="small"
          value={user.email}
          variant="outlined"
        />
      </Grid>
    </Box>
  );
};

export default AddUserRowComponent;
