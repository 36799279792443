import React, { useEffect } from "react";
import {
  Box,
  Checkbox,
  Grid,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";

import { User } from "../../interfaces/users/User";
import UserLocationsPopoverComponent from "./UserLocationsPopover";
import UserRolesPopoverComponent from "./UserRolesPopover";
import UserActionsPopoverComponent from "./UserActionsPopover";
import { useUsersContext } from "../../providers/UsersProvider";

export interface UserGridRowProps {
  user: User;
  id: string;
  setValidity: (id: string, isValid: boolean) => void;
  setClickedUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  deleteUserIsEnabled: boolean;
}

const UserGridRowComponent: React.FC<UserGridRowProps> = ({
  user,
  id,
  setValidity,
  setClickedUser,
  deleteUserIsEnabled,
}) => {
  const [isFirstTime, setIsFirstTime] = React.useState(true);

  const [phoneNumber, setPhoneNumber] = React.useState<
    string | null | undefined
  >(user.phoneNumber);
  const [userEmail, setUserEmail] = React.useState<string | null | undefined>(
    user.email
  );

  const [timer, setTimer] = React.useState<NodeJS.Timeout>(
    setTimeout(() => {}, 1)
  );
  const { updateUser } = useUsersContext();

  useEffect(() => {
    if (isFirstTime) {
      setIsFirstTime(false);
    } else {
      resetTimer();
      handleUpdateUser();
    }
  }, [phoneNumber, userEmail, user.isValid]);

  useEffect(() => {
    setPhoneNumber(user.phoneNumber);
    setUserEmail(user.email);
  }, [user]);

  const onUserChanged = (user: User) => {
    const updatedUser = { ...user, isChecked: true };
    updateUser(id, updatedUser);
  };

  const handleUpdateUser = () => {
    const isValid = phoneIsValid(phoneNumber) && emailIsValid(userEmail);
    const updatedUser = {
      ...user,
      phoneNumber: phoneNumber ?? "",
      email: userEmail ?? "",
      isValid: isValid,
      isChecked: isValid ? true : false,
    };
    setValidity(user.userId, isValid);
    updateUser(user.userId, updatedUser);
  };

  const handleCheckUser = (checkedValue: boolean) => {
    if (user.isValid) {
      const updatedUser = { ...user, isChecked: checkedValue };
      updateUser(user.userId, updatedUser);
    }
  };

  const resetTimer = () => {
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        handleUpdateUser();
      }, 500)
    );
  };

  const phoneIsValid = (phone: string | null | undefined): boolean => {
    if (!phone || phone === "") {
      return true;
    }

    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
      phone
    );
  };

  const emailIsValid = (email: string | null | undefined): boolean => {
    if (!email || email === "") {
      return false;
    }

    return /^[a-zA-Z0-9.+-_]*@\w+([\.-]?\w+)*(\.\w{2,})+$/im.test(email);
  };
  const userGridRowStyles: Record<string, SxProps> = {
    userGridRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 1,
      userSelect: "text",
      width: "100%",
      borderBottom: "1px solid #F4F4F6",
    },
    button: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      color: "primary.main",
      background: "hsla(236, 75%, 65%, 0.07)",
      borderRadius: "4px",
      padding: "5px",
      width: "65%",
      margin: "auto",
    },
    textfield: {
      width: "90%",
    },
  };

  return (
    <Box data-testid={`user-row-${id}`} sx={userGridRowStyles.userGridRow}>
      <Grid item xs={1}>
        <Box>
          <Checkbox
            checked={user.isChecked == true}
            data-testid={`userRowCheckbox-${id}`}
            onChange={(e) => handleCheckUser(e.target.checked)}
          />
        </Box>
      </Grid>
      <Grid item xs={2}>
        <Typography>{user.firstName}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>{user.lastName}</Typography>
      </Grid>
      <Grid item xs={4}>
        <TextField
          data-testid="emailTextField"
          error={!emailIsValid(userEmail)}
          placeholder="Email..."
          size="small"
          sx={userGridRowStyles.textfield}
          value={userEmail}
          variant="outlined"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUserEmail(event.target.value);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          data-testid="phoneNumberTextField"
          error={!phoneIsValid(phoneNumber)}
          placeholder="Phone..."
          size="small"
          sx={userGridRowStyles.textfield}
          value={phoneNumber}
          variant="outlined"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPhoneNumber(event.target.value);
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Box>
          <UserRolesPopoverComponent
            id={id}
            user={user}
            onUserChanged={onUserChanged}
          />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Box>
          <UserLocationsPopoverComponent
            id={id}
            user={user}
            onUserChanged={onUserChanged}
          />
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box>
          <UserActionsPopoverComponent
            deleteUserIsEnabled={deleteUserIsEnabled}
            id={id}
            setClickedUser={setClickedUser}
            user={user}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default UserGridRowComponent;
