import React, { createContext, useContext, ReactNode, useState } from "react";
import { SpecialTaxEvent, Tax } from "../interfaces/settings/Taxes";
import { useXhrService } from "./XhrServiceProvider";
import { usePortal } from "./PortalProvider";

export interface TaxesContextProps {
  taxes: Tax[];
  setTaxes: React.Dispatch<React.SetStateAction<Tax[]>>;
  specialTaxEvents: SpecialTaxEvent[];
  setSpecialTaxEvents: React.Dispatch<React.SetStateAction<SpecialTaxEvent[]>>;
  addTax: (newTax: Tax) => void;
  deleteTax: (taxId: string) => void;
  updateTax: (updatedTax: Tax) => void;
  getSpecialTaxEvents: (locationId: string) => void;
  deleteSpecialTaxEvent: (specialTaxEvent: SpecialTaxEvent) => void;
  addOrUpdateSpecialTaxEvent: (specialTaxEvent: SpecialTaxEvent) => void;
}

const TaxesContext = createContext<TaxesContextProps | undefined>(undefined);

type TaxesProviderProps = {
  children: ReactNode;
  initialState?: Partial<TaxesContextProps>;
};

export const TaxesProvider: React.FC<TaxesProviderProps> = ({
  children,
  initialState,
}) => {
  const [taxes, setTaxes] = useState<Tax[]>(initialState?.taxes || []);
  const [specialTaxEvents, setSpecialTaxEvents] = useState<SpecialTaxEvent[]>(
    initialState?.specialTaxEvents || []
  );
  const portal = usePortal();
  const xhrService = useXhrService();

  const addTax = (newTax: Tax) => {
    setTaxes((currentTaxes) => [...currentTaxes, newTax]);
  };

  const updateTax = (updatedTax: Tax) => {
    setTaxes((currentTaxes) =>
      currentTaxes.map((tax) =>
        tax.taxId === updatedTax.taxId ? updatedTax : tax
      )
    );
  };

  const deleteTax = (taxId: string) => {
    if (taxId.includes("temp-")) {
      setTaxes((currentTaxes) =>
        currentTaxes.filter((tax) => tax.taxId !== taxId)
      );
    } else {
      setTaxes((currentTaxes) =>
        currentTaxes.map((tax) =>
          tax.taxId === taxId ? { ...tax, locationIds: undefined } : tax
        )
      );
    }
  };

  const getSpecialTaxEvents = (locationId: string) => {
    portal!.navigation.isLoading(true);
    const endpoint = `v1/Taxes/Events/Locations/${locationId}`;

    const onSuccess = function (response: string) {
      var taxEvents = JSON.parse(response) as SpecialTaxEvent[];
      setSpecialTaxEvents(taxEvents);
      portal!.navigation.isLoading(false);
    };

    xhrService!.doRicsApiXhr(endpoint, null, onSuccess, undefined, "GET");
  };

  const addOrUpdateSpecialTaxEvent = (specialTaxEvent: SpecialTaxEvent) => {
    setSpecialTaxEvents((currentEvents) => {
      const index = currentEvents.findIndex(
        (event) => event.eventId === specialTaxEvent.eventId
      );
      if (index !== -1) {
        return currentEvents.map((event, idx) =>
          idx === index ? specialTaxEvent : event
        );
      } else {
        return [...currentEvents, specialTaxEvent];
      }
    });
  };

  const deleteSpecialTaxEvent = (eventToDelete: SpecialTaxEvent) => {
    const modifiedEventForDeletion = {
      ...eventToDelete,
      locationIds: undefined,
    };
    xhrService!.doRicsApiXhr(
      `v1/Taxes/Events/`,
      modifiedEventForDeletion,
      (response) => {
        console.log("Delete successful:", response);

        setSpecialTaxEvents((currentEvents) =>
          currentEvents.filter(
            (event) => event.eventId !== eventToDelete.eventId
          )
        );
      },
      (error) => {
        console.error("Delete failed:", error);
      },
      "PUT"
    );
  };

  const value = {
    taxes,
    setTaxes,
    specialTaxEvents,
    setSpecialTaxEvents,
    addTax,
    deleteTax,
    updateTax,
    getSpecialTaxEvents,
    addOrUpdateSpecialTaxEvent,
    deleteSpecialTaxEvent,
  };

  return (
    <TaxesContext.Provider value={value}>{children}</TaxesContext.Provider>
  );
};

export const useTaxesContext = () => {
  const context = useContext(TaxesContext);
  if (context === undefined) {
    throw new Error("useTaxesContext must be used within a TaxesProvider");
  }
  return context;
};
