import { Box, Grid, List, SxProps, Theme, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "@hello-pangea/dnd";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { featureFlags } from "../../../constants/featureFlags";
import { returnMethodIds } from "../../../constants/settings/refunds/returnMethodIds";
import { MerchantType } from "../../../enums/MerchantType";
import { ReturnMethods } from "../../../interfaces/salesettings/ReturnMethods";
import { ReturnReasons } from "../../../interfaces/salesettings/ReturnReasons";
import { SaleSettings } from "../../../interfaces/salesettings/SaleSettings";
import { usePortal } from "../../../providers/PortalProvider";
import { InfoTooltipComponent } from "../../common/InfoTooltip";
import LeftRightContentComponent from "../../common/LeftRightContent";
import StyledSwitchComponent from "../../styled/StyledSwitch";
import { handleLocationsOverrideChange } from "../LocationsCanOverrideToggle";
import { ReturnMethodItemComponent } from "../cardComponents/refunds/ReturnMethodItem";
import { ReturnReasonItemComponent } from "../cardComponents/refunds/ReturnReasonItem";
import SettingsCardComponent from "./SettingsCard";

export interface RefundsProps {
  id: string;
  locationSettings: SaleSettings;
  onSaveSettingsCard: (
    settingsToSave: SaleSettings,
    modifySettings?: (settings: SaleSettings, locationId?: string) => void
  ) => Promise<void>;
  locationId?: string;
  posDeviceSettingsEnabled?: boolean;
  onboardingWorkflow?: boolean;
}

export const RefundsComponent: React.FC<RefundsProps> = ({
  id,
  locationSettings,
  onSaveSettingsCard,
  locationId,
}) => {
  const portal = usePortal();
  const globalTheme = useTheme();
  const flags = useFlags();

  const isRicsPayMerchant =
    locationSettings.merchantSettings?.merchantType == MerchantType.RicsPay ??
    false;

  const filterReturnMethods = (returnMethods: ReturnMethods[]) => {
    const filteredMethods = returnMethods!
      .slice()
      .sort((a, b) => a.returnMethodId.localeCompare(b.returnMethodId))
      .map((method) => {
        let isVisible = true;
        if (
          method.returnMethodId === returnMethodIds.AlternateCredit.id &&
          method.returnMethodSubTypeId ===
            returnMethodIds.AlternateCredit.subTypeId
        ) {
          isVisible = isRicsPayMerchant;
        }

        return { ...method, isVisible };
      });

    return filteredMethods;
  };

  const [canSave, setCanSave] = React.useState(false);
  const [canLocationsOverride, setCanLocationsOverride] = useState(
    portal?.state.saleSettings["Tenant"]?.locationsCanOverrideRefunds ?? true
  );
  const [returnMethods, setReturnMethods] = React.useState(
    filterReturnMethods(locationSettings.returnMethods!)
  );
  const [tenderTypeOptions, setTenderTypeOptions] = React.useState(
    locationSettings.tenderTypeOptions
  );
  const [returnReasons, setReturnReasons] = React.useState(
    locationSettings.returnReasons
  );
  const [isStandAloneReturnsEnabled, setIsStandAloneReturnsEnabled] =
    React.useState(locationSettings.enableStandAloneReturns ?? false);

  const [isDisabled, setIsDisabled] = React.useState(false);
  const [isNoReturnMethodsErrorVisible, setShowIsNoReturnMethodsErrorVisible] =
    useState(
      locationSettings.returnMethods?.every((option) => !option.enabled)
    );
  const [isNoReturnReasonsErrorVisible, setShowIsNoReturnReasonsErrorVisible] =
    useState(
      locationSettings.returnReasons?.every((option) => !option.enabled)
    );

  const [isReturnMethodsUpdated, setIsReturnMethodsUpdated] = useState(false);
  const [isReturnReasonsUpdated, setIsReturnReasonsUpdated] = useState(false);

  const canLocationsOverrideInitial =
    portal!.state.saleSettings["Tenant"].locationsCanOverrideRefunds ?? true;

  const isStandAloneReturnsEnabledInitial =
    locationSettings.enableStandAloneReturns ?? false;

  const canSaveCheck = () => {
    const isAnyReturnMethodEnabled = returnMethods!.some(
      (option) => option.enabled
    );
    const isAnyReturnReasonEnabled = returnReasons!.some(
      (option) => option.enabled
    );

    const hasCanLocationsOverrideChanged =
      canLocationsOverride !== canLocationsOverrideInitial;

    const hasStandAloneReturnsChanged =
      isStandAloneReturnsEnabled !== isStandAloneReturnsEnabledInitial;

    setShowIsNoReturnMethodsErrorVisible(!isAnyReturnMethodEnabled);
    setShowIsNoReturnReasonsErrorVisible(!isAnyReturnReasonEnabled);

    return (
      isAnyReturnMethodEnabled &&
      isAnyReturnReasonEnabled &&
      (isReturnMethodsUpdated ||
        isReturnReasonsUpdated ||
        hasCanLocationsOverrideChanged ||
        hasStandAloneReturnsChanged)
    );
  };

  useEffect(() => {
    setIsDisabled(!!locationId && !canLocationsOverride);
  }, [locationId, canLocationsOverride]);

  useEffect(() => {
    setCanSave(canSaveCheck());
  }, [
    returnMethods,
    returnReasons,
    canLocationsOverride,
    isStandAloneReturnsEnabled,
  ]);

  useEffect(() => {
    const isClutchReturnMethodEnabled =
      isReturnMethodEnabled(
        returnMethodIds.Clutch.id,
        returnMethodIds.Clutch.subTypeId
      ) || false;

    const updatedTenderOptions = tenderTypeOptions!.map((option) => {
      if (
        option.tenderTypeOptionId === returnMethodIds.Clutch.id &&
        option.tenderTypeOptionSubTypeId === returnMethodIds.Clutch.subTypeId
      ) {
        return { ...option, enabled: isClutchReturnMethodEnabled };
      }
      return option;
    });

    if (
      JSON.stringify(tenderTypeOptions) !== JSON.stringify(updatedTenderOptions)
    ) {
      setTenderTypeOptions(updatedTenderOptions);
    }
  }, [tenderTypeOptions, returnMethods]);

  const handleSaveSettings = () => {
    const settingsToSave: SaleSettings = {
      tenderTypeOptions: tenderTypeOptions,
      returnMethods: returnMethods,
      returnReasons: returnReasons,
      locationId: locationId,
      enableStandAloneReturns: isStandAloneReturnsEnabled,
    };

    const modifySettings = (settings: SaleSettings) => {
      if (!locationId) {
        settings.locationsCanOverrideRefunds = canLocationsOverride;
      } else {
        if (settings.deviceSettings) {
          settings.deviceSettings.locationsCanOverride = undefined;
        }
        settings.locationsCanOverridePaymentDetails = undefined;
        settings.locationsCanOverrideRefunds = undefined;
      }
    };

    onSaveSettingsCard(settingsToSave, modifySettings);
    setCanSave(false);
  };
  const onUpdateReturnMethods = (index: number) => {
    setReturnMethods((prevReturnMethods) => {
      const updatedMethods = [...prevReturnMethods];
      const methodBeingToggled = updatedMethods[index];

      methodBeingToggled.enabled = !methodBeingToggled.enabled;

      if (
        methodBeingToggled.returnMethodId ===
          returnMethodIds.AlternateCredit.id &&
        methodBeingToggled.returnMethodSubTypeId ===
          returnMethodIds.AlternateCredit.subTypeId &&
        methodBeingToggled.enabled
      ) {
        const creditIndex = updatedMethods.findIndex(
          (m) =>
            m.returnMethodId === returnMethodIds.Credit.id &&
            !m.returnMethodSubTypeId
        );
        if (creditIndex !== -1) {
          updatedMethods[creditIndex].enabled = true;
        }
      } else if (
        methodBeingToggled.returnMethodId === returnMethodIds.Credit.id &&
        !methodBeingToggled.returnMethodSubTypeId &&
        !methodBeingToggled.enabled
      ) {
        const altCreditIndex = updatedMethods.findIndex(
          (m) =>
            m.returnMethodId === returnMethodIds.AlternateCredit.id &&
            m.returnMethodSubTypeId ===
              returnMethodIds.AlternateCredit.subTypeId
        );
        if (altCreditIndex !== -1) {
          updatedMethods[altCreditIndex].enabled = false;
        }
      }

      return updatedMethods;
    });

    setIsReturnMethodsUpdated(true);
  };

  const updateReturnReasons = (
    index: number,
    changes: Partial<ReturnReasons>
  ) => {
    setReturnReasons((currentReasons) => {
      const updatedReasons = [...currentReasons!];
      updatedReasons[index] = { ...updatedReasons[index], ...changes };
      return updatedReasons;
    });
    setIsReturnReasonsUpdated(true);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const updatedReturnReasons = Array.from(returnReasons!);
    const [reorderedItem] = updatedReturnReasons.splice(result.source.index, 1);

    updatedReturnReasons.splice(result.destination.index, 0, reorderedItem);
    updatedReturnReasons.forEach((element) => {
      element.sortOrder = updatedReturnReasons.indexOf(element);
    });

    setReturnReasons(updatedReturnReasons);
    setIsReturnReasonsUpdated(true);
  };

  const isReturnMethodEnabled = (id: string, subTypeId?: string) => {
    const method = returnMethods!.find(
      (m) =>
        m.returnMethodId === id &&
        (subTypeId === undefined || m.returnMethodSubTypeId === subTypeId)
    );
    return method && method.enabled;
  };

  const standaloneReturnsTooltipText =
    "When toggled on, individual locations can issue stand-alone returns without a prior sale. When toggled off, stand-alone returns will be disabled.";
  const refundsStyles: Record<string, SxProps<Theme>> = {
    behaviorList: {
      width: "85%",
    },

    errorTypography: {
      color: "red",
      textAlign: "center",
    },
    roundingSecondaryText: {
      color: globalTheme.palette.common.gray,
    },
    standaloneReturnsBox: {
      display: "flex",
      alignItems: "center",
      gap: 2,
    },
  };

  return (
    <SettingsCardComponent
      canSave={canSave}
      disabled={isDisabled}
      headerText="Refunds"
      id={id}
      locationsCanOverrideToggleProps={{
        locationsCanOverride: canLocationsOverride,
        onChange: handleLocationsOverrideChange(setCanLocationsOverride),
        disabled: !!locationId,
      }}
      subHeaderText="Specify supported refund methods and reasons for return."
      onSave={handleSaveSettings}
    >
      <Box>
        <LeftRightContentComponent
          leftHeading="Refund Methods"
          rightHeading="How can sales associates offer refunds?"
        >
          <Box>
            <Grid container spacing={1}>
              {returnMethods.map(
                (returnMethod, index) =>
                  returnMethod.isVisible && (
                    <ReturnMethodItemComponent
                      index={index}
                      isOptionDisabled={isDisabled}
                      key={
                        returnMethod.returnMethodId +
                        returnMethod.returnMethodSubTypeId
                      }
                      locationId={locationId}
                      returnMethod={returnMethod}
                      onUpdateReturnMethods={onUpdateReturnMethods}
                    />
                  )
              )}
            </Grid>
          </Box>
        </LeftRightContentComponent>
        {isNoReturnMethodsErrorVisible && (
          <Typography sx={refundsStyles.errorTypography}>
            You must enable at least one return method.
          </Typography>
        )}
      </Box>
      <Box>
        <LeftRightContentComponent
          leftHeading="Return Reasons"
          rightHeading="When an item is returned, associates are required to specify a reason. We have provided some defaults, but you're welcome to add your own, and drag to reorder them."
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" isDropDisabled={isDisabled}>
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={refundsStyles.behaviorList}
                >
                  {returnReasons!.map((returnReason, index) => (
                    <Draggable
                      draggableId={returnReason.returnReasonId}
                      index={index}
                      isDragDisabled={isDisabled}
                      key={returnReason.returnReasonId}
                    >
                      {(provided, snapshot) => (
                        <ReturnReasonItemComponent
                          index={index}
                          isOptionDisabled={isDisabled}
                          provided={provided}
                          returnReason={returnReason}
                          snapshot={snapshot}
                          updateReturnReasons={updateReturnReasons}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </LeftRightContentComponent>
        {isNoReturnReasonsErrorVisible && (
          <Typography sx={refundsStyles.errorTypography}>
            You must enable at least one return reason.
          </Typography>
        )}
      </Box>
      <LeftRightContentComponent
        hidden={!flags[featureFlags.StandAloneReturns]}
        leftHeading="Miscellaneous"
        rightHeading="Other settings"
      >
        <Box sx={refundsStyles.standaloneReturnsBox}>
          <StyledSwitchComponent
            checked={isStandAloneReturnsEnabled}
            disabled={isDisabled}
            onChange={(e) => setIsStandAloneReturnsEnabled(e.target.checked)}
          />
          <Typography>Enable standalone returns</Typography>
          <InfoTooltipComponent
            hoverText={standaloneReturnsTooltipText}
            placement="top"
          />
        </Box>
      </LeftRightContentComponent>
    </SettingsCardComponent>
  );
};
