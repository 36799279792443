import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  SxProps,
  Theme,
  Typography,
} from "@mui/material/";
import { useTheme } from "@mui/material/styles";
import PrimaryButtonComponent from "../../../styled/PrimaryButton";
import { useTaxesContext } from "../../../../providers/TaxesProvider";
import { useXhrService } from "../../../../providers/XhrServiceProvider";
import {
  ProductTaxCondition,
  ProductTaxEventCondition,
  SpecialTaxEvent,
} from "../../../../interfaces/settings/Taxes";
import { AdvancedTaxConfigurationTableComponent } from "./AdvancedTaxConfigurationTable";
import { AdvancedSpecialTaxConfigurationTableComponent } from "./AdvancedSpecialTaxConfigurationTable";
import { Tax } from "../../../../interfaces/settings/Taxes";

export interface AdvancedTaxConfigurationDialogProps {
  isAdvancedTaxConfigurationDialogOpen: boolean;
  isEvent: boolean;
  setisAdvancedTaxConfigurationDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  specialTaxEventId?: string;
}

export const AdvancedTaxConfigurationDialogComponent: React.FC<
  AdvancedTaxConfigurationDialogProps
> = ({
  isAdvancedTaxConfigurationDialogOpen,
  setisAdvancedTaxConfigurationDialogOpen,
  isEvent,
  specialTaxEventId,
}) => {
  const { taxes, updateTax, specialTaxEvents, addOrUpdateSpecialTaxEvent } =
    useTaxesContext();
  const xhrService = useXhrService();
  const globalTheme = useTheme();
  const [selectedTaxId, setSelectedTaxId] = useState<string>(taxes[0].taxId);
  const [currentProductTaxConditions, setCurrentProductTaxConditions] =
    React.useState<ProductTaxCondition[]>([]);

  const [currentTaxEventConditions, setCurrentProductTaxEventConditions] =
    React.useState<ProductTaxEventCondition[]>([]);

  useEffect(() => {
    if (!isEvent) {
      createProductTaxes();
    } else {
      createProductEventTaxes();
    }
  }, []);

  const saveTaxConfiguration = async (taxToSave: Tax) => {
    return new Promise((resolve, reject) => {
      xhrService!.doRicsApiXhr(
        "v1/Taxes",
        taxToSave,
        (response) => {
          resolve(JSON.parse(response));
        },
        (error) => {
          console.error("Save tax failed", error);
          reject(error);
        },
        "PUT"
      );
    }).catch((error) => {
      console.error("An error occurred during the tax save operation", error);
    });
  };

  const saveEventConfiguration = async (eventToSave: SpecialTaxEvent) => {
    return new Promise((resolve, reject) => {
      xhrService!.doRicsApiXhr(
        "v1/Taxes/Events",
        eventToSave,
        (response) => {
          resolve(JSON.parse(response));
        },
        (error) => {
          console.error("Save tax event failed", error);
          reject(error);
        },
        "PUT"
      );
    }).catch((error) => {
      console.error("An error occurred during the event save operation", error);
    });
  };

  const saveTaxes = async () => {
    try {
      for (const tax of taxes) {
        const matchingProductTaxes = currentProductTaxConditions.filter(
          (pt) => pt.taxId === tax.taxId && pt.productType
        );
        if (matchingProductTaxes.length === 0) continue;

        const updatedTax = {
          ...tax,
          productTaxes: matchingProductTaxes,
        };

        updateTax(updatedTax);

        await saveTaxConfiguration(updatedTax);
      }

      setisAdvancedTaxConfigurationDialogOpen(false);
    } catch (error) {
      console.error("Error saving taxes:", error);
    }
  };
  const saveSpecialTaxEvent = async () => {
    const currentSpecialTaxEvent = specialTaxEvents.find(
      (event) => event.eventId === specialTaxEventId
    );

    if (!currentSpecialTaxEvent) {
      console.error("No tax event with eventId:", specialTaxEventId);
      return;
    }

    const updatedProductTaxes = currentTaxEventConditions.filter(
      (t) => t.productType
    );

    const updatedTaxEvent = {
      ...currentSpecialTaxEvent,
      productTaxes: updatedProductTaxes,
    };
    addOrUpdateSpecialTaxEvent(updatedTaxEvent);

    await saveEventConfiguration(updatedTaxEvent);
    setisAdvancedTaxConfigurationDialogOpen(false);
  };

  const createProductTaxes = () => {
    const currentProductTaxes: ProductTaxCondition[] = [];

    const existingProductTaxes: ProductTaxCondition[] = taxes.reduce<
      ProductTaxCondition[]
    >((acc, tax) => {
      if (tax.productTaxes) {
        const updatedProductTaxes = tax.productTaxes.map((productTax) => ({
          ...productTax,
          taxId: tax.taxId,
          id: uuidv4(),
        }));
        acc = acc.concat(updatedProductTaxes);
      }
      return acc;
    }, []);

    for (let i = 0; i < existingProductTaxes.length; i++) {
      if (!!existingProductTaxes && i < existingProductTaxes.length) {
        currentProductTaxes.push(existingProductTaxes[i]);
      }
    }

    setCurrentProductTaxConditions(currentProductTaxes);
  };

  const createProductEventTaxes = () => {
    const currentProductEventTaxes: ProductTaxEventCondition[] = [];

    const existingProductEventTaxes = specialTaxEvents.find(
      (x) => x.eventId === specialTaxEventId
    )?.productTaxes;

    for (let i = 0; i < 30; i++) {
      if (!!existingProductEventTaxes && i < existingProductEventTaxes.length) {
        currentProductEventTaxes.push({
          ...existingProductEventTaxes[i],
          id: uuidv4(),
        });
      }
    }

    setCurrentProductTaxEventConditions(currentProductEventTaxes);
  };

  const addEmptyTaxConditionRow = () => {
    setCurrentProductTaxConditions((prevConditions) => [
      ...prevConditions,
      {
        id: uuidv4(),
        productType: "",
        taxId: selectedTaxId,
        taxRate: undefined,
        threshold: undefined,
        onlyTaxAboveThreshold: undefined,
      } as ProductTaxCondition,
    ]);
  };

  const addEmptyTaxEventConditionRow = () => {
    setCurrentProductTaxEventConditions((prevConditions) => [
      ...prevConditions,
      {
        id: uuidv4(),
        productType: "",
        taxRate: undefined,
        taxId: selectedTaxId,
      } as ProductTaxEventCondition,
    ]);
  };

  const handleClose = () => {
    setisAdvancedTaxConfigurationDialogOpen(false);
  };

  const handleItemClick = (taxId: string) => {
    setSelectedTaxId(taxId);
  };

  const advancedTaxConfigurationStyles: Record<string, SxProps<Theme>> = {
    taxItem: {
      p: 2,
      cursor: "pointer",
      borderBottom: 1,
      borderColor: globalTheme.palette.common.inactive,
    },
    selectedTaxItem: {},
    dialogContent: {
      padding: 0,
      borderTop: 1,
      borderBottom: 1,
      borderColor: globalTheme.palette.common.inactive,
    },
    taxJurisdictionGrid: {
      borderRight: 1,
      borderColor: globalTheme.palette.common.inactive,
    },
    headerBox: {
      display: "flex",
      flexDirection: "column",
      gap: 2,
    },
    addRowBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      mt: 1,
      mb: 1,
    },
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={isAdvancedTaxConfigurationDialogOpen}
      sx={advancedTaxConfigurationStyles.dialogContainer}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box sx={advancedTaxConfigurationStyles.headerBox}>
          <Typography fontWeight="bold" variant="h6">
            {isEvent ?
              "Advanced Tax Event Configuration"
            : "Advanced Tax Configuration"}
          </Typography>
          <Typography
            color={globalTheme.palette.common.gray}
            variant="subtitle2"
          >
            Choose a tax jurisdiction from the left, then click cells in the
            table to edit specific product classes.
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent sx={advancedTaxConfigurationStyles.dialogContent}>
        <Grid container>
          <Grid
            item
            sx={advancedTaxConfigurationStyles.taxJurisdictionGrid}
            xs={2}
          >
            {taxes.map((tax) => (
              <Grid
                item
                key={tax.taxId}
                sx={{
                  ...advancedTaxConfigurationStyles.taxItem,
                  ...(selectedTaxId === tax.taxId && {
                    backgroundColor: globalTheme.palette.primary.main,
                    color: "#FFFFFF",
                  }),
                }}
                xs={12}
                onClick={() => handleItemClick(tax.taxId)}
              >
                <Typography variant="body1">{tax.description}</Typography>
              </Grid>
            ))}
          </Grid>

          <Grid item xs={10}>
            <Box>
              {!isEvent ?
                <AdvancedTaxConfigurationTableComponent
                  productTaxConditions={currentProductTaxConditions}
                  selectedTaxId={selectedTaxId}
                  setProductTaxConditions={setCurrentProductTaxConditions}
                />
              : <AdvancedSpecialTaxConfigurationTableComponent
                  productTaxEventConditions={currentTaxEventConditions}
                  setProductTaxEventConditions={
                    setCurrentProductTaxEventConditions
                  }
                  taxId={selectedTaxId}
                />
              }
              <Box sx={advancedTaxConfigurationStyles.addRowBox}>
                <PrimaryButtonComponent
                  size="large"
                  onClick={() =>
                    isEvent ?
                      addEmptyTaxEventConditionRow()
                    : addEmptyTaxConditionRow()
                  }
                >
                  + Add Row
                </PrimaryButtonComponent>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <PrimaryButtonComponent
          autoFocus
          onClick={isEvent ? saveSpecialTaxEvent : saveTaxes}
        >
          Save Configuration
        </PrimaryButtonComponent>
      </DialogActions>
    </Dialog>
  );
};
