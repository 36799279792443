import { apiVersions } from "../constants/apiVersions";
import { Device } from "../interfaces/devices/Device";
import { IDevicesService } from "../interfaces/services/DevicesService";
import { DevicesResponse } from "../interfaces/responses/DevicesResponse";
import { doRicsApiXhr } from "./XhrService";
const getDevices = (): Promise<Array<Device>> => {
  return new Promise((resolve, reject) => {
    const endpoint = `v${apiVersions.Default}/Devices`;

    const onSuccess = (response: string) => {
      const devices: Array<Device> = JSON.parse(response);
      resolve(devices);
    };

    const onFailure = () => {
      reject();
    };

    doRicsApiXhr(endpoint, null, onSuccess, onFailure);
  });
};

const declineDeviceActivation = (
  deviceId: string,
  locationId?: string
): Promise<Device> => {
  return new Promise((resolve, reject) => {
    const locationPart = `${locationId ? `/Locations/${locationId}` : ""}`;
    const endpoint = `v${apiVersions.Default}/Devices${locationPart}/${deviceId}/DeclineActivation`;

    const onSuccess = (response: string) => {
      const device: Device = JSON.parse(response);
      resolve(device);
    };

    const onFailure = () => {
      reject();
    };

    doRicsApiXhr(endpoint, null, onSuccess, onFailure);
  });
};

const declineDeviceActivations = (
  deviceIds: Array<string>,
  locationId?: string
): Promise<Array<Device>> => {
  return new Promise((resolve, reject) => {
    const locationPart = `${locationId ? `/Locations/${locationId}` : ""}`;
    const endpoint = `v${apiVersions.Default}/Devices${locationPart}/DeclineActivation`;

    const onSuccess = (response: string) => {
      const devicesResponse: DevicesResponse = JSON.parse(response);
      resolve(devicesResponse.devices);
    };

    const onFailure = () => {
      reject();
    };

    doRicsApiXhr(endpoint, deviceIds, onSuccess, onFailure, "POST");
  });
};

const activateDevice = (
  deviceId: string,
  locationId?: string
): Promise<Device> => {
  return new Promise((resolve, reject) => {
    const locationPart = `${locationId ? `/Locations/${locationId}` : ""}`;
    const endpoint = `v${apiVersions.Default}/Devices${locationPart}/${deviceId}/Activate`;

    const onSuccess = (response: string) => {
      const device: Device = JSON.parse(response);
      resolve(device);
    };

    const onFailure = () => {
      reject();
    };

    doRicsApiXhr(endpoint, null, onSuccess, onFailure);
  });
};

const activateDevices = (
  deviceIds: Array<string>,
  locationId?: string
): Promise<Array<Device>> => {
  return new Promise((resolve, reject) => {
    const locationPart = `${locationId ? `/Locations/${locationId}` : ""}`;
    const endpoint = `v${apiVersions.Default}/Devices${locationPart}/Activate`;

    const onSuccess = (response: string) => {
      const devicesResponse: DevicesResponse = JSON.parse(response);
      resolve(devicesResponse.devices);
    };

    const onFailure = () => {
      reject();
    };

    doRicsApiXhr(endpoint, deviceIds, onSuccess, onFailure, "POST");
  });
};

const deleteDevice = (
  deviceId: string,
  locationId?: string
): Promise<Device> => {
  return new Promise((resolve, reject) => {
    const locationPart = `${locationId ? `/Locations/${locationId}` : ""}`;
    const endpoint = `v${apiVersions.Default}/Devices${locationPart}/${deviceId}`;

    const onSuccess = (response: string) => {
      const device: Device = JSON.parse(response);
      resolve(device);
    };

    const onFailure = () => {
      reject();
    };

    doRicsApiXhr(endpoint, null, onSuccess, onFailure, "DELETE");
  });
};

const deleteDevices = (
  deviceIds: Array<string>,
  locationId?: string
): Promise<Array<Device>> => {
  return new Promise((resolve, reject) => {
    const locationPart = `${locationId ? `/Locations/${locationId}` : ""}`;
    const endpoint = `v${apiVersions.Default}/Devices${locationPart}/Delete`;

    const onSuccess = (response: string) => {
      const devicesResponse: DevicesResponse = JSON.parse(response);
      resolve(devicesResponse.devices);
    };

    const onFailure = () => {
      reject();
    };

    doRicsApiXhr(endpoint, deviceIds, onSuccess, onFailure, "DELETE");
  });
};

export const DevicesService: IDevicesService = {
  getDevices: getDevices,
  declineDeviceActivation: declineDeviceActivation,
  declineDeviceActivations: declineDeviceActivations,
  activateDevice: activateDevice,
  activateDevices: activateDevices,
  deleteDevice: deleteDevice,
  deleteDevices: deleteDevices,
};
