import TvOutlinedIcon from "@mui/icons-material/TvOutlined";
import { Box, SxProps, Theme, useTheme } from "@mui/material";
import React, { useState } from "react";
import TenantLevelPageHeader from "../components/common/TenantLevelPageHeader";
import DevicesGrid from "../components/devices/DevicesGrid";

const DevicesPage: React.FC = () => {
  const theme = useTheme();
  const [devicesCount, setDevicesCount] = useState(0);

  const devicesStyles: Record<string, SxProps<Theme> | undefined> = {
    devicesContentBox: {
      position: "relative",
      backgroundColor: theme.palette.common.contentPageBackground,
      pt: 2,
      flexGrow: 1,
      overflow: "auto",
      width: "100%",
    },
    devicesGridContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      width: "80%",
      margin: "0 auto",
    },
  };

  return (
    <>
      <TenantLevelPageHeader
        headerIcon={<TvOutlinedIcon />}
        headerText="All Devices"
        itemsCount={devicesCount}
      />
      <Box sx={devicesStyles.devicesContentBox}>
        <Box sx={devicesStyles.devicesGridContainer}>
          <DevicesGrid setDevicesCount={setDevicesCount} />
        </Box>
      </Box>
    </>
  );
};

export default DevicesPage;
