import { isEmpty } from "../helpers/stringHelpers";
import {
  ApplicationPortal,
  UserPermissions,
} from "../interfaces/providers/ApplicationPortal";
import { IDataService } from "../interfaces/services/DataService";
import { Identity } from "../interfaces/users/User";
import { AuthService } from "./AuthService";
import { DevicesService } from "./DevicesService";
import { IdentityService } from "./IdentityService";
import { TenantService } from "./TenantService";
import { doRicsApiXhr, fileUploadXhr } from "./XhrService";
import { ProductConstants } from "../constants/productConstants";
import { BrandingSettings } from "../interfaces/settings/BrandingSettings";
import { Configuration } from "../interfaces/settings/Configuration";
import { Location } from "../interfaces/locations/Location";
import { SaleSettings } from "../interfaces/salesettings/SaleSettings";
import { LocationIdResponse } from "../interfaces/responses/LocationIdResponse";
import { Tax } from "../interfaces/settings/Taxes";
import { TenantInfoResponse } from "../interfaces/responses/TenantInfoResponse";

const portal: ApplicationPortal = window.portal || {};
portal.features = portal.features || {};
portal.configurations = portal.configurations || {};
portal.constants = portal.constants || {};
portal.constants.productConstants = portal.constants.productConstants || {};

portal!.dataService = portal!.dataService || {};

portal!.dataService.messageStatuses = portal!.dataService.messageStatuses || [];
portal!.dataService.totalMessageStatuses =
  portal!.dataService.totalMessageStatuses || 0;
portal!.dataService.signalSuccess = portal!.dataService.signalSuccess || {};
portal!.dataService.signalFailure = portal!.dataService.signalFailure || {};

//TODO: Clean this up
portal!.configurations.setupWizardComplete = "POSPortalSetupWizardCompleted";
portal!.configurations.customerSignatureRequired =
  "POSCustomerSignatureRequired";
portal!.configurations.receiptIntroText = "POSIntroText";
portal!.configurations.receiptFooterText = "POSFooterText";
portal!.configurations.setupPaymentWizardComplete =
  "POSPortalPaymentWizardCompleted";
portal!.configurations.setupUserWizardComplete =
  "POSPortalUserImportWizardCompleted";
portal!.configurations.setupSettingsWizardComplete =
  "POSPortalSettingsWizardCompleted";
portal!.configurations.setupBrandingWizardComplete =
  "POSPortalBrandingWizardCompleted";

// Main entry point to load everything needed for initial load
export const getIdentity = async (
  identitiesVersion: string,
  successCallback: () => void,
  failCallback: () => void
) => {
  portal!.dataService.totalMessageStatuses = 0;

  portal!.dataService.signalSuccess = successCallback;
  portal!.dataService.signalFailure = failCallback;

  const initialPostbackNumber = portal!.dataService.totalMessageStatuses;
  const onSuccess = (response: Identity) => {
    portal!.state.currentUser = response;

    const userPermissions = new Array<UserPermissions>();
    response.roles.map((role) => {
      if (role.tenantId && role.locationId == undefined) {
        const existing = userPermissions.filter(
          (u) => u.tenantId == role.tenantId && u.locationId == undefined
        )[0];
        if (existing) {
          const claims = existing.claims.concat(role.identityScopes);
          existing.claims = claims.filter((v, i, s) => {
            return s.indexOf(v) === i;
          });
          return;
        }

        const newPermissions: UserPermissions = {
          tenantId: role.tenantId,
          claims: role.identityScopes,
        };

        userPermissions.push(newPermissions);
        return;
      }

      if (role.tenantId && role.locationId) {
        const existing = userPermissions.filter(
          (u) => u.tenantId == role.tenantId && u.locationId == role.locationId
        )[0];
        if (existing) {
          const claims = existing.claims.concat(role.identityScopes);
          existing.claims = claims.filter((v, i, s) => {
            return s.indexOf(v) === i;
          });
          return;
        }

        const newPermissions: UserPermissions = {
          tenantId: role.tenantId,
          locationId: role.locationId,
          claims: role.identityScopes,
        };

        userPermissions.push(newPermissions);
      }
    });

    portal!.state.userPermissions = userPermissions;

    postbackMessageStatus(initialPostbackNumber, true);
  };

  const onFailure = () => {
    postbackMessageStatus(initialPostbackNumber, false);
  };

  const genericOnSuccess = (successCallbackNumber?: number) => {
    if (successCallbackNumber === undefined) {
      return;
    }
    postbackMessageStatus(successCallbackNumber, true);
  };

  const genericOnFailure = (successCallbackNumber?: number) => {
    if (successCallbackNumber === undefined) {
      return;
    }
    postbackMessageStatus(successCallbackNumber, false);
  };

  portal.features.refreshTokenEnabled =
    await getRefreshTokenFeatureEnablementStatus();
  await getTenantInfo(
    genericOnSuccess,
    genericOnFailure,
    ++portal!.dataService.totalMessageStatuses
  );

  await getTaxes(
    genericOnSuccess,
    genericOnFailure,
    ++portal!.dataService.totalMessageStatuses
  );
  await loadConstants(++portal!.dataService.totalMessageStatuses);
  await loadConfigurations(
    genericOnSuccess,
    genericOnFailure,
    ++portal!.dataService.totalMessageStatuses
  );
  await getLocations(
    ++portal!.dataService.totalMessageStatuses,
    genericOnSuccess,
    genericOnFailure
  );
  await getTenantIdentities(
    genericOnSuccess,
    genericOnFailure,
    identitiesVersion,
    ++portal!.dataService.totalMessageStatuses
  );

  IdentityService.getCurrentIdentity(identitiesVersion, onSuccess, onFailure);
};

const getRefreshTokenFeatureEnablementStatus = (): Promise<boolean> => {
  return new Promise((resolve) => {
    const endpoint = "v1/Features/PortalRefreshToken";
    const onSuccess = (response: string) => {
      const feature = JSON.parse(response);
      resolve(feature.isEnabled);
    };
    const onFailure = () => {
      resolve(false);
    };
    doRicsApiXhr(endpoint, null, onSuccess, onFailure);
  });
};

const loadConstants = (successCallbackNumber: number) => {
  return new Promise((resolve, reject) => {
    const onSuccess = (response: string) => {
      var responseObj: ProductConstants = JSON.parse(response);
      portal.constants.productConstants.stateCodes = responseObj.stateCodes;
      portal.constants.productConstants.countryCodes = responseObj.countryCodes;
      portal.constants.productConstants.genders = responseObj.genders;
      portal.constants.productConstants.productTypeData =
        responseObj.productTypeData;
      portal.constants.productConstants.seasons = responseObj.seasons;
      portal.constants.productConstants.sports = responseObj.sports;
      portal.constants.productConstants.years = responseObj.years;
      portal.constants.productConstants.weightUnits = responseObj.weightUnits;
      portal.constants.productConstants.lengthUnits = responseObj.lengthUnits;
      portal.constants.productConstants.sizes = responseObj.sizes;
      portal.constants.productConstants.colors = responseObj.colors;
      portal.dataService.postbackMessageStatus(successCallbackNumber, true);
      resolve(successCallbackNumber);
    };

    const onFailure = () => {
      portal.dataService.postbackMessageStatus(successCallbackNumber, false);
      reject();
    };

    const endpoint = "v1.1/TenantProducts/ValidationConstants";

    const payload = [
      "CountryCodes",
      "StateCodes",
      "Genders",
      "ProductTypeData",
      "Seasons",
      "Sports",
      "Years",
      "WeightUnits",
      "LengthUnits",
      "Sizes",
      "Colors",
    ];

    doRicsApiXhr(endpoint, payload, onSuccess, onFailure, "POST");
  });
};

export const loadConfigurations = (
  successCallback: (callbackNumber?: number) => void = () => {},
  failureCallback: (callbackNumber?: number) => void = () => {},
  successCallbackNumber?: number
): Promise<Configuration[]> => {
  return new Promise((resolve, reject) => {
    const onSuccess = (response: string) => {
      const responseObj: Configuration[] = JSON.parse(response);
      portal.configurations.configurations = {};

      if (!responseObj) {
        successCallback(successCallbackNumber);
        resolve([]);
        return;
      }

      for (var i = 0; i < responseObj.length; i++) {
        var currentConfiguration = responseObj[i];

        portal.configurations.configurations[
          currentConfiguration.configurationName
        ] =
          portal.configurations.configurations[
            currentConfiguration.configurationName
          ] || [];
        portal.configurations.configurations[
          currentConfiguration.configurationName
        ].push(currentConfiguration);
      }

      successCallback(successCallbackNumber);
      resolve([]);
    };

    const onFailure = () => {
      failureCallback(successCallbackNumber);
      reject();
    };

    var endpoint = "v1/Configurations?";

    const names = [
      portal.configurations.setupWizardComplete,
      portal.configurations.customerSignatureRequired,
      portal.configurations.receiptIntroText,
      portal.configurations.receiptFooterText,
      portal.configurations.setupPaymentWizardComplete,
      portal.configurations.setupUserWizardComplete,
      portal.configurations.setupSettingsWizardComplete,
      portal.configurations.setupBrandingWizardComplete,
    ];

    names.forEach((name) => {
      endpoint = endpoint + "configurationNames=" + name + "&";
    });

    endpoint = endpoint.slice(0, -1);

    doRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
  });
};

const postbackMessageStatus = (index: number, status: boolean) => {
  portal!.dataService.messageStatuses[index] = status;
  if (status === false) {
    portal!.dataService.signalFailure();
    portal!.dataService.messageStatuses = [];
    return;
  }

  for (var i = 0; i < portal!.dataService.totalMessageStatuses; i++) {
    if (!portal!.dataService.messageStatuses[i]) {
      return;
    }
  }

  setupLocations();
  portal!.dataService.messageStatuses = [];
  portal!.dataService.signalSuccess();
};

const setupLocations = () => {
  const setupLocation = (location: Location) => {
    location.usersAtLocation = [];
    if (
      portal!.state.tenantIdentities &&
      portal!.state.tenantIdentities.length > 0
    ) {
      for (let i = 0; i < portal!.state.tenantIdentities.length; i++) {
        var currentIdentity = portal!.state.tenantIdentities[i];
        var sanitizedLocationId = location.locationId.toLowerCase();

        if (
          currentIdentity.roles &&
          currentIdentity.roles.length > 0 &&
          currentIdentity.roles.findIndex(
            (x) =>
              x.tenantId === portal!.state.tenantInfo.tenantId &&
              (x.identityRole === "Owner" ||
                x.locationId === sanitizedLocationId)
          ) > -1
        ) {
          location.usersAtLocation.push(currentIdentity);
        }
      }
    }
  };

  if (!portal!.state.locations) {
    return;
  }

  for (var i = 0; i < portal!.state.locations.length; i++) {
    var currentLocation = portal!.state.locations[i];
    setupLocation(currentLocation);
  }
};

const getTenantInfo = (
  successCallback: (response: number) => void,
  failureCallback: (response: number) => void,
  successCallbackNumber: number
): Promise<TenantInfoResponse> =>
  new Promise((resolve, reject) => {
    const endpoint = "v1/Tenants/Info";

    const onSuccess = (response: string) => {
      const result = JSON.parse(response);

      const tenantInfoResponse: TenantInfoResponse = {
        currentTenant: result.currentTenant,
        allTenants: result.allTenants,
      };
      portal!.state.tenantInfo = tenantInfoResponse.currentTenant;
      portal!.state.allTenantInfo = tenantInfoResponse.allTenants;

      successCallback(successCallbackNumber);
      resolve(tenantInfoResponse);
    };

    const onFailure = () => {
      failureCallback(successCallbackNumber);
      reject();
    };

    doRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
  });

const getTaxes = (
  successCallback: (response: number) => void,
  failureCallback: (response: number) => void,
  successCallbackNumber: number
): Promise<Tax[]> =>
  new Promise((resolve, reject) => {
    const endpoint = "v1/Taxes";

    const onSuccess = (response: string) => {
      const result: Tax[] = JSON.parse(response);

      if (result && result.length > 0) {
        portal!.state.taxes = result;
      }

      if (portal!.state.taxes) {
        portal!.state.taxDecimals = 4;
        portal!.state.thresholdDecimals = 2;

        for (var i = 0; i < portal!.state.taxes.length; i++) {
          var currentTax = portal!.state.taxes[i];
          if (
            currentTax.taxRate &&
            currentTax.taxRate <= 1 &&
            currentTax.taxRate >= -1
          ) {
            currentTax.taxRate = currentTax.taxRate * 100.0;
            currentTax.taxRate = Number(
              currentTax.taxRate.toFixed(portal!.state.taxDecimals)
            );
          } else {
            currentTax.taxRate = 0;
          }

          if (currentTax.productTaxes && currentTax.productTaxes.length > 0) {
            const productTaxes = currentTax.productTaxes;

            for (var j = 0; j < productTaxes.length; j++) {
              var productTax = productTaxes[j];
              if (
                productTax.taxRate &&
                productTax.taxRate <= 1 &&
                productTax.taxRate >= -1
              ) {
                productTax.taxRate = productTax.taxRate * 100.0;
                productTax.taxRate = Number(
                  productTax.taxRate.toFixed(portal!.state.taxDecimals)
                );
              } else {
                productTax.taxRate = 0;
              }

              if (productTax.threshold && productTax.threshold > 0) {
                productTax.threshold = Number(
                  productTax.threshold.toFixed(portal!.state.thresholdDecimals)
                );
              }
            }
          }
        }
      }

      successCallback(successCallbackNumber);
      resolve(result);
    };

    const onFailure = () => {
      failureCallback(successCallbackNumber);
      reject();
    };

    doRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
  });

const getLocations = (
  successCallbackNumber: number,
  successCallback: (response: number) => void,
  failureCallback: (response: number) => void
): Promise<LocationIdResponse[]> => {
  return new Promise((resolve, reject) => {
    const endpoint = "v1/Locations";

    const onSuccess = (response: string) => {
      const result: LocationIdResponse[] = JSON.parse(response);
      const locations: Location[] = result
        .filter((l: LocationIdResponse) => l.disabledOn === undefined)
        .map((l: LocationIdResponse): Location => {
          return {
            name: l.name,
            locationId: l.locationId,
            originalLocationId: l.originalLocationId,
            isActive: l.isActive,
            contact: l.contact,
            imageUrl: l.imageUrl,
            hidden: false,
            usersAtLocation: [],
            setupWizard: l.setupWizard,
          };
        });

      portal!.state.locations = locations;
      portal!.state.shouldUpdateAllLocations = false;
      portal!.state.locationIdsToUpdate = [];

      if (!portal!.state.locations || portal!.state.locations.length < 1) {
        onFailure();
        return;
      }

      const onSuccess2 = async () => {
        try {
          await getBrandingSettings();
          onSuccess3();
        } catch {
          onFailure3();
        }
      };

      const onSuccess3 = () => {
        successCallback(successCallbackNumber);
      };

      const onFailure3 = () => {
        failureCallback(successCallbackNumber);
      };

      const onFailure2 = () => {
        failureCallback(successCallbackNumber);
      };

      getSaleSettings(onSuccess2, onFailure2);

      setupLocations();
      resolve(result);
    };

    const onFailure = () => {
      failureCallback(successCallbackNumber);
      reject();
    };

    doRicsApiXhr(endpoint, null, onSuccess, onFailure);
  });
};

export const getLocation = (
  locationId: string,
  successCallback: () => void,
  failureCallback: () => void
): Promise<LocationIdResponse> =>
  new Promise((resolve, reject) => {
    const endpoint = "v1/Locations/" + locationId;
    const onSuccess = (response: string) => {
      const location = JSON.parse(response);
      const cachedLocation = portal!.state.locations.filter(
        (l) => l.locationId == location.locationId
      )[0];
      const cachedLocationIndex =
        portal!.state.locations.indexOf(cachedLocation);
      portal!.state.locations[cachedLocationIndex] = location;

      successCallback();
      resolve(location);
    };

    const onFailure = () => {
      failureCallback();
      reject();
    };

    doRicsApiXhr(endpoint, null, onSuccess, onFailure);
  });

export const enqueueLocationForUpdate = (
  locationId: string | null | undefined
) => {
  if (
    !isEmpty({ str: locationId }) &&
    portal!.state.locationIdsToUpdate.indexOf(locationId!) === -1
  )
    portal!.state.locationIdsToUpdate.push(locationId!);
};

const getTenantIdentities = (
  successCallback: (response?: number) => void,
  failureCallback: (response?: number) => void,
  identitiesVersion: string,
  successCallbackNumber?: number
): Promise<Identity[]> =>
  new Promise((resolve, reject) => {
    const onSuccess = (identities: Array<Identity>) => {
      if (!identities || identities.length === 0) {
        if (successCallback) {
          successCallback(successCallbackNumber);
        }
        resolve([]);
        return;
      }

      portal!.state.tenantIdentities = identities;
      if (successCallback) {
        successCallback(successCallbackNumber);
      }
      resolve(identities);
    };

    const onFailure = () => {
      if (failureCallback) {
        failureCallback(successCallbackNumber);
      }
      reject();
    };

    IdentityService.getIdentities(identitiesVersion, onSuccess, onFailure);
  });

export const getToken = () => {
  return window.localStorage.getItem("Token");
};

export const uploadFile = async <T>(
  endpoint: string,
  file: File
): Promise<T> => {
  try {
    const loFormData = new FormData();
    loFormData.append("file", file);

    return new Promise<T>((resolve, reject) => {
      const onSuccess = (result: string) => {
        const parsedResult: T = JSON.parse(result);
        resolve(parsedResult);
        portal.navigation.isLoading(false);
      };

      const onFailure = () => {
        alert("File upload has failed."); // TODO: NK: better failure handling
        portal.navigation.isLoading(false);
        reject(new Error("File upload has failed"));
      };

      fileUploadXhr(endpoint, loFormData, "POST", onSuccess, onFailure);
    });
  } catch (ex) {
    alert(ex);
    throw ex;
  }
};

export const logout = () => {
  window.localStorage.clear();
  window.sessionStorage.clear();
  portal.navigation.isLoading(false);
};

export const saveSaleSettings = (
  saleSettings: SaleSettings,
  successCallback: () => void,
  failureCallback: () => void
) => {
  portal.navigation.isLoading(true);
  var endpoint = "v1.2/SaleSettings";

  const success = () => {
    portal.navigation.isLoading(false);
    successCallback();
  };

  const fail = () => {
    alert("Something went wrong");
    portal.navigation.isLoading(false);
    failureCallback();
  };

  doRicsApiXhr(endpoint, saleSettings, success, fail, "PUT");
};

export const getSaleSettings = (
  successCallback: () => void,
  failureCallback: () => void
) => {
  const endpoint = "v1.2/SaleSettings";

  const onSuccess = (response: string) => {
    const result: SaleSettings[] = JSON.parse(response);
    if (!result || result.length === 0) {
      onFailure();
      return;
    }

    portal.state.saleSettings = {};
    for (let x = 0; x < result.length; x++) {
      const setting = result[x];
      const location = setting.locationId ? setting.locationId : "Tenant";
      portal.state.saleSettings[location] = setting;
    }

    successCallback();
  };

  const onFailure = () => {
    failureCallback();
  };

  doRicsApiXhr(endpoint, undefined, onSuccess, onFailure, "GET");
};

export const saveBrandingSettings = (
  brandingSettings: BrandingSettings
): Promise<string> => {
  portal.navigation.isLoading(true);
  const endpoint = "v1/Branding/Settings";

  return new Promise((resolve, reject) => {
    doRicsApiXhr(
      endpoint,
      brandingSettings,
      (response: string) => {
        portal.navigation.isLoading(false);
        resolve(response);
      },
      (error?: string) => {
        portal.navigation.isLoading(false);
        reject(error);
      },
      "POST"
    );
  });
};

export const getBrandingSettings = (): Promise<void> => {
  const endpoint = "v1/Branding/Settings";

  return new Promise((resolve, reject) => {
    const onSuccess = (response: string) => {
      const result: BrandingSettings[] = JSON.parse(response);
      if (!result) {
        onFailure();
        return;
      }

      portal.state.brandingSettings = {};
      for (const setting of result) {
        const location = setting.locationId ? setting.locationId : "Tenant";
        portal.state.brandingSettings[location] = setting;
      }

      resolve();
    };

    const onFailure = () => {
      reject();
    };

    const locationList = portal.state.locations.map(
      (location) => location.locationId
    );

    const parameters = {
      LocationIds: locationList,
    };

    doRicsApiXhr(endpoint, parameters, onSuccess, onFailure);
  });
};

export const getQueryParam = (name: string) => {
  const regexString = name + "=([^&]*)";
  const regex = new RegExp(regexString);
  const match = window.location.search.match(regex);

  return match && match.length > 1 ? match[1] : null;
};

portal!.dataService.postbackMessageStatus = postbackMessageStatus;

export const DataService: IDataService = {
  getIdentity: getIdentity,
  loadConfigurations: loadConfigurations,
  logout: logout,
  saveSaleSettings: saveSaleSettings,
  getSaleSettings: getSaleSettings,
  saveBrandingSettings: saveBrandingSettings,
  getBrandingSettings: getBrandingSettings,
  refreshTenantInfo: getTenantInfo,
  refreshTaxes: getTaxes,
  refreshLocations: getLocations,
  refreshTenantIdentities: getTenantIdentities,
  getToken: getToken,
  uploadFile: uploadFile,
  enqueueLocationForUpdate: enqueueLocationForUpdate,
  getLocation: getLocation,
  identities: IdentityService,
  tenants: TenantService,
  auth: AuthService,
  devices: DevicesService,
};
