import React from "react";
import { SxProps, Theme, Typography } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Location } from "../../interfaces/locations/Location";

interface LocationOwnerProps {
  location: Location;
}

const LocationOwnerComponent: React.FC<LocationOwnerProps> = ({ location }) => {
  const formatContactName = () => {
    const firstName =
      location.contact?.firstName ? location.contact?.firstName : "";
    const lastName =
      location.contact?.lastName ? location.contact?.lastName : "";
    let name = firstName + " " + lastName;
    if (name === " ") {
      name = "Unassigned";
    }
    return name;
  };
  const locationOwnerStyles: Record<string, SxProps<Theme> | undefined> = {
    storeContactIcon: {
      mt: "-2px",
      height: "16px",
    },
    storeContactName: {
      fontSize: "12px",
    },
  };

  return (
    <>
      <AccountCircleOutlinedIcon sx={locationOwnerStyles.storeContactIcon} />
      <Typography sx={locationOwnerStyles.storeContactName}>
        {formatContactName()}
      </Typography>
    </>
  );
};

export default LocationOwnerComponent;
