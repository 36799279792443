import { Box, SxProps, Theme, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomerSettings } from "../../../interfaces/salesettings/CustomerSettings";
import { SaleSettings } from "../../../interfaces/salesettings/SaleSettings";
import { useDataService } from "../../../providers/DataServiceProvider";
import { usePortal } from "../../../providers/PortalProvider";
import LeftRightContentComponent from "../../common/LeftRightContent";
import StyledSwitchComponent from "../../styled/StyledSwitch";
import { handleLocationsOverrideChange } from "../LocationsCanOverrideToggle";
import SettingsCardComponent from "./SettingsCard";

export interface CustomerSettingsProps {
  id: string;
  locationSettings?: SaleSettings;
  onSaveSettingsCard: (
    settingsToSave: SaleSettings,
    modifySettings?: (settings: SaleSettings, locationId?: string) => void
  ) => Promise<void>;
  locationId?: string;
}

const CustomerSettingsComponent: React.FC<CustomerSettingsProps> = ({
  id,
  locationId,
  locationSettings,
  onSaveSettingsCard,
}) => {
  const globalTheme = useTheme();
  const portal = usePortal();
  const dataService = useDataService();

  const [canLocationsOverride, setCanLocationsOverride] = useState(
    portal?.state.saleSettings["Tenant"]?.customerSettings
      ?.locationsCanOverride ?? true
  );
  const [canSave, setCanSave] = useState(false);
  const [isCustomerRequiredForSale, setIsCustomerRequiredForSale] = useState(
    locationSettings?.customerSettings?.customerRequiredForSale
  );
  const [isDisabled, setIsDisabled] = React.useState(false);

  const updateCanSave = () => {
    const hasLocationsCanOverrideChanged =
      canLocationsOverride !==
      portal?.state.saleSettings["Tenant"]?.customerSettings
        ?.locationsCanOverride;

    const hasCustomerRequiredForSaleChanged =
      isCustomerRequiredForSale !==
      locationSettings?.customerSettings?.customerRequiredForSale;

    setCanSave(
      hasLocationsCanOverrideChanged || hasCustomerRequiredForSaleChanged
    );
  };

  const handleSave = () => {
    const settingsToSave: CustomerSettings = {
      customerRequiredForSale: isCustomerRequiredForSale,
    };

    const modifySettings = (settings: SaleSettings | CustomerSettings) => {
      if (locationId) {
        dataService.enqueueLocationForUpdate(locationId);
        (settings as SaleSettings).locationId = locationId;
        (settings as SaleSettings).customerSettings!.locationsCanOverride =
          undefined;
      } else {
        portal!.state.shouldUpdateAllLocations = true;
        (settings as SaleSettings).customerSettings!.locationsCanOverride =
          canLocationsOverride;
      }
    };

    const updatedSaleSettings: SaleSettings = {
      ...locationSettings,
      customerSettings: settingsToSave,
    };

    onSaveSettingsCard(updatedSaleSettings, modifySettings);
    setCanSave(false);
  };

  useEffect(() => {
    updateCanSave();
  }, [canLocationsOverride, isCustomerRequiredForSale]);

  useEffect(() => {
    setIsDisabled(!!locationId && !canLocationsOverride);
  }, [locationId, canLocationsOverride]);

  const customerSettingsStyles: Record<string, SxProps<Theme> | undefined> = {
    requireCustomerContent: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    requireCustomerSwitchText: {
      fontSize: "14px",
      color: globalTheme.palette.common.black,
    },
  };

  return (
    <SettingsCardComponent
      canSave={canSave}
      disabled={isDisabled}
      headerText="Customer Settings"
      id={id}
      locationsCanOverrideToggleProps={{
        locationsCanOverride: canLocationsOverride,
        onChange: handleLocationsOverrideChange(setCanLocationsOverride),
        disabled: !!locationId,
      }}
      subHeaderText="Configure preferences and settings pertaining to customers."
      onSave={handleSave}
    >
      <LeftRightContentComponent
        leftHeading="Customer Required"
        rightHeading="Do you want to require customer to be assigned to every sale?"
      >
        <Box sx={customerSettingsStyles.requireCustomerContent}>
          <StyledSwitchComponent
            checked={isCustomerRequiredForSale}
            disabled={isDisabled}
            onChange={(e) => setIsCustomerRequiredForSale(e.target.checked)}
          />
          <Typography sx={customerSettingsStyles.requireCustomerSwitchText}>
            Require a customer assigned to all sales
          </Typography>
        </Box>
      </LeftRightContentComponent>
    </SettingsCardComponent>
  );
};

export default CustomerSettingsComponent;
