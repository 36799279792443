import { Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";

export const AddUserHeaderComponent: React.FC = () => {
  const addUserHeaderStyles: Record<string, SxProps<Theme> | undefined> = {
    addUserHeaderBody: {
      fontSize: "16px",
      letterSpacing: "-0.5px",
      mt: 1,
    },
    addUserImportHeading: {
      fontSize: "24px",
      letterSpacing: "-0.5px",
    },
    addUserTextContainer: {
      textAlign: "center",
      flex: "none",
      mb: 2,
    },
  };

  return (
    <Box sx={addUserHeaderStyles.addUserTextContainer}>
      <Typography sx={addUserHeaderStyles.addUserImportHeading}>
        Add existing users to this location:
      </Typography>
    </Box>
  );
};
