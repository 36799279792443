import React from "react";
import { Button, SxProps, Theme } from "@mui/material";
import NearMeIcon from "@mui/icons-material/NearMe";
import { usePortal } from "../../providers/PortalProvider";
import { ApplicationPortal } from "../../interfaces/providers/ApplicationPortal";
import { Location } from "../../interfaces/locations/Location";
import { useTheme } from "@mui/material/styles";

interface LocationInviteProps {
  location: Location;
  setShowModal: (showModal: boolean, location?: Location) => void;
}

const LocationInviteButtonComponent: React.FC<LocationInviteProps> = ({
  location,
  setShowModal,
}) => {
  const portal = usePortal();
  const theme = useTheme();

  const onFinishClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    portal!.state!.currentLocation = location;
    showFinishAndInviteModal(portal);
  };

  const showFinishAndInviteModal = (portal: ApplicationPortal | null) => {
    var location = portal!.state.currentLocation;
    if (location.setupWizard.isFinishAndInviteUsersCompleted) {
      return;
    }
    setShowModal(true, location);
  };

  const locationInviteButtonStyles: Record<string, SxProps<Theme>> = {
    finishAndInviteButton: {
      backgroundColor: theme.palette.common.ricsPurple,
      color: "white",
      whiteSpace: "nowrap",
      "&:hover": {
        backgroundColor: theme.palette.common.ricsPurple,
      },
      "&:focus": {
        backgroundColor: theme.palette.common.ricsPurple,
      },
    },
    finishAndInviteIcon: {
      ml: 1,
      fontSize: "16px",
    },
  };

  return (
    <Button
      sx={locationInviteButtonStyles.finishAndInviteButton}
      onClick={(e) => onFinishClick(e)}
    >
      Finish &amp; Invite Users
      <NearMeIcon sx={locationInviteButtonStyles.finishAndInviteIcon} />
    </Button>
  );
};

export default LocationInviteButtonComponent;
