import { returnMethodIds } from "./returnMethodIds";

interface ReturnMethodTooltipConfig {
  returnMethodId: string;
  returnMethodSubTypeId?: string;
  tooltipText: string;
}

export const returnMethodTooltips: ReturnMethodTooltipConfig[] = [
  {
    returnMethodId: returnMethodIds.AlternateCredit.id,
    returnMethodSubTypeId: returnMethodIds.AlternateCredit.subTypeId,
    tooltipText:
      "Allows salespeople to process refunds to a different credit card than the one used for the original transaction. Note: Credit Card refunds must be enabled to use Alternate Credit Card refunds.",
  },
];
