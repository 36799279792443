import { ConfigurationsWrapper } from "../providers/ApplicationPortal";

export interface Configuration {
  configurationId: string;
  configurationName: string;
  configurationValue: string;
  locationId: string;
  tenantId: string;
}

export const showWelcomeModal = (configurations: ConfigurationsWrapper) => {
  var isWorkflowComplete = false;
  if (
    configurations.configurations &&
    configurations.configurations[configurations.setupWizardComplete]
  ) {
    for (
      let i = 0;
      i <
      configurations.configurations[configurations.setupWizardComplete].length;
      i++
    ) {
      var tempPaymentConfiguration =
        configurations.configurations[configurations.setupWizardComplete][i];
      if (!tempPaymentConfiguration.locationId) {
        isWorkflowComplete =
          tempPaymentConfiguration.configurationValue === "true";
      }
    }
  }

  return !isWorkflowComplete;
};
