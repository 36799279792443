import React, { useState } from "react";
import LocationDetailsTaxesCardComponent from "../LocationDetailsTaxesCard";
import SpecialTaxEventCardComponent from "./SpecialTaxEventCard";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useTaxesContext } from "../../../../providers/TaxesProvider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SpecialTaxEventDialogComponent from "./SpecialTaxEventDialog";
import TextButtonComponent from "../../../styled/TextButton";
export interface LocationDetailsSpecialTaxEventsProps {
  locationId: string;
}

const LocationDetailsSpecialTaxEventsComponent: React.FC<
  LocationDetailsSpecialTaxEventsProps
> = ({ locationId }) => {
  const globalTheme = useTheme();
  const { specialTaxEvents } = useTaxesContext();
  const [isSpecialTaxDialogOpen, setIsSpecialTaxDialogOpen] = useState(false);

  return (
    <LocationDetailsTaxesCardComponent
      dataTestId="locationDetailsSpecialTaxEvents"
      header="Special Tax Events"
      subheader="Create special tax events like a Tax-Free Weekend."
    >
      <Grid container spacing={2}>
        {specialTaxEvents
          .filter(
            (event) =>
              event.locationIds && event.locationIds.includes(locationId)
          )
          .map((event, index) => (
            <Grid item key={index} xs={12}>
              <SpecialTaxEventCardComponent
                locationId={locationId}
                specialTaxEvent={event}
              />
            </Grid>
          ))}
      </Grid>
      <Box mt={1}>
        <TextButtonComponent
          disableRipple
          focusColor={globalTheme.palette.primary.main}
          hoverColor={globalTheme.palette.primary.main}
          startIcon={<AddCircleIcon />}
          textColor={globalTheme.palette.primary.main}
          onClick={() => setIsSpecialTaxDialogOpen(true)}
        >
          Create a Special Tax Event...
        </TextButtonComponent>
      </Box>
      <SpecialTaxEventDialogComponent
        isSpecialTaxDialogOpen={isSpecialTaxDialogOpen}
        locationId={locationId}
        setIsSpecialTaxDialogOpen={setIsSpecialTaxDialogOpen}
      />
    </LocationDetailsTaxesCardComponent>
  );
};
export default LocationDetailsSpecialTaxEventsComponent;
