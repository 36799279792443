import React, { useState } from "react";
import {
  Box,
  Button,
  List,
  Modal,
  Popover,
  Typography,
  ListItem,
  IconButton,
} from "@mui/material";
import SpecialTaxEventDialogComponent from "./SpecialTaxEventDialog";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import { useTaxesContext } from "../../../../providers/TaxesProvider";
import { SpecialTaxEvent } from "../../../../interfaces/settings/Taxes";
import { useXhrService } from "../../../../providers/XhrServiceProvider";
import { usePortal } from "../../../../providers/PortalProvider";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export interface SpecialTaxActionsPopoverProps {
  locationId: string;
  specialTaxEvent: SpecialTaxEvent;
}

const SpecialTaxActionsPopoverComponent: React.FC<
  SpecialTaxActionsPopoverProps
> = ({ locationId, specialTaxEvent }) => {
  const [isDeleteTaxModalShown, setIsDeleteTaxModalShown] = useState(false);
  const [isDuplicateTaxModalShown, setIsDuplicateTaxModalShown] =
    useState(false);

  const [specialTaxActionsPopoverAnchor, setSpecialTaxActionsPopoverAnchor] =
    React.useState<HTMLButtonElement | null>(null);
  const {
    addOrUpdateSpecialTaxEvent,
    deleteSpecialTaxEvent,
    getSpecialTaxEvents,
  } = useTaxesContext();

  const closeDeleteTaxModal = () => setIsDeleteTaxModalShown(false);
  const closeDuplicateTaxModal = () => setIsDuplicateTaxModalShown(false);

  const openSpecialTaxActionsPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setSpecialTaxActionsPopoverAnchor(event.currentTarget);
  };
  const closeSpecialTaxActionsPopover = () => {
    setSpecialTaxActionsPopoverAnchor(null);
  };
  const portal = usePortal();
  const globalTheme = useTheme();
  const xhrService = useXhrService();
  const isPopoverOpen = Boolean(specialTaxActionsPopoverAnchor);
  const [isSpecialTaxDialogOpen, setIsSpecialTaxDialogOpen] =
    React.useState(false);

  const duplicateSpecialTaxEvent = async (existingEvent: SpecialTaxEvent) => {
    portal!.navigation.isLoading(true);
    setSpecialTaxActionsPopoverAnchor(null);

    const newSpecialTaxEvent: SpecialTaxEvent = {
      ...existingEvent,
      eventId: crypto.randomUUID(),
    };

    try {
      await xhrService!.doRicsApiXhr(
        "v1/Taxes/Events",
        newSpecialTaxEvent,
        undefined,
        undefined,
        "PUT"
      );
      addOrUpdateSpecialTaxEvent(newSpecialTaxEvent);
    } catch (error) {
      console.error("Failed to duplicate tax event:", error);
    } finally {
      portal!.navigation.isLoading(false);
    }
  };
  const handleDuplicate = () => {
    duplicateSpecialTaxEvent(specialTaxEvent);
    setIsDuplicateTaxModalShown(false);
  };

  const handleDelete = () => {
    deleteSpecialTaxEvent(specialTaxEvent);
    getSpecialTaxEvents(locationId);
    setIsDeleteTaxModalShown(false);
  };

  const specialTaxActionsPopoverStyles: Record<string, SxProps<Theme>> = {
    specialTaxActionsPopoverPaper: {
      bgcolor: "white",
    },
    specialTaxActionsPopoverButton: {
      color: globalTheme.palette.common.gray,
      "&:focus": {
        backgroundColor: "white",
        color: "black",
      },
    },
    specialTaxActionsPopoverDeleteButton: {
      color: "red",
    },
    modalButtons: {
      ml: 0.5,
      mr: 0.5,
    },
    specialTaxModal: {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      borderRadius: "8px",
      boxShadow: 24,
      p: 4,
      textAlign: "center",
    },
    specialTaxModalButtonBox: {
      paddingTop: "15px",
    },
    specialTaxActionIcon: {
      fontSize: "16px",
    },
    specialTaxActionButton: {
      color: "#000000",
      "&:hover": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
      "&:active": {
        outline: "none",
        border: "none",
        backgroundColor: "red",
      },
      "&.Mui-focusVisible": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
      "&:focus": {
        backgroundColor: "transparent",
        color: "#000000",
      },
    },
  };

  return (
    <Box>
      <IconButton
        disableRipple
        aria-label="Special Tax Actions Menu Button"
        data-testid={`specialTaxActionsButton-${specialTaxEvent.eventId}`}
        size="small"
        sx={specialTaxActionsPopoverStyles.specialTaxActionButton}
        onClick={openSpecialTaxActionsPopover}
      >
        <MoreVertIcon
          sx={specialTaxActionsPopoverStyles.specialTaxActionIcon}
        />
      </IconButton>
      <Popover
        anchorEl={specialTaxActionsPopoverAnchor}
        data-testid="specialTaxActionsPopover"
        open={isPopoverOpen}
        onClose={closeSpecialTaxActionsPopover}
      >
        <List disablePadding>
          <ListItem>
            <Button
              data-testid="updateSpecialTaxButton"
              startIcon={<ModeEditIcon />}
              sx={specialTaxActionsPopoverStyles.specialTaxActionsPopoverButton}
              onClick={(e) => {
                setIsSpecialTaxDialogOpen(true);
                e.stopPropagation();
              }}
            >
              Edit
            </Button>
          </ListItem>
          <ListItem>
            <Button
              data-testid="duplicateSpecialTaxButton"
              startIcon={<ContentCopyIcon />}
              sx={specialTaxActionsPopoverStyles.specialTaxActionsPopoverButton}
              onClick={(e) => {
                e.stopPropagation();
                setIsDuplicateTaxModalShown(true);
              }}
            >
              Duplicate
            </Button>
          </ListItem>

          <ListItem>
            <Button
              data-testid="deleteSpecialTaxButton"
              startIcon={<DeleteForeverOutlinedIcon />}
              sx={{
                ...specialTaxActionsPopoverStyles.specialTaxActionsPopoverButton,
                color: "red",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setIsDeleteTaxModalShown(true);
              }}
            >
              Delete
            </Button>
          </ListItem>
        </List>

        <Modal
          data-testid="specialTaxModal"
          open={isDeleteTaxModalShown}
          onClose={closeDeleteTaxModal}
        >
          <Box sx={specialTaxActionsPopoverStyles.specialTaxModal}>
            <Typography component="h2" variant="h6">
              Confirm
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Are you sure you want to delete this special tax event?
            </Typography>
            <Box sx={specialTaxActionsPopoverStyles.specialTaxModalButtonBox}>
              <Button
                data-testid="declineDeleteSpecialTaxButton"
                sx={specialTaxActionsPopoverStyles.modalButtons}
                variant="contained"
                onClick={() => setIsDeleteTaxModalShown(false)}
              >
                No
              </Button>
              <Button
                data-testid="confirmDeleteSpecialTaxButton"
                sx={specialTaxActionsPopoverStyles.modalButtons}
                variant="contained"
                onClick={handleDelete}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          data-testid="duplicateSpecialTaxModal"
          open={isDuplicateTaxModalShown}
          onClose={closeDuplicateTaxModal}
        >
          <Box sx={specialTaxActionsPopoverStyles.specialTaxModal}>
            <Typography component="h2" variant="h6">
              Confirm
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Are you sure you want to duplicate this special tax event?
            </Typography>
            <Box sx={specialTaxActionsPopoverStyles.specialTaxModalButtonBox}>
              <Button
                data-testid="declineDuplicateSpecialTaxButton"
                sx={specialTaxActionsPopoverStyles.modalButtons}
                variant="contained"
                onClick={() => setIsDuplicateTaxModalShown(false)}
              >
                No
              </Button>
              <Button
                data-testid="confirmDuplicateSpecialTaxButton"
                sx={specialTaxActionsPopoverStyles.modalButtons}
                variant="contained"
                onClick={handleDuplicate}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
        <SpecialTaxEventDialogComponent
          closeSpecialTaxActionsPopover={closeSpecialTaxActionsPopover}
          isSpecialTaxDialogOpen={isSpecialTaxDialogOpen}
          setIsSpecialTaxDialogOpen={setIsSpecialTaxDialogOpen}
          specialTaxEvent={specialTaxEvent}
        />
      </Popover>
    </Box>
  );
};

export default SpecialTaxActionsPopoverComponent;
