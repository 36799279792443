import React, { ReactNode, ReactElement } from "react";
import { Box, useTheme } from "@mui/material";
import RicsSideMenuTabHeader from "./RicsSideMenuTabHeader";
import { SxProps, Theme } from "@mui/material";

export interface LocationDetailsPageProps {
  dataTestId: string;
  headerText: string;
  headerIcon: ReactElement;
  children: ReactNode;
  headerButton?: ReactNode;
}

const LocationDetailsPageComponent: React.FC<LocationDetailsPageProps> = ({
  dataTestId,
  headerText,
  headerIcon,
  children,
  headerButton,
}) => {
  const globalTheme = useTheme();

  const locationDetailsPageStyles: Record<string, SxProps<Theme>> = {
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      width: "100%",
      alignItems: "center",
      margin: "16px auto",
      padding: "16px",
    },
    headerBox: {
      position: "relative",
    },
    headerButtonBox: {
      position: "absolute",
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      paddingRight: 2,
    },
    page: {
      height: "calc(100vh - 64px)",
      overflowY: "auto",
      backgroundColor: globalTheme.palette.common.contentPageBackground,
    },
  };
  return (
    <Box data-testid={dataTestId} sx={locationDetailsPageStyles.page}>
      <Box sx={locationDetailsPageStyles.headerBox}>
        <RicsSideMenuTabHeader
          headerIcon={headerIcon}
          headerText={headerText}
        />
        <Box sx={locationDetailsPageStyles.headerButtonBox}>{headerButton}</Box>
      </Box>
      <Box sx={locationDetailsPageStyles.contentContainer}>{children}</Box>
    </Box>
  );
};
export default LocationDetailsPageComponent;
