import { IIdentityService } from "../interfaces/services/IdentityService";
import { Identity } from "../interfaces/users/User";
import { doRicsApiXhr } from "./XhrService";

const getCurrentIdentity = (
  version: string,
  onSuccess: (identity: Identity) => void,
  onFailure: () => void
) => {
  const endpoint = `v${version}/Identities/Current`;

  const onSuccessCallback = (response: string) => {
    const identity: Identity = JSON.parse(response);
    onSuccess(identity);
  };

  doRicsApiXhr(endpoint, undefined, onSuccessCallback, onFailure, "GET");
};

const getIdentities = (
  version: string,
  onSuccess: (identities: Array<Identity>) => void,
  onFailure: () => void
) => {
  const endpoint = `v${version}/Identities`;

  const onSuccessCallback = (response: string) => {
    const identities: Array<Identity> = JSON.parse(response);
    onSuccess(identities);
  };

  doRicsApiXhr(endpoint, undefined, onSuccessCallback, onFailure, "GET");
};

const addRoleToIdentity = (
  identityId: string,
  tenantId: string,
  locationId: string,
  roleName: string,
  version: string,
  onSuccess: () => void,
  onFailure: () => void
) => {
  const endpoint = `v${version}/Identities/${identityId}/roles/${tenantId}/${locationId}/${roleName}`;
  doRicsApiXhr(endpoint, null, onSuccess, onFailure, "PUT");
};

const saveIdentity = (
  identity: Identity,
  saveToRics9: boolean,
  version: string,
  onSuccess: (newIdentity: Identity) => void,
  onFailure: (errorResponse: string) => void
) => {
  const endpoint = `v${version}/Identities${
    saveToRics9 ? "?saveRics9User=true" : ""
  }`;

  const onSuccessCallback = (response: string) => {
    const newIdentity: Identity = JSON.parse(response);
    onSuccess(newIdentity);
  };

  const onFailureCallback = (response?: string) => {
    if (response) {
      onFailure(response);
    } else {
      onFailure(
        "There was a problem saving your identity. Please try again or contact support."
      );
    }
  };
  doRicsApiXhr(endpoint, identity, onSuccessCallback, onFailureCallback, "PUT");
};

const getIdentity = (
  identityId: string,
  version: string,
  onSuccess: (identity: Identity) => void,
  onFailure: () => void
) => {
  const endpoint = `v${version}/Identities/${identityId}`;

  const onSuccessCallback = (response: string) => {
    const identity: Identity = JSON.parse(response);
    onSuccess(identity);
  };

  doRicsApiXhr(endpoint, null, onSuccessCallback, onFailure, "GET");
};

const getIdentityPin = (
  identityId: string,
  version: string,
  onSuccess: (pin: string) => void,
  onFailure: () => void
) => {
  const endpoint = `v${version}/Identities/${identityId}/Pin`;

  const onSuccessCallback = (response: string) => {
    const pin: string = JSON.parse(response);
    onSuccess(pin);
  };

  doRicsApiXhr(endpoint, null, onSuccessCallback, onFailure, "GET");
};

const createIdentityPin = (
  emailAddress: string,
  version: string,
  onSuccess: () => void,
  onFailure: () => void
) => {
  const endpoint = `v${version}/Identities/CreatePin`;
  const payload = {
    emailAddress: emailAddress,
  };

  doRicsApiXhr(endpoint, payload, onSuccess, onFailure, "PUT");
};

export const IdentityService: IIdentityService = {
  getCurrentIdentity: getCurrentIdentity,
  getIdentities: getIdentities,
  addRoleToIdentity: addRoleToIdentity,
  createIdentityPin: createIdentityPin,
  getIdentity: getIdentity,
  getIdentityPin: getIdentityPin,
  saveIdentity: saveIdentity,
};
