import React, { useEffect } from "react";
import {
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Popover,
  SxProps,
  Theme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { roleNames } from "../../constants/roles";
import {
  Location,
  SelectedLocation,
} from "../../interfaces/locations/Location";
import { User } from "../../interfaces/users/User";
import { usePortal } from "../../providers/PortalProvider";

interface UserLocationsPopoverProps {
  user: User;
  id: string;
  onUserChanged: (updatedUser: User) => void;
  errorOutline?: boolean;
}

const UserLocationsPopoverComponent: React.FC<UserLocationsPopoverProps> = ({
  user,
  onUserChanged,
  errorOutline,
}) => {
  const portal = usePortal();

  const mapLocationToSelectedLocation = (
    location: Location
  ): SelectedLocation => {
    return {
      selected: false,
      disabled: false,
      locationId: location.locationId,
      name: location.name,
    };
  };

  const [locations, setLocations] = React.useState<SelectedLocation[]>(
    portal!.state.locations.map(mapLocationToSelectedLocation)
  );
  const [locationsDisplay, setLocationsDisplay] = React.useState<string>("");
  const [isLocationsDisabled, setIsLocationsDisabled] = React.useState(false);
  const [locationPopoverAnchor, setlocationPopoverAnchor] =
    React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setlocationPopoverAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setlocationPopoverAnchor(null);
  };
  const isPopoverOpen = Boolean(locationPopoverAnchor);
  const isAllSelected = locations.every((location) => location.selected);

  useEffect(() => {
    setInitialLocationState();
  }, [user.roles]);

  useEffect(() => {
    if (isPopoverOpen) {
      const newUserLocationIds = locations
        .filter((location) => location.selected)
        .map((location) => location.locationId);

      const updatedUser = {
        ...user,
        locations: newUserLocationIds,
      };

      onUserChanged(updatedUser);
    }
  }, [locations]);

  useEffect(() => {
    formatLocationDisplay(locations);
  }, [locations]);

  const setInitialLocationState = () => {
    const hasOwnerRole = user.roles.some((r) => r.name === roleNames.Owner);
    setIsLocationsDisabled(hasOwnerRole);
    if (hasOwnerRole) {
      const updatedLocations = locations.map((location) => ({
        ...location,
        selected: true,
      }));

      setLocations(updatedLocations);
    } else {
      const userLocationIds = user.locations;
      const updatedLocations = locations.map((location) => ({
        ...location,
        selected: userLocationIds.includes(location.locationId),
      }));

      setLocations(updatedLocations);
    }
  };

  const formatLocationDisplay = (locations: SelectedLocation[]) => {
    const selectedLocations = locations.filter((location) => location.selected);
    let display = "";

    if (locations.every((l) => l.selected)) {
      display = "All";
    } else {
      if (locations.some((l) => l.selected)) {
        selectedLocations.every((location) => {
          display += location.name;
          if (display.length > 10) {
            display = display.substr(0, 10) + "...";
            return false;
          }
          return true;
        });
      } else {
        display = "None";
      }
    }
    setLocationsDisplay(display);
  };

  const toggleAllLocations = () => {
    const isAllLocationsSelected = locations.every(
      (location) => location.selected
    );
    const updatedLocations = locations.map((location) => ({
      ...location,
      selected: !isAllLocationsSelected,
    }));

    setLocations(updatedLocations);
  };

  const updateLocation = (locationId: string) => {
    const updatedLocations = locations.map((location) => {
      if (location.locationId === locationId) {
        return { ...location, selected: !location.selected };
      }
      return location;
    });

    setLocations(updatedLocations);
  };

  const userLocationsPopoverStyles: Record<string, SxProps> = {
    button: {
      display: "flex",
      fontSize: "12px",
      alignItems: "center",
      textAlign: "center",
      color: "primary.main",
      background: "hsla(236, 75%, 65%, 0.07)",
      borderRadius: "4px",
      padding: "5px",
      width: "80%",
      margin: "auto",
      "&:hover": {
        backgroundColor: "hsla(236, 75%, 65%, 0.07)",
      },
      "&:focus": {
        backgroundColor: "hsla(236, 75%, 65%, 0.07)",
        color: "primary.main",
      },
    },
    buttonError: {
      border: "0.1px solid red",
    },
    userLocationsPopoverPaper: {
      bgcolor: "white",
    },
  };

  return (
    <>
      <Button
        data-testid="userLocationsPopoverButton"
        sx={
          {
            ...userLocationsPopoverStyles.button,
            ...(errorOutline ? userLocationsPopoverStyles.buttonError : {}),
          } as SxProps<Theme>
        }
        onClick={handleClick}
      >
        {locationsDisplay}
        <ExpandMoreIcon />
      </Button>
      <Popover
        PaperProps={{
          sx: userLocationsPopoverStyles.userLocationsPopoverPaper,
        }}
        anchorEl={locationPopoverAnchor}
        data-testid="userLocationsPopover"
        open={isPopoverOpen}
        onClose={handleClose}
      >
        <List>
          <ListItem key="all">
            <Checkbox
              checked={isAllSelected}
              data-testid="checkAllLocations"
              disabled={isLocationsDisabled}
              onChange={() => toggleAllLocations()}
            ></Checkbox>
            <ListItemText>Select All</ListItemText>
          </ListItem>
          {locations.map((location) => {
            return (
              <ListItem key={location.locationId}>
                <Checkbox
                  checked={location.selected}
                  disabled={isLocationsDisabled}
                  onChange={() => updateLocation(location.locationId)}
                />
                <ListItemText>{location.name}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
};

export default UserLocationsPopoverComponent;
