import { LDFlagSet } from "launchdarkly-js-sdk-common";
import { apiVersions } from "../constants/apiVersions";
import { featureFlags } from "../constants/featureFlags";

export const getVersion = (flags: LDFlagSet | null) => {
  if (flags && flags[featureFlags.GlobalProductCatalogPage]) {
    return apiVersions.TwoDotTwo;
  } else if (flags && flags[featureFlags.LabelsPage]) {
    return apiVersions.TwoDotOne;
  } else {
    return apiVersions.Default;
  }
};
