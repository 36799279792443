import { RicsSideMenuTab } from "../interfaces/common/RicsSideMenuTab";
import InfoIcon from "@mui/icons-material/Info";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreateIcon from "@mui/icons-material/Create";
import ImagesearchRollerIcon from "@mui/icons-material/ImagesearchRoller";
import ComputerIcon from "@mui/icons-material/Computer";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import SettingsIcon from "@mui/icons-material/Settings";

export const locationDetailsTabs: RicsSideMenuTab[] = [
  {
    tabKey: "Details",
    tabButtonText: "Location Details",
    tabHeaderText: "Location Details",
    tabIcon: InfoIcon,
  },
  {
    tabKey: "Users",
    tabButtonText: "Users",
    tabHeaderText: "Users",
    tabIcon: AccountCircleIcon,
  },
  {
    tabKey: "Taxes",
    tabButtonText: "Taxes",
    tabHeaderText: "Taxes",
    tabIcon: CreateIcon,
  },
  {
    tabKey: "Branding",
    tabButtonText: "Branding",
    tabHeaderText: "Brand Manager",
    tabIcon: ImagesearchRollerIcon,
  },
  {
    tabKey: "Devices",
    tabButtonText: "Devices",
    tabHeaderText: "Devices",
    tabIcon: ComputerIcon,
  },
  {
    tabKey: "DrawerHistory",
    tabButtonText: "Drawer History",
    tabHeaderText: "Drawer History",
    tabIcon: PointOfSaleIcon,
  },
  {
    tabKey: "Settings",
    tabButtonText: "Settings",
    tabHeaderText: "Settings",
    tabIcon: SettingsIcon,
  },
];
