import { Box, Checkbox, Grid, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import { toShortString } from "../../helpers/dateHelpers";
import { Device } from "../../interfaces/devices/Device";
import { SelectableObject } from "../../interfaces/common/SelectableObject";
import DeviceActionButtonComponent from "./DeviceActionButton";
import { useDataService } from "../../providers/DataServiceProvider";
import { useTheme } from "@mui/material/styles";

export interface DevicesGridRowProps {
  selectableDevice: SelectableObject<Device>;
  locationId?: string;
  onSelect?: (deviceId: string, checked: boolean) => void;
  onUpdate?: () => void;
}

const DevicesGridRowComponent: React.FC<DevicesGridRowProps> = ({
  selectableDevice,
  locationId,
  onSelect,
  onUpdate,
}) => {
  const device = selectableDevice.value;
  const isSelected = selectableDevice.selected;
  const activeStatus = "Active";
  const dataService = useDataService();
  const theme = useTheme();

  const formatDateCol = (dateNumber: number) => {
    if (dateNumber) {
      const date = new Date(dateNumber);
      return toShortString(date);
    } else {
      return "None";
    }
  };

  const getRequesterField = (device: Device) => {
    if (locationId) {
      return device.deviceLocations.filter(
        (dl) => dl.locationId === locationId
      )[0]?.activationRequesterName;
    }

    let lastActiveDeviceLocation =
      device.deviceLocations.length > 0 &&
      device.deviceLocations.reduce((prev, current) =>
        prev.lastActive > current.lastActive ? prev : current
      );
    return lastActiveDeviceLocation ?
        lastActiveDeviceLocation.activationRequesterName
      : "No Requester Data";
  };

  const getLastActiveField = (device: Device) => {
    if (
      device.deviceLocations === undefined ||
      device.deviceLocations.length === 0
    ) {
      return "No Activity";
    }

    if (locationId) {
      let specificLocationDate = Date.parse(
        device.deviceLocations.filter((dl) => dl.locationId === locationId)[0]
          ?.lastActive
      );
      return formatDateCol(specificLocationDate);
    }

    let latestDate = Math.max.apply(
      Math,
      device.deviceLocations.map((dl) => Date.parse(dl.lastActive))
    );

    return formatDateCol(latestDate);
  };

  const isDeviceActive = (device: Device) => {
    if (locationId) {
      return (
        device.deviceLocations.filter((dl) => dl.locationId === locationId)[0]
          ?.status === activeStatus
      );
    }

    return device.deviceLocations.some((dl) => dl.status === activeStatus);
  };

  const onActivate = () => {
    dataService.devices.activateDevice(device.id, locationId).then(onUpdate);
  };

  const onDelete = () => {
    dataService.devices.deleteDevice(device.id, locationId).then(onUpdate);
  };

  const onRevoke = () => {
    dataService.devices
      .declineDeviceActivation(device.id, locationId)
      .then(onUpdate);
  };

  const devicesGridRowStyles: Record<string, SxProps | undefined> = {
    rowGrid: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      pb: 1,
      pt: 1,
      userSelect: "text",
      width: "100%",
      borderBottom: "1px solid #F4F4F6",
    },
    valueText: {
      fontSize: "16px",
      letterSpacing: "-0.5px",
    },
    deviceActionsButtonGridItem: {
      display: "flex",
      justifyContent: "center",
    },
    deviceStatusHolder: {
      display: "flex",
      justifyContent: "center",
    },
    deviceStatusLabel: {
      display: "flex",
      borderRadius: "4px",
      textAlign: "center",
      padding: "4px",
      fontSize: "12px",
      color: "white",
    },
    deviceStatusLabelActive: {
      backgroundColor: theme.palette.common.active,
    },
    deviceStatusLabelInactive: {
      backgroundColor: theme.palette.common.inactive,
    },
  };

  return (
    <Box
      data-testid={`device-row-${device.id}`}
      sx={devicesGridRowStyles.rowGrid}
    >
      <Grid item xs={1}>
        <Checkbox
          checked={isSelected}
          data-testid="selectionCheckbox"
          onChange={(e, v) => onSelect && onSelect(device.id, v)}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography sx={devicesGridRowStyles.valueText}>
          {device.name}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography sx={devicesGridRowStyles.valueText}>
          {device.model}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={devicesGridRowStyles.valueText}>{device.id}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography sx={devicesGridRowStyles.valueText}>
          {getRequesterField(device)}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography sx={devicesGridRowStyles.valueText}>
          {getLastActiveField(device)}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Box sx={devicesGridRowStyles.deviceStatusHolder}>
          <Typography
            sx={
              {
                ...devicesGridRowStyles.deviceStatusLabel,
                ...(isDeviceActive(device) ?
                  devicesGridRowStyles.deviceStatusLabelActive
                : devicesGridRowStyles.deviceStatusLabelInactive),
              } as SxProps<Theme>
            }
          >
            {isDeviceActive(device) ? "Activated" : "Deactivated"}
          </Typography>
        </Box>
      </Grid>
      <Grid item sx={devicesGridRowStyles.deviceActionsButtonGridItem} xs={1}>
        <DeviceActionButtonComponent
          deviceIsActive={isDeviceActive(device)}
          rowButton={true}
          onActivate={onActivate}
          onDelete={onDelete}
          onRevoke={onRevoke}
        />
      </Grid>
    </Box>
  );
};

export default DevicesGridRowComponent;
