import React, { createContext, useContext } from "react";
import { AmazonService } from "../interfaces/services/AmazonService";
import {
  authorizeAccount,
  createAccount,
  disableAccount,
  enableAccount,
  getAccount,
  getAccounts,
  saveAccount,
} from "../services/AmazonService";

const AmazonServiceContext = createContext<AmazonService | undefined>(
  undefined
);
interface AmazonServiceProviderProps {
  children: React.ReactNode;
}

export const AmazonServiceProvider: React.FC<AmazonServiceProviderProps> = ({
  children,
}) => {
  const amazonService: AmazonService = {
    getAccounts: getAccounts,
    authorizeAccount: authorizeAccount,
    enableAccount: enableAccount,
    disableAccount: disableAccount,
    getAccount: getAccount,
    saveAccount: saveAccount,
    createAccount: createAccount,
  };

  return (
    <>
      <AmazonServiceContext.Provider value={amazonService}>
        {children}
      </AmazonServiceContext.Provider>
    </>
  );
};

export const useAmazonService = () => {
  return useContext(AmazonServiceContext);
};
