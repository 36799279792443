import React, { Dispatch, SetStateAction } from "react";
import { IconButton, SxProps, TextField, Theme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MenuIcon from "@mui/icons-material/Menu";
import ViewCompactIcon from "@mui/icons-material/ViewCompact";

interface ListAndCardsToggleProps {
  setIsCardMode: Dispatch<SetStateAction<boolean>>;
  onChangeSearchText: (searchTerm: string) => void;
  showSearchInput: boolean;
  setShowSearchInput: Dispatch<SetStateAction<boolean>>;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  searchPlaceholder: string;
}

const ListAndCardsToggleComponent: React.FC<ListAndCardsToggleProps> = ({
  setIsCardMode,
  onChangeSearchText,
  showSearchInput,
  setShowSearchInput,
  searchText,
  setSearchText,
  searchPlaceholder,
}) => {
  const toggleSearchInput = () => {
    if (showSearchInput) {
      setSearchText("");
    } else {
      setSearchText("");
    }

    setShowSearchInput(!showSearchInput);
  };

  const searchUsers = (searchText: string) => {
    setSearchText(searchText);
    onChangeSearchText(searchText);
  };

  const setButtonStyle = (isCardSelected: boolean) => {
    setIsCardMode(isCardSelected);
  };

  const listAndCardsToggleStyles: Record<string, SxProps<Theme> | undefined> = {
    userSearchInput: {
      "& .MuiInputBase-input": {
        height: "12px",
        border: "1px solid #EDEDED",
        borderRadius: "4px",
        color: "#000000",
      },
      marginLeft: "8px",
      marginRight: "5px",
    },
  };

  return (
    <>
      {showSearchInput ?
        <TextField
          data-testid="searchTextField"
          placeholder={searchPlaceholder}
          sx={listAndCardsToggleStyles.userSearchInput}
          value={searchText}
          onChange={(e) =>
            searchUsers((e.target as HTMLInputElement).value as string)
          }
        />
      : null}
      <IconButton
        data-testid="searchToggleButton"
        onClick={() => toggleSearchInput()}
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        data-testid="gridViewButton"
        onClick={() => setButtonStyle(false)}
      >
        <MenuIcon />
      </IconButton>
      <IconButton
        data-testid="cardViewButton"
        onClick={() => setButtonStyle(true)}
      >
        <ViewCompactIcon />
      </IconButton>
    </>
  );
};

export default ListAndCardsToggleComponent;
