import React, { useState, useEffect, Dispatch, SetStateAction } from "react";

import { User, Identity } from "../../interfaces/users/User";
import { Box, Checkbox, Grid, SxProps, Typography } from "@mui/material";

import UserCardComponent from "./UserCard";
import UserGridRowComponent from "./UserGridRow";
import { usePortal } from "../../providers/PortalProvider";
import { useUsersContext } from "../../providers/UsersProvider";

export interface UsersGridProps {
  users: Array<User>;
  locationId?: string;
  isCardMode: boolean;
  deleteUserIsEnabled: boolean;
  setCanSave: Dispatch<SetStateAction<boolean>>;
}

const UsersGridComponent: React.FC<UsersGridProps> = ({
  users,
  isCardMode,
  deleteUserIsEnabled,
  setCanSave,
}) => {
  const portal = usePortal();

  const [clickedUser, setClickedUser] = useState<User>();
  const [clickedUserIdentity, setClickedUserIdentity] = useState<Identity>();

  const [rowValidities, setRowValidities] = React.useState({});
  const { setUsers } = useUsersContext();

  const setRowValidity = (id: string, isValid: boolean) => {
    setRowValidities((prev) => ({ ...prev, [id]: isValid }));
  };

  const isAllUsersChecked = users.every((u) => u.isChecked == true);
  useEffect(() => {
    setClickedUserIdentity(
      portal!.state.tenantIdentities?.find(
        (x) => x.identityId === clickedUser?.userId
      )
    );
  }, [clickedUser, clickedUserIdentity, portal]);

  useEffect(() => {
    const isAllValid = Object.values(rowValidities).every(Boolean);
    setCanSave(isAllValid);
  }, [rowValidities]);

  const handleCheckboxClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const checkbox = e.target as HTMLInputElement;
    toggleAllValidUsersCheckedStatus(checkbox.checked);
  };

  const toggleAllValidUsersCheckedStatus = (checked: boolean) => {
    const updatedUsers = users.map((user) => {
      if (checked) {
        return { ...user, isChecked: user.isValid };
      } else {
        return { ...user, isChecked: false };
      }
    });
    setUsers(updatedUsers);
  };
  const userGridStyles: Record<string, SxProps | undefined> = {
    userGrid: {
      display: "flex",
      letterSpacing: "-0.5px",
      alignItems: "stretch",
      justifyContent: "center",
    },
    headerGrid: {
      lineHeight: "16px",
      alignItems: "center",
      textAlign: "center",
      background: "#ffffff",
      borderBottom: "2px solid #F4F4F6",
      width: "100%",
      padding: 1,
    },
    userRowGrid: {
      display: "flex",
      justifyContent: "center",
      background: "#ffffff",
      color: "#7F7F7F",
      fontSize: "14px",
      letterSpacing: "-0.5px",
      alignItems: "center",
      textAlign: "center",
    },
    headerText: {
      fontSize: "14px",
      letterSpacing: "-0.5px",
      fontWeight: "bold",
    },
    gridContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100vh",
      width: "80%",
      margin: "0 auto",
    },
    headingText: {
      fontSize: "20px",
      margin: "10px",
      fontWeight: "bold",
    },
    userActionsPopoverPaper: {
      bgcolor: "white",
    },
    userActionsPopoverButton: {
      color: "black",
      "&:focus": {
        backgroundColor: "white",
        color: "black",
      },
    },
    deleteUserModal: {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      border: "2px solid #000",
      boxShadow: 24,
      p: 4,
      textAlign: "center",
    },
    deleteUserButtonBox: {
      paddingTop: "15px",
    },
  };

  return (
    <Box data-testid="usersGrid" sx={userGridStyles.gridContainer}>
      {!isCardMode && (
        <Grid
          container
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          sx={userGridStyles.userGrid}
        >
          <Grid container columns={24} sx={userGridStyles.headerGrid}>
            <Grid item xs={1}>
              <Checkbox
                checked={isAllUsersChecked}
                data-testid="checkAllCheckbox"
                onClick={handleCheckboxClick}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography sx={userGridStyles.headerText}>First Name</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={userGridStyles.headerText}>Last Name</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={userGridStyles.headerText}>Email</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={userGridStyles.headerText}>
                Phone Number
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography noWrap sx={userGridStyles.headerText}>
                Role(s)
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={userGridStyles.headerText}>
                Location(s)
              </Typography>
            </Grid>
            <Grid item xs={1} />
          </Grid>
          <Grid
            container
            columns={24}
            data-testid="userGridViewContainer"
            sx={userGridStyles.userRowGrid}
          >
            {users.map((user) => {
              if (!user.isHidden) {
                return (
                  <UserGridRowComponent
                    deleteUserIsEnabled={deleteUserIsEnabled}
                    id={user.userId}
                    key={user.userId}
                    setClickedUser={setClickedUser}
                    setValidity={setRowValidity}
                    user={user}
                  />
                );
              }
            })}
          </Grid>
        </Grid>
      )}
      {isCardMode && (
        <Grid container data-testid="userCardViewContainer" spacing={1}>
          {users &&
            users
              .filter((user) => !user.isHidden)
              .map((user, i) => (
                <Grid item key={i}>
                  <UserCardComponent key={i} user={user} />
                </Grid>
              ))}
        </Grid>
      )}
    </Box>
  );
};

export default UsersGridComponent;
