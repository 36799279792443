interface DeviceTimeout {
  id: string;
  displayText: string;
}

export const deviceTimeout: Record<string, DeviceTimeout> = {
  Never: { id: "Never", displayText: "Never" },
  OneMinute: { id: "OneMinute", displayText: "After 1 minute" },
  FiveMinutes: { id: "FiveMinutes", displayText: "After 5 minutes" },
  TenMinutes: { id: "TenMinutes", displayText: "After 10 minutes" },
  ThirtyMinutes: { id: "ThirtyMinutes", displayText: "After 30 minutes" },
  EverySale: {
    id: "EverySale",
    displayText: "At the conclusion of every sale",
  },
};
