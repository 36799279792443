import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";
import React from "react";
import globalTheme from "../../theme/globalTheme";

const PrimaryButtonComponent = styled(
  ({ className, ...props }: ButtonProps) => (
    <>
      <Button {...props} classes={{ root: className }} />
    </>
  )
)(() => ({
  backgroundColor: globalTheme.palette.primary.main,
  color: globalTheme.palette.common.white,
  "&:hover": {
    backgroundColor: globalTheme.palette.primary.main,
    color: globalTheme.palette.common.white,
    opacity: 0.9,
  },
  "&.Mui-disabled": {
    backgroundColor: globalTheme.palette.primary.main,
    color: globalTheme.palette.common.white,
    opacity: 0.7,
  },
}));

export default PrimaryButtonComponent;
