import { Avatar, Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";

interface RicsSideMenuTabHeaderProps {
  headerIcon: React.ReactElement;
  headerText: string;
  children?: React.ReactNode;
}

const ricsSideMenuTabHeaderStyles: Record<string, SxProps<Theme> | undefined> =
  {
    header: {
      background: "white",
    },
    sideMenuTabHeaderToolbar: {
      padding: "16px",
      maxHeight: "64px",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      width: "100%",
    },
    sideMenuTabHeaderIcon: {
      backgroundColor: "primary.main",
    },
    shadowBox: {
      height: "4px",
      backgroundImage:
        "linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(255,255,255,0))",
    },
    sideMenuTabHeaderTitle: {
      fontSize: "24px",
      letterSpacing: "-0.5px",
      lineHeight: "28px",
      paddingLeft: "16px",
    },
  };

const RicsSideMenuTabHeaderComponent: React.FC<RicsSideMenuTabHeaderProps> = ({
  headerIcon,
  headerText,
  children,
}) => {
  return (
    <Box sx={ricsSideMenuTabHeaderStyles.header}>
      <Box sx={ricsSideMenuTabHeaderStyles.sideMenuTabHeaderToolbar}>
        <Avatar sx={ricsSideMenuTabHeaderStyles.sideMenuTabHeaderIcon}>
          {headerIcon}
        </Avatar>
        <Typography sx={ricsSideMenuTabHeaderStyles.sideMenuTabHeaderTitle}>
          {headerText}
        </Typography>
        <Box>{children}</Box>
      </Box>
      <Box sx={ricsSideMenuTabHeaderStyles.shadowBox} />
    </Box>
  );
};

export default RicsSideMenuTabHeaderComponent;
