import React, { useState } from "react";
import { Box, Grid, Typography, SxProps } from "@mui/material";
import { Location } from "../../interfaces/locations/Location";
import FinishAndInviteUsersModalComponent from "./FinishAndInviteUsersModal";

import LocationCardComponent from "./LocationCard";
import LocationGridRowComponent from "./LocationGridRow";

interface LocationsGridProps {
  locations: Array<Location>;
  isCardMode: boolean;
}

const LocationsGridComponent: React.FC<LocationsGridProps> = ({
  locations,
  isCardMode,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inviteLocation, setInviteLocation] = useState<Location>();

  const showModalForLocation = (showModal: boolean, location?: Location) => {
    setInviteLocation(location);
    setIsModalOpen(showModal);
  };

  const locationGridStyles: Record<string, SxProps | undefined> = {
    locationGrid: {
      display: "flex",
      letterSpacing: "-0.5px",
      alignItems: "stretch",
      justifyContent: "center",
    },
    headerGrid: {
      lineHeight: "16px",
      alignItems: "center",
      textAlign: "center",
      background: "#ffffff",
      borderBottomColor: "#F4F4F6",
      borderBottomWidth: "4px",
      borderBottomStyle: "solid",
      width: "100%",
    },
    locationRowGrid: {
      display: "flex",
      justifyContent: "center",
      background: "#ffffff",
      color: "#7F7F7F",
      fontSize: "14px",
      letterSpacing: "-0.5px",
      alignItems: "center",
      textAlign: "center",
    },
    headerText: {
      fontSize: "12px",
      fontFamily: "Inter-bold",
      letterSpacing: "-0.5px",
    },
    gridContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100vh",
      width: "80%",
      margin: "0 auto",
    },
    headingText: {
      fontSize: "20px",
      margin: "10px",
      fontWeight: "bold",
    },
  };

  return (
    <Box sx={locationGridStyles.gridContainer}>
      {!isCardMode && (
        <Grid
          container
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          sx={locationGridStyles.locationGrid}
        >
          <Grid container sx={locationGridStyles.headerGrid}>
            <Grid item xs={1} />
            <Grid item xs={3}>
              <Typography sx={locationGridStyles.headerText}>
                Store No.
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={locationGridStyles.headerText}>
                Address
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={locationGridStyles.headerText}>City</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={locationGridStyles.headerText}>State</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography sx={locationGridStyles.headerText}>Zip</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography noWrap sx={locationGridStyles.headerText}>
                Store Owner
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={locationGridStyles.headerText}>Status</Typography>
            </Grid>
          </Grid>
          <Grid container sx={locationGridStyles.locationRowGrid}>
            {locations.map((location, index) => {
              if (!location.hidden) {
                return (
                  <LocationGridRowComponent
                    key={index}
                    location={location}
                    setShowModal={showModalForLocation}
                  />
                );
              }
            })}
          </Grid>
        </Grid>
      )}
      {isCardMode &&
        locations.filter((l) => l.isActive === false && !l.hidden).length >
          0 && (
          <>
            <Box sx={{ width: "100%" }}>
              <Typography sx={locationGridStyles.headingText}>
                Pending Setup
              </Typography>
            </Box>
            <Grid container spacing={1}>
              {locations &&
                locations
                  .filter((l) => l.isActive === false && !l.hidden)
                  .map((location, i) => (
                    <Grid item key={i}>
                      <LocationCardComponent
                        key={location.locationId}
                        location={location}
                        setShowModal={showModalForLocation}
                      />
                    </Grid>
                  ))}
            </Grid>
          </>
        )}
      {isCardMode &&
        locations.filter((l) => l.isActive === true && !l.hidden).length >
          0 && (
          <>
            <Box sx={{ width: "100%" }}>
              <Typography sx={locationGridStyles.headingText}>
                Active
              </Typography>
            </Box>
            <Grid container spacing={1}>
              {locations &&
                locations
                  .filter((l) => l.isActive === true && !l.hidden)
                  .map((location, i) => (
                    <Grid item key={i}>
                      <LocationCardComponent
                        key={location.locationId}
                        location={location}
                        setShowModal={showModalForLocation}
                      />
                    </Grid>
                  ))}
            </Grid>
          </>
        )}
      <FinishAndInviteUsersModalComponent
        location={inviteLocation}
        setShowModal={showModalForLocation}
        showModal={isModalOpen}
      />
    </Box>
  );
};

export default LocationsGridComponent;
