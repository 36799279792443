import { roles } from "../constants/roles";
import { Role } from "../interfaces/users/Role";

export const getRoleDisplayName = (roles: Array<Role>) => {
  if (roles.length > 1) {
    return "Multiple";
  }

  if (roles.length === 1) {
    return roles[0].displayName;
  }

  return "None";
};

export const getSelectedRoles = (userRoles: Array<Role>) => {
  const selectedRoles = roles.map((r) => {
    const userRole = userRoles.find((ur) => ur.name === r.name);
    r.isSelected = userRole != undefined;
    return { ...r };
  });

  return selectedRoles;
};
