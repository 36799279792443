export const productTypes: { value: string; label: string }[] = [
  { value: "Animal", label: "Animal" },
  { value: "Appliance", label: "Appliance" },
  { value: "Bag", label: "Bag" },
  { value: "Bath", label: "Bath" },
  { value: "Beauty/Personal-Care", label: "Beauty/Personal Care" },
  { value: "Bedding", label: "Bedding" },
  { value: "Beverage", label: "Beverage" },
  { value: "Bra", label: "Bra" },
  { value: "Carpet/Rug", label: "Carpet/Rug" },
  { value: "Case", label: "Case" },
  { value: "Clothing-Accessory", label: "Clothing Accessory" },
  { value: "Coat/Jacket", label: "Coat/Jacket" },
  { value: "Dress", label: "Dress" },
  { value: "Electronic", label: "Electronic" },
  { value: "Eyewear", label: "Eyewear" },
  { value: "Food", label: "Food" },
  { value: "Footwear", label: "Footwear" },
  { value: "Footwear-Accessory", label: "Footwear Accessory" },
  { value: "Furniture", label: "Furniture" },
  { value: "Glove", label: "Glove" },
  { value: "Headwear", label: "Headwear" },
  { value: "Home/Garden/Tool", label: "Home/Garden/Tool" },
  { value: "Jewelry", label: "Jewelry" },
  { value: "Kitchenware", label: "Kitchenware" },
  { value: "Legwear/Sock", label: "Legwear/Sock" },
  { value: "Medical-Equipment", label: "Medical Equipment" },
  { value: "Musical-Equipment", label: "Musical Equipment" },
  { value: "Neckwear", label: "Neckwear" },
  { value: "Nutrition", label: "Nutrition" },
  { value: "Office-Supplies", label: "Office Supplies" },
  { value: "Outfit", label: "Outfit" },
  { value: "Pants", label: "Pants" },
  { value: "Service", label: "Service" },
  { value: "Shirt", label: "Shirt" },
  { value: "Shorts", label: "Shorts" },
  { value: "Skirt", label: "Skirt" },
  { value: "Sports-Equipment", label: "Sports Equipment" },
  { value: "Supplement", label: "Supplement" },
  { value: "Sweater", label: "Sweater" },
  { value: "Swimwear", label: "Swimwear" },
  { value: "Toy", label: "Toy" },
  { value: "Undergarment", label: "Undergarment" },
];
