import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PercentIcon from "@mui/icons-material/Percent";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { Tax } from "../../../../interfaces/settings/Taxes";
import { useTaxesContext } from "../../../../providers/TaxesProvider";

export interface TaxJurisdictionRowProps {
  tax: Tax;
}

export const TaxJurisdictionRowComponent: React.FC<TaxJurisdictionRowProps> = ({
  tax,
}) => {
  const formatRateString = (taxRate: number) => {
    let percentRate = taxRate * 100;
    let truncatedRate = percentRate.toFixed(4);
    let rateWithoutTrailingZeros = Number(truncatedRate).toString();
    return rateWithoutTrailingZeros;
  };

  const [taxDescription, setTaxDescription] = useState(tax.description);
  const [taxRateString, setTaxRateString] = useState<string>(
    tax.taxRateFormValue ?? (tax.taxRate ? formatRateString(tax.taxRate) : "")
  );

  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState(
    !tax.description ||
      (tax.description.length > 0 && tax.description.length < 4)
  );
  const [isTaxRateInvalid, setIsTaxRateInvalid] = useState(() => {
    const taxRateValue = parseFloat(taxRateString) / 100;
    const isValidNumber =
      !isNaN(taxRateValue) && isFinite(taxRateValue) && taxRateValue > 0;
    const hasValidDecimals =
      taxRateString === "" ||
      (isValidNumber &&
        (!taxRateString.includes(".") ||
          taxRateString.split(".")[1]?.length <= 4));
    return !isValidNumber || !hasValidDecimals;
  });

  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    setIsDeleted(!tax.locationIds || tax.locationIds.length === 0);
  }, [tax.locationIds]);

  const { deleteTax, updateTax } = useTaxesContext();

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newDescription = event.target.value;
    setTaxDescription(newDescription);
    setIsDescriptionInvalid(newDescription.length < 4);
    updateTax({ ...tax, description: newDescription });
  };

  const handleTaxRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputTaxRateString = event.target.value;
    setTaxRateString(inputTaxRateString);

    if (inputTaxRateString.trim() === "") {
      setIsTaxRateInvalid(true);
      updateTax({ ...tax, taxRate: undefined });
      return;
    }

    const newTaxRateDecimal = parseFloat(
      (parseFloat(inputTaxRateString) / 100).toFixed(6)
    );
    const isValidNumber =
      !isNaN(newTaxRateDecimal) && isFinite(newTaxRateDecimal);
    const hasValidDecimals =
      inputTaxRateString.split(".")[1]?.length <= 4 ||
      !inputTaxRateString.includes(".");

    setIsTaxRateInvalid(!isValidNumber || !hasValidDecimals);

    const updatedTax = {
      ...tax,
      taxRateFormValue: inputTaxRateString,
    };

    if (isValidNumber && hasValidDecimals) {
      updatedTax.taxRate = newTaxRateDecimal;
    }
    updateTax(updatedTax);
  };

  const taxJurisdictionRowStyles: Record<string, SxProps> = {
    taxRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      pb: 1,
      pt: 1,
      width: "100%",
    },
    taxDescriptionField: {
      width: "100%",
      pr: 1,
    },
  };

  if (isDeleted) {
    return null;
  }

  return (
    <Box
      data-testid={`taxJurisdictionRow-${tax.taxId}`}
      sx={taxJurisdictionRowStyles.taxRow}
    >
      <Grid item xs={8}>
        <TextField
          data-testid={`taxDescriptionTextField-${tax.taxId}`}
          error={isDescriptionInvalid}
          sx={taxJurisdictionRowStyles.taxDescriptionField}
          value={taxDescription}
          variant="outlined"
          onChange={handleDescriptionChange}
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <PercentIcon />
              </InputAdornment>
            ),
          }}
          data-testid={`taxRateTextField-${tax.taxId}`}
          error={isTaxRateInvalid}
          value={taxRateString}
          onChange={handleTaxRateChange}
        />
      </Grid>
      <Grid item xs={2}>
        <IconButton
          data-testid={`taxRowDeleteButton-${tax.taxId}`}
          onClick={() => deleteTax(tax.taxId)}
        >
          <CancelRoundedIcon />
        </IconButton>
      </Grid>
    </Box>
  );
};

export default TaxJurisdictionRowComponent;
