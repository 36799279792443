import React, { useEffect } from "react";
import { Alert, Box, Collapse, Grid, IconButton } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { SxProps, Theme } from "@mui/material";
import UserCardComponent from "../../users/UserCard";
import CloseIcon from "@mui/icons-material/Close";
import { useUsersContext } from "../../../providers/UsersProvider";
import { roleNames } from "../../../constants/roles";
import AddUserButtonComponent from "../../users/AddUserButton";
import LocationDetailsPageComponent from "../LocationDetailsPage";

export interface LocationDetailsUsersProps {
  locationId: string;
}

const LocationDetailsUsersComponent: React.FC<LocationDetailsUsersProps> = ({
  locationId,
}) => {
  const { getUsers, users } = useUsersContext();

  const [isUserRoleMessageOpen, setIsUserRoleMessageOpen] =
    React.useState(true);

  useEffect(() => {
    getUsers();
  }, [locationId]);

  const locationDetailsUsersStyles: Record<string, SxProps<Theme>> = {
    cardContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      pt: 2,
    },
    cardGrid: {
      justifyContent: "center",
    },
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      width: "100%",
      alignItems: "center",
      margin: "16px auto",
      padding: "16px",
    },
    headerBox: {
      position: "relative",
    },
    headerButtonBox: {
      position: "absolute",
      right: 0,
      top: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      paddingRight: 2,
    },
  };
  return (
    <LocationDetailsPageComponent
      dataTestId="locationDetailsUsers"
      headerButton={
        <AddUserButtonComponent
          locationId={locationId}
          onImportSuccess={getUsers}
        />
      }
      headerIcon={<AccountCircleIcon />}
      headerText="Users"
    >
      <Collapse in={isUserRoleMessageOpen}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              data-testid="closeUserRoleMessageButton"
              size="small"
              onClick={() => {
                setIsUserRoleMessageOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity="info"
        >
          To update a user&apos;s role, please go to the All Users page.
        </Alert>
      </Collapse>
      <Box sx={locationDetailsUsersStyles.cardContainer}>
        <Grid container spacing={1} sx={locationDetailsUsersStyles.cardGrid}>
          {users
            .filter(
              (user) =>
                (!user.isHidden && user.locations.includes(locationId)) ||
                user.roles.some((r) => r.name === roleNames.Owner)
            )
            .map((user, i) => (
              <Grid item key={i}>
                <UserCardComponent key={i} user={user} />
              </Grid>
            ))}
        </Grid>
      </Box>
    </LocationDetailsPageComponent>
  );
};
export default LocationDetailsUsersComponent;
