import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFlags } from "launchdarkly-react-client-sdk";
import { featureFlags } from "../../constants/featureFlags";
import { RicsSideMenuTab } from "../../interfaces/common/RicsSideMenuTab";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { usePortal } from "../../providers/PortalProvider";

import {
  Box,
  CssBaseline,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Menu,
  SxProps,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";

import { styled, Theme, CSSObject } from "@mui/material/styles";

interface SideMenuProps {
  selectedTab: RicsSideMenuTab;
  tabs: Array<RicsSideMenuTab>;
  contentId: string;
  navigationEnabled: boolean;
  setSelectedTab: (tabKey: string) => void;
  getCustomHeader?: (menuCollapsed: boolean) => React.JSX.Element;
  location: { name: string; locationId: string };
}

const drawerWidth = 240;
const closedDrawerWidth = 64;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerComponent = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const RicsSideMenuComponent: React.FC<SideMenuProps> = ({
  selectedTab,
  tabs,
  setSelectedTab,
  location,
}) => {
  const portal = usePortal();
  const flags = useFlags();
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(true);
  const [isLocationsMenuOpen, setIsLocationsMenuOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsSideMenuOpen(!isSideMenuOpen);
  };

  const [isCashCountEnabled, setIsCashCountEnabled] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [selectedLocationName, setSelectedLocationName] = useState<string>(
    location.name
  );
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setMenuAnchor(event.currentTarget);
    setIsLocationsMenuOpen(!isLocationsMenuOpen);
  };
  const handleMenuClose = () => {
    setMenuAnchor(null);
    setIsLocationsMenuOpen(false);
  };

  useEffect(() => {
    getCashCountFeatureFlag();
  }, [flags]);

  const locationNamesAndIds = portal!.state.locations.map((l) => {
    return { Name: l.name, LocationId: l.locationId };
  });

  const navigateToLocation = (locationId: string) => {
    const locationRoute = "/locationdetails/" + encodeURIComponent(locationId);
    return locationRoute;
  };

  const handleLocationClick = (locationId: string, locationName: string) => {
    setSelectedLocationName(locationName);
    setIsLocationsMenuOpen(false);
  };

  const mappedLocationOptions = locationNamesAndIds.map((l) => (
    <MenuItem
      component={Link}
      key={l.LocationId}
      to={navigateToLocation(l.LocationId)}
      onClick={() => handleLocationClick(l.LocationId, l.Name)}
    >
      {l.Name}
    </MenuItem>
  ));

  const getCashCountFeatureFlag = () => {
    var cashCountFeatureFlag = flags[featureFlags.CashCount];
    setIsCashCountEnabled(cashCountFeatureFlag);
  };

  const sideMenuStyles: Record<string, SxProps<Theme>> = {
    componentContainerBox: {
      display: "flex",
    },
    drawer: {
      width: "240px",
      flexShrink: 0,
      "& .MuiDrawer-paper": {
        width: isSideMenuOpen ? drawerWidth : closedDrawerWidth,
        boxSizing: "border-box",
        mt: 8,
        bgcolor: "#252525",
        height: "calc(100vh - 64px)",
      },
    },
    drawerTabText: {
      color: "white",
      opacity: isSideMenuOpen ? 1 : 0,
    },
    drawerTabIcon: {
      color: "#929292",
      minWidth: 0,
      mr: isSideMenuOpen ? 3 : "auto",
      justifyContent: "center",
    },
    currentLocationIcon: {
      color: "#929292",
      minWidth: 0,
      justifyContent: "center",
    },
    selectedDrawerTabIcon: {
      color: "white",
    },
    drawerTabButton: {
      minHeight: 48,
      justifyContent: isSideMenuOpen ? "initial" : "center",
      px: 2.5,
      "&.Mui-selected": {
        bgcolor: "#343434",
        color: "white",
      },
    },
    drawerTabItem: {
      display: "block",
    },
    footerList: {
      marginTop: "auto",
    },
    locationMenuPaper: {
      bgcolor: "white",
    },
  };

  return (
    <Box sx={sideMenuStyles.componentContainerBox}>
      <CssBaseline />
      <DrawerComponent
        open={isSideMenuOpen}
        sx={sideMenuStyles.drawer}
        variant="permanent"
      >
        <Divider />
        <List>
          <ListItem disablePadding sx={sideMenuStyles.drawerTabItem}>
            <ListItemButton
              sx={sideMenuStyles.drawerTabButton}
              onClick={handleClick}
            >
              <ListItemText
                primary={selectedLocationName}
                primaryTypographyProps={{ noWrap: true }}
                sx={{ ...sideMenuStyles.drawerTabText, textAlign: "center" }}
              />
              <ListItemIcon sx={sideMenuStyles.currentLocationIcon}>
                <ArrowDropDownIcon />
              </ListItemIcon>
            </ListItemButton>
            <Menu
              PaperProps={{ sx: sideMenuStyles.locationMenuPaper }}
              anchorEl={menuAnchor}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={isLocationsMenuOpen}
              sx={{ paddingTop: "64px" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleMenuClose}
            >
              {mappedLocationOptions}
            </Menu>
          </ListItem>
          {tabs.map((tab) => {
            const isSelected = selectedTab.tabKey === tab.tabKey;

            return (
              (tab.tabKey !== "DrawerHistory" || isCashCountEnabled) && (
                <ListItem
                  disablePadding
                  key={tab.tabKey}
                  sx={sideMenuStyles.drawerTabItem}
                  onClick={() => setSelectedTab(tab.tabKey)}
                >
                  <ListItemButton
                    selected={isSelected}
                    sx={sideMenuStyles.drawerTabButton}
                  >
                    <ListItemIcon
                      sx={
                        {
                          ...sideMenuStyles.drawerTabIcon,
                          ...(isSelected ?
                            sideMenuStyles.selectedDrawerTabIcon
                          : {}),
                        } as SxProps<Theme>
                      }
                    >
                      <tab.tabIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={tab.tabButtonText}
                      sx={sideMenuStyles.drawerTabText}
                    />
                    {isSelected && <ChevronRightIcon />}
                  </ListItemButton>
                </ListItem>
              )
            );
          })}
        </List>
        <List sx={sideMenuStyles.footerList}>
          <ListItem disablePadding>
            <ListItemButton
              sx={sideMenuStyles.drawerTabButton}
              onClick={handleDrawerToggle}
            >
              <ListItemIcon sx={sideMenuStyles.drawerTabIcon}>
                {isSideMenuOpen ?
                  <ArrowBackIcon />
                : <ArrowForwardIcon />}
              </ListItemIcon>
              <ListItemText
                primary="Collapse Menu"
                sx={sideMenuStyles.drawerTabText}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </DrawerComponent>
    </Box>
  );
};

export default RicsSideMenuComponent;
