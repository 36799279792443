import { roles } from "../constants/roles";
import { Identity, User } from "../interfaces/users/User";

const emailIsValid = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const getUserFromIdentity = (identity: Identity) => {
  const locationIds: Array<string> = [];
  identity.roles
    .filter((x) => x.locationId != undefined)
    .forEach((x) => {
      if (locationIds.some((l) => l === x.locationId)) {
        return;
      }

      locationIds.push(x.locationId!);
    });
  const userRoles = roles.filter((r) =>
    identity.roles.some((ir) => ir.identityRole === r.name)
  );

  const mappedUser: User = {
    userId: identity.identityId,
    email: identity.emailAddress,
    firstName: identity.firstName,
    lastName: identity.lastName,
    deletedOn: identity.deletedOn,
    login: "",
    phoneNumber: identity.phoneNumber,
    ricsUserId: identity.rics9UserId,
    ricsXIdentityId: identity.identityId,
    locations: locationIds,
    roles: userRoles,
    selectedToImport: false,
    selectedToEdit: false,
    errorString: "",
    isValid: emailIsValid(identity.emailAddress),
    isActivated: identity.isActivated,
    isVerified: identity.isVerified,
    isSelected: false,
  };

  return mappedUser;
};
