import React, { useEffect, useState } from "react";
import { useUsersContext } from "../../providers/UsersProvider";
import UserImportRowComponent from "./UserImportRow";
import { User } from "../../interfaces/users/User";
import { Box, Checkbox, Grid, SxProps, Theme, Typography } from "@mui/material";
export interface UserImportGridProps {
  locationId?: string;
}

export const UserImportGridComponent: React.FC<UserImportGridProps> = ({
  locationId,
}) => {
  const { rics9Users, setRics9Users, toggleAllUserImportCheckedUsers } =
    useUsersContext();
  const [isAllUsersChecked, setIsAllUsersChecked] = useState(false);

  useEffect(() => {
    if (rics9Users) {
      setIsAllUsersChecked(rics9Users.every((user) => user.selectedToImport));
    }
  }, [rics9Users]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    toggleAllUserImportCheckedUsers(e.target.checked);
  };

  const updateUser = (ricsUserId: string, updatedUser: User) => {
    const currentUsers = rics9Users.map((user) =>
      user.ricsUserId === ricsUserId ? updatedUser : user
    );

    if (setRics9Users) {
      setRics9Users(currentUsers);
    }
  };

  const userImportGridStyles: Record<string, SxProps<Theme> | undefined> = {
    headerGrid: {
      lineHeight: "16px",
      alignItems: "center",
      textAlign: "center",
      background: "#ffffff",
      borderBottom: "2px solid #F4F4F6",
      width: "100%",
      pr: "17px",
    },
    headerGridText: {
      fontSize: "14px",
      letterSpacing: "-0.5px",
      fontWeight: "bold",
    },
    userImportGridContainer: {
      overflowY: "auto",
      flex: "1 1 auto",
      letterSpacing: "-0.5px",
      alignItems: "center",
      maxWidth: "100%",
      textAlign: "center",
    },
    userImportRowGrid: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      background: "#ffffff",
      color: "#7F7F7F",
      fontSize: "14px",
      letterSpacing: "-0.5px",
    },
  };

  return (
    <>
      <Grid container columns={24} sx={userImportGridStyles.headerGrid}>
        <Grid item xs={1}>
          <Checkbox
            checked={isAllUsersChecked}
            onChange={handleCheckboxChange}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography sx={userImportGridStyles.headerGridText}>
            First Name
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography sx={userImportGridStyles.headerGridText}>
            Last Name
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={userImportGridStyles.headerGridText}>
            Email
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography noWrap sx={userImportGridStyles.headerGridText}>
            Role(s)
          </Typography>
        </Grid>
        {!locationId && (
          <Grid item xs={4}>
            <Typography sx={userImportGridStyles.headerGridText}>
              Location(s)
            </Typography>
          </Grid>
        )}
      </Grid>
      <Box sx={userImportGridStyles.userImportGridContainer}>
        <Grid
          container
          columns={24}
          data-testid="userImportGridViewContainer"
          sx={userImportGridStyles.userImportRowGrid}
        >
          {rics9Users.map((user, index) => {
            if (!user.isHidden) {
              return (
                <UserImportRowComponent
                  id={user.ricsUserId}
                  key={index}
                  locationId={locationId}
                  user={user}
                  onUpdateUser={updateUser}
                />
              );
            }
          })}
        </Grid>
      </Box>
    </>
  );
};
