import React from "react";
import {
  Box,
  Card,
  CardActionArea,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { Location } from "../../interfaces/locations/Location";
import LocationStatusComponent from "./LocationStatus";
import LocationOwnerComponent from "./LocationOwner";
import LocationLogoComponent from "./LocationLogo";
import { useNavigate } from "react-router";
import { paths } from "../../constants/paths";

interface LocationCardProps {
  location: Location;
  setShowModal: (showModal: boolean, location?: Location) => void;
}

const locationCardStyles: Record<string, SxProps<Theme> | undefined> = {
  cardStyle: {
    backgroundColor: "white",
    width: "264px",
    m: "10px",
  },
  cardActionArea: {
    "&:hover": {
      backgroundColor: "white",
    },
    "&:focus": {
      backgroundColor: "white",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
  },
  cardHeading: {
    fontSize: "16px",
    textTransform: "capitalize",
    mb: "15px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  logoBox: {
    height: "72px",
    width: "72px",
    marginBottom: "25px",
  },
  logoImage: {
    height: "100%",
    width: "100%",
    margin: "auto",
  },
  storeContactBox: {
    mt: "30px",
    display: "flex",
    alignItems: "center",
    color: "primary.main",
    background: "#edfafd",
    borderRadius: "4px",
    height: "24px",
    padding: "4px 9px 0px 5px",
  },
};

const LocationCardComponent: React.FC<LocationCardProps> = ({
  location,
  setShowModal,
}) => {
  const navigate = useNavigate();

  return (
    <Card sx={locationCardStyles.cardStyle}>
      <Box sx={locationCardStyles.cardContent}>
        <CardActionArea
          sx={
            {
              ...locationCardStyles.cardActionArea,
              ...locationCardStyles.cardContent,
            } as SxProps<Theme>
          }
          onClick={() =>
            navigate(paths.LocationDetails + "/" + location.locationId)
          }
        >
          <Box sx={locationCardStyles.logoBox}>
            <LocationLogoComponent
              location={location}
              logoStyle={locationCardStyles.logoImage}
            />
          </Box>
          <Box sx={locationCardStyles.cardHeading}>
            <Typography>{location.name}</Typography>
          </Box>
          <Box sx={locationCardStyles.storeContactBox}>
            <LocationOwnerComponent
              location={location}
            ></LocationOwnerComponent>
          </Box>
        </CardActionArea>

        <LocationStatusComponent
          clickToBegin={true}
          location={location}
          setShowModal={setShowModal}
        ></LocationStatusComponent>
      </Box>
    </Card>
  );
};

export default LocationCardComponent;
