import React from "react";
import { AddUserHeaderComponent } from "./AddUserHeader";
import { AddUserFooterComponent } from "./AddUserFooter";
import { useUsersContext } from "../../../providers/UsersProvider";
import { Box, Modal, SxProps, Theme } from "@mui/material";
import { AddUserGridComponent } from "./AddUserGrid";

export interface AddUserToLocationModalProps {
  isAddUserModalOpen: boolean;
  locationId: string;
  setIsAddUserModalOpen: (value: boolean) => void;
}

export const AddUserToLocationModalComponent: React.FC<
  AddUserToLocationModalProps
> = ({ isAddUserModalOpen, locationId, setIsAddUserModalOpen }) => {
  const { users } = useUsersContext();
  const handleCloseModal = () => {
    setIsAddUserModalOpen(false);
    // unassignedUsers.forEach((user) => {
    //   const updatedUser = { ...user, selectedToImport: false };
    //   updateUser(user.userId, updatedUser);
    // });
  };

  const unassignedUsers = users.filter(
    (user) =>
      !user.locations.includes(locationId) &&
      !user.roles.some((role) => role.name === "Owner")
  );

  const addUserModalStyles: Record<string, SxProps<Theme> | undefined> = {
    addUserModalContainer: {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      width: "75%",
      height: "75%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      maxHeight: "100%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      p: 4,
      borderRadius: "8px",
      boxShadow: "none",
    },
  };

  return (
    <Modal
      data-testid="addUserToLocationModal"
      open={isAddUserModalOpen}
      onClose={handleCloseModal}
    >
      <Box sx={addUserModalStyles.addUserModalContainer}>
        <AddUserHeaderComponent />
        <AddUserGridComponent unassignedUsers={unassignedUsers} />
        <AddUserFooterComponent
          handleCloseModal={handleCloseModal}
          locationId={locationId}
          unassignedUsers={unassignedUsers}
        />
      </Box>
    </Modal>
  );
};
