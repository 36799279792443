import { Avatar, Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";

interface TenantLevelPageHeaderProps {
  headerText: string;
  headerIcon: React.ReactElement;
  itemsCount?: number;
  children?: React.ReactNode;
}

const tenantLevelPageHeaderStyles: Record<string, SxProps<Theme> | undefined> =
  {
    header: {
      background: "white",
    },
    tenantHeaderToolbar: {
      paddingLeft: "10%",
      paddingRight: "10%",
      paddingTop: "16px",
      paddingBottom: "16px",
      maxHeight: "64px",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
    },
    tenantHeaderIcon: {
      backgroundColor: "primary.main",
    },
    itemsCount: {
      paddingLeft: "8px",
      color: "#c0c0c0",
    },
    shadowBox: {
      height: "4px",
      backgroundImage:
        "linear-gradient(to bottom, rgba(0,0,0,0.1), rgba(255,255,255,0))",
    },
    tenantHeaderTitle: {
      fontSize: "24px",
      letterSpacing: "-0.5px",
      lineHeight: "28px",
      paddingLeft: "16px",
    },
  };

const TenantLevelPageHeaderComponent: React.FC<TenantLevelPageHeaderProps> = ({
  headerText,
  headerIcon,
  itemsCount,
  children,
}) => {
  return (
    <Box sx={tenantLevelPageHeaderStyles.header}>
      <Box sx={tenantLevelPageHeaderStyles.tenantHeaderToolbar}>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <Avatar sx={tenantLevelPageHeaderStyles.tenantHeaderIcon}>
            {headerIcon}
          </Avatar>
          <Typography sx={tenantLevelPageHeaderStyles.tenantHeaderTitle}>
            {headerText}
            {itemsCount ?
              <Typography
                noWrap
                component="span"
                sx={tenantLevelPageHeaderStyles.itemsCount}
              >
                ({itemsCount})
              </Typography>
            : null}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>{children}</Box>
      </Box>
      <Box sx={tenantLevelPageHeaderStyles.shadowBox} />
    </Box>
  );
};

export default TenantLevelPageHeaderComponent;
