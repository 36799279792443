import React from "react";
import { Chip, Stack, SxProps, Theme, Typography } from "@mui/material";

export interface UserImportCounterProps {
  selectedUserCount: number;
}

export const UserImportCounterComponent: React.FC<UserImportCounterProps> = ({
  selectedUserCount,
}) => {
  const userImportCounterStyles: Record<string, SxProps<Theme> | undefined> = {
    selectedEmployeesText: {
      fontSize: "12px",
      letterSpacing: "-0.5px",
      color: "primary.main",
      ml: 1,
      mt: 1,
    },
  };

  return (
    <Stack direction="row">
      <Chip color="primary" label={selectedUserCount} />
      <Typography sx={userImportCounterStyles.selectedEmployeesText}>
        Selected Employees
      </Typography>
    </Stack>
  );
};
