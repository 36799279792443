import { Role } from "../interfaces/users/Role";

export const roleNames = {
  Salesperson: "Salesperson",
  Manager: "Manager",
  Owner: "Owner",
  User: "User",
  Rics: "Rics",
  Inventory: "Inventory",
  Buyer: "Buyer",
} as const;

export const roles: Role[] = [
  {
    name: roleNames.Salesperson,
    displayName: "Sales Associate",
    description:
      "Sales associates can access the POS and process the transactions.",
    isHidden: false,
    rank: 5,
    id: 5,
  },
  {
    name: roleNames.Manager,
    displayName: "Manager",
    description: "Managers can access the POS and perform manager actions.",
    isHidden: false,
    rank: 10,
    id: 6,
  },
  {
    name: roleNames.Owner,
    displayName: "Administrator",
    description:
      "Admins can access POS and the web portal to manage all settings.",
    isHidden: false,
    rank: 15,
    id: 2,
  },
  {
    name: roleNames.User,
    displayName: "User",
    description: "Hidden",
    isHidden: true,
    rank: 0,
    id: 4,
  },
  {
    name: roleNames.Rics,
    displayName: "Rics",
    description: "Hidden",
    isHidden: true,
    rank: 20,
    id: 1,
  },
  {
    name: roleNames.Inventory,
    displayName: "Inventory",
    description: "Inventory users can access the portal to manage labels",
    isHidden: false,
    rank: 1,
    id: 7,
  },
  {
    name: roleNames.Buyer,
    displayName: "Buyer",
    description: "Buyers can access the portal to manage products",
    isHidden: false,
    rank: 1,
    id: 8,
  },
];
