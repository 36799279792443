import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import StyledSwitchComponent from "../../../styled/StyledSwitch";
import { ReturnMethods } from "../../../../interfaces/salesettings/ReturnMethods";
import { SxProps, Theme } from "@mui/material";
import { InfoTooltipComponent } from "../../../common/InfoTooltip";
import { returnMethodTooltips } from "../../../../constants/settings/refunds/returnMethodTooltips";

interface ReturnMethodItemProps {
  returnMethod: ReturnMethods;
  index: number;
  isOptionDisabled: boolean;
  onUpdateReturnMethods: (index: number) => void;
  locationId?: string;
}

export const ReturnMethodItemComponent: React.FC<ReturnMethodItemProps> = ({
  returnMethod,
  index,
  isOptionDisabled,
  onUpdateReturnMethods,
  locationId,
}) => {
  const returnMethodTooltipConfig = returnMethodTooltips.find(
    (config) =>
      config.returnMethodId === returnMethod.returnMethodId &&
      config.returnMethodSubTypeId === returnMethod.returnMethodSubTypeId
  );

  const shouldShowTooltip = !!returnMethodTooltipConfig;
  const returnMethodTooltipText =
    returnMethodTooltipConfig ? returnMethodTooltipConfig.tooltipText : "";

  const returnMethodItemStyles: Record<string, SxProps<Theme>> = {
    clutchText: {
      fontSize: "12px",
      textAlign: "center",
      color: "red",
    },
    refundMethodToggleBox: {
      padding: 1,
      display: "flex",
      alignItems: "center",
    },
    refundMethodOptionText: {
      fontSize: "14px",
      ml: 1,
      mr: 1,
    },
  };
  return (
    <Grid
      item
      key={returnMethod.returnMethodId + returnMethod.returnMethodSubTypeId}
      xs={6}
    >
      <Box sx={returnMethodItemStyles.refundMethodToggleBox}>
        <StyledSwitchComponent
          checked={returnMethod.enabled}
          data-testid={`tenderTypeToggle-${returnMethod.returnMethodId}-${returnMethod.returnMethodSubTypeId}`}
          disabled={isOptionDisabled}
          onChange={() => onUpdateReturnMethods(index)}
        />
        <Typography sx={returnMethodItemStyles.refundMethodOptionText}>
          {returnMethod.returnMethodSubTypeLabel ??
            returnMethod.returnMethodLabel}
        </Typography>
        {shouldShowTooltip && (
          <InfoTooltipComponent
            hoverText={returnMethodTooltipText}
            placement="top"
          />
        )}
      </Box>
      {returnMethod.returnMethodId === "GiftCard" &&
        returnMethod.returnMethodSubTypeId === "Clutch" &&
        returnMethod.enabled &&
        !locationId && (
          <Grid item xs={6}>
            <Typography sx={returnMethodItemStyles.clutchText}>
              Configure at Location(s)
            </Typography>
          </Grid>
        )}
    </Grid>
  );
};
