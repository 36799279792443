import React, { ReactNode } from "react";
import { IXhrService } from "../interfaces/services/XhrService";
import {
  doRics9Xhr,
  doRicsApiXhr,
  doRicsXhr,
  fileUploadXhr,
} from "../services/XhrService";

const { createContext, useContext } = React;

const XhrServiceContext = createContext<IXhrService | null>(null);

interface XhrServiceProviderProps {
  children: ReactNode;
  xhrService?: IXhrService;
}

export const XhrServiceProvider = ({
  children,
  xhrService,
}: XhrServiceProviderProps) => {
  const value = xhrService ?? {
    doRicsXhr: doRicsXhr,
    doRicsApiXhr: doRicsApiXhr,
    doRics9Xhr: doRics9Xhr,
    fileUploadXhr: fileUploadXhr,
  };

  return (
    <XhrServiceContext.Provider value={value}>
      {children}
    </XhrServiceContext.Provider>
  );
};

export const useXhrService = () => {
  return useContext(XhrServiceContext);
};
