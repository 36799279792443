import React, { useEffect, useState } from "react";
import { Box, Button, SxProps, Theme } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useFlags } from "launchdarkly-react-client-sdk";
import TenantLevelPageHeaderComponent from "../components/common/TenantLevelPageHeader";
import AddUserButtonComponent from "../components/users/AddUserButton";
import ListAndCardsToggleComponent from "../components/users/ListAndCardsToggle";
import UsersGridComponent from "../components/users/UsersGrid";
import { useUsersContext } from "../providers/UsersProvider";
import { featureFlags } from "../constants/featureFlags";
import { useTheme } from "@mui/material/styles";

export const AllUsersPage: React.FC = () => {
  const [isCardMode, setIsCardMode] = useState(false);
  const [isDeleteUserEnabled, setIsDeleteUserEnabled] = useState(false);
  const [isSearchInputVisible, setIsSearchInputVisible] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [canSave, setCanSave] = useState(false);
  const { updateAllSelectedIdentities, getUsers, setUsers, users } =
    useUsersContext();

  const flags = useFlags();
  const theme = useTheme();
  const usersCount = users.length;

  const isAnyUserSelected = users.some((user) => user.isChecked);

  useEffect(() => {
    getUsers();
  }, [
    flags[featureFlags.LabelsPage],
    flags[featureFlags.GlobalProductCatalogPage],
  ]);

  useEffect(() => {
    getDeleteUserFeatureEnabledStatus();
  }, [flags[featureFlags.DeleteUser]]);

  const getDeleteUserFeatureEnabledStatus = () => {
    const featureFlag = flags[featureFlags.DeleteUser];
    setIsDeleteUserEnabled(featureFlag);
  };
  const filterListbySearchTerm = (searchTerm: string) => {
    searchTerm = searchTerm.toUpperCase();

    const filteredUsers = users.map((u) => {
      if (
        u.lastName?.toUpperCase().includes(searchTerm) ||
        u.firstName?.toUpperCase().includes(searchTerm) ||
        u.email?.toUpperCase().includes(searchTerm) ||
        u.userId?.toUpperCase().includes(searchTerm) ||
        u.ricsUserId?.toUpperCase().includes(searchTerm) ||
        u.ricsXIdentityId?.toUpperCase().includes(searchTerm)
      ) {
        u.isHidden = false;
      } else {
        u.isHidden = true;
      }

      return u;
    });

    setUsers(filteredUsers);
  };

  const onImportSuccess = () => {
    getUsers();
  };

  const allUsersStyles: Record<string, SxProps<Theme> | undefined> = {
    headerIcon: {
      color: "white",
    },
    allLocationsContentBox: {
      position: "relative",
      pt: 2,
      flexGrow: 1,
    },
    page: {
      overflowY: "auto",
      backgroundColor: theme.palette.common.contentPageBackground,
    },
  };

  return (
    <Box data-testid="allUsersPage" sx={allUsersStyles.page}>
      <TenantLevelPageHeaderComponent
        headerIcon={<AccountCircleOutlinedIcon />}
        headerText="All Users"
        itemsCount={usersCount}
      >
        <ListAndCardsToggleComponent
          searchPlaceholder="Search for users"
          searchText={searchText}
          setIsCardMode={setIsCardMode}
          setSearchText={setSearchText}
          setShowSearchInput={setIsSearchInputVisible}
          showSearchInput={isSearchInputVisible}
          onChangeSearchText={filterListbySearchTerm}
        />
        <AddUserButtonComponent onImportSuccess={onImportSuccess} />
        <Button
          data-testid="saveChangesButton"
          disabled={!isAnyUserSelected || !canSave}
          sx={allUsersStyles.headerButton}
          variant="contained"
          onClick={() => updateAllSelectedIdentities()}
        >
          Save Changes
        </Button>
      </TenantLevelPageHeaderComponent>
      <Box sx={allUsersStyles.allLocationsContentBox}>
        <UsersGridComponent
          deleteUserIsEnabled={isDeleteUserEnabled}
          isCardMode={isCardMode}
          locationId={undefined}
          setCanSave={setCanSave}
          users={users}
        />
      </Box>
    </Box>
  );
};
