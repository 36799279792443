import { Box, Grid, SxProps, Theme, Typography, useTheme } from "@mui/material";
import React from "react";

interface LeftRightContentProps {
  leftHeading?: React.ReactNode | string;
  rightHeading?: React.ReactNode | string;
  children?: React.ReactNode;
  hidden?: boolean;
  testId?: string;
}

const LeftRightContentComponent: React.FC<LeftRightContentProps> = ({
  leftHeading,
  rightHeading,
  children,
  hidden,
  testId,
}) => {
  const globalTheme = useTheme();

  const leftRightContentStyles: Record<string, SxProps<Theme> | undefined> = {
    root: {
      "&.MuiGrid-root": {
        margin: 0,
        width: "100%",
        display: hidden ? "none" : "flex",
      },
    },
    headingText: { fontSize: "14px", color: globalTheme.palette.common.gray },
    leftContent: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "8px",
      paddingY: rightHeading ? "20px" : 0,
    },
  };

  return (
    <Grid
      container
      data-testid={testId}
      spacing={2}
      sx={leftRightContentStyles.root}
    >
      <Grid item xs={2}>
        <Typography sx={leftRightContentStyles.headingText}>
          {leftHeading}
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography sx={leftRightContentStyles.headingText}>
          {rightHeading}
        </Typography>
        <Box sx={leftRightContentStyles.leftContent}>{children}</Box>
      </Grid>
    </Grid>
  );
};

export default LeftRightContentComponent;
