import { SaleSettings } from "../interfaces/salesettings/SaleSettings";
import { doRicsApiXhr } from "./XhrService";

const save = (saleSettings: SaleSettings): Promise<SaleSettings> => {
  return new Promise((resolve, reject) => {
    const endpoint = "v1.2/SaleSettings";

    const onSuccess = (response: string) => {
      const saleSettings: SaleSettings = JSON.parse(response);
      resolve(saleSettings);
    };

    const onFailure = () => {
      const reason = "Failed to save sale settings.";
      console.error(reason);
      reject(reason);
    };

    doRicsApiXhr(endpoint, saleSettings, onSuccess, onFailure, "PUT");
  });
};

const get = (locationId: string): Promise<SaleSettings> => {
  return new Promise((resolve, reject) => {
    const endpoint = `v1.2/SaleSettings/${locationId}`;

    const onSuccess = (response: string) => {
      const saleSettings: SaleSettings = JSON.parse(response);
      resolve(saleSettings);
    };

    const onFailure = () => {
      const reason = `Failed to retrieve sale settings for location '${locationId}'`;
      console.error(reason);
      reject(reason);
    };

    doRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
  });
};

const getAll = (): Promise<Array<SaleSettings>> => {
  return new Promise((resolve, reject) => {
    const endpoint = "v1.2/SaleSettings";

    const onSuccess = (response: string) => {
      const saleSettings: Array<SaleSettings> = JSON.parse(response);
      resolve(saleSettings);
    };

    const onFailure = () => {
      const reason = "Failed to retrieve sale settings.";
      console.error(reason);
      reject(reason);
    };

    doRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
  });
};

export const SaleSettingsService = {
  save: save,
  get: get,
  getAll: getAll,
};
