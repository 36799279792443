import React, { useEffect, useState } from "react";
import CreateIcon from "@mui/icons-material/Create";
import LocationDetailsTaxConfigurationComponent from "./jurisdictions/LocationDetailsTaxConfiguration";
import LocationDetailsSpecialTaxEventsComponent from "./specialTaxEvents/LocationDetailsSpecialTaxEvents";
import { Grid, SxProps, Theme, useTheme } from "@mui/material";
import { Tax } from "../../../interfaces/settings/Taxes";
import { useTaxesContext } from "../../../providers/TaxesProvider";
import { usePortal } from "../../../providers/PortalProvider";
import { useXhrService } from "../../../providers/XhrServiceProvider";
import LocationDetailsPageComponent from "../LocationDetailsPage";
export interface LocationDetailsTaxesProps {
  locationId: string;
}

const LocationDetailsTaxesComponent: React.FC<LocationDetailsTaxesProps> = ({
  locationId,
}) => {
  const portal = usePortal();
  const xhrService = useXhrService();
  const { getSpecialTaxEvents, setTaxes } = useTaxesContext();
  const [isTaxesLoaded, setIsTaxesLoaded] = useState(false);

  useEffect(() => {
    getTaxes();
    getSpecialTaxEvents(locationId);
  }, []);

  const getTaxes = () => {
    portal!.navigation.isLoading(true);
    let endpoint = `v1/Taxes/Locations/${locationId}`;

    let onSuccess = function (response: string) {
      var taxes = JSON.parse(response) as Tax[];
      setTaxes(taxes);
      portal!.navigation.isLoading(false);
      setIsTaxesLoaded(true);
    };

    xhrService!.doRicsApiXhr(endpoint, null, onSuccess, undefined, "GET");
  };

  const globalTheme = useTheme();

  const locationDetailsTaxesStyles: Record<string, SxProps<Theme>> = {
    page: {
      height: "calc(100vh - 64px)",
      overflowY: "auto",
      backgroundColor: globalTheme.palette.common.contentPageBackground,
    },
    cardItem: {
      width: "50%",
      paddingTop: 4,
    },
  };

  return (
    <LocationDetailsPageComponent
      dataTestId="locationDetailsTaxes"
      headerIcon={<CreateIcon />}
      headerText="Taxes"
    >
      <Grid container alignItems="center" direction="column">
        <Grid item sx={locationDetailsTaxesStyles.cardItem} xs={12}>
          {isTaxesLoaded && (
            <LocationDetailsTaxConfigurationComponent
              getTaxes={getTaxes}
              locationId={locationId}
            />
          )}
        </Grid>

        <Grid item sx={locationDetailsTaxesStyles.cardItem} xs={12}>
          <LocationDetailsSpecialTaxEventsComponent locationId={locationId} />
        </Grid>
      </Grid>
    </LocationDetailsPageComponent>
  );
};
export default LocationDetailsTaxesComponent;
