import { Box, SxProps, Theme, Typography, useTheme } from "@mui/material";
import React from "react";
import { InfoTooltipComponent } from "../common/InfoTooltip";
import StyledSwitchComponent from "../styled/StyledSwitch";
export interface LocationsCanOverrideToggleProps {
  locationsCanOverride?: boolean;
  onChange?:
    | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
  disabled?: boolean;
  hidden?: boolean;
}

export const handleLocationsOverrideChange = (
  setCanLocationsOverride: React.Dispatch<React.SetStateAction<boolean>>
) => {
  return () => {
    setCanLocationsOverride((prev) => !prev);
  };
};

const LocationsCanOverrideToggleComponent: React.FC<
  LocationsCanOverrideToggleProps
> = ({ locationsCanOverride, onChange, disabled, hidden }) => {
  const globalTheme = useTheme();

  const tooltipText =
    "When toggled on, individual locations can override these global settings with their own. When toggled off, all locations will revert back to the global settings.";

  const locationsCanOverrideToggleStyles: Record<
    string,
    SxProps<Theme> | undefined
  > = {
    root: {
      display: hidden ? "none" : "flex",
      alignItems: "center",
      gap: "8px",
    },
    text: {
      fontSize: "14px",
      color: globalTheme.palette.common.black,
    },
    tooltipIcon: {
      fontSize: "20px",
      fill: globalTheme.palette.common.inactive,
      ":hover": {
        cursor: "pointer",
        fill: globalTheme.palette.common.black,
      },
    },
  };

  return (
    <Box sx={locationsCanOverrideToggleStyles.root}>
      <StyledSwitchComponent
        checked={locationsCanOverride}
        data-testid="locationsCanOverrideToggleSwitch"
        disableRipple={true}
        disabled={disabled}
        onChange={onChange}
      />
      <Typography sx={locationsCanOverrideToggleStyles.text}>
        Locations can override
      </Typography>
      <InfoTooltipComponent hoverText={tooltipText} placement="top" />
    </Box>
  );
};

export default LocationsCanOverrideToggleComponent;
