import React, { useState } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import TextButtonComponent from "../../../styled/TextButton";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import TuneIcon from "@mui/icons-material/Tune";
import { SpecialTaxEvent } from "../../../../interfaces/settings/Taxes";
import dayjs from "dayjs";
import { useTaxesContext } from "../../../../providers/TaxesProvider";
import SpecialTaxActionsPopoverComponent from "./SpecialTaxActionsPopover";
import { AdvancedTaxConfigurationDialogComponent } from "../advancedTaxConfiguration/AdvancedTaxConfigurationDialog";
export interface SpecialTaxEventCardProps {
  locationId: string;
  specialTaxEvent: SpecialTaxEvent;
}

const SpecialTaxEventCardComponent: React.FC<SpecialTaxEventCardProps> = ({
  locationId,
  specialTaxEvent,
}) => {
  const globalTheme = useTheme();
  const { taxes } = useTaxesContext();
  const [
    isAdvancedTaxConfigurationDialogOpen,
    setisAdvancedTaxConfigurationDialogOpen,
  ] = useState(false);

  const formattedStartDate = dayjs(specialTaxEvent.startDate).format(
    "MM/DD/YYYY"
  );
  const formattedEndDate = dayjs(specialTaxEvent.endDate).format("MM/DD/YYYY");
  const dateRange = `${formattedStartDate} - ${formattedEndDate}`;
  const affectedJurisdictions = specialTaxEvent.applicableTaxIds!.map((x) => {
    return taxes.find((y) => y.taxId === x)?.description;
  });
  const affectedProducts =
    specialTaxEvent.applyToEverything ? "Everything Sold"
    : specialTaxEvent.productTaxes && specialTaxEvent.productTaxes.length > 0 ?
      specialTaxEvent.productTaxes.map((tax) => tax.productType).join(", ")
    : "No Product Categories Configured";

  const specialTaxCardStyles: Record<string, SxProps<Theme>> = {
    affectedTextLeft: {
      fontSize: 14,
      fontWeight: "bold",
    },
    affectedTextRight: {
      fontSize: 13,
    },
    cardHeaderBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      mb: 2,
    },
    dateTypography: {
      color: globalTheme.palette.common.gray,
    },
    dateButtonBox: {
      display: "flex",
      alignItems: "center",
    },
  };

  return (
    <Card
      data-testid={`specialTaxEventCard-${specialTaxEvent.eventId}`}
      elevation={4}
    >
      <CardContent>
        <Box sx={specialTaxCardStyles.cardHeaderBox}>
          <Typography>{specialTaxEvent.eventName}</Typography>
          <Box sx={specialTaxCardStyles.dateButtonBox}>
            <Typography sx={specialTaxCardStyles.dateTypography}>
              {dateRange}
            </Typography>
            <SpecialTaxActionsPopoverComponent
              locationId={locationId}
              specialTaxEvent={specialTaxEvent}
            />
          </Box>
        </Box>
        <Typography component="div">
          <Box component="span" sx={specialTaxCardStyles.affectedTextLeft}>
            Tax jurisdictions affected:{" "}
          </Box>
          <Box component="span" sx={specialTaxCardStyles.affectedTextRight}>
            {affectedJurisdictions.length > 0 ?
              affectedJurisdictions.join(", ")
            : "None"}
          </Box>
        </Typography>

        <Typography component="div">
          <Box component="span" sx={specialTaxCardStyles.affectedTextLeft}>
            Product categories affected:{" "}
          </Box>
          <Box component="span" sx={specialTaxCardStyles.affectedTextRight}>
            {affectedProducts}
          </Box>
        </Typography>

        <Box mt={2}>
          <TextButtonComponent
            disableRipple
            focusColor={globalTheme.palette.primary.main}
            hoverColor={globalTheme.palette.primary.main}
            startIcon={<TuneIcon />}
            textColor={globalTheme.palette.primary.main}
            variant="text"
            onClick={() => setisAdvancedTaxConfigurationDialogOpen(true)}
          >
            Click for Advanced Tax Configuration...{" "}
          </TextButtonComponent>
        </Box>
      </CardContent>
      <AdvancedTaxConfigurationDialogComponent
        isAdvancedTaxConfigurationDialogOpen={
          isAdvancedTaxConfigurationDialogOpen
        }
        isEvent={true}
        setisAdvancedTaxConfigurationDialogOpen={
          setisAdvancedTaxConfigurationDialogOpen
        }
        specialTaxEventId={specialTaxEvent.eventId}
      />
    </Card>
  );
};
export default SpecialTaxEventCardComponent;
