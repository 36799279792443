import * as React from "react";
import { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Avatar,
  Menu,
  MenuItem,
  Toolbar,
  Box,
  SxProps,
  Theme,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Outlet } from "react-router";

import { useFlags } from "launchdarkly-react-client-sdk";
import { featureFlags } from "../../constants/featureFlags";
import { usePortal } from "../../providers/PortalProvider";
import { useDataService } from "../../providers/DataServiceProvider";
import { claims, hasPermissionTo } from "../../constants/claims";
import { sessionStorage } from "../../constants/sessionStorage";
import { paths } from "../../constants/paths";

const RicsToolbarComponent = () => {
  const portal = usePortal();
  const dataService = useDataService();
  const flags = useFlags();
  const navigate = useNavigate();

  const [accountAnchorElement, setAccountAnchorElement] =
    useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAccountAnchorElement(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAccountAnchorElement(null);
  };

  const [isSetupButtonVisible, setIsSetupButtonVisible] = useState(false);
  const setWorkflowButtonVisibility = (portal!.state.setWorkflowButton = () => {
    let isSetupComplete = true;
    for (let i = 0; i < portal!.state.locations?.length; i++) {
      const location = portal!.state.locations[i];
      if (!location.isActive) {
        isSetupComplete = false;
        break;
      }
    }

    setIsSetupButtonVisible(!isSetupComplete);
  });

  const path = window.location.pathname;
  useEffect(() => {
    setWorkflowButtonVisibility();
  }, [path]);

  const handleLogout = () => {
    handleCloseUserMenu();
    dataService.logout();
    navigate(paths.Login);
    navigate(0);
  };
  const vanityUrl =
    window.sessionStorage.getItem(sessionStorage.VanityUrl) ?? undefined;

  const navigateToBackOffice = () => {
    if (vanityUrl === undefined) {
      return;
    }
    window.localStorage.clear();
    const url = vanityUrl.replace("https://", "").replace("http://", "");
    window.location.replace(`https://${url}`);
  };

  const toolbarStyles: Record<string, SxProps<Theme> | undefined> = {
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: "black",
    },
    leftBox: {
      display: { xs: "flex", md: "flex" },
      alignItems: "stretch",
      height: "65px",
    },
    environmentText: {
      color: "#FA5A5A",
      fontWeight: "bolder",
      fontSize: "18px",
      backgroundColor: "white",
      mr: 1,
      ml: 1,
    },
    logoWrapper: {
      display: "flex",
      alignItems: "center",
    },
    rightBox: {
      display: { xs: "flex", md: "flex" },
      justifyContent: "flex-end",
      gap: "16px",
    },
    menuButton: {
      color: "white",
      height: "1000",
      "&.active": {
        backgroundColor: "rgba(255, 255, 255, 0.25)",
      },
      "&:focus": {
        backgroundColor: "rgba(255, 255, 255, 0.25)",
      },
    },
    setupWizardButton: {
      color: "white",
      backgroundColor: "#616AE8",
      "&:hover": {
        backgroundColor: "#616AE8",
      },
    },
    setupWizardBox: {
      display: "flex",
      justifyContent: "center",
      margin: "auto",
    },
    toolbarIcon: {
      color: "white",
      mr: 1,
    },
    userBox: {
      display: { xs: "none", md: "flex" },
      alignItems: "center",
    },
    userAvatar: {
      ml: 1,
      mr: 1,
    },
    userTypography: {
      ml: 1,
      mr: 1,
    },
    userAccountButton: {
      backgroundColor: "#FFF",
      "&:hover": {
        backgroundColor: "#FFF",
      },
    },
  };

  return (
    <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
      <Toolbar disableGutters={true} sx={toolbarStyles.toolbar}>
        <Box sx={toolbarStyles.leftBox}>
          <Box sx={toolbarStyles.logoWrapper}>
            <Link to="/">
              {portal!.common.environment.toUpperCase() !== "PROD" ?
                <Typography sx={toolbarStyles.environmentText}>
                  {portal!.common.environment.toUpperCase()}
                </Typography>
              : <Box
                  alt="logo"
                  component="img"
                  src="assets/img/rics-logo.svg"
                  sx={{ width: "100%", height: "auto" }}
                />
              }
            </Link>
          </Box>
          {hasPermissionTo(claims.Owner, portal!.state.userPermissions) ?
            <>
              <Button
                component={NavLink}
                sx={toolbarStyles.menuButton}
                to={paths.Locations}
              >
                All Locations
              </Button>

              <Button
                component={NavLink}
                hidden={
                  !hasPermissionTo(claims.Owner, portal!.state.userPermissions)
                }
                sx={toolbarStyles.menuButton}
                to={paths.Users}
              >
                All Users
              </Button>
              <Button
                component={NavLink}
                hidden={
                  !hasPermissionTo(claims.Owner, portal!.state.userPermissions)
                }
                sx={toolbarStyles.menuButton}
                to={paths.Devices}
              >
                All Devices
              </Button>
              <Button
                component={NavLink}
                hidden={
                  !hasPermissionTo(claims.Owner, portal!.state.userPermissions)
                }
                sx={toolbarStyles.menuButton}
                to={paths.Settings}
              >
                Settings
              </Button>
              {flags[featureFlags.IntegrationsPage] ?
                <Button
                  component={NavLink}
                  hidden={
                    !hasPermissionTo(
                      claims.Owner,
                      portal!.state.userPermissions
                    )
                  }
                  sx={toolbarStyles.menuButton}
                  to={paths.Integrations}
                >
                  Integrations
                </Button>
              : null}
            </>
          : null}
          {(
            flags[featureFlags.LabelsPage] &&
            hasPermissionTo(claims.Labels, portal!.state.userPermissions)
          ) ?
            <Button
              component={NavLink}
              sx={toolbarStyles.menuButton}
              to={paths.ManageLabels}
            >
              Manage Labels
            </Button>
          : null}
          {(
            isSetupButtonVisible &&
            hasPermissionTo(claims.Owner, portal!.state.userPermissions)
          ) ?
            <Box sx={toolbarStyles.setupWizardBox}>
              <Button
                component={NavLink}
                sx={toolbarStyles.setupWizardButton}
                to={paths.Welcome}
              >
                <AutoFixHighIcon sx={toolbarStyles.toolbarIcon} />
                Setup Wizard
              </Button>
            </Box>
          : null}
        </Box>

        <Box sx={toolbarStyles.rightBox}>
          {vanityUrl ?
            <Button
              sx={toolbarStyles.menuButton}
              onClick={navigateToBackOffice}
            >
              <ExitToAppIcon sx={toolbarStyles.toolbarIcon} />
              Return to Backoffice
            </Button>
          : null}
          <Box sx={toolbarStyles.userBox}>
            <Button
              startIcon={
                <Avatar alt="Profile Picture" src="img/adam-white-bg.png" />
              }
              sx={toolbarStyles.menuButton}
              onClick={handleOpenUserMenu}
            >
              My Account
              <ExpandMoreIcon sx={toolbarStyles.toolbarIcon} />
            </Button>

            <Menu
              keepMounted
              anchorEl={accountAnchorElement}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(accountAnchorElement)}
              sx={{ mt: "45px" }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>
                <LogoutIcon sx={toolbarStyles.toolbarIcon} />
                <Button sx={toolbarStyles.menuButton} onClick={handleLogout}>
                  Logout
                </Button>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
      <Outlet />
    </Box>
  );
};
export default RicsToolbarComponent;
