import React, { useEffect } from "react";
import {
  Box,
  Container,
  TextField,
  Button,
  Grid,
  SxProps,
  Theme,
} from "@mui/material";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useDataService } from "../providers/DataServiceProvider";
import { usePortal } from "../providers/PortalProvider";
import { useXhrService } from "../providers/XhrServiceProvider";
import { apiVersions } from "../constants/apiVersions";
import { featureFlags } from "../constants/featureFlags";
import { useFlags } from "launchdarkly-react-client-sdk";
import { paths } from "../constants/paths";
import { Identity } from "../interfaces/users/User";
import { AuthResponse } from "../interfaces/responses/AuthResponse";
import { useTheme } from "@mui/material/styles";

interface LoginPageProps {
  initialize: (onSuccess?: () => void, onFailure?: () => void) => void;
}

const LoginPage: React.FC<LoginPageProps> = ({ initialize }) => {
  const theme = useTheme();
  const portal = usePortal();
  const dataService = useDataService();
  const xhrService = useXhrService();
  const navigate = useNavigate();
  const flags = useFlags();

  const [userNameInputValue, setUserNameInputValue] = React.useState("");
  const [passwordInputValue, setPasswordInputValuet] = React.useState("");

  const getAuthToken = (emailAddress: string, password: string) => {
    portal!.navigation.isLoading(true);

    const endpoint = "v1/Auth/CreateToken";
    const payload = {
      emailAddress: emailAddress,
      password: password,
    };

    const onFailure = () => {
      alert("Incorrect username or password.");
      portal!.navigation.isLoading(false);
    };

    xhrService!.doRicsApiXhr(
      endpoint,
      payload,
      parseAuthResponse,
      onFailure,
      "PUT"
    );
  };

  const parseAuthResponse = (response: string) => {
    const parsedResponse: AuthResponse = JSON.parse(response);
    if (!parsedResponse.token) {
      alert("Invalid username/password combination. Please try again.");
      portal!.navigation.isLoading(false);
      return;
    }

    window.localStorage.setItem("Token", parsedResponse.token);
    window.localStorage.setItem("RefreshToken", parsedResponse.refreshToken);

    getIdentityAndNavigate();
  };

  const getIdentityAndNavigate = () => {
    portal!.navigation.isLoading(true);
    const version =
      flags[featureFlags.LabelsPage] ?
        apiVersions.TwoDotOne
      : apiVersions.Default;

    const onSuccess = (identity: Identity) => {
      portal!.state.currentUser = identity;
      document.onkeydown = null;
      initialize();
    };

    const onFailure = () => {
      window.localStorage.clear();
      window.sessionStorage.clear();

      alert("Something went wrong logging you in. Please try again.");
      navigate(paths.Login);
      portal!.navigation.isLoading(false);
    };

    dataService!.identities.getCurrentIdentity(version, onSuccess, onFailure);
  };

  const loginButtonClicked = () => {
    window.localStorage.setItem("LoginEmailAddress", userNameInputValue.trim());
    getAuthToken(userNameInputValue.trim(), passwordInputValue);
  };

  const beforeLoad = () => {
    if (dataService!.getToken()) {
      getIdentityAndNavigate();

      return;
    }
  };

  const setUsernameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserNameInputValue(e.target.value);
  };
  const setPasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordInputValuet(e.target.value);
  };

  useEffect(() => {
    beforeLoad();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        loginButtonClicked();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [userNameInputValue, passwordInputValue]);
  const loginStyles: Record<string, SxProps<Theme> | undefined> = {
    outerBox: {
      height: "100vh",
      width: "100%",
      backgroundColor: theme.palette.common.ricsPurple,
    },
    formBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    logoImage: {
      width: "100%",
      height: "auto",
    },
    signInButton: {
      margin: "auto",
      marginLeft: 0,
      padding: 1,
      marginTop: "10px",
      display: "block",
      fontSize: 14,
      borderRadius: "15px",
      "&.Mui-disabled": {
        background: "#ffffff40",
        color: "#ffffff80",
      },
    },
    forgotPasswordLink: {
      textDecoration: "underline",
      fontSize: 14,
      color: theme.palette.common.linkColor,
    },
  };

  return (
    <Box sx={loginStyles.outerBox}>
      <Box sx={loginStyles.formBox}>
        <Container maxWidth="xs">
          <Grid container alignItems="center" direction="column" spacing={2}>
            <Grid item sx={{ marginBottom: 5 }}>
              <Box
                alt="Portal Logo"
                component="img"
                src="/img/rics-logo.svg"
                sx={loginStyles.logoImage}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: false }}
                id="usernameInput"
                label={userNameInputValue === "" ? "Email..." : ""}
                name="username"
                sx={{ mb: 1 }}
                value={userNameInputValue}
                onChange={setUsernameInputChange}
              />
              <TextField
                fullWidth
                InputLabelProps={{ shrink: false }}
                id="passwordInput"
                label={passwordInputValue === "" ? "Password..." : ""}
                sx={{ mt: 1 }}
                type="password"
                value={passwordInputValue}
                onChange={setPasswordInputChange}
              />
              <Button
                disabled={
                  !(
                    userNameInputValue.length > 0 &&
                    passwordInputValue.length > 0
                  )
                }
                fullWidth={true}
                id="signInButton"
                sx={loginStyles.signInButton}
                variant="contained"
                onClick={loginButtonClicked}
              >
                Sign In
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                component={Link}
                sx={loginStyles.forgotPasswordLink}
                to={paths.Password}
              >
                Forgot Password?
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default LoginPage;
