import React, { useState } from "react";
import {
  Box,
  Button,
  List,
  Modal,
  Popover,
  SxProps,
  Typography,
  ListItem,
  IconButton,
} from "@mui/material";
import { User } from "../../interfaces/users/User";
import { useUsersContext } from "../../providers/UsersProvider";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { usePortal } from "../../providers/PortalProvider";
import { useXhrService } from "../../providers/XhrServiceProvider";

export interface UserActionsPopoverProps {
  user: User;
  id: string;
  setClickedUser: React.Dispatch<React.SetStateAction<User | undefined>>;
  deleteUserIsEnabled: boolean;
}

const UserActionsPopoverComponent: React.FC<UserActionsPopoverProps> = ({
  user,
  id,
  deleteUserIsEnabled,
}) => {
  const [isDeleteUserModalShown, setIsDeleteUserModalShown] = useState(false);
  const [userActionsPopoverAnchor, setUserActionsPopoverAnchor] =
    React.useState<HTMLButtonElement | null>(null);
  const portal = usePortal();
  const xhrService = useXhrService();
  const { updateSingleIdentity } = useUsersContext();

  const closeDeleteUserModal = () => setIsDeleteUserModalShown(false);
  const openUserActionsPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setUserActionsPopoverAnchor(() => {
      return event.currentTarget;
    });
  };
  const closeUserActionsPopover = () => {
    setUserActionsPopoverAnchor(() => {
      return null;
    });
  };
  const isPopoverOpen = Boolean(userActionsPopoverAnchor);

  const updateSelectedUser = () => {
    setUserActionsPopoverAnchor(null);
    if (user) {
      updateSingleIdentity(user);
    }
  };

  const deleteSelectedUser = () => {
    if (user) {
      user.deletedOn = new Date();
      updateSingleIdentity(user);
    }
    setIsDeleteUserModalShown(false);
  };

  const sendWelcomeEmail = (identityId: string) => {
    setUserActionsPopoverAnchor(null);
    const endpoint = "v1/Auth/SendEmailVerification";

    let baseUrl = process.env.REACT_APP_PORTAL_BASE_URL;
    baseUrl = baseUrl?.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl;
    const redirectUri = `${baseUrl}/password/`;

    const payload = {
      identityId: identityId,
      tenantId: portal!.state.tenantInfo.tenantId,
      redirectUri: redirectUri,
    };

    const onSuccess = () => {
      alert("Please check the provided email address to finish account setup.");
    };

    const onFailure = () => {
      alert(
        "Something went wrong while trying to send the activation email. Please try again."
      );
    };

    xhrService!.doRicsApiXhr(endpoint, payload, onSuccess, onFailure, "PUT");
  };

  const userActionsPopoverStyles: Record<string, SxProps | undefined> = {
    userActionsPopoverPaper: {
      bgcolor: "white",
    },
    userActionsPopoverButton: {
      color: "black",
      "&:focus": {
        backgroundColor: "white",
        color: "black",
      },
    },
    deleteUserModal: {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      borderRadius: "8px",
      boxShadow: 24,
      p: 4,
      textAlign: "center",
    },
    deleteUserButtonBox: {
      paddingTop: "15px",
    },
    userActionIcon: {
      fontSize: "16px",
    },
    userActionButton: {
      backgroundColor: "#EDEDED",
      color: "#000000",
      "&:hover": {
        backgroundColor: "#EDEDED",
      },
      "&:focus": {
        backgroundColor: "#EDEDED",
        color: "#000000",
      },
    },
  };

  return (
    <IconButton
      disableRipple
      data-testid={`userActionsButton-${id}`}
      size="small"
      sx={userActionsPopoverStyles.userActionButton}
      onClick={openUserActionsPopover}
    >
      <MoreVertIcon sx={userActionsPopoverStyles.userActionIcon} />
      <Popover
        PaperProps={{ sx: userActionsPopoverStyles.userActionsPopoverPaper }}
        anchorEl={userActionsPopoverAnchor}
        data-testid="userActionsPopover"
        open={isPopoverOpen}
        onClose={closeUserActionsPopover}
      >
        <List disablePadding>
          <ListItem>
            <Button
              data-testid="updateUserButton"
              disabled={!user.isValid}
              startIcon={<SaveOutlinedIcon />}
              sx={userActionsPopoverStyles.userActionsPopoverButton}
              onClick={() => updateSelectedUser()}
            >
              Update User
            </Button>
          </ListItem>
          {!user.isVerified && (
            <ListItem>
              <Button
                data-testid="resendVerificationEmailButton"
                disabled={!user.isValid}
                startIcon={<EmailOutlinedIcon />}
                sx={userActionsPopoverStyles.userActionsPopoverButton}
                onClick={() => sendWelcomeEmail(user.userId)}
              >
                Resend Verification Email
              </Button>
            </ListItem>
          )}
          {deleteUserIsEnabled && (
            <ListItem>
              <Button
                data-testid="deleteUserButton"
                disabled={!user.isValid}
                startIcon={<DeleteForeverOutlinedIcon />}
                sx={userActionsPopoverStyles.userActionsPopoverButton}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsDeleteUserModalShown(true);
                }}
              >
                Delete User
              </Button>
            </ListItem>
          )}
        </List>

        <Modal
          data-testid="deleteUserModal"
          open={isDeleteUserModalShown}
          onClose={closeDeleteUserModal}
        >
          <Box sx={userActionsPopoverStyles.deleteUserModal}>
            <Typography component="h2" id="modal-modal-title" variant="h6">
              Confirm
            </Typography>
            <Typography sx={{ mt: 2 }}>Delete user?</Typography>
            <Box sx={userActionsPopoverStyles.deleteUserButtonBox}>
              <Button
                data-testid="declineDeleteUserButton"
                onClick={() => setIsDeleteUserModalShown(false)}
              >
                No
              </Button>
              <Button
                data-testid="confirmDeleteUserButton"
                onClick={deleteSelectedUser}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
      </Popover>
    </IconButton>
  );
};

export default UserActionsPopoverComponent;
