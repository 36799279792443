import {
  Grid,
  TextField,
  Box,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { RicsSideMenuTab } from "../../interfaces/common/RicsSideMenuTab";
import InfoIcon from "@mui/icons-material/Info";
import LeftRightContentComponent from "../common/LeftRightContent";
import { Location } from "../../interfaces/locations/Location";
import Divider from "@mui/material/Divider";
import LocationDetailsPageComponent from "./LocationDetailsPage";

export interface LocationDetailsInfoProps {
  selectedTab: RicsSideMenuTab;
  currentLocation: Location;
}

const LocationDetailsInfoComponent: React.FC<LocationDetailsInfoProps> = ({
  currentLocation,
}) => {
  const address = currentLocation?.contact?.address;
  const globalTheme = useTheme();

  const locationDetailsInfoStyles: Record<string, SxProps<Theme> | undefined> =
    {
      container: {
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        width: "75%",
        backgroundColor: globalTheme.palette.common.white,
        margin: "16px auto",
        padding: "16px",
      },
      headingHolder: {
        display: "flex",
        alignItems: "center",
        margin: "16px",
      },
      headingText: {
        fontSize: "20px",
        color: globalTheme.palette.common.black,
        marginRight: "8px",
      },
      headingNote: {
        display: "inline-block",
        fontSize: "12px",
        background: "#989898",
        color: "white",
        padding: "2px 5px",
        borderRadius: "5px",
      },
      gridContainer: {
        paddingRight: "16px",
      },
      grayTextField: {
        borderRadius: "8px",
        width: "100%",
        marginBottom: "10px",
        backgroundColor: "#f0f0f0",
      },
      textFieldHeaderText: {
        fontSize: "14px",
        color: globalTheme.palette.common.gray,
      },
    };

  return (
    <LocationDetailsPageComponent
      dataTestId="locationDetailsInfo"
      headerIcon={<InfoIcon />}
      headerText="Location Details"
    >
      {" "}
      <Box sx={locationDetailsInfoStyles.container}>
        <Box sx={locationDetailsInfoStyles.headingHolder}>
          <Typography sx={locationDetailsInfoStyles.headingText}>
            Store Location Information
          </Typography>
          <Typography sx={locationDetailsInfoStyles.headingNote}>
            Manage in RICS9 Back Office
          </Typography>
        </Box>
        <Divider variant="middle" />
        <LeftRightContentComponent leftHeading="Address">
          <Grid
            container
            spacing={1}
            sx={locationDetailsInfoStyles.gridContainer}
          >
            <Grid item xs={12}>
              <Box>
                <Typography
                  gutterBottom
                  sx={locationDetailsInfoStyles.textFieldHeaderText}
                >
                  Location Name
                </Typography>
                <TextField
                  InputProps={{ readOnly: true }}
                  sx={locationDetailsInfoStyles.grayTextField}
                  value={currentLocation?.name}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box>
                <Typography
                  gutterBottom
                  sx={locationDetailsInfoStyles.textFieldHeaderText}
                >
                  Address Line 1
                </Typography>
                <TextField
                  InputProps={{ readOnly: true }}
                  sx={locationDetailsInfoStyles.grayTextField}
                  value={address?.line1}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <Typography
                  gutterBottom
                  sx={locationDetailsInfoStyles.textFieldHeaderText}
                >
                  Building or Suite Number (optional)
                </Typography>
                <TextField
                  InputProps={{ readOnly: true }}
                  sx={locationDetailsInfoStyles.grayTextField}
                  value={address?.line2}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box>
                <Typography
                  gutterBottom
                  sx={locationDetailsInfoStyles.textFieldHeaderText}
                >
                  City
                </Typography>
                <TextField
                  InputProps={{ readOnly: true }}
                  sx={locationDetailsInfoStyles.grayTextField}
                  value={address?.city}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography
                  gutterBottom
                  sx={locationDetailsInfoStyles.textFieldHeaderText}
                >
                  State
                </Typography>
                <TextField
                  InputProps={{ readOnly: true }}
                  sx={locationDetailsInfoStyles.grayTextField}
                  value={address?.state}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Typography
                  gutterBottom
                  sx={locationDetailsInfoStyles.textFieldHeaderText}
                >
                  Zip
                </Typography>
                <TextField
                  InputProps={{ readOnly: true }}
                  sx={locationDetailsInfoStyles.grayTextField}
                  value={address?.zipCode}
                />
              </Box>
            </Grid>
          </Grid>
        </LeftRightContentComponent>
      </Box>
    </LocationDetailsPageComponent>
  );
};

export default LocationDetailsInfoComponent;
