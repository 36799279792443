import React, { useEffect, useState } from "react";

import { Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import { isEqual } from "lodash";
import { ReceiptSettings } from "../../../interfaces/salesettings/ReceiptSettings";
import { SaleSettings } from "../../../interfaces/salesettings/SaleSettings";
import { useDataService } from "../../../providers/DataServiceProvider";
import { usePortal } from "../../../providers/PortalProvider";
import LeftRightContentComponent from "../../common/LeftRightContent";
import StyledSwitchComponent from "../../styled/StyledSwitch";
import { handleLocationsOverrideChange } from "../LocationsCanOverrideToggle";
import SettingsCardComponent from "./SettingsCard";

export interface ReceiptSettingsProps {
  id: string;
  locationSettings: SaleSettings;
  onSaveSettingsCard: (
    settingsToSave: SaleSettings,
    modifySettings?: (settings: SaleSettings, locationId?: string) => void
  ) => Promise<void>;
  locationId?: string;
}

export const ReceiptSettingsComponent: React.FC<ReceiptSettingsProps> = ({
  id,
  locationSettings,
  onSaveSettingsCard,
  locationId,
}) => {
  const portal = usePortal();
  const dataService = useDataService();

  const [canSave, setCanSave] = useState(false);
  const [receiptOptions, setReceiptOptions] = React.useState(
    locationSettings.receiptOptionSettings?.receiptOptions
  );

  const [canLocationsOverride, setCanLocationsOverride] = useState(
    portal?.state.saleSettings["Tenant"]?.receiptOptionSettings
      ?.locationsCanOverride ?? true
  );

  const [isDisabled, setIsDisabled] = React.useState(false);

  const receiptOptionsInitial =
    locationSettings.receiptOptionSettings?.receiptOptions;
  const canLocationsOverrideInitial =
    portal?.state.saleSettings["Tenant"]?.receiptOptionSettings
      ?.locationsCanOverride ?? true;
  const isAnyReceiptOptionSelected: boolean =
    receiptOptions?.some((option) => option.enabled) ?? false;

  const canSaveCheck = () => {
    const hasReceiptOptionsChanged: boolean = !isEqual(
      receiptOptions,
      receiptOptionsInitial
    );

    const hasLocationsCanOverrideChanged: boolean =
      canLocationsOverride !== canLocationsOverrideInitial;

    return (
      isAnyReceiptOptionSelected &&
      (hasReceiptOptionsChanged || hasLocationsCanOverrideChanged)
    );
  };

  const handleSaveSettings = () => {
    const settingsToSave: ReceiptSettings = {
      locationsCanOverride: canLocationsOverride,
      receiptOptions: receiptOptions,
    };

    const modifySettings = (settings: SaleSettings) => {
      if (locationId) {
        dataService.enqueueLocationForUpdate(locationId);
        (settings as SaleSettings).locationId = locationId;
        (settings as SaleSettings).receiptOptionSettings!.locationsCanOverride =
          undefined;
      } else {
        portal!.state.shouldUpdateAllLocations = true;
        (settings as SaleSettings).receiptOptionSettings!.locationsCanOverride =
          canLocationsOverride;
      }
    };

    const updatedSaleSettings: SaleSettings = {
      ...locationSettings,
      receiptOptionSettings: settingsToSave,
    };

    onSaveSettingsCard(updatedSaleSettings, modifySettings);
    setCanSave(false);
  };

  const onUpdateItems = (index: number) => {
    setReceiptOptions((receiptOptions) => {
      const updatedOptions = [...receiptOptions!];
      updatedOptions[index] = {
        ...updatedOptions[index],
        enabled: !updatedOptions[index].enabled,
      };
      return updatedOptions;
    });
  };

  useEffect(() => {
    setCanSave(canSaveCheck());
  }, [receiptOptions, canLocationsOverride]);

  useEffect(() => {
    setIsDisabled(!!locationId && !canLocationsOverride);
  }, [locationId, canLocationsOverride]);

  const receiptSettingsStyles: Record<string, SxProps<Theme>> = {
    errorTypography: {
      color: "red",
      textAlign: "center",
    },
    receiptOptionToggleBox: {
      padding: "8px",
      display: "flex",
      alignItems: "center",
    },
    receiptOptionText: {
      fontSize: "14px",
      ml: 1,
    },
  };

  return (
    <SettingsCardComponent
      canSave={canSave}
      disabled={isDisabled}
      headerText="Receipt Settings"
      id={id}
      locationsCanOverrideToggleProps={{
        locationsCanOverride: canLocationsOverride,
        onChange: handleLocationsOverrideChange(setCanLocationsOverride),
        disabled: !!locationId,
      }}
      subHeaderText="Specify supported receipt options."
      onSave={handleSaveSettings}
    >
      <Box>
        <LeftRightContentComponent
          leftHeading="Receipt Options"
          rightHeading="What types of receipts should be enabled?"
        >
          <Box>
            <Grid container spacing={1}>
              {receiptOptions!.map((receiptOption, index) => (
                <Grid item key={receiptOption.receiptOptionId} xs={6}>
                  <Box sx={receiptSettingsStyles.receiptOptionToggleBox}>
                    <StyledSwitchComponent
                      checked={receiptOption.enabled}
                      data-testid={`tenderTypeToggle-${receiptOption.receiptOptionId}`}
                      disabled={isDisabled}
                      onChange={() => onUpdateItems(index)}
                    />
                    <Typography sx={receiptSettingsStyles.receiptOptionText}>
                      {receiptOption.receiptOptionDisplayName}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </LeftRightContentComponent>
        {!isAnyReceiptOptionSelected && (
          <Typography sx={receiptSettingsStyles.errorTypography}>
            You must enable at least one receipt type.
          </Typography>
        )}
      </Box>
    </SettingsCardComponent>
  );
};
