import {
  Box,
  IconButton,
  SxProps,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { cloneDeep } from "lodash";
import SettingsCardComponent from "../../settings/cards/SettingsCard";
import { usePortal } from "../../../providers/PortalProvider";
import { useDataService } from "../../../providers/DataServiceProvider";
import { BrandingSettings } from "../../../interfaces/settings/BrandingSettings";
import StyledSwitchComponent from "../../styled/StyledSwitch";
import { UploadLogoResponse } from "../../../interfaces/responses/UploadLogoResponse";
import { useAlert } from "../../../providers/AlertProvider";
import ErrorIcon from "@mui/icons-material/Error";
export interface LocationDetailsBrandingCardProps {
  locationId: string;
  brandingSettings: BrandingSettings;
}

const LocationDetailsBrandingCardComponent: React.FC<
  LocationDetailsBrandingCardProps
> = ({ brandingSettings, locationId }) => {
  const globalTheme = useTheme();
  const portal = usePortal();
  const { showAlert } = useAlert();
  const dataService = useDataService();
  const [localBrandingSettings, setLocalBrandingSettings] = useState(
    cloneDeep(brandingSettings)
  );

  const [canSave, setCanSave] = useState(false);
  const uploadInputRef = React.useRef<HTMLInputElement>(null);
  const smallLogoUrl = localBrandingSettings.logos?.smallLogoUrl;
  const imgPlaceholderUrl = "img/image-placeholder.svg";

  const [thumbnail, setThumbnail] = useState(smallLogoUrl || imgPlaceholderUrl);
  const [isReceiptIntroValid, setIsReceiptIntroValid] = useState(
    localBrandingSettings.receiptIntroText.length > 0
  );
  const [isReceiptFooterValid, setIsReceiptFooterValid] = useState(
    localBrandingSettings.receiptFooterText.length > 0
  );
  const [isAdditionalTextValid, setIsAdditionalTextValid] = useState(
    localBrandingSettings.additionalReceiptText ?
      localBrandingSettings.additionalReceiptText.length > 0
    : false
  );
  const [isBrandingImageValid, setIsBrandingImageValid] = useState(
    localBrandingSettings.logos?.smallLogoUrl?.trim() !== ""
  );

  const isDisabled =
    !!locationId && localBrandingSettings.locationsCanOverride === false;

  const uploadBrandingImage = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const endpoint = "v1/Branding/UploadLogo";
    portal!.navigation.isLoading(true);

    const uploadImage = e.target.files?.[0];
    if (!uploadImage) {
      portal!.navigation.isLoading(false);
      return;
    }

    if (!/^.+(\.jpeg|\.jpg|\.png)$/.test(uploadImage.name.toLowerCase())) {
      alert(
        "Please provide an image with a valid file extension. Accepted image extensions: .png, .jpeg, .jpg"
      );
      portal!.navigation.isLoading(false);
      return;
    }

    try {
      const response = await dataService!.uploadFile<UploadLogoResponse>(
        endpoint,
        uploadImage
      );
      const fileUrl = response.fileUrl;

      let newLogos = { smallLogoUrl: fileUrl };
      if (localBrandingSettings.logos) {
        newLogos = { ...localBrandingSettings.logos, smallLogoUrl: fileUrl };
      }

      setLocalBrandingSettings({ ...localBrandingSettings, logos: newLogos });
      setThumbnail(fileUrl);
    } catch (error) {
      showAlert(`Failed to upload image: ${error}`, "error", <ErrorIcon />);
    } finally {
      portal!.navigation.isLoading(false);
    }
  };

  const changeImage = () => {
    if (!isDisabled && uploadInputRef && uploadInputRef.current) {
      uploadInputRef.current.click();
    }
  };
  const clearImage = () => {
    if (localBrandingSettings.logos) {
      var newLogos = {
        ...localBrandingSettings.logos,
        smallLogoUrl: undefined,
      };
      setLocalBrandingSettings({ ...localBrandingSettings, logos: newLogos });
    }

    setThumbnail(imgPlaceholderUrl);
  };

  useEffect(() => {
    setCanSave(canSaveCheck());
  }, [
    localBrandingSettings.receiptIntroText,
    localBrandingSettings.receiptFooterText,
    localBrandingSettings.additionalReceiptText,
    localBrandingSettings.logos,
  ]);
  const canSaveCheck = () => {
    const isIntroValid = localBrandingSettings.receiptIntroText.length > 0;
    const isFooterValid = localBrandingSettings.receiptFooterText.length > 0;
    const isAdditionalTextValid =
      localBrandingSettings.additionalReceiptInformation ?
        (localBrandingSettings.additionalReceiptText?.length ?? 0) > 0
      : true;
    const isBrandingImageValid =
      (localBrandingSettings.logos?.smallLogoUrl?.trim() ?? "") !== "";

    setIsReceiptIntroValid(isIntroValid);
    setIsReceiptFooterValid(isFooterValid);
    setIsAdditionalTextValid(isAdditionalTextValid);
    setIsBrandingImageValid(isBrandingImageValid);

    return (
      isIntroValid &&
      isFooterValid &&
      isAdditionalTextValid &&
      isBrandingImageValid
    );
  };
  const handleAdditionalInfoToggle = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = e.target.checked;
    setLocalBrandingSettings((prevSettings) => ({
      ...prevSettings,
      additionalReceiptInformation: isChecked,
      additionalReceiptText: isChecked ? "" : undefined,
    }));
  };

  const saveBranding = async () => {
    try {
      portal!.navigation.isLoading(true);

      const settings = { ...localBrandingSettings, locationId: locationId };

      if (localBrandingSettings.locationId) {
        settings.locationsCanOverride = undefined;
        await dataService!.enqueueLocationForUpdate(locationId);
      } else {
        portal!.state.shouldUpdateAllLocations = true;
      }

      await dataService!.saveBrandingSettings(settings);
      await dataService!.getBrandingSettings();
      await dataService!.loadConfigurations();

      portal!.navigation.isLoading(false);
    } catch (error) {
      portal!.navigation.isLoading(false);
      showAlert(`Failed to save branding: ${error}`, "error", <ErrorIcon />);
    }
  };
  const brandingCardComponentStyles: Record<string, SxProps<Theme>> = {
    additionalReceiptInfoContainer: {
      display: "flex",
      alignItems: "center",
      mt: 1,
    },
    additionalReceiptText: {
      ml: 1,
      fontSize: "14px",
    },
    clearButton: {
      position: "absolute",
      top: "-160px",
      right: "-20px",
      "&:hover": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
      "&:active": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
      "&.Mui-focusVisible": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
      "&:focus": {
        backgroundColor: "transparent",
        color: "#000000",
      },
    },
    headerText: {
      fontSize: "20px",
      color: globalTheme.palette.common.black,
    },
    imageContainer: {
      position: "relative",
      border: `1px solid ${isBrandingImageValid ? "#ccc" : "red"}`,
      borderRadius: "4px",
      display: "inline-block",
      width: "160px",
      height: "160px",
      padding: "10px",
      boxSizing: "border-box",
      mt: 3,
      mb: 2,
    },
    imageUploadButton: {
      width: "100%",
      height: "100%",
      padding: "0",
      color: "#000000",
      "&:hover": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
      "&:active": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
      "&.Mui-focusVisible": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
      "&:focus": {
        backgroundColor: "transparent",
        color: "#000000",
      },
    },
    receiptTextField: {
      width: "65%",
      mt: 2,
      mb: 2,
    },
    subHeaderText: {
      fontSize: "14px",
      color: globalTheme.palette.common.gray,
    },
  };
  return (
    <SettingsCardComponent
      canSave={canSave}
      disabled={isDisabled}
      headerText="Customize receipts and branding"
      id="location-settings-branding"
      locationsCanOverrideToggleProps={{
        disabled: !!locationId,
        locationsCanOverride:
          localBrandingSettings.locationsCanOverride ?? true,
      }}
      subHeaderText="Branding for your receipts"
      onSave={saveBranding}
    >
      <Box>
        <Typography sx={brandingCardComponentStyles.headerText}>
          Receipt Branding
        </Typography>
        <Typography sx={brandingCardComponentStyles.subHeaderText}>
          Choose a logo you would like to display on the top of printed and
          emailed receipts. By default, all child locations will inherit the
          same logo.
        </Typography>
        <Box
          data-testid="logoImageContainer"
          sx={brandingCardComponentStyles.imageContainer}
        >
          <IconButton
            disableRipple
            sx={brandingCardComponentStyles.imageUploadButton}
            onClick={changeImage}
          >
            <img src={thumbnail} style={{ width: "100%", height: "100%" }} />
          </IconButton>
          {thumbnail != imgPlaceholderUrl && (
            <IconButton
              disableRipple
              sx={brandingCardComponentStyles.clearButton}
              onClick={clearImage}
            >
              <CancelRoundedIcon />
            </IconButton>
          )}
          <input
            hidden
            accept=".jpg,.jpeg,.png"
            data-testid="logoUploadInput"
            disabled={isDisabled}
            ref={uploadInputRef}
            type="file"
            onChange={uploadBrandingImage}
          />
        </Box>
      </Box>
      <Box>
        <Typography sx={brandingCardComponentStyles.headerText}>
          Receipt Text
        </Typography>
        <TextField
          multiline
          data-testid="receiptIntroTextField"
          disabled={isDisabled}
          error={!isReceiptIntroValid}
          helperText={
            !isReceiptIntroValid ? "Intro Text is a required field." : ""
          }
          label="Intro Text"
          rows={3}
          sx={brandingCardComponentStyles.receiptTextField}
          value={localBrandingSettings.receiptIntroText}
          onChange={(e) => {
            setLocalBrandingSettings((prevSettings) => ({
              ...prevSettings,
              receiptIntroText: e.target.value,
            }));
          }}
        />
        <TextField
          multiline
          data-testid="receiptFooterTextField"
          disabled={isDisabled}
          error={!isReceiptFooterValid}
          helperText={
            !isReceiptFooterValid ? "Footer Text is a required field." : ""
          }
          label="Footer Text"
          rows={3}
          sx={brandingCardComponentStyles.receiptTextField}
          value={localBrandingSettings.receiptFooterText}
          onChange={(e) => {
            setLocalBrandingSettings((prevSettings) => ({
              ...prevSettings,
              receiptFooterText: e.target.value,
            }));
          }}
        />
      </Box>
      <Box>
        <Typography sx={brandingCardComponentStyles.headerText}>
          Additional Receipt Step
        </Typography>
        <Typography sx={brandingCardComponentStyles.subHeaderText}>
          When enabled, displays additional receipt text after each sale and
          requires a customer signature.
        </Typography>
        <Box sx={brandingCardComponentStyles.additionalReceiptInfoContainer}>
          <StyledSwitchComponent
            checked={localBrandingSettings.additionalReceiptInformation}
            data-testid="additionalReceiptInfoToggle"
            onChange={handleAdditionalInfoToggle}
          />
          <Typography sx={brandingCardComponentStyles.additionalReceiptText}>
            Additional Receipt Information
          </Typography>
        </Box>
      </Box>
      {localBrandingSettings.additionalReceiptInformation && (
        <Box data-testid="additionalReceiptInfoContainer">
          <TextField
            multiline
            data-testid="additionalInfoTextField"
            disabled={isDisabled}
            error={!isAdditionalTextValid}
            helperText={
              !isAdditionalTextValid ?
                "Additional Receipt Text is a required field."
              : ""
            }
            label="Additional Receipt Text"
            rows={3}
            sx={brandingCardComponentStyles.receiptTextField}
            value={localBrandingSettings.additionalReceiptText}
            onChange={(e) => {
              setLocalBrandingSettings((prevSettings) => ({
                ...prevSettings,
                additionalReceiptText: e.target.value,
              }));
            }}
          />
        </Box>
      )}
    </SettingsCardComponent>
  );
};

export default LocationDetailsBrandingCardComponent;
