import React, { useEffect, useState } from "react";
import LocationDetailsTaxesCardComponent from "../LocationDetailsTaxesCard";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TuneIcon from "@mui/icons-material/Tune";
import TaxJurisdictionGridComponent from "./TaxJurisdictionGrid";
import { useXhrService } from "../../../../providers/XhrServiceProvider";
import { usePortal } from "../../../../providers/PortalProvider";
import { useTaxesContext } from "../../../../providers/TaxesProvider";
import { Tax } from "../../../../interfaces/settings/Taxes";
import PrimaryButtonComponent from "../../../styled/PrimaryButton";
import { cloneDeep, isEqual } from "lodash";
import { useTheme } from "@mui/material/styles";
import { AdvancedTaxConfigurationDialogComponent } from "../advancedTaxConfiguration/AdvancedTaxConfigurationDialog";
import TextButtonComponent from "../../../styled/TextButton";
export interface LocationDetailsTaxConfigurationProps {
  locationId: string;
  getTaxes: () => void;
}

const LocationDetailsTaxConfigurationComponent: React.FC<
  LocationDetailsTaxConfigurationProps
> = ({ locationId, getTaxes }) => {
  const portal = usePortal();
  const xhrService = useXhrService();
  const globalTheme = useTheme();

  const saveTaxes = () => {
    portal!.navigation.isLoading(true);
    const savePromises = taxes.map((tax) => {
      const shouldClearTaxId = tax.taxId && tax.taxId.includes("temp");

      const taxToUpdate = {
        ...tax,
        taxId: shouldClearTaxId ? undefined : tax.taxId,
      };

      return new Promise((resolve, reject) => {
        xhrService!.doRicsApiXhr(
          "v1/Taxes",
          taxToUpdate,
          (response) => {
            resolve(JSON.parse(response));
          },
          (error) => {
            console.error("Save tax failed", error);
            reject(error);
          },
          "PUT"
        );
      });
    });

    Promise.all(savePromises)
      .then(() => {
        getTaxes();
      })
      .catch((error) => {
        console.error("An error occurred during tax save operations", error);
      })
      .finally(() => {
        portal!.navigation.isLoading(false);
      });
  };
  const { addTax, taxes } = useTaxesContext();
  const [canSave, setCanSave] = useState(false);
  const [
    isAdvancedTaxConfigurationDialogOpen,
    setisAdvancedTaxConfigurationDialogOpen,
  ] = useState(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);
  const [taxesInitial, setTaxesInitial] = useState<Tax[]>(() =>
    cloneDeep(taxes)
  );

  const canSaveCheck = () => {
    const isTaxesUpdated = !isEqual(taxesInitial, taxes);
    const filteredTaxes = taxes.filter((tax) =>
      tax.locationIds?.includes(locationId)
    );
    const isTaxesValid = filteredTaxes.every((tax) => {
      const isDescriptionValid = tax.description && tax.description.length >= 4;
      const taxRateFormValue = tax.taxRateFormValue ?? "";
      const hasValidDecimals =
        taxRateFormValue.split(".")[1]?.length <= 4 ||
        !taxRateFormValue.includes(".");
      const isTaxRateValid =
        typeof tax.taxRate === "number" && tax.taxRate >= 0 && hasValidDecimals;

      return isDescriptionValid && isTaxRateValid;
    });
    setIsErrorDisplayed(!isTaxesValid);
    return isTaxesUpdated && isTaxesValid;
  };
  useEffect(() => {
    setTaxesInitial(cloneDeep(taxes));
  }, []);

  useEffect(() => {
    setCanSave(canSaveCheck());
  }, [taxes, locationId, setCanSave]);

  const createNewTax = () => {
    let id: string = crypto.randomUUID();
    let newTax: Tax = {
      tenantId: portal!.state.tenantInfo.tenantId,
      taxId: "temp-" + id,
      locationIds: [locationId],
      description: "",
      taxRate: undefined,
      onlyTaxAboveThreshold: false,
    };
    addTax(newTax);
  };

  const taxConfigurationStyles: Record<string, SxProps<Theme>> = {
    errorTypography: {
      color: "red",
      textAlign: "center",
      fontSize: "14px",
    },
    invalidInputBox: {
      mt: 1,
    },
    saveButton: {
      mt: 1,
    },
  };

  return (
    <LocationDetailsTaxesCardComponent
      dataTestId="locationDetailsTaxConfiguration"
      header="Configure your store's tax details"
      subheader="Add tax jurisdictions based on your location. Locations generally have at least one tax jurisdiction, but may have several (state, county, district, etc):
"
    >
      <Box>
        <TaxJurisdictionGridComponent />
        <Box mt={1}>
          <TextButtonComponent
            color="primary"
            data-testid="createNewTaxButton"
            startIcon={<AddIcon />}
            variant="text"
            onClick={createNewTax}
          >
            Create a Tax Jurisdiction
          </TextButtonComponent>
        </Box>
        <Box mt={1}>
          <TextButtonComponent
            disableRipple
            focusColor={globalTheme.palette.primary.main}
            hoverColor={globalTheme.palette.primary.main}
            startIcon={<TuneIcon />}
            textColor={globalTheme.palette.primary.main}
            variant="text"
            onClick={() => setisAdvancedTaxConfigurationDialogOpen(true)}
          >
            Click for Advanced Tax Configuration...{" "}
          </TextButtonComponent>
        </Box>
        <PrimaryButtonComponent
          data-testid="saveTaxConfigurationsButton"
          disabled={!canSave}
          sx={taxConfigurationStyles.saveButton}
          onClick={saveTaxes}
        >
          Save Changes
        </PrimaryButtonComponent>
      </Box>
      {isErrorDisplayed && (
        <Box sx={taxConfigurationStyles.invalidInputBox}>
          <Typography sx={taxConfigurationStyles.errorTypography}>
            Each tax jurisdiction must be named at least 4 characters and
            include a tax rate up to 4 decimal places.
          </Typography>
        </Box>
      )}
      <AdvancedTaxConfigurationDialogComponent
        isAdvancedTaxConfigurationDialogOpen={
          isAdvancedTaxConfigurationDialogOpen
        }
        isEvent={false}
        setisAdvancedTaxConfigurationDialogOpen={
          setisAdvancedTaxConfigurationDialogOpen
        }
      />
    </LocationDetailsTaxesCardComponent>
  );
};
export default LocationDetailsTaxConfigurationComponent;
