import React from "react";
import LocationDetailsPageComponent from "../LocationDetailsPage";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import DevicesGridComponent from "../../devices/DevicesGrid";
export interface LocationDetailsTaxesProps {
  locationId: string;
}

const LocationDetailsDevicesComponent: React.FC<LocationDetailsTaxesProps> = ({
  locationId,
}) => {
  return (
    <LocationDetailsPageComponent
      dataTestId="locationDetailsDevices"
      headerIcon={<PointOfSaleIcon />}
      headerText="Devices"
    >
      <DevicesGridComponent locationId={locationId} />
    </LocationDetailsPageComponent>
  );
};
export default LocationDetailsDevicesComponent;
