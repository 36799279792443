import React from "react";
import RouterComponent from "./components/routing/RouterComponent";
import { DataServiceProvider } from "./providers/DataServiceProvider";
import { PortalProvider } from "./providers/PortalProvider";
import { BrowserRouter } from "react-router-dom";
import { XhrServiceProvider } from "./providers/XhrServiceProvider";
import { AmazonServiceProvider } from "./providers/AmazonServiceProvider";
import { AlertProvider } from "./providers/AlertProvider";
import { ECommerceServiceProvider } from "./providers/ECommerceServiceProvider";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";

import globalTheme from "./theme/globalTheme";

const App: React.FC = () => {
  return (
    <PortalProvider>
      <XhrServiceProvider>
        <DataServiceProvider>
          <AmazonServiceProvider>
            <ECommerceServiceProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ThemeProvider theme={globalTheme}>
                  <AlertProvider>
                    <CssBaseline />
                    <BrowserRouter>
                      <RouterComponent />
                    </BrowserRouter>
                  </AlertProvider>
                </ThemeProvider>
              </LocalizationProvider>
            </ECommerceServiceProvider>
          </AmazonServiceProvider>
        </DataServiceProvider>
      </XhrServiceProvider>
    </PortalProvider>
  );
};

export default App;
