import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  TextField,
} from "@mui/material/";
import PrimaryButtonComponent from "../../../styled/PrimaryButton";
import { SxProps, Theme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { usePortal } from "../../../../providers/PortalProvider";
import { useXhrService } from "../../../../providers/XhrServiceProvider";
import { SpecialTaxEvent } from "../../../../interfaces/settings/Taxes";
import { useTaxesContext } from "../../../../providers/TaxesProvider";
import { useAlert } from "../../../../providers/AlertProvider";
import ErrorIcon from "@mui/icons-material/Error";
dayjs.extend(isSameOrAfter);

export interface SpecialTaxEventDialogProps {
  closeSpecialTaxActionsPopover?: () => void;
  isSpecialTaxDialogOpen: boolean;
  setIsSpecialTaxDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  specialTaxEvent?: SpecialTaxEvent;
  locationId?: string;
}

const SpecialTaxEventDialogComponent: React.FC<SpecialTaxEventDialogProps> = ({
  closeSpecialTaxActionsPopover,
  isSpecialTaxDialogOpen,
  setIsSpecialTaxDialogOpen,
  specialTaxEvent,
  locationId,
}) => {
  const { taxes, addOrUpdateSpecialTaxEvent } = useTaxesContext();
  const portal = usePortal();
  const { showAlert } = useAlert();
  const xhrService = useXhrService();
  const handleClose = () => {
    setIsSpecialTaxDialogOpen(false);
  };
  const [canSave, setCanSave] = useState(false);
  const [willApplyToEverything, setWillApplyToEverything] = useState(
    specialTaxEvent?.applyToEverything ?? true
  );

  const [eventName, setEventName] = React.useState<string>(
    specialTaxEvent?.eventName ?? "New Special Tax Event"
  );
  const [startDate, setStartDate] = useState<Dayjs | null>(
    specialTaxEvent && specialTaxEvent.startDate ?
      dayjs(specialTaxEvent.startDate)
    : dayjs()
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    specialTaxEvent && specialTaxEvent.endDate ?
      dayjs(specialTaxEvent.endDate)
    : null
  );

  const [checkedTaxIds, setCheckedTaxIds] = useState<string[]>(
    specialTaxEvent?.applicableTaxIds ?? []
  );

  const handleCheck = (event: ChangeEvent<HTMLInputElement>, taxId: string) => {
    if (event.target.checked) {
      setCheckedTaxIds((prev) => [...prev, taxId]);
    } else {
      setCheckedTaxIds((prev) => prev.filter((id) => id !== taxId));
    }
  };

  useEffect(() => {
    if (specialTaxEvent && specialTaxEvent.applicableTaxIds) {
      setCheckedTaxIds(specialTaxEvent.applicableTaxIds);
    }
  }, [specialTaxEvent]);

  useEffect(() => {
    if (!isSpecialTaxDialogOpen) {
      setWillApplyToEverything(specialTaxEvent?.applyToEverything ?? true);
      setEventName(specialTaxEvent?.eventName ?? "New Special Tax Event");
      setStartDate(
        specialTaxEvent && specialTaxEvent.startDate ?
          dayjs(specialTaxEvent.startDate)
        : dayjs()
      );
      setEndDate(
        specialTaxEvent && specialTaxEvent.endDate ?
          dayjs(specialTaxEvent.endDate)
        : null
      );
      setCheckedTaxIds([]);
    }
  }, [isSpecialTaxDialogOpen]);

  useEffect(() => {
    if (specialTaxEvent?.applicableTaxIds) {
      setCheckedTaxIds(specialTaxEvent.applicableTaxIds);
    }
  }, [specialTaxEvent]);

  const saveSpecialTaxEvent = async () => {
    let taxEvent: SpecialTaxEvent;
    portal!.navigation.isLoading(true);
    if (specialTaxEvent) {
      taxEvent = specialTaxEvent;
      taxEvent.applicableTaxIds = checkedTaxIds;
      taxEvent.eventName = eventName ?? "";
      taxEvent.startDate = startDate!.toDate();
      taxEvent.endDate = endDate!.toDate();
      taxEvent.applyToEverything = willApplyToEverything;
    } else {
      let id: string = crypto.randomUUID();

      taxEvent = {
        tenantId: portal!.state.tenantInfo.tenantId,
        eventId: id,
        locationIds: [locationId!],
        applicableTaxIds: checkedTaxIds,
        eventName: eventName,
        startDate: startDate!.toDate(),
        endDate: endDate!.toDate(),
        applyToEverything: willApplyToEverything,
      };
    }
    try {
      await xhrService!.doRicsApiXhr(
        "v1/Taxes/Events",
        taxEvent,
        undefined,
        undefined,
        "PUT"
      );
      addOrUpdateSpecialTaxEvent(taxEvent);
    } catch (error) {
      showAlert(`Failed to save tax event: ${error}`, "error", <ErrorIcon />);
    } finally {
      setIsSpecialTaxDialogOpen(false);
      if (closeSpecialTaxActionsPopover) {
        closeSpecialTaxActionsPopover();
      }
      portal!.navigation.isLoading(false);
    }
  };

  const canSaveCheck = () => {
    const today = dayjs().startOf("day");
    return (
      startDate !== null &&
      endDate !== null &&
      eventName.trim() !== "" &&
      dayjs(startDate).isSameOrAfter(today) &&
      dayjs(endDate).isSameOrAfter(today)
    );
  };
  useEffect(() => {
    const isSaveEnabled = canSaveCheck();
    setCanSave(isSaveEnabled);
  }, [startDate, endDate, eventName]);

  const specialTaxDialogStyles: Record<string, SxProps<Theme>> = {
    checkBoxList: {
      display: "flex",
      flexDirection: "column",
    },
    datePickerLeft: {
      mr: 1,
    },
    datePickerBox: {
      my: 1,
    },
    descriptionText: {
      fontSize: 12,
      my: 1,
    },
    dialogActions: {
      justifyContent: "space-between",
      ml: 1,
      mr: 1,
    },
    everythingSoldContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 2,
    },
    everythingSoldButtons: {
      width: "45%",
    },
  };

  return (
    <Dialog
      data-testid="specialTaxDialog"
      open={isSpecialTaxDialogOpen}
      onClose={handleClose}
    >
      <DialogTitle>
        {specialTaxEvent ? "Save Changes" : "Create a Tax Event"}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText sx={specialTaxDialogStyles.descriptionText}>
          Provide some basic details about this tax event.
        </DialogContentText>
        <TextField
          autoFocus
          fullWidth
          required
          label="Event Name"
          placeholder="e.g. 2024 Tax Free Weekend"
          value={eventName}
          variant="outlined"
          onChange={(event) => setEventName(event.target.value)}
        />
        <Box sx={specialTaxDialogStyles.datePickerBox}>
          <DatePicker
            disablePast
            label="Start Date"
            sx={specialTaxDialogStyles.datePickerLeft}
            value={startDate}
            onChange={(newDate) => setStartDate(newDate)}
          />
          <DatePicker
            disablePast
            label="End Date"
            value={endDate}
            onChange={(newDate) => setEndDate(newDate)}
          />
        </Box>

        <DialogContentText sx={specialTaxDialogStyles.descriptionText}>
          Note that the tax event will begin at 12:01 AM of the date entered in
          the start date field and end at 11:59 PM of the date entered in the
          end date field.
        </DialogContentText>
        <DialogContentText sx={specialTaxDialogStyles.descriptionText}>
          Select the tax jurisdictions below that are affected by this tax
          event:
        </DialogContentText>
        <Box sx={specialTaxDialogStyles.checkBoxList}>
          {taxes.map((tax, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedTaxIds.includes(tax.taxId)}
                  onChange={(event) => handleCheck(event, tax.taxId)}
                />
              }
              key={tax.taxId || index}
              label={tax.description}
            />
          ))}
        </Box>
        <DialogContentText sx={specialTaxDialogStyles.descriptionText}>
          What will be tax free?
        </DialogContentText>
        <Box sx={specialTaxDialogStyles.everythingSoldContainer}>
          <Button
            color="primary"
            sx={specialTaxDialogStyles.everythingSoldButtons}
            variant={willApplyToEverything ? "contained" : "outlined"}
            onClick={() => setWillApplyToEverything(true)}
          >
            Everything Sold
          </Button>
          <Button
            color="primary"
            sx={specialTaxDialogStyles.everythingSoldButtons}
            variant={!willApplyToEverything ? "contained" : "outlined"}
            onClick={() => setWillApplyToEverything(false)}
          >
            Specific Products or Categories
          </Button>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={specialTaxDialogStyles.dialogActions}>
        <PrimaryButtonComponent onClick={handleClose}>
          Cancel
        </PrimaryButtonComponent>
        <PrimaryButtonComponent
          disabled={!canSave}
          onClick={saveSpecialTaxEvent}
        >
          {specialTaxEvent ? "Save Changes" : "Create Tax Event"}
        </PrimaryButtonComponent>
      </DialogActions>
    </Dialog>
  );
};

export default SpecialTaxEventDialogComponent;
