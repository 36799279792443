export const isEmpty = ({ str }: { str: string | null | undefined }) => {
  return !str || str.length === 0;
};

export const getBoolean = (str: string) => {
  switch (str) {
    case "true":
    case "1":
      return true;
    default:
      return false;
  }
};

export const toCapitalizedWords = (name: string) => {
  var words = name.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalize).join(" ");
};

export const capitalize = (word: string) => {
  return word.charAt(0).toUpperCase() + word.substring(1);
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
