import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Box,
  SxProps,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import throttle from "lodash/throttle";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DeviceSettings } from "../../interfaces/salesettings/DeviceSettings";
import { MerchantSettings } from "../../interfaces/salesettings/MerchantSettings";
import { SaleSettings } from "../../interfaces/salesettings/SaleSettings";
import { useAlert } from "../../providers/AlertProvider";
import { usePortal } from "../../providers/PortalProvider";
import { SaleSettingsService } from "../../services/SaleSettingsService";
import ClutchSettingsComponent from "./cards/ClutchSettings";
import CustomerSettingsComponent from "./cards/CustomerSettings";
import PaymentDetailsComponent from "./cards/PaymentDetails";
import PaymentProcessingComponent from "./cards/PaymentProcessing";
import { PosDeviceSettingsComponent } from "./cards/PosDeviceSettings";
import { ReceiptSettingsComponent } from "./cards/ReceiptSettings";
import { RefundsComponent } from "./cards/Refunds";

export interface SettingsGridProps {
  locationId?: string;
}

const SettingsGridComponent: React.FC<SettingsGridProps> = ({ locationId }) => {
  const portal = usePortal();
  const { showAlert } = useAlert();

  const updateCachedSaleSettings = (saleSettings: SaleSettings) => {
    let key = !!locationId ? locationId : "Tenant";
    portal!.state.saleSettings[key] = saleSettings;

    setSaleSettings(
      portal?.state.saleSettings[locationId ?? "Tenant"] ??
        portal?.state.saleSettings["Tenant"]
    );
  };

  const onSaveSettingsCard = async (
    settingsToSave: SaleSettings | MerchantSettings | DeviceSettings,
    modifySettings?: (salesSettings: SaleSettings) => void
  ) => {
    portal!.navigation.isLoading(true);

    let salesSettings =
      locationId ?
        (portal!.state.saleSettings[locationId] ??
        portal!.state.saleSettings["Tenant"])
      : portal!.state.saleSettings["Tenant"];

    if ("merchantId" in settingsToSave) {
      salesSettings.merchantSettings = {
        ...(salesSettings.merchantSettings || {}),
        ...settingsToSave,
      };
    } else {
      Object.assign(salesSettings, settingsToSave);
    }

    modifySettings?.(salesSettings);

    try {
      const saleSettings = await SaleSettingsService.save(salesSettings);
      updateCachedSaleSettings(saleSettings);
    } catch (error) {
      showAlert("Unable to save sale settings", "error", <ErrorIcon />);
    }

    portal!.navigation.isLoading(false);
  };

  const [isClutchHidden, setIsClutchHidden] = useState(true);
  const [saleSettings, setSaleSettings] = useState(
    portal?.state.saleSettings[locationId ?? "Tenant"] ??
      portal?.state.saleSettings["Tenant"]
  );

  useEffect(() => {
    const isClutchTenderEnabled = saleSettings?.tenderTypeOptions?.some(
      (t) =>
        t.tenderTypeOptionId == "GiftCard" &&
        t.tenderTypeOptionSubTypeId == "Clutch" &&
        t.enabled == true
    );

    const isClutchRefundEnabled = saleSettings?.returnMethods?.some(
      (r) =>
        r.returnMethodId == "GiftCard" &&
        r.returnMethodSubTypeId == "Clutch" &&
        r.enabled == true
    );

    setIsClutchHidden(
      !locationId || (!isClutchTenderEnabled && !isClutchRefundEnabled)
    );
  }, [locationId, saleSettings]);

  const settingsItems = [
    {
      label: "Payment Processing",
      hash: "payment-processing",
      content: (
        <PaymentProcessingComponent
          id="payment-processing"
          locationId={locationId}
          merchantSettings={saleSettings?.merchantSettings}
          onSaveSettingsCard={onSaveSettingsCard}
        />
      ),
    },
    {
      label: "Payment Details",
      hash: "payment-details",
      content: (
        <PaymentDetailsComponent
          id="payment-details"
          locationId={locationId}
          locationSettings={saleSettings!}
          onSaveSettingsCard={onSaveSettingsCard}
        />
      ),
    },
    {
      label: "Refunds",
      hash: "refunds",
      content: (
        <RefundsComponent
          id="refunds"
          locationId={locationId}
          locationSettings={saleSettings!}
          onSaveSettingsCard={onSaveSettingsCard}
        />
      ),
    },
    {
      label: "POS Device Settings",
      hash: "pos-device-settings",
      content: (
        <PosDeviceSettingsComponent
          id="pos-device-settings"
          locationId={locationId}
          locationSettings={saleSettings!}
          onSaveSettingsCard={onSaveSettingsCard}
        />
      ),
    },
    {
      label: "Customer Settings",
      hash: "customer-settings",
      content: (
        <CustomerSettingsComponent
          id="customer-settings"
          locationId={locationId}
          locationSettings={saleSettings!}
          onSaveSettingsCard={onSaveSettingsCard}
        />
      ),
    },
    {
      label: "Receipt Settings",
      hash: "receipt-settings",
      content: (
        <ReceiptSettingsComponent
          id="receipt-settings"
          locationId={locationId}
          locationSettings={saleSettings!}
          onSaveSettingsCard={onSaveSettingsCard}
        />
      ),
    },
    {
      label: "Clutch Settings",
      hash: "clutch-settings",
      content: (
        <ClutchSettingsComponent
          hidden={isClutchHidden}
          id="clutch-settings"
          locationId={locationId}
          locationSettings={saleSettings!}
          onSaveSettingsCard={onSaveSettingsCard}
        />
      ),
      hidden: isClutchHidden,
    },
  ];

  const globalTheme = useTheme();
  const [value, setValue] = useState<string | null>(settingsItems[0].hash);
  const [scrollableMaxHeight, setScrollableMaxHeight] = useState(700);
  const scrollableContentRef = useRef<HTMLElement>();
  const clickedRef = useRef(false);
  const unsetClickedRef = useRef<ReturnType<typeof setTimeout> | undefined>(
    undefined
  );

  const noop = () => {};

  const useThrottledOnScroll = (callback?: () => void, delay?: number) => {
    const throttledCallback = useMemo(
      () =>
        callback ?
          throttle(() => {
            callback();
          }, delay)
        : noop,
      [callback, delay]
    );

    useEffect(() => {
      if (throttledCallback === noop) {
        return undefined;
      }

      scrollableContentRef.current?.addEventListener(
        "scroll",
        throttledCallback
      );
      return () => {
        scrollableContentRef.current?.removeEventListener(
          "scroll",
          throttledCallback
        );
      };
    }, [throttledCallback]);
  };

  const findActiveIndex = useCallback(() => {
    if (clickedRef.current) {
      return;
    }

    let active;
    for (let i = settingsItems.length - 1; i >= 0; i -= 1) {
      const item = settingsItems[i];
      const node = document.getElementById(item.hash);

      if (process.env.NODE_ENV !== "production" && !node) {
        console.error(
          `Missing node on the item ${JSON.stringify(item, null, 2)}`
        );
      }

      if (!node || !scrollableContentRef.current) {
        continue;
      }

      let nodeTop = node.getBoundingClientRect().top;
      let nodeBottom = node.getBoundingClientRect().bottom;
      let scrollableBoxTop =
        scrollableContentRef.current.getBoundingClientRect().top;

      let isWithinRange =
        nodeTop <= scrollableBoxTop && scrollableBoxTop <= nodeBottom;

      if (isWithinRange) {
        active = item;
      }
    }

    if (active && value !== active.hash) {
      setValue(active.hash);
    }
  }, [value, settingsItems]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1000);

    if (value !== newValue) {
      setValue(newValue);

      let settingsElement = document.getElementById(newValue);
      let parent = scrollableContentRef.current;
      if (settingsElement && parent) {
        let parentTop = parent.getBoundingClientRect().top;
        let scrollTop = parent.scrollTop;
        let settingsElementTop = settingsElement.getBoundingClientRect().top;
        let scrollToTop = scrollTop + settingsElementTop - parentTop;
        parent.scrollTo({
          top: scrollToTop,
          behavior: "smooth",
        });
      }
    }
  };

  const calculateScrollableMaxHeight = () => {
    if (!scrollableContentRef.current) {
      return;
    }

    let top = scrollableContentRef.current.getBoundingClientRect().top;
    let bottom = window.innerHeight;
    let maxHeight = bottom - top;
    setScrollableMaxHeight(maxHeight);
  };

  useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current);
    },
    []
  );

  useEffect(() => {
    calculateScrollableMaxHeight();
    window.addEventListener("resize", calculateScrollableMaxHeight);
    return () => {
      window.removeEventListener("resize", calculateScrollableMaxHeight);
    };
  });

  useThrottledOnScroll(
    settingsItems.length > 0 ? findActiveIndex : undefined,
    166
  );

  const settingsGridStyles: Record<string, SxProps<Theme> | undefined> = {
    content: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "24px",
    },
    spacer: {
      flexGrow: 1,
    },
    tabs: {
      "& .MuiTabs-indicator": {
        display: "none",
      },
      width: "60%",
    },
    tab: {
      color: globalTheme.palette.common.black,
      backgroundColor: globalTheme.palette.common.white,
      padding: "8px",
      display: "flex",
      minHeight: "initial",
      textTransform: "none",
      "&.Mui-selected": {
        color: globalTheme.palette.common.white,
        backgroundColor: globalTheme.palette.primary.main,
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: globalTheme.palette.primary.main,
        },
        "&:focus": {
          color: globalTheme.palette.common.white,
          backgroundColor: globalTheme.palette.primary.main,
        },
      },
      "&:hover": {
        backgroundColor: globalTheme.palette.common.white,
      },
      "&:focus": {
        color: globalTheme.palette.common.black,
        backgroundColor: globalTheme.palette.common.white,
      },
    },
    tabContent: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
    },
    tabText: {
      flexGrow: 1,
      textAlign: "left",
      marginRight: "auto",
      fontSize: "inherit",
      ml: 1,
    },
    tabIcon: {
      color: "white",
      pr: 1,
      alignSelf: "center",
    },
    scrollableContent: {
      maxHeight: `${scrollableMaxHeight}px`,
      flexGrow: 1,
      overflowY: "scroll",
      scrollbarWidth: "none",
      msOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        width: "0",
      },
    },
    ghostCard: {
      height: "100%",
      backgroundColor: "transparent",
      mb: "10px",
    },
  };

  return (
    <Box data-testid="settingsGrid" sx={settingsGridStyles.content}>
      <Tabs
        orientation="vertical"
        sx={settingsGridStyles.tabs}
        value={value}
        onChange={handleChange}
      >
        {settingsItems.map((item) => {
          if (item.hidden) {
            return;
          }

          return (
            <Tab
              disableFocusRipple={true}
              disableRipple={true}
              key={item.hash}
              label={
                <Box sx={settingsGridStyles.tabContent}>
                  <Typography sx={settingsGridStyles.tabText}>
                    {item.label}
                  </Typography>
                  <Box sx={settingsGridStyles.spacer} />
                  <ChevronRightIcon sx={settingsGridStyles.tabIcon} />
                </Box>
              }
              sx={settingsGridStyles.tab}
              value={item.hash}
            />
          );
        })}
      </Tabs>
      <Box ref={scrollableContentRef} sx={settingsGridStyles.scrollableContent}>
        {settingsItems.map((item) => (
          <Box key={item.hash}>{item.content}</Box>
        ))}
        {settingsItems.length > 1 && <Box sx={settingsGridStyles.ghostCard} />}
      </Box>
    </Box>
  );
};

export default SettingsGridComponent;
