import React from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SxProps,
  TableCell,
  TableRow,
  Theme,
} from "@mui/material/";
import { useTheme } from "@mui/material/styles";
import { ProductTaxCondition } from "../../../../interfaces/settings/Taxes";
import { productTypes } from "../../../../constants/productTypes";
import StyledSwitchComponent from "../../../styled/StyledSwitch";
import CancelIcon from "@mui/icons-material/Cancel";
import Decimal from "decimal.js";

export interface AdvancedTaxConfigurationTableRowProps {
  productTaxCondition: ProductTaxCondition;
  updateProductTaxCondition: (updatedCondition: ProductTaxCondition) => void;
  clearProductTaxCondition: () => void;
}

export const AdvancedTaxConfigurationTableRowComponent: React.FC<
  AdvancedTaxConfigurationTableRowProps
> = ({
  productTaxCondition,
  updateProductTaxCondition,
  clearProductTaxCondition,
}) => {
  const globalTheme = useTheme();
  const handleInputChange = (
    field: keyof ProductTaxCondition,
    value: string | number | boolean
  ) => {
    updateProductTaxCondition({
      ...productTaxCondition,
      [field]: value,
    });
  };

  const advancedTaxConfigurationTableRowStyles: Record<
    string,
    SxProps<Theme>
  > = {
    deleteButton: {
      "&:hover": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
      "&:active": {
        outline: "none",
        border: "none",
      },
      "&.Mui-focusVisible": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
      "&:focus": {
        backgroundColor: "transparent",
        color: "#000000",
      },
    },
    deleteIcon: {
      color: globalTheme.palette.common.inactive,
    },
    productTypeCell: {
      width: "30%",
      fontSize: 12,
      borderRight: 1,
      borderColor: globalTheme.palette.common.inactive,
      p: 1,
    },
    taxRateCell: {
      width: "15%",
      fontSize: 12,
      borderRight: 1,
      borderColor: globalTheme.palette.common.inactive,
      p: 1,
    },
    thresholdCell: {
      width: "10%",
      fontSize: 12,
      borderRight: 1,
      borderColor: globalTheme.palette.common.inactive,
      p: 1,
    },
    aboveThresholdCell: {
      width: "20%",
      fontSize: 12,
      borderRight: 1,
      borderColor: globalTheme.palette.common.inactive,
      p: 1,
    },
    aboveThresholdBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    },
    deleteCell: {
      width: "5%",
      fontSize: 12,
    },
    productTypeInactive: {
      color: globalTheme.palette.common.inactive,
    },
    productTypeActive: {
      color: "inherit",
    },
  };

  return (
    <TableRow>
      <TableCell sx={advancedTaxConfigurationTableRowStyles.productTypeCell}>
        <Select
          displayEmpty
          fullWidth
          sx={
            productTaxCondition.productType ?
              advancedTaxConfigurationTableRowStyles.productTypeActive
            : advancedTaxConfigurationTableRowStyles.productTypeInactive
          }
          value={productTaxCondition.productType}
          variant="outlined"
          onChange={(e) =>
            handleInputChange("productType", e.target.value as string)
          }
        >
          <MenuItem disabled value="">
            Please Select a Product Type
          </MenuItem>
          {productTypes.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell sx={advancedTaxConfigurationTableRowStyles.taxRateCell}>
        <OutlinedInput
          defaultValue={0}
          disabled={!productTaxCondition.productType}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          inputProps={{ min: 0 }}
          placeholder="0.0"
          type="number"
          value={new Decimal(productTaxCondition.taxRate || 0).mul(100)}
          onChange={(e) => {
            const inputValue = e.target.value;
            const parsedValue =
              inputValue ? new Decimal(inputValue).div(100) : new Decimal(0);
            handleInputChange("taxRate", parsedValue.toNumber());
          }}
        />
      </TableCell>

      <TableCell sx={advancedTaxConfigurationTableRowStyles.thresholdCell}>
        <OutlinedInput
          disabled={!productTaxCondition.productType}
          inputProps={{ min: 0 }}
          placeholder="0.00"
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          type="number"
          value={productTaxCondition.threshold || ""}
          onChange={(e) =>
            handleInputChange("threshold", parseFloat(e.target.value))
          }
        />
      </TableCell>

      <TableCell sx={advancedTaxConfigurationTableRowStyles.aboveThresholdCell}>
        <Box sx={advancedTaxConfigurationTableRowStyles.aboveThresholdBox}>
          <StyledSwitchComponent
            checked={productTaxCondition.onlyTaxAboveThreshold || false}
            disabled={
              !productTaxCondition.productType || !productTaxCondition.threshold
            }
            onChange={(e) =>
              handleInputChange("onlyTaxAboveThreshold", e.target.checked)
            }
          />
        </Box>
      </TableCell>

      <TableCell sx={advancedTaxConfigurationTableRowStyles.deleteCell}>
        <IconButton
          disableRipple
          disabled={!productTaxCondition.productType}
          size="small"
          sx={advancedTaxConfigurationTableRowStyles.deleteButton}
          onClick={clearProductTaxCondition}
        >
          <CancelIcon sx={advancedTaxConfigurationTableRowStyles.deleteIcon} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
