import React from "react";
import { Box, Grid, Typography, SxProps } from "@mui/material";

import { Location } from "../../interfaces/locations/Location";
import LocationOwnerComponent from "./LocationOwner";
import LocationLogoComponent from "./LocationLogo";
import { Link } from "react-router-dom";
import { paths } from "../../constants/paths";
import LocationProgressBarComponent from "./LocationProgressBar";
import LocationInviteButtonComponent from "./LocationInviteButton";

interface LocationGridRowProps {
  location: Location;
  setShowModal: (showModal: boolean, location?: Location) => void;
}

const LocationGridRowComponent: React.FC<LocationGridRowProps> = ({
  location,
  setShowModal,
}) => {
  const formatAddress = () => {
    const line1 =
      location.contact?.address?.line1 ? location.contact?.address?.line1 : "";
    const line2 =
      location.contact?.address?.line2 ? location.contact?.address?.line2 : "";
    return line1 + " " + line2;
  };

  const locationGridRowStyles: Record<string, SxProps> = {
    locationGridRow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "10px",
      userSelect: "text",
      width: "100%",
      borderBottom: "3px solid #F4F4F6",
    },
    storeOwner: {
      display: "flex",
      alignItems: "center",
      textAlign: "center",
      color: "primary.main",
      background: "#edfafd",
      borderRadius: "4px",
      padding: "5px",
      width: "65%",
      margin: "auto",
    },
    inviteButton: {
      alignItems: "center",
      height: "50%",
      fontSize: "10px",
    },
    logoImage: {
      height: "35%",
      width: "35%",
      margin: "auto",
      border: "0.1px solid lightgray",
    },
    logoContainer: {
      padding: "5px",
    },
    activeLocationBox: {
      borderRadius: "4px",
      fontSize: "12px",
      textAlign: "center",
      backgroundColor: "#00C19C",
      display: "inline-block",
      p: 1,
      color: "white",
    },
    activeLocationText: {
      fontSize: "12px",
      color: "white",
    },
  };

  const readyToInvite = () => {
    return (
      !location.setupWizard.isFinishAndInviteUsersCompleted &&
      location.setupWizard.arePaymentsSetup &&
      location.setupWizard.isBrandingSetup &&
      location.setupWizard.areUsersSetup &&
      location.setupWizard.areSettingsSetup &&
      location.setupWizard.areTaxesSetup
    );
  };

  return (
    <Box sx={locationGridRowStyles.locationGridRow}>
      <Grid item xs={1}>
        <Box sx={locationGridRowStyles.logoContainer}>
          <LocationLogoComponent
            location={location}
            logoStyle={locationGridRowStyles.logoImage}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Link to={paths.LocationDetails + "/" + location.locationId}>
          {location.locationId}
        </Link>
      </Grid>
      <Grid item xs={2}>
        {formatAddress()}
      </Grid>
      <Grid item xs={1}>
        {location.contact?.address?.city}
      </Grid>
      <Grid item xs={1}>
        {location.contact?.address?.state}
      </Grid>
      <Grid item xs={1}>
        {location.contact?.address?.zipCode}
      </Grid>
      <Grid item xs={1}>
        <Box sx={locationGridRowStyles.storeOwner}>
          <LocationOwnerComponent location={location}></LocationOwnerComponent>
        </Box>
      </Grid>
      <Grid item xs={2}>
        {location.isActive ?
          <Box sx={locationGridRowStyles.activeLocationBox}>
            <Typography sx={locationGridRowStyles.activeLocationText}>
              Active
            </Typography>
          </Box>
        : readyToInvite() ?
          <Box sx={locationGridRowStyles.inviteButton}>
            <LocationInviteButtonComponent
              location={location}
              setShowModal={setShowModal}
            />
          </Box>
        : <LocationProgressBarComponent
            arePaymentsSetup={location.setupWizard.arePaymentsSetup}
            areSettingsSetup={location.setupWizard.areSettingsSetup}
            areTaxesSetup={location.setupWizard.areTaxesSetup}
            areUsersSetup={location.setupWizard.areUsersSetup}
            isBrandingSetup={location.setupWizard.isBrandingSetup}
          />
        }
      </Grid>
    </Box>
  );
};

export default LocationGridRowComponent;
