import React from "react";
import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@mui/material/";
import { useTheme } from "@mui/material/styles";
import { AdvancedSpecialTaxConfigurationTableRowComponent } from "./AdvancedSpecialTaxConfigurationTableRow";
import { ProductTaxEventCondition } from "../../../../interfaces/settings/Taxes";

export interface AdvancedSpecialTaxConfigurationTableProps {
  taxId: string;
  productTaxEventConditions: ProductTaxEventCondition[];
  setProductTaxEventConditions: React.Dispatch<
    React.SetStateAction<ProductTaxEventCondition[]>
  >;
}

export const AdvancedSpecialTaxConfigurationTableComponent: React.FC<
  AdvancedSpecialTaxConfigurationTableProps
> = ({ taxId, productTaxEventConditions, setProductTaxEventConditions }) => {
  const globalTheme = useTheme();

  const updateProductTaxEventCondition = (
    id: string,
    updatedCondition: ProductTaxEventCondition
  ) => {
    setProductTaxEventConditions((prevConditions: ProductTaxEventCondition[]) =>
      prevConditions.map((condition) =>
        condition.id === id ? { ...condition, ...updatedCondition } : condition
      )
    );
  };

  const clearProductTaxEventCondition = (id: string) => {
    setProductTaxEventConditions((prevConditions) =>
      prevConditions.map((condition) =>
        condition.id === id ?
          {
            ...condition,
            productType: "",
            taxRate: undefined,
            taxId: condition.taxId,
          }
        : condition
      )
    );
  };

  const advancedTaxConfigurationTableStyles: Record<string, SxProps<Theme>> = {
    productTypeCell: {
      width: "40%",
      fontSize: 12,
      fontWeight: "bold",
      borderRight: 1,
      borderColor: globalTheme.palette.common.inactive,
    },
    taxRateCell: {
      width: "25%",
      fontSize: 12,
      fontWeight: "bold",
      borderRight: 1,
      borderColor: globalTheme.palette.common.inactive,
    },
    deleteCell: {
      width: "5%",
      fontSize: 12,
      fontWeight: "bold",
    },
    thresholdCellBox: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
    },
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={advancedTaxConfigurationTableStyles.productTypeCell}>
              Product Type
            </TableCell>
            <TableCell sx={advancedTaxConfigurationTableStyles.taxRateCell}>
              Tax Rate
            </TableCell>
            <TableCell
              sx={advancedTaxConfigurationTableStyles.deleteCell}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productTaxEventConditions
            .filter((p) => p.taxId === taxId)
            .map((p) => (
              <AdvancedSpecialTaxConfigurationTableRowComponent
                clearProductTaxEventCondition={() =>
                  clearProductTaxEventCondition(p.id)
                }
                key={p.id}
                productTaxEventCondition={p}
                updateProductTaxEventCondition={(
                  updatedCondition: ProductTaxEventCondition
                ) => updateProductTaxEventCondition(p.id, updatedCondition)}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
