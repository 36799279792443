import React, { ReactElement } from "react";
import Alert, { AlertColor, AlertProps } from "@mui/material/Alert";
import { SvgIconProps } from "@mui/material/SvgIcon";
import { SxProps, Theme } from "@mui/material";

interface GenericAlertProps {
  open: boolean;
  message: string;
  severity: AlertColor;
  icon?: ReactElement<SvgIconProps>;
  variant?: AlertProps["variant"];

  onClose?: () => void;
}

const genericAlertStyles: Record<string, SxProps<Theme>> = {
  alert: {
    position: "fixed",
    top: "10%",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1000,
    maxWidth: "600px",
    width: "auto",
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const GenericAlertComponent: React.FC<GenericAlertProps> = ({
  icon,
  open,
  message,
  severity,
  variant = "filled",
  onClose,
}) => {
  if (!open) return null;

  return (
    <Alert
      data-testid="alertComponent"
      icon={icon}
      severity={severity}
      sx={genericAlertStyles.alert}
      variant={variant}
      onClose={onClose}
    >
      {message}
    </Alert>
  );
};
