import { Box, SxProps, Theme, Typography } from "@mui/material";
import React from "react";

export const UserImportHeaderComponent: React.FC = () => {
  const userImportHeaderStyles: Record<string, SxProps<Theme> | undefined> = {
    userImportBody: {
      fontSize: "16px",
      letterSpacing: "-0.5px",
      mt: 1,
    },
    userImportHeading: {
      fontSize: "24px",
      letterSpacing: "-0.5px",
    },
    userImportTextContainer: {
      textAlign: "center",
      flex: "none",
      mb: 2,
    },
  };

  return (
    <Box sx={userImportHeaderStyles.userImportTextContainer}>
      <Typography sx={userImportHeaderStyles.userImportHeading}>
        Import Users
      </Typography>
      <Typography sx={userImportHeaderStyles.userImportBody}>
        Select the users that you wish to import into Rics Retail. In order to
        access the point-of-sale system, users must have a valid email address
        added to their record.
      </Typography>
    </Box>
  );
};
