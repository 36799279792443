import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect } from "react";
import { roleNames, roles } from "../../constants/roles";
import { getVersion } from "../../helpers/apiVersionsHelpers";
import { Location } from "../../interfaces/locations/Location";
import {
  Identity,
  Rics9User,
  ScopedRole,
  User,
} from "../../interfaces/users/User";
import { useDataService } from "../../providers/DataServiceProvider";
import { usePortal } from "../../providers/PortalProvider";
import { useXhrService } from "../../providers/XhrServiceProvider";
import { UserImportFooterComponent } from "./UserImportFooter";
import { UserImportGridComponent } from "./UserImportGrid";
import { UserImportHeaderComponent } from "./UserImportHeader";
import { useUsersContext } from "../../providers/UsersProvider";
import {
  ApiUser,
  GetRics9UserResponse,
} from "../../interfaces/responses/GetRics9UsersResponse";
import { Box, Modal, SxProps, Theme } from "@mui/material";
import { ErrorResponse } from "../../interfaces/responses/ErrorResponse";

export interface UserImportModalProps {
  userImportOpen: boolean;
  setUserImportOpen: (value: boolean) => void;
  locationId?: string;
  onImportSuccess: () => void;
}

export const UserImportModalComponent: React.FC<UserImportModalProps> = ({
  userImportOpen,
  setUserImportOpen,
  locationId,
  onImportSuccess,
}) => {
  const xhrService = useXhrService();
  const dataService = useDataService();
  const flags = useFlags();
  const portal = usePortal();
  const { rics9Users, setRics9Users } = useUsersContext();
  const handleCloseModal = () => {
    setUserImportOpen(false);
    setRics9Users(Array<User>());
  };

  useEffect(() => {
    const fetchData = async () => {
      if (userImportOpen) {
        try {
          await getRics9Token();
          await getUsersFromRics9();
        } catch (error) {
          console.error("Error in fetching data:", error);
        }
      }
    };

    fetchData();
  }, [userImportOpen]);

  const getUsersFromRics9 = async () => {
    portal!.navigation.isLoading(true);
    try {
      const usersFromRics9 = await getRics9Users();

      const filteredUsers = usersFromRics9.filter(
        (user) =>
          !portal!.state.tenantIdentities.some(
            (tenantIdentity) => tenantIdentity.rics9UserId === user.ricsUserId
          )
      );
      const mappedUsers = filteredUsers.map((user) => {
        const hasLocationId = !!locationId;

        const mappedUser: User = {
          userId: "",
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          login: user.login,
          phoneNumber: user.phoneNumber,
          ricsUserId: user.ricsUserId,
          ricsXIdentityId: user.ricsXIdentityId ?? "",
          locations: hasLocationId ? [locationId!] : [],
          roles: roles.filter((r) => r.name === "Salesperson"),
          selectedToImport: false,
          selectedToEdit: false,
          errorString: "",
          isValid: false,
          isActivated: false,
          isVerified: false,
          isSelected: false,
        };
        return mappedUser;
      });

      setRics9Users(mappedUsers);
    } catch (error) {
      console.error("Error getting users from Rics9:", error);
    } finally {
      portal!.navigation.isLoading(false);
    }
  };

  const getRics9Token = async (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (portal!.state.rics9Token) {
        resolve();
        return;
      }

      const endpoint = "v2/Rics9/ConfigurationToken";
      const onSuccess = (responseString: string) => {
        const response = JSON.parse(responseString);
        portal!.state.rics9Token = response.rics9Token;
        resolve();
      };

      const onFailure = () => {
        reject(new Error("Failed to get Rics9 token"));
      };

      xhrService!.doRicsApiXhr(endpoint, null, onSuccess, onFailure, "GET");
    });
  };

  const getRics9Users = async (): Promise<Rics9User[]> => {
    return new Promise((resolve, reject) => {
      const payload = {};
      const onSuccess = (responseString: string) => {
        const response: GetRics9UserResponse = JSON.parse(responseString);
        if (response.Users && Array.isArray(response.Users)) {
          const usersWithCamelCase = response.Users.map((apiUser: ApiUser) => {
            const convertedUser: Rics9User = {
              email: apiUser.Email,
              firstName: apiUser.FirstName,
              lastName: apiUser.LastName,
              login: apiUser.Login,
              phoneNumber: apiUser.PhoneNumber,
              ricsUserId: apiUser.RicsUserId,
              ricsXIdentityId: apiUser.RicsXIdentityId,
            };
            return convertedUser;
          });
          resolve(usersWithCamelCase);
        } else {
          reject(new Error("Invalid response format"));
        }
      };

      const onFailure = () => {
        reject(new Error("Failed to get Rics9 users"));
      };

      xhrService!.doRics9Xhr(
        portal!.state.rics9Token,
        "Manufacturer/GetUsersToImport",
        payload,
        onSuccess,
        onFailure
      );
    });
  };
  const createIdentities = (finalSuccess: () => void) => {
    const selectedUsers = Array<User>();
    for (let i = 0; i < rics9Users.length; i++) {
      if (rics9Users[i].selectedToImport === true) {
        selectedUsers.push(rics9Users[i]);
      }
    }

    let count = -1;
    const failedIdentities = Array<User>();

    const onFailure = () => {
      portal!.navigation.isLoading(false);
    };

    const onNext = () => {
      count++;
      if (count >= selectedUsers.length) {
        if (failedIdentities.length > 0) {
          onFailure();
        } else {
          finalSuccess();
        }
        return;
      }

      const currentEmployee = selectedUsers[count];
      const failureWrapper = (response: string) => {
        const parsedResponse: ErrorResponse = JSON.parse(response);
        const errorString = JSON.stringify(parsedResponse.title);
        currentEmployee.errorString = errorString;
        failedIdentities.push(currentEmployee);

        onNext();
      };
      createIdentity(currentEmployee, onNext, failureWrapper);
    };

    onNext();
  };

  const createIdentity = (
    userToCreate: User,
    resolve: () => void,
    reject: (errorResponse: string) => void
  ) => {
    const ownerRole = userToCreate.roles.find(
      (r) => r.name === roleNames.Owner
    );
    const locationIds = Array<string>();
    userToCreate.locations.forEach((locationId) => {
      if (locationId) {
        locationIds.push(locationId);
      }
    });

    const userRoles: Array<ScopedRole> = [];
    userToCreate.roles.forEach((r) => {
      if (ownerRole) {
        const scopedRole = {
          tenantId: portal!.state.tenantInfo.tenantId,
          identityRole: r.name,
        } as ScopedRole;
        userRoles.push(scopedRole);
        return;
      }

      locationIds.forEach((l) => {
        const scopedRole = {
          tenantId: portal!.state.tenantInfo.tenantId,
          identityRole: r.name,
          locationId: l,
        } as ScopedRole;
        userRoles.push(scopedRole);
      });
    });

    const identity: Identity = {
      identityId: userToCreate.userId,
      emailAddress: userToCreate.email,
      firstName: userToCreate.firstName,
      lastName: userToCreate.lastName,
      phoneNumber: userToCreate.phoneNumber,
      rics9UserId: userToCreate.ricsUserId,
      tenants: [portal!.state.tenantInfo.tenantId],
      roles: userRoles,
      isActivated: userToCreate.isActivated,
      isVerified: userToCreate.isVerified,
    };

    const onSuccess = (newIdentity: Identity) => {
      sendWelcomeEmails(newIdentity.identityId as string, locationIds, resolve);
    };

    const onFailure = (errorResponse: string) => {
      reject(errorResponse);
    };

    const version = getVersion(flags);
    dataService.identities.saveIdentity(
      identity,
      true,
      version,
      onSuccess,
      onFailure
    );
  };

  const sendWelcomeEmails = (
    identityId: string,
    locationIds: (string | undefined)[],
    onResolve: () => void
  ) => {
    let count = -1;

    const onNext = () => {
      count++;
      if (count >= locationIds.length) {
        onResolve();
        return;
      }

      if (locationIds[count]) {
        const currentLocation = portal!.state.locations.find(
          (x: Location) => x.locationId === locationIds[count]
        );

        if (currentLocation!.isActive) {
          sendWelcomeEmail(identityId, locationIds[count] as string, onNext);
        } else {
          onNext();
        }
      }
    };

    onNext();
  };

  const sendWelcomeEmail = (
    identityId: string,
    locationId: string,
    onSuccess: () => void
  ) => {
    const endpoint = "v1/Auth/SendEmailVerification";

    let baseUrl = process.env.REACT_APP_PORTAL_BASE_URL;
    baseUrl = baseUrl?.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl;
    const redirectUri = `${baseUrl}/password/`;

    const payload = {
      identityId: identityId,
      tenantId: portal!.state.tenantInfo.tenantId,
      locationId: locationId,
      redirectUri: redirectUri,
    };

    xhrService!.doRicsApiXhr(endpoint, payload, onSuccess, undefined, "PUT");
  };

  const importUsers = () => {
    portal!.navigation.isLoading(true);
    const version = getVersion(flags);

    const onFinalSuccess = () => {
      locationId ?
        dataService!.enqueueLocationForUpdate(locationId)
      : (portal!.state.shouldUpdateAllLocations = true);
      const successCallback = () => {
        onImportSuccess();
        handleCloseModal();
        portal!.navigation.isLoading(false);
      };

      setRics9Users(Array<User>());
      dataService!.refreshTenantIdentities(
        successCallback,
        successCallback,
        version,
        undefined
      );
    };

    createIdentities(onFinalSuccess);
  };

  const userImportModalStyles: Record<string, SxProps<Theme> | undefined> = {
    userImportModalContainer: {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      width: "75%",
      height: "75%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      maxHeight: "100%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      p: 4,
      borderRadius: "8px",
      boxShadow: "none",
    },
  };

  return (
    <Modal
      data-testid="userImportModal"
      open={userImportOpen}
      onClose={handleCloseModal}
    >
      <Box sx={userImportModalStyles.userImportModalContainer}>
        <UserImportHeaderComponent />
        <UserImportGridComponent locationId={locationId} />
        <UserImportFooterComponent importUsers={importUsers} />
      </Box>
    </Modal>
  );
};
