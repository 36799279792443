import React, { useEffect, useState } from "react";
import { useUsersContext } from "../../../providers/UsersProvider";
import { usePortal } from "../../../providers/PortalProvider";
import { useDataService } from "../../../providers/DataServiceProvider";
import { Box, Grid, SxProps, Theme } from "@mui/material";
import PrimaryButtonComponent from "../../styled/PrimaryButton";
import { getVersion } from "../../../helpers/apiVersionsHelpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import { User } from "../../../interfaces/users/User";
import { useAlert } from "../../../providers/AlertProvider";
import ErrorIcon from "@mui/icons-material/Error";

export interface AddExistingUserFooterProps {
  handleCloseModal: () => void;
  locationId: string;
  unassignedUsers: User[];
}

export const AddUserFooterComponent: React.FC<AddExistingUserFooterProps> = ({
  handleCloseModal,
  locationId,
  unassignedUsers,
}) => {
  const portal = usePortal();
  const dataService = useDataService();
  const flags = useFlags();
  const { updateUser } = useUsersContext();
  const { showAlert } = useAlert();
  const [selectedUserCount, setSelectedUserCount] = useState(
    unassignedUsers.filter((user) => user.selectedToImport).length
  );
  useEffect(
    () =>
      setSelectedUserCount(
        unassignedUsers.filter((user) => user.selectedToImport).length
      ),
    [unassignedUsers]
  );

  const addUsersToLocation = async () => {
    portal!.navigation.isLoading(true);

    const selectedUsers = unassignedUsers.filter((x) => x.selectedToImport);

    const version = getVersion(flags);

    try {
      for (const user of selectedUsers) {
        await new Promise<void>((resolve, reject) => {
          dataService.identities.addRoleToIdentity(
            user.userId,
            portal!.state.tenantInfo.tenantId,
            locationId,
            user.roles[0].name,
            version,
            resolve,
            reject
          );
        });
        const updatedUser = {
          ...user,
          locations: [...user.locations, locationId],
        };
        updateUser(user.userId, updatedUser);
      }

      dataService!.enqueueLocationForUpdate(locationId);
    } catch (error) {
      alert();

      showAlert(
        "Something unexpected went wrong adding these users to this location. Please try again.",
        "error",
        <ErrorIcon />
      );
    } finally {
      handleCloseModal();
      portal!.navigation.isLoading(false);
    }
  };
  const addExistingUserFooterStyles: Record<string, SxProps<Theme>> = {
    addExistingUserFooterContainer: {
      height: "50px",
      width: "100%",
      bottom: "0",
      mt: 2,
      backgroundColor: "#FFF",
    },
    addExistingUserFooterGrid: {
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
  return (
    <Box sx={addExistingUserFooterStyles.addExistingUserFooterContainer}>
      <Grid
        container
        sx={addExistingUserFooterStyles.addExistingUserFooterGrid}
      >
        <Grid item>
          <PrimaryButtonComponent onClick={handleCloseModal}>
            Cancel
          </PrimaryButtonComponent>
        </Grid>
        <Grid item>
          <PrimaryButtonComponent
            color="primary"
            data-testid="addExistingUsersButton"
            disabled={selectedUserCount < 1}
            variant="contained"
            onClick={addUsersToLocation}
          >
            Add {selectedUserCount} User(s)
          </PrimaryButtonComponent>
        </Grid>
      </Grid>
    </Box>
  );
};
