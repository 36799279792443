import React, { useEffect, useState } from "react";
import { UserImportCounterComponent } from "./UserImportCounter";
import { useUsersContext } from "../../providers/UsersProvider";

import { Box, Button, Grid, SxProps, Theme } from "@mui/material";

export interface UserImportProps {
  importUsers: () => void;
}

export const UserImportFooterComponent: React.FC<UserImportProps> = ({
  importUsers,
}) => {
  const { rics9Users } = useUsersContext();
  const [selectedUserCount, setSelectedUserCount] = useState(
    rics9Users.filter((user) => user.selectedToImport).length
  );
  useEffect(
    () =>
      setSelectedUserCount(
        rics9Users.filter((user) => user.selectedToImport).length
      ),
    [rics9Users]
  );
  const userImportFooterStyles: Record<string, SxProps<Theme> | undefined> = {
    userImportFooterContainer: {
      height: "50px",
      width: "100%",
      bottom: "0",
      mt: 2,
      backgroundColor: "#FFF",
    },
    userImportFooterGrid: {
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
  return (
    <Box sx={userImportFooterStyles.userImportFooterContainer}>
      <Grid container sx={userImportFooterStyles.userImportFooterGrid}>
        <Grid item>
          <UserImportCounterComponent selectedUserCount={selectedUserCount} />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            data-testid="importUsersButton"
            disabled={selectedUserCount < 1}
            variant="contained"
            onClick={importUsers}
          >
            Import Users
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
