import React from "react";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SxProps,
  TableCell,
  TableRow,
  Theme,
} from "@mui/material/";
import { useTheme } from "@mui/material/styles";
import { ProductTaxEventCondition } from "../../../../interfaces/settings/Taxes";
import { productTypes } from "../../../../constants/productTypes";
import CancelIcon from "@mui/icons-material/Cancel";
import Decimal from "decimal.js";

export interface AdvancedSpecialTaxConfigurationTableRowProps {
  productTaxEventCondition: ProductTaxEventCondition;
  updateProductTaxEventCondition: (
    updatedCondition: ProductTaxEventCondition
  ) => void;
  clearProductTaxEventCondition: () => void;
}

export const AdvancedSpecialTaxConfigurationTableRowComponent: React.FC<
  AdvancedSpecialTaxConfigurationTableRowProps
> = ({
  productTaxEventCondition,
  updateProductTaxEventCondition,
  clearProductTaxEventCondition,
}) => {
  const globalTheme = useTheme();
  const handleInputChange = (
    field: keyof ProductTaxEventCondition,
    value: string | number | boolean
  ) => {
    updateProductTaxEventCondition({
      ...productTaxEventCondition,
      [field]: value,
    });
  };

  const advancedTaxConfigurationTableRowStyles: Record<
    string,
    SxProps<Theme>
  > = {
    deleteButton: {
      "&:hover": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
      "&:active": {
        outline: "none",
        border: "none",
      },
      "&.Mui-focusVisible": {
        outline: "none",
        border: "none",
        backgroundColor: "transparent",
      },
      "&:focus": {
        backgroundColor: "transparent",
        color: "#000000",
      },
    },
    deleteIcon: {
      color: globalTheme.palette.common.inactive,
    },
    productTypeCell: {
      width: "40%",
      fontSize: 12,
      borderRight: 1,
      borderColor: globalTheme.palette.common.inactive,
      p: 1,
    },
    taxRateCell: {
      width: "25%",
      fontSize: 12,
      borderRight: 1,
      borderColor: globalTheme.palette.common.inactive,
      p: 1,
    },
    deleteCell: {
      width: "5%",
      fontSize: 12,
    },
    productTypeInactive: {
      color: globalTheme.palette.common.inactive,
    },
    productTypeActive: {
      color: "inherit",
    },
  };

  return (
    <TableRow>
      <TableCell sx={advancedTaxConfigurationTableRowStyles.productTypeCell}>
        <Select
          displayEmpty
          fullWidth
          sx={
            productTaxEventCondition.productType ?
              advancedTaxConfigurationTableRowStyles.productTypeActive
            : advancedTaxConfigurationTableRowStyles.productTypeInactive
          }
          value={productTaxEventCondition.productType}
          variant="outlined"
          onChange={(e) =>
            handleInputChange("productType", e.target.value as string)
          }
        >
          <MenuItem disabled value="">
            Please Select a Product Type
          </MenuItem>
          {productTypes.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell sx={advancedTaxConfigurationTableRowStyles.taxRateCell}>
        <OutlinedInput
          defaultValue={0}
          disabled={!productTaxEventCondition.productType}
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          inputProps={{ min: 0 }}
          placeholder="0.0"
          type="number"
          value={new Decimal(productTaxEventCondition.taxRate || 0).mul(100)}
          onChange={(e) => {
            const inputValue = e.target.value;
            const parsedValue =
              inputValue ? new Decimal(inputValue).div(100) : new Decimal(0);
            handleInputChange("taxRate", parsedValue.toNumber());
          }}
        />
      </TableCell>
      <TableCell sx={advancedTaxConfigurationTableRowStyles.deleteCell}>
        <IconButton
          disableRipple
          disabled={!productTaxEventCondition.productType}
          size="small"
          sx={advancedTaxConfigurationTableRowStyles.deleteButton}
          onClick={clearProductTaxEventCondition}
        >
          <CancelIcon sx={advancedTaxConfigurationTableRowStyles.deleteIcon} />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};
