import { Box, SxProps, Theme, Typography, useTheme } from "@mui/material";
import React from "react";
import PrimaryButtonComponent from "../../styled/PrimaryButton";
import LocationsCanOverrideToggleComponent, {
  LocationsCanOverrideToggleProps,
} from "../LocationsCanOverrideToggle";

export interface SettingsCardProps {
  id: string;
  headerText: string;
  subHeaderText: string;
  children?: React.ReactNode;
  canSave?: boolean;
  onSave?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  locationsCanOverrideToggleProps?: LocationsCanOverrideToggleProps;
  disabled?: boolean;
  hidden?: boolean;
}

const SettingsCardComponent: React.FC<SettingsCardProps> = ({
  id,
  children,
  headerText,
  subHeaderText,
  canSave,
  onSave,
  locationsCanOverrideToggleProps,
  disabled,
  hidden,
}) => {
  const globalTheme = useTheme();

  const settingsCardStyles: Record<string, SxProps<Theme> | undefined> = {
    root: {
      display: hidden ? "none" : "flex",
      backgroundColor: "white",
      marginBottom: "10px",
      borderRadius: "8px",
      paddingX: "16px",
      paddingY: "12px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    header: {
      display: "flex",
      alignItems: "start",
      justifyContent: "space-between",
    },
    headerTextHolder: {
      display: "flex",
      flexDirection: "column",
      rowGap: "8px",
    },
    headerText: {
      fontSize: "20px",
      color: globalTheme.palette.common.black,
    },
    subHeaderText: {
      fontSize: "14px",
      color: globalTheme.palette.common.gray,
    },
    content: {
      paddingY: "24px",
    },
  };

  return (
    <Box data-testid={id} id={id} sx={settingsCardStyles.root}>
      <Box sx={settingsCardStyles.container}>
        <Box sx={settingsCardStyles.header}>
          <Box sx={settingsCardStyles.headerTextHolder}>
            <Typography sx={settingsCardStyles.headerText}>
              {headerText}
            </Typography>
            <Typography sx={settingsCardStyles.subHeaderText}>
              {subHeaderText}
            </Typography>
          </Box>
          <LocationsCanOverrideToggleComponent
            {...locationsCanOverrideToggleProps}
          />
        </Box>
        <Box sx={settingsCardStyles.content}>{children}</Box>
        <Box>
          <PrimaryButtonComponent
            data-testid={`saveChangesButton-${id}`}
            disabled={!canSave || disabled}
            onClick={onSave}
          >
            Save Changes
          </PrimaryButtonComponent>
        </Box>
      </Box>
    </Box>
  );
};

export default SettingsCardComponent;
