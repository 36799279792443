import React from "react";

import { Location } from "../../interfaces/locations/Location";
import { usePortal } from "../../providers/PortalProvider";
import { SxProps, Avatar, Theme } from "@mui/material";

interface LocationLogoProps {
  location: Location;
  logoStyle?: SxProps<Theme>;
}

const LocationLogoComponent: React.FC<LocationLogoProps> = ({
  location,
  logoStyle,
}) => {
  const portal = usePortal();
  const getCorrectLogo = (location: Location) => {
    let imageUrl;

    const locationSettings =
      portal!.state.brandingSettings[location.locationId];
    const tenantSettings = portal!.state.brandingSettings["Tenant"];

    if (locationSettings && locationSettings.logos) {
      imageUrl = locationSettings.logos.smallLogoUrl;
    }

    if (!imageUrl && tenantSettings && tenantSettings.logos) {
      imageUrl = tenantSettings.logos.smallLogoUrl;
    }

    if (!imageUrl) {
      if (portal!.state.tenantInfo.images) {
        imageUrl = portal!.state.tenantInfo.images.image500x500;
      }
      imageUrl = location.imageUrl || imageUrl;
    }

    return imageUrl;
  };

  return <Avatar src={getCorrectLogo(location)} sx={logoStyle} />;
};

export default LocationLogoComponent;
