import React from "react";
import TaxJurisdictionRowComponent from "./TaxJurisdictionRow";
import { Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import { useTaxesContext } from "../../../../providers/TaxesProvider";

export const TaxJurisdictionGridComponent = () => {
  const { taxes } = useTaxesContext();

  const taxJurisdictionGridStyles: Record<string, SxProps<Theme>> = {
    headerGridText: {
      fontSize: "14px",
      letterSpacing: "-0.5px",
      fontWeight: "bold",
    },

    taxJurisdictionRowGrid: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      background: "#ffffff",
      color: "#7F7F7F",
      fontSize: "14px",
      letterSpacing: "-0.5px",
    },
  };

  return (
    <>
      <Grid container sx={taxJurisdictionGridStyles.headerGrid}>
        <Grid item xs={8}>
          <Typography sx={taxJurisdictionGridStyles.headerGridText}>
            Tax Jurisdiction
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography sx={taxJurisdictionGridStyles.headerGridText}>
            Tax Rate
          </Typography>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Box sx={taxJurisdictionGridStyles.taxJurisdictionGridContainer}>
        <Grid container sx={taxJurisdictionGridStyles.taxJurisdictionRowGrid}>
          {taxes.map((tax) => {
            return <TaxJurisdictionRowComponent key={tax.taxId} tax={tax} />;
          })}
        </Grid>
      </Box>
    </>
  );
};

export default TaxJurisdictionGridComponent;
