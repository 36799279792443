import React, { useContext, ReactNode } from "react";
import { User } from "../interfaces/users/User";
import { getVersion } from "../helpers/apiVersionsHelpers";
import { useDataService } from "./DataServiceProvider";
import { useFlags } from "launchdarkly-react-client-sdk";
import { usePortal } from "./PortalProvider";
import { Identity } from "../interfaces/users/User";
import { getUserFromIdentity } from "../helpers/identityHelpers";
import { ScopedRole } from "../interfaces/users/User";
import { useAlert } from "./AlertProvider";
import ErrorIcon from "@mui/icons-material/Error";
export interface UsersContextProps {
  readonly toggleAllUserImportCheckedUsers: (status: boolean) => void;
  readonly users: Array<User>;
  readonly setUsers: (users: Array<User>) => void;
  readonly rics9Users: Array<User>;
  readonly setRics9Users: (rics9Users: Array<User>) => void;
  readonly getUsers: () => void;
  readonly updateUser: (id: string, updatedUser: User) => void;
  readonly updateSingleIdentity: (userToUpdate: User) => void;
  readonly updateAllSelectedIdentities: () => void;
}
export const UsersContext = React.createContext<UsersContextProps>({
  toggleAllUserImportCheckedUsers: () => undefined,
  users: new Array<User>(),
  setUsers: () => undefined,
  rics9Users: new Array<User>(),
  setRics9Users: () => undefined,
  getUsers: () => undefined,
  updateUser: () => undefined,
  updateSingleIdentity: () => undefined,
  updateAllSelectedIdentities: () => undefined,
});

type UsersProviderProps = {
  children: ReactNode;
  initialState?: Partial<UsersContextProps>;
};
export const UsersProvider: React.FC<UsersProviderProps> = ({
  children,
  initialState,
}) => {
  const dataService = useDataService();
  const flags = useFlags();
  const portal = usePortal();
  const { showAlert } = useAlert();
  const [users, setUsers] = React.useState<Array<User>>(
    initialState?.users || []
  );
  const [rics9Users, setRics9Users] = React.useState<Array<User>>(
    initialState?.rics9Users || []
  );

  const toggleAllUserImportCheckedUsers = (status: boolean) => {
    const updatedUsers = rics9Users.map((user) => {
      if (user.isValid || !status) {
        return {
          ...user,
          selectedToImport: status,
        };
      }
      return user;
    });

    setRics9Users(updatedUsers);
  };

  const getUsers = () => {
    portal!.navigation.isLoading(true);

    const version = getVersion(flags);
    const onSuccess = (identities: Array<Identity>) => {
      const refreshCurrentIdentity = () => {
        const currentIdentity = portal!.state.tenantIdentities.find(
          (x) => x.identityId === portal!.state.currentUser.identityId
        );

        if (!!currentIdentity) {
          portal!.state.currentUser = currentIdentity;
        }

        portal!.navigation.isLoading(false);
      };

      if (!identities) {
        onFailure();
        return;
      }

      const mappedUsers: Array<User> = [];
      for (let i = 0; i < identities.length; i++) {
        const identity = identities[i];
        mappedUsers.push(getUserFromIdentity(identity));
      }

      setUsers(mappedUsers);
      dataService!.refreshTenantIdentities(
        refreshCurrentIdentity,
        onFailure,
        version,
        undefined
      );
    };

    const onFailure = () => {
      portal!.navigation.isLoading(false);
      showAlert(
        "Something went wrong while trying to retrieve users.",
        "error",
        <ErrorIcon />
      );
      return;
    };

    dataService.identities.getIdentities(version, onSuccess, onFailure);
  };

  const updateUser = (id: string, updatedUser: User) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) => (user.userId === id ? updatedUser : user))
    );
  };

  const updateSingleIdentity = (userToUpdate: User) => {
    portal!.navigation.isLoading(true);

    const onSuccess = () => {
      getUsers();
    };

    const onFailure = () => {
      portal!.navigation.isLoading(false);
      showAlert("Failed to update user's identity.", "error", <ErrorIcon />);
    };

    updateIdentity(userToUpdate, onSuccess, onFailure);
  };

  const updateAllSelectedIdentities = async () => {
    portal!.navigation.isLoading(true);

    const usersToUpdate = users.filter(
      (user) => user.isChecked && !user.isHidden
    );

    try {
      for (const user of usersToUpdate) {
        await new Promise<void>((resolve, reject) => {
          setTimeout(() => {
            updateIdentity(user, resolve, reject);
          }, 100);
        });
      }
      const updatedUsers = users.map((user) => ({
        ...user,
        isChecked: user.isChecked ? false : user.isChecked,
      }));
      setUsers(updatedUsers);
      portal!.navigation.isLoading(false);
    } catch (error) {
      portal!.navigation.isLoading(false);
      showAlert(
        "Something went wrong while trying to update identities.",
        "error",
        <ErrorIcon />
      );
    }
  };

  const updateIdentity = (
    userToUpdate: User,
    onSuccess: () => void,
    onFailure: () => void
  ) => {
    const locationIds = userToUpdate.locations.filter(Boolean);

    const userRoles: Array<ScopedRole> = [];
    userToUpdate.roles.forEach((r) => {
      const scopedRole = {
        tenantId: portal!.state.tenantInfo.tenantId,
        identityRole: r.name,
      } as ScopedRole;

      userRoles.push({ ...scopedRole });

      locationIds.forEach((l) => {
        userRoles.push({ ...scopedRole, locationId: l });
      });
    });

    const identity: Identity = {
      identityId: userToUpdate.userId,
      emailAddress: userToUpdate.email,
      firstName: userToUpdate.firstName,
      lastName: userToUpdate.lastName,
      deletedOn: userToUpdate.deletedOn,
      phoneNumber: userToUpdate.phoneNumber,
      rics9UserId: userToUpdate.ricsUserId,
      tenants: [portal!.state.tenantInfo.tenantId],
      roles: userRoles,
      isActivated: userToUpdate.isActivated,
      isVerified: userToUpdate.isVerified,
    };
    const version = getVersion(flags);
    dataService.identities.saveIdentity(
      identity,
      false,
      version,
      onSuccess,
      onFailure
    );
  };

  const value = {
    toggleAllUserImportCheckedUsers,
    users: users,
    setUsers: setUsers,
    rics9Users,
    setRics9Users,
    getUsers,
    updateUser,
    updateSingleIdentity,
    updateAllSelectedIdentities,
  };

  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};

export const useUsersContext = () => {
  const context = useContext(UsersContext);
  if (context === undefined) {
    throw new Error("useUsersContext must be used within a UsersProvider");
  }
  return context;
};
